import React from 'react';
//import logo from "./assets/icon-logo-browser-extension.svg";
import bubblelogo from "./assets/group-19.svg"

export default class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showhistory: false,
            addressSearchHistory: this.props.addressHistoryArray || [],
            fullAddress: ""
        }
      //  this.props.getAllDatafromCache();
    }



    render() {
        return (
            <nav className="top-header d-flex">
                    <div>
                        <img src={bubblelogo} alt=''/>
                    </div>
{/* {                    <div className="d-flex flex-column address">
                        <span>Home Address</span> 
                        <div>{this.props.address}</div>
                    </div>
                    <div className="d-flex agent t2">
                        <div className="img-crop">
                            <img src={this.props.brokerLogoS3Link} alt="Logo" />
                        </div>
                        <div className="d-flex flex-column">
                            <span className="agent-name">  {this.props.agentName} </span> 
                            <span className="agency-name"> {this.props.brokerName} </span> 
                        </div>
                    </div>} */}
                    <ul className="d-flex flex-row">
                        <li className="orange">
                            Home
                        </li>
                        <li>
                            Plans
                        </li>
                        <li>
                            Testimonials
                        </li>
                    </ul>
                    <button>
                        Login
                    </button>
            </nav>
        )
    }
}