import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
// import WhatIfScenarioDatasCrudTest from "./components/WhatIfScenarioDatasCrudTest";
import * as serviceWorker from './serviceWorker';

// </React.StrictMode>
ReactDOM.render((
  <BrowserRouter>
    <App />
    {/* <CostEstimatorV2 /> */}
    {/* <CostEstimatorV2 /> */}
    {/* <WhatIfScenarioDatasCrudTest /> */}
  </BrowserRouter>
), document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
