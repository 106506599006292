import React from "react";
import Slider from "rc-slider";

import MortgageComponent from "./Mortgage.component";

import ComponentHelper from "./ComponentHelper";
import Utility from "./Utility";
import GlobalVariables from "../GlobalConstantsVariables";
import SVgEditable from './SVGEditable.img';

const CLASS_NAME = ":LifeInsuranceDetails:";

export default class HomeInsuranceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            calculatedCoverage: this.props.calculatedCoverage,
            age: this.props.age,
            birthYear: this.props.birthYear,
            gender: this.props.gender,
            smoker: this.props.smoker,
            clientId: this.props.clientId,
            termDesired: this.props.termDesired,
            hazardScore: "Great",
            radon: "A",
            designatedFault: "A",
            earthquake: "B",
            earthquake2: "B"
        }
        this.pushDataInDBAndCache = this.pushDataInDBAndCache.bind(this);
    }

    componentDidMount() { }

    onAfterChangeMortgage = (value) => {
        let METHOD_NAME = ":onAfterChangeMortgage:";
        let keyInput = 'mortgageAmount';
        console.log("Entered:Mortgage:value ", value, METHOD_NAME, CLASS_NAME);
        this.pushDataInDBAndCache({ 'value': value, 'key': keyInput, keyInput: value });
    }
    onChangeMortgage = (value) => {
        this.setState({ 'mortgageAmount': value });
        this.props.onChangeItem('mortgageAmount', value);
    }
    onAfterChangeLivingsizeSqft = (value) => {
        let METHOD_NAME = ":onAfterChangeLivingsizeSqft:";
        let input4AppDataPersistence = { id: this.props.id, stateCode: this.props.stateCode, livingsizeSqft: value };
        console.log("Entered:livingsizeSqft:value ", value, "# input4AppDataPersistence: ", input4AppDataPersistence, METHOD_NAME, CLASS_NAME);
        let utilityAmountLocal = Utility.calculateUtilityAmount(this.props.stateCode, value);
        this.props.onChangeItem('utilityAmount', utilityAmountLocal);
        this.pushDataInDBAndCache({ 'value': value, 'key': 'livingsizeSqft', keyInput: value });

        // ComponentHelper.calculateAndSaveUtilityAmount(input4AppDataPersistence, this.props.onChangeItem);
    }
    onChangeLivingsizeSqft = (value) => {
        this.setState({ 'livingsizeSqft': value });
        this.props.onChangeItem('livingsizeSqft', value);
    }
    onChangeYearBuilt = e => {
        const key = 'yearBuilt';
        let yearBuilt = e;

        if (isNaN(e)) {
            //value comes from NumberFormat input 
            yearBuilt = e.target.value
        }

        this.setState({ [key]: yearBuilt });
        this.props.onChangeItem(key, yearBuilt);

    }
    onChangeBathrooms = e => {
        const key = 'bath';
        let bathroomValue = e;

        if (isNaN(e)) {
            //value comes from NumberFormat input 
            bathroomValue = e.target.value
        }
        if (!bathroomValue || bathroomValue < 1) {
            return;
        }
        this.setState({ [key]: bathroomValue });
        this.props.onChangeItem(key, bathroomValue);
    }
    onChangeBedrooms = e => {
        const key = 'bed';
        let bedroomValue = e;
        if (isNaN(e)) {
            //value comes from NumberFormat input 
            bedroomValue = e.target.value
        }
        if (!bedroomValue || bedroomValue < 1) {
            return;
        }
        this.setState({ [key]: bedroomValue });
        this.props.onChangeItem(key, bedroomValue);
    }

    pushDataInDBAndCache(inputJson) {
        let METHOD_NAME = ":pushDataInDBAndCache:";
        let stateJson = {};
        let key, value;
        key = inputJson.key; value = inputJson.value;
        delete inputJson.key; delete inputJson.value;
        let localState = this.state;
        localState[key] = value;
        // UI Specific parameters to cache
        stateJson.age = localState.age;
        stateJson.ageBand = localState.ageBand;
        stateJson.birthYear = localState.birthYear;
        stateJson.gender = localState.gender;
        stateJson.health = localState.health;
        stateJson.smoker = localState.smoker;
        stateJson.termDesired = localState.termDesired;
        // stateJson. = this.state.
        stateJson.clientId = this.state.clientId;
        stateJson.id = this.state.clientId;
        stateJson.cacheId = this.state.clientId + "++All";
        stateJson.eventStr = CLASS_NAME.replace(/:/g, '') + 'Update';
        stateJson[key] = value;
        if (stateJson.birthYear != null && stateJson.age == null) {
            stateJson.age = Utility.getAgeFromBirthYear(stateJson.birthYear);
            this.setState({ age: stateJson.age });
        }
        console.log("%$% stateJson:", stateJson, METHOD_NAME, CLASS_NAME);
        try {
            ComponentHelper.updateAppDataAndCache(GlobalVariables.getClientDataCategoryAll(), stateJson);
        } catch (error) {
            METHOD_NAME += ":updateAppDataAndCache:Failed:";
            console.log("%$% error.code: ", error.code, " # stateJson:", stateJson, METHOD_NAME, CLASS_NAME);
            if (error.code === 'ConditionalCheckFailedException') {
                ComponentHelper.saveAppDataAndCache(GlobalVariables.getClientDataCategoryAll(), stateJson);
            } else {
                console.log("%$% PROBLEM PROBLEM Need to handle this code", METHOD_NAME, CLASS_NAME);
            }
        }
        GlobalVariables.setAPIInputJson(GlobalVariables.getPacLifeNextAPITag(), inputJson);
        // ComponentHelper.updateAppDataAndCache("IndividualUpdates", json4DyanoDB); sdsfsdf
        if (this.props.onChangeAmountsFromHash != null) {
            this.refreshDoughnut(key, value);
        }

    }

    refreshDoughnut(changedInputParamName, inputValue) {
        let METHOD_NAME = `:refreshDoughnut:${changedInputParamName}:`;
        let paramName, key, calculatedCoverageLocal;
        console.log("%$% Input:changedInputParamName:", changedInputParamName, "# inputValue:", inputValue, METHOD_NAME, CLASS_NAME);
        console.log("%$% listingPriceAmount:", this.props.listingPriceAmount, METHOD_NAME, CLASS_NAME);
        /*
        Life Insurance field received:
         1. re-calculate life insurance 
         2. Update AppData
        */
        let inputKeyLocal = changedInputParamName;
        if (GlobalVariables.getLifeInsuranceFieldsArray().indexOf(inputKeyLocal) > -1) {
            if (inputKeyLocal === 'calculatedCoverage') {
                // if (true) {
                METHOD_NAME += ":calculatedCoverage:inputValue:" + inputValue;
                calculatedCoverageLocal = inputValue != null && inputValue.toString().length < 4 ? `${inputValue}000` : inputValue;
                calculatedCoverageLocal = typeof calculatedCoverageLocal === 'string' ? Number(calculatedCoverageLocal) : calculatedCoverageLocal;
                calculatedCoverageLocal = Math.round((calculatedCoverageLocal/100000))*100000;
                
                //DATA_RELEASE_VERSION__stateCode__gender__health4LifeInsurance__termDesired__smoker__age__coverageAmount
                // 'data_category': 'app_constant', 'Poor': 3, 'Average': 2, 'Good': 1, 'Excellent': 0
                // IN____________600000
                // v2__stateCode__1__1__20__0__35__coverageAmount
                console.log("%$% this.props.stateCode:", this.props.stateCode, " # key: ", key, " # calculatedCoverageLocal: ", calculatedCoverageLocal, METHOD_NAME, CLASS_NAME);
                let genderLocal = this.state.gender && this.state.gender !== -1 ? this.state.gender : 1;
                let healthLocal = this.state.health && this.state.health !== -1 ? this.state.health : 2;
                switch (healthLocal) {
                    case 0: healthLocal = 3; break;
                    case 1: healthLocal = 2; break;
                    case 2: healthLocal = 1; break;
                    case 3: healthLocal = 0; break;
                    default: healthLocal = 2; break;
                }
                let termDesiredLocal = this.state.termDesired != null ? this.state.termDesired : 20;
                let smokerLocal = this.state.smoker != null && this.state.smoker !== -1 ? this.state.smoker : 1;
                let ageLocal = this.state.birthYear != null && this.state.birthYear !== -1 ? this.state.birthYear : 1990;
                ageLocal = Utility.getAgeFromBirthYear(this.state.birthYear);
                console.log("ageLocal:", ageLocal, METHOD_NAME, CLASS_NAME);
                ageLocal = ageLocal != null && ageLocal.toString().length > 0 ? ageLocal : 30;

                const idForLifeInsuranceAmount = `${GlobalVariables.getDataReleaseVersion()}__${this.props.stateCode}__${genderLocal}__${healthLocal}__${termDesiredLocal}__${smokerLocal}__${ageLocal}__${calculatedCoverageLocal}`;
                // make sure proper address is keyed and is saved in DB to fetch lifeInsuranceAmount
                ComponentHelper.getData(idForLifeInsuranceAmount).then((array) => {
                    METHOD_NAME += ":getData:than:"
                    if (array != null && array.length > 0) {
                        console.log("%$% array: ", array, " #idForLifeInsuranceAmount: ", idForLifeInsuranceAmount, METHOD_NAME, CLASS_NAME);

                        let lifeInsuranceAmount;
                        lifeInsuranceAmount = array[0].lifeInsuranceAmount != null ? array[0].lifeInsuranceAmount : array[0].premiumAmountPerMonth;
                        let inputJson = { 'id': this.props.id, 'lifeInsuranceAmount': lifeInsuranceAmount, 'calculatedCoverage': calculatedCoverageLocal };

                        if (inputKeyLocal === 'birthYear') {
                            inputJson['age'] = ageLocal;
                            inputJson['ageBand'] = Utility.getFormattedAge(inputValue);
                        } else {
                            inputJson[inputKeyLocal] = inputValue;
                        }
                        this.props.onChangeAmountsFromHash('IndividualDetails', inputJson);
                        console.log("%$% lifeInsuranceAmount:", lifeInsuranceAmount, METHOD_NAME, CLASS_NAME);
                        this.setState({ 'lifeInsuranceAmount': lifeInsuranceAmount, 'calculatedCoverage': calculatedCoverageLocal });
                    }
                });
            } else {
                if (this.props.listingPriceAmount == null) {
                    key = `${this.props.id}++calculatedCoverage`;
                    ComponentHelper.getCachedData2(key).then((data) => {
                        METHOD_NAME += ":calculatedCoverage:getCachedData2.then:" + paramName;
                        console.log("%$% data: ", data, METHOD_NAME, CLASS_NAME);
                        let json = JSON.parse(data.Payload);
                        calculatedCoverageLocal = json.body.output;
                        this.setState({ coverage: calculatedCoverageLocal, calculatedCoverage: calculatedCoverageLocal });
                        //DATA_RELEASE_VERSION__stateCode__gender__health4LifeInsurance__termDesired__smoker__age__coverageAmount
                        // IN____________600000
                        // v2__stateCode__1__1__20__0__35__coverageAmount
                        console.log("%$% this.props.stateCode:", this.props.stateCode, " # key: ", key, " # calculatedCoverageLocal: ", calculatedCoverageLocal, METHOD_NAME, CLASS_NAME);
                        this.processLifeInsuranceAmount(calculatedCoverageLocal);
                    });

                } else {
                    // calculate coverageAmount from listingPrice if available
                    let cachedValue1 = Utility.calculateCoverage(this.props.listingPriceAmount);
                    this.setState({ 'calculatedCoverage': cachedValue1 });
                    this.props.onChangeItem('calculatedCoverage', cachedValue1);
                    console.log('%$% calculatedCoverage: ', cachedValue1, METHOD_NAME, CLASS_NAME);
                    //'getQuotesFromPacLifeNext': ["DONE:solicitationState", "DONE:faceAmount", "riskClass", "gender", "premiumFrequency", "dateOfBirth", "productCode"
                    GlobalVariables.setAPIInputJson(GlobalVariables.getPacLifeNextAPITag(), { 'calculatedCoverage': cachedValue1 });
                    this.processLifeInsuranceAmount(inputKeyLocal, inputValue, cachedValue1);
                }
            }
        }
    }

    processLifeInsuranceAmount(inputKeyLocal, inputValue, calculatedCoverageAmount) {
        let METHOD_NAME = ":processLifeInsuranceAmount:";
        let genderLocal = this.state.gender != null && this.state.gender !== -1 ? this.state.gender : 1;
        let healthLocal = this.state.health != null && this.state.health !== -1 ? this.state.health : 2;
        switch (healthLocal) {
            case 0: healthLocal = 3; break;
            case 1: healthLocal = 2; break;
            case 2: healthLocal = 1; break;
            case 3: healthLocal = 0; break;
            default: healthLocal = 2; break;
        }
        let termDesiredLocal = this.state.termDesired != null ? this.state.termDesired : 20;
        let smokerLocal = this.state.smoker != null && this.state.smoker !== -1 ? this.state.smoker : 1;
        let ageLocal = this.state.birthYear != null && this.state.birthYear !== -1 ? this.state.birthYear : 1990;
        ageLocal = Utility.getAgeFromBirthYear(this.state.birthYear);
        console.log("ageLocal:", ageLocal, METHOD_NAME, CLASS_NAME);
        ageLocal = ageLocal != null && ageLocal.toString().length > 0 ? ageLocal : 30;

        const idForLifeInsuranceAmount = `${GlobalVariables.getDataReleaseVersion()}__${this.props.stateCode}__${genderLocal}__${healthLocal}__${termDesiredLocal}__${smokerLocal}__${ageLocal}__${calculatedCoverageAmount}`;
        // make sure proper address is keyed and is saved in DB to fetch lifeInsuranceAmount
        ComponentHelper.getData(idForLifeInsuranceAmount).then((array) => {
            METHOD_NAME += ":getData:than:"
            if (array != null && array.length > 0) {
                console.log("array: ", array, METHOD_NAME, CLASS_NAME);
                let lifeInsuranceAmount;
                lifeInsuranceAmount = array[0].lifeInsuranceAmount != null ? array[0].lifeInsuranceAmount : array[0].premiumAmountPerMonth;
                let inputJson = { 'id': this.props.id, 'lifeInsuranceAmount': lifeInsuranceAmount, 'calculatedCoverage': calculatedCoverageAmount };

                if (inputKeyLocal === 'birthYear') {
                    inputJson['age'] = ageLocal;
                    inputJson['ageBand'] = Utility.getFormattedAge(inputValue);
                } else {
                    inputJson[inputKeyLocal] = inputValue;
                }
                this.props.onChangeAmountsFromHash('IndividualDetails', inputJson);
                console.log("lifeInsuranceAmount:", lifeInsuranceAmount, METHOD_NAME, CLASS_NAME);
                this.setState({ 'lifeInsuranceAmount': lifeInsuranceAmount, 'calculatedCoverage': calculatedCoverageAmount });
            }
        });
    }

    onChangeTermDesired = (value) => {
        const key = 'termDesired';
        this.setState({ [key]: value });
        this.props.onChangeItem(key, value);
    }

    onAfterChangeTermDesired = (inputValue) => {
        let METHOD_NAME = ":onAfterChangeTermDesired:";
        console.log("Entered:: ", inputValue, METHOD_NAME, CLASS_NAME);
        let stateJson = { 'key': 'termDesired', 'value': inputValue, 'termDesired': inputValue };
        this.pushDataInDBAndCache(stateJson);
        const hash = ComponentHelper.getLocalCacheFor(this.state.id);
        hash['id'] = this.state.id;
        hash['termDesired'] = inputValue;
        this.props.onChangeAmountsFromHash('StepDougnutChart', hash);
    }

    render() {
        return (
            <div className="home-insurance">
                <div className="d-flex scores">
                    <div className="hazard">
                        <span className="info">
                            {this.props.fullAddress.substring(5, 24)}
                        </span>
                        <div className="d-flex">
                            <div className="svg-edit">
                                <SVgEditable score="A+" color="00a12f" endAngle="325"/>
                            </div>
                            <div className="d-flex flex-column">
                                <span className="label">Hazard Score</span>
                                <span className="final-score">{this.state.hazardScore}</span>
                            </div>
                        </div>
                    </div>

                    <div className="details">
                        <span className="info">
                            Hazard Score Details
                        </span>
                        <div className="d-flex flex-row scores-row">
                            <div className="d-flex flex-column foodenv">
                                <span className="label">Designated Fault</span>
                                <span>{this.state.designatedFault}</span>
                            </div>
                            <div className="d-flex flex-column famunity">
                                <span className="label">Earthquake</span>
                                <span>{this.state.earthquake}</span>
                            </div>
                            <div className="d-flex flex-column exer">
                                <span className="label">Radon</span>
                                <span>{this.state.radon}</span>
                            </div>
                            <div className="d-flex flex-column medincome">
                                <span className="label">Earthquake</span>
                                <span>{this.state.earthquake2}</span>
                            </div>
                        </div>
                    </div>
                </div>
<div className="mortgage-window">
                <span className="details-label mt-1">
                    Home Details
                </span>

                <div className="d-flex slider-row">
                    <div className="d-flex flex-column fs-slider-block">
                        <div className="d-flex flex-row ">
                            <span className="slider-label">
                                Living Area:
                            </span>
                            <div className="label">
                                {Utility.getFormattedNumberValue(this.props.livingsizeSqft)} Sq Ft &nbsp;
                            </div>
                        </div>
                        <div className="slider-aligned">
                            <Slider
                                min={100}
                                max={10000}
                                step={100}
                                onAfterChange={this.onAfterChangeLivingsizeSqft}
                                onChange={this.onChangeLivingsizeSqft}
                                value={this.props.livingsizeSqft}
                                defaultValue={this.props.livingsizeSqft}
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-column fs-slider-block">
                        <div className="d-flex flex-row ">
                            <span className="slider-label">
                                Year Built:
                            </span>
                            <div className="label">
                                {this.props.yearBuilt} &nbsp;
                            </div>
                        </div>
                        <div className="slider-aligned">
                            <Slider
                                value={this.props.yearBuilt}
                                defaultValue={this.props.yearBuilt}
                                min={1950}
                                max={2015}
                                step={1}
                                onChange={this.onChangeYearBuilt}
                            />
                        </div>
                    </div>

                </div>

                <div className="d-flex slider-row">

                    <div className="d-flex flex-column fs-slider-block">
                        <div className="d-flex flex-row ">
                            <span className="slider-label">
                                Bedrooms:
                            </span>
                            <div className="label">
                                {this.props.bed} &nbsp;
                            </div>
                        </div>
                        <div className="slider-aligned">
                            <Slider
                                value={this.props.bed}
                                defaultValue={this.props.bed}
                                min={0}
                                max={15}
                                step={1}
                                onChange={this.onChangeBedrooms}
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-column fs-slider-block">
                        <div className="d-flex flex-row ">
                            <span className="slider-label">
                                Bathrooms:
                            </span>
                            <div className="label">
                                {this.props.bath} &nbsp;
                            </div>
                        </div>
                        <div className="slider-aligned">
                            <Slider
                                value={this.props.bath}
                                defaultValue={this.props.bath}
                                min={0}
                                max={15}
                                step={0.5}
                                onChange={this.onChangeBathrooms}
                            />
                        </div>
                    </div>
                
                </div>
           
                <span className="details-label">
                    Mortgage Details
                </span>
   
                <MortgageComponent
                    id={this.props.id}
                    avmAmount={this.props.avmAmount || 0}
                    coverageDesired={this.props.coverageDesired}
                    utilityAmount={this.props.utilityAmount}
                    propertyTaxAmount={this.props.propertyTaxAmount}
                    loanType={this.props.loanType}
                    listingPriceAmount={this.props.listingPriceAmount}
                    mortgageAmount={this.props.mortgageAmount}
                    homeInsuranceAmount={this.props.homeInsuranceAmount}
                    lifeInsuranceAmount={this.props.lifeInsuranceAmount}
                    interestRate={this.props.interestRate}
                    mortgageWasInitiated={this.props.mortgageWasInitiated}
                    onChangeAmountsFromHash={this.props.onChangeAmountsFromHash}
                    refreshDataArrayLink={this.props.onChangeDataArray}
                    onChangeItem={this.props.onChangeItem}
                    resetToDefaultValues={this.props.resetToDefaultValues}
                    setDefaultValues={this.props.setDefaultValues}
                />
            </div>
            </div>);
    }
}