import React from "react";

import { Button, Form, Container, Col } from "react-bootstrap";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import loader from "../purple-line-loader1.svg";

const CLASS_NAME = ":Address";
const minLengthAutocomplete = 3;

export default class Address extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: "",
			fullAddress: "",
		};
		this.reactReference = React.createRef();
	}
	render() {
		return (
			<Container className="mt-2">
				{this.props.activeView && (
					<Form>
						<Form.Group controlId="formBasicEmail">
							<Form.Label>Search Address</Form.Label>
							<GooglePlacesAutocomplete
								placeholder="Your home address"
								loader={<img src={loader} alt=""/>}
								ref={(instance) => {
									this.reactReference = instance;
								}}
								onSelect={({ description }) => {
									let METHOD_NAME = "onSelect:";
									console.log(
										"description:",
										description,
										METHOD_NAME,
										CLASS_NAME
									);
									this.setState({
										data: description,
										fullAddress: description,
									});
								}}
								componentRestrictions={{ country: "us" }}
								minLengthAutocomplete={minLengthAutocomplete}
							/>
						</Form.Group>
						<Col className="text-right">
							<Button
								variant="primary"
								type="submit"
								onClick={this.props.updateView}
							>
								<div className="hiddenDiv">{this.state.fullAddress}</div>
								Next
							</Button>
						</Col>
					</Form>
				)}
			</Container>
		);
	}
}
