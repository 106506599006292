import React from 'react';

function polarToCartesian(centerX, centerY, radius, angleInDegrees){
    var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;
  
    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
}

function describeArc(x, y, radius, startAngle, endAngle){
      
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
        "M", start.x, start.y, 
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;       
}

const SVGEditable = ({ color, score, endAngle } ) =>        (
            <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg">
                <path fill="white" d={describeArc(24,24,21,0,+endAngle)} stroke={`#${color}`}  strokeWidth="3"/>
                <text x="12" y="30" id="svg-hazard-score" strokeWidth="3" fill={`#${color}`}>{score}</text>
            </svg>
          )

export default SVGEditable;
