import AWS from "aws-sdk";
import { Amplify } from '@aws-amplify/core';
import { API, graphqlOperation } from 'aws-amplify';
import { createAppData, updateAppData } from '../graphql/mutations';
import { getAppData, listAppDatas } from '../graphql/queries';
// import { updateAppData } from '../graphql/mutations';
import awsExports from "./../aws-exports";
import GlobalConstantsVariables from "../GlobalConstantsVariables";
import Utility from './Utility';
import UIBusinessLogic from "bit-uibl-data-driver";
const CriticalInputHash = UIBusinessLogic.CriticalInput;
const ESTIMATOR_WHATIFS_API1_LAMBDA = 'dwellingcost5-Lambda-SCOMW3US0YHS';
const ESTIMATOR_WHATIFS_API2_LAMBDA = 'WhatIfScenarioLambdaV2';
Amplify.configure(awsExports);
// Initialize the Amazon Cognito credentials provider
AWS.config.region = 'us-west-2'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: CriticalInputHash.IDENTITY_POOL_ID,
});
const CLASS_NAME = "ComponentHelper:";//CAN_BE_CACHED_KEYS 'homeInsuranceAmount', 'lifeInsuranceAmount'
const CAN_BE_CACHED_KEYS = ['stateCode', 'livingsizeSqft', 'calculatedCoverage', 'utilityAmount', 'propertyTaxAmount', 'mortgageAmount', 'avmAmount', 'listingPriceAmount', 'agentBrandingCode'];

let dbId;
let classChartDataArray;
const classLevelHash = {};

function decryptCodes(codesArr, passcode) {
    let result = []; let str = '';
    // let codesArr = content.split('-');
    let passLen = passcode.length;
    for (let i = 0; i < codesArr.length; i++) {
        let passOffset = i % passLen;
        let calAscii = (codesArr[i] - passcode.charCodeAt(passOffset));
        result.push(calAscii);
    }
    for (let i = 0; i < result.length; i++) {
        let ch = String.fromCharCode(result[i]); str += ch;
    }
    return str;
}
export default {
    getDbId: () => {
        const METHOD_NAME = ":getDbId:";
        console.log('Entered', METHOD_NAME, CLASS_NAME)
        return dbId;
    },
    setDbId: (inputFlag) => {
        dbId = inputFlag;
    },
    encryptCodes: (content, passcode) => {
        // console.log("Input:content: ", content);
        var result = []; var passLen = passcode.length;
        for (var i = 0; i < content.length; i++) {
            var passOffset = i % passLen;
            var calAscii = (content.charCodeAt(i) + passcode.charCodeAt(passOffset));
            result.push(calAscii);
        }
        // console.log("Before getUniform..result: ", result);
        return Utility.getUniformNumberString(result);
        // return JSON.stringify(result);
    },
    getDecryptedData: (code, passcode) => {
        let tempVariable;
        tempVariable = Utility.getNumberArrayFrom(code);
        const decrypted = decryptCodes(tempVariable, passcode);
        return decrypted;
    },
    parseQueryParameters: (queryParameterStr, cipher) => {
        // queryParameterStr = "0__34228 Northwind Ter Fremont CA 94555__1205b53167183a61923a12811a86141__os__info__Google Inc.__3.2.5.1550.1195000__Internal__1205b53167183a61923a12811a86141-34228-Northwind-Ter-Fremont-CA";
        // cipher = "localhost:3000";
        const METHOD_NAME = ":parseQueryParameters:";
        let outputQueryParamHash;
        console.log("$#$ queryParameterStr:", queryParameterStr, " # cipher:", cipher, METHOD_NAME, CLASS_NAME);

        if (queryParameterStr != null && queryParameterStr.length > 0) {
            // Input format 
            // ?source=realtor.com/realestateandhomes-detail&fullAddress=4529%20Winona%20Ct%20Denver%20CO%2080212
            // &sourceUrl=https://www.realtor.com/realestateandhomes-detail/4529-Winona-Ct_Denver_CO_80212_M15371-30
            // const urlParams = new URLSearchParams(queryParameterStr);
            // let docketIdStr = urlParams.get('docketId');
            let tempVariable;
            tempVariable = Utility.getNumberArrayFrom(queryParameterStr);
            const decrypted = decryptCodes(tempVariable, cipher);
            let docketIdStr, extension, fullAddress, clientId, os, info, vendor, bedBathSqft, sourceUrl, continuationId;
            docketIdStr = decrypted;
            console.log("$#$ decrypted:", decrypted, METHOD_NAME, CLASS_NAME);

            [extension, fullAddress, clientId, os, info, vendor, bedBathSqft, sourceUrl, continuationId] = decrypted.split('__');
            console.log("$#$ decrypted:", decrypted, METHOD_NAME, CLASS_NAME);
            let bed, bath, livingsizeSqft, listingPriceAmount, yearBuilt;
            console.log("$#$ bedBathSqft:", bedBathSqft, METHOD_NAME, CLASS_NAME);

            try {
                bedBathSqft = bedBathSqft.replace(/\+/g, '');
                // console.log("$$$ bedBathSqft:", bedBathSqft, METHOD_NAME, CLASS_NAME);
                let tempArray = bedBathSqft.split('.');
                console.log("$#$ tempArray:", tempArray, METHOD_NAME, CLASS_NAME);

                // 9..3813.2149000
                // 3.3.1322.205000.2010
                // 9.7!5.3813.2149000
                // Scenario 1:9.8.3813.2149000
                // console.log("$$$ tempArray:", tempArray, METHOD_NAME, CLASS_NAME);
                // 3.2!5.2820.499900.2006
                if (tempArray.length === 5) {
                    [bed, bath, livingsizeSqft, listingPriceAmount, yearBuilt] = tempArray;
                } else if (tempArray.length === 4)
                    [bed, bath, livingsizeSqft, listingPriceAmount] = tempArray;
                else if (tempArray.length === 3)
                    [bed, bath, livingsizeSqft] = tempArray;

                if (bed.indexOf('!') !== -1) bed = bed.replace(/!/g, '.');
                if (bath.indexOf('!') !== -1) bath = bath.replace(/!/g, '.');
                livingsizeSqft = livingsizeSqft.replace(/,/g, '');
                console.log("$#$ livingsizeSqft:", livingsizeSqft, METHOD_NAME, CLASS_NAME);

            } catch (error) {
                console.log("$#$ error:", error, METHOD_NAME, CLASS_NAME);
                [bed, bath, livingsizeSqft, listingPriceAmount] = [0, 0, 0, 0]
            }
            GlobalConstantsVariables.setSource1(extension);
            GlobalConstantsVariables.setFullAddress(fullAddress);
            outputQueryParamHash = {
                docketIdStr: docketIdStr, extension: extension, fullAddress: fullAddress,
                clientId: clientId, os: os, info: info, vendor: vendor, bed: bed, bath: bath, livingsizeSqft: livingsizeSqft, yearBuilt: yearBuilt, sourceUrl: sourceUrl, continuationId: continuationId
            };
            console.log("$#$ listingPriceAmount:", listingPriceAmount, METHOD_NAME, CLASS_NAME);

            if (listingPriceAmount != null) outputQueryParamHash['listingPriceAmount'] = listingPriceAmount;
            console.log("$#$ source1:", GlobalConstantsVariables.getSource1(), " # fullAddress:", GlobalConstantsVariables.getFullAddress(), METHOD_NAME, CLASS_NAME);
        } else {
            console.log("$#$ Invalid Input:queryParameterStr:", queryParameterStr, " &/or cipher: ", cipher, METHOD_NAME, CLASS_NAME);
        }
        console.log("$#$ outputQueryParamHash:", outputQueryParamHash, METHOD_NAME, CLASS_NAME);

        return outputQueryParamHash;
    },
    parseQueryParametersv1: (queryParameterStr) => {
        let METHOD_NAME = ":parseQueryParameters:";
        console.log("queryParameterStr:", queryParameterStr, METHOD_NAME, CLASS_NAME);
        // Input format 
        // ?source=realtor.com/realestateandhomes-detail&fullAddress=4529%20Winona%20Ct%20Denver%20CO%2080212
        // &sourceUrl=https://www.realtor.com/realestateandhomes-detail/4529-Winona-Ct_Denver_CO_80212_M15371-30
        const urlParams = new URLSearchParams(queryParameterStr);
        const source1 = urlParams.get('source');
        const extension = urlParams.get('extension');
        const fullAddress = urlParams.get('fullAddress');
        GlobalConstantsVariables.setSource1(source1);
        GlobalConstantsVariables.setSource1(extension);
        GlobalConstantsVariables.setFullAddress(fullAddress);
        let outputQueryParamHash = { source1: source1, fullAddress: fullAddress, extension: extension };
        console.log("source1:", GlobalConstantsVariables.getSource1(), " # fullAddress:", GlobalConstantsVariables.getFullAddress(), METHOD_NAME, CLASS_NAME);
        return outputQueryParamHash;//docketId
    },
    saveDataAndCalculateAmounts: async (fullAddress, SESSION_ID, extension = '0') => {
        let METHOD_NAME = ":saveDataAndCalculateAmounts:";
        console.log("SESSION_ID:", SESSION_ID, "fullAddress: ", fullAddress, " # extension:", extension, METHOD_NAME, CLASS_NAME);
        let dataCategory = extension != null && extension === '0' ? 'extension_search' : 'address_search';
        let json = { uid: SESSION_ID, fullAddress: fullAddress, eventStr: "searchedAddress", dataCategory: dataCategory }; // created_at: createdAt
        console.log('json:', json, METHOD_NAME, CLASS_NAME);

        // await API.graphql(graphqlOperation(createAppData, { input: json })).then((response2) => {
        //     METHOD_NAME = "API.graphql.then:";
        //     console.log("response2: ", JSON.stringify(response2, null, " "), METHOD_NAME, CLASS_NAME);
        //     let item = {};
        //     if (response2 != null && response2.data != null && (item = response2.data.createAppData) != null) {
        //         console.log("item.id: ", item.id, " # item.uid: ", item.uid, METHOD_NAME, CLASS_NAME);
        //         dbId = item.id;
        //         //one call to calculate and update AppData || Cache
        //         processCalculateAndCache(item);
        //     }
        // });
        // return dbId;
        return await API.graphql(graphqlOperation(createAppData, { input: json }));
    },
    processCalculateAndCache: async (item) => {
        let METHOD_NAME = ":processCalculateAndCache:";
        let lambda = new AWS.Lambda();
        const jsonString = JSON.stringify(item);
        console.log("Entered:Input:item:", item, METHOD_NAME, CLASS_NAME);

        let params = {
            FunctionName: ESTIMATOR_WHATIFS_API2_LAMBDA,
            InvocationType: 'RequestResponse',
            LogType: 'Tail',
            Payload: `{ "fun": "processCalculateAndCache", "fetchViaAPI": "false", "uid": "${item.id}", "dataCategory": "calculated_amounts", "input": ${jsonString}}`
        };
        console.log("Before invoke WhatIfScenarioLambdaV2.processCalculateAndCache -------> : item.id:", item.id, METHOD_NAME, CLASS_NAME);
        return await lambda.invoke(params).promise();
    },
    saveUpdateClientData: async (item) => {
        let METHOD_NAME = ":saveUpdateClientData:";
        let lambda = new AWS.Lambda();
        item.clientId = item.id;
        const jsonString = JSON.stringify(item);
        console.log("Entered:Input:item:", item, METHOD_NAME, CLASS_NAME);

        let params = {
            FunctionName: ESTIMATOR_WHATIFS_API2_LAMBDA,
            InvocationType: 'RequestResponse',
            LogType: 'Tail',
            Payload: `{ "fun": "saveUpdateClientData", "fetchViaAPI": "false", "uid": "${item.id}", "dataCategory": "calculated_amounts", "input": ${jsonString}}`
        };
        console.log("Before invoke WhatIfScenarioLambdaV2.saveUpdateClientData-------> : item.id:", item.id, METHOD_NAME, CLASS_NAME);
        return await lambda.invoke(params).promise();
    },
    calculateAndSaveUtilityAmount: async (item, onChangeItem) => {
        let METHOD_NAME = ":calculateAndSaveUtilityAmount:";
        let lambda = new AWS.Lambda();
        const jsonString = JSON.stringify(item);
        console.log("Entered:Input:item:", item, METHOD_NAME, CLASS_NAME);

        let params = {
            FunctionName: ESTIMATOR_WHATIFS_API2_LAMBDA,
            InvocationType: 'RequestResponse',
            LogType: 'Tail',
            Payload: `{ "fun": "calculateAndSaveUtilityAmount", "fetchViaAPI": "false", "uid": "${item.id}", "dataCategory": "calculated_amounts", "input": ${jsonString}}`
        };
        console.log("Before invoke WhatIfScenarioLambdaV2.processCalculateAndCache -------> : item.id:", item.id, METHOD_NAME, CLASS_NAME);
        lambda.invoke(params, function (err, data) {
            METHOD_NAME += ".lambda.invoked:";
            console.log("datadatadatadatadatadata: ", data, METHOD_NAME, CLASS_NAME);
            if (err) {
                console.log("ErrorErrorErrorErrorErrorError", err, METHOD_NAME, CLASS_NAME);
            } else {
                console.log("elseelseelseelse: processCalculateAndCache success! item.id ", item.id, " # data:", data, METHOD_NAME, CLASS_NAME);
                let json = JSON.parse(data.Payload);
                const utilityAmountLocal = json.body.output;
                console.log("utilityAmountLocal:", utilityAmountLocal, METHOD_NAME, CLASS_NAME);
                onChangeItem('utilityAmount', utilityAmountLocal);
            }
        });
    },
    /**
     * Required inputs: coverageInput, clientIdInput,stateCodeInput
     */
    calculateAndSaveLifeInsuranceAmount: async (item, onChangeItem) => {
        let METHOD_NAME = ":calculateAndSaveLifeInsuranceAmount:";
        let lambda = new AWS.Lambda();
        console.log("Entered:Input:item:", item, METHOD_NAME, CLASS_NAME);
        let paramName, key, coverageInput;
        coverageInput = item.calculatedCoverage;
        let clientIdInput = item.clientId;
        let stateCodeInput = item.stateCode;
        paramName = 'All';
        key = `${clientIdInput}++${paramName}`;
        console.log("Input:All:key: ", key, METHOD_NAME, CLASS_NAME);
        let methodName = "getWhatIfScenarioDataFromCache";
        let params = {
            FunctionName: ESTIMATOR_WHATIFS_API1_LAMBDA,
            InvocationType: 'RequestResponse',
            LogType: 'Tail',
            Payload: '{ "fun": "' + methodName + '", "parameters": { "key":"' + key + '"}}'
        };
        console.log("lambda.invoke(params):", params, METHOD_NAME, CLASS_NAME);
        lambda.invoke(params, function (err, response) {
            if (response != null) {
                METHOD_NAME += ":All:lambda.invoke(params).then:" + paramName;
                console.log("output:response: ", response, METHOD_NAME, CLASS_NAME);
                let tempJson = JSON.parse(response.Payload);
                let output = tempJson.body.output;
                let cachedJsonStr = output.replace(/__-__/g, '"');
                // Patch to fix failed addresses extraction:
                // let alreadyJsonFlag = false;
                if (cachedJsonStr.indexOf('_-_-_') === -1 && cachedJsonStr.indexOf('"addresses":"[') !== -1 && cachedJsonStr.indexOf(']"}') !== -1) {
                    cachedJsonStr = cachedJsonStr.replace('"addresses":"[', '"addresses":[').replace(']"}', ']}');
                    //   alreadyJsonFlag = true;
                }
                cachedJsonStr = Utility.patchBrokenData1(cachedJsonStr);
                console.log('cachedJsonStr: ', cachedJsonStr, METHOD_NAME, CLASS_NAME);
                let data = JSON.parse(cachedJsonStr);
                let tempArray = [];
                // LIFE_DATA_RELEASE_VERSION__stateCode__gender__health4LifeInsurance__policyType__isSmoker__age__coverageAmount
                //"v2__" + stateCode + "__1__1__20__0__35__" + calculatedCoverage;
                const LIFE_DATA_RELEASE_VERSION = "v3";
                tempArray.push(LIFE_DATA_RELEASE_VERSION);
                let value = stateCodeInput != null ? stateCodeInput : 'CA';
                tempArray.push(value);
                value = data.gender != null ? data.gender : '1';
                tempArray.push(value);
                value = data.health != null ? data.health : '1';
                tempArray.push(value);
                value = data.policyType != null ? data.policyType : '20';
                tempArray.push(value);
                value = data.isSmoker != null ? data.isSmoker : '0';
                tempArray.push(value);
                value = data.age != null ? data.age : '35';
                tempArray.push(value);
                value = coverageInput != null ? coverageInput : '400000';
                tempArray.push(value);
                console.log('tempArray:', tempArray, METHOD_NAME, CLASS_NAME);
                const lifeInsuranceConcatedInput = tempArray.join("__");
                console.log("lifeInsuranceConcatedInput: ", lifeInsuranceConcatedInput, METHOD_NAME, CLASS_NAME);
                // query GetAppData($id: ID!) {
                API.graphql(graphqlOperation(getAppData, { id: lifeInsuranceConcatedInput })).then((response) => {
                    METHOD_NAME += ".then:";
                    let json;
                    if (response != null && response.data != null && (json = response.data.getAppData) != null) {
                        console.log("Before: onChangeItem: json: ", json, METHOD_NAME, CLASS_NAME);
                        console.log("json['premiumAmountPerMonth']: ", json['premiumAmountPerMonth'], METHOD_NAME, CLASS_NAME);
                        onChangeItem('lifeInsuranceAmount', json['premiumAmountPerMonth']);
                    }
                });

            }
        });

        // let params = {
        //     FunctionName: ESTIMATOR_WHATIFS_API2_LAMBDA,
        //     InvocationType: 'RequestResponse',
        //     LogType: 'Tail',
        //     Payload: `{ "fun": "calculateAndSaveLifeInsuranceAmount", "fetchViaAPI": "false", "uid": "${item.id}", "dataCategory": "calculated_amounts", "input": ${jsonString}}`
        // };
        // console.log("Before invoke WhatIfScenarioLambdaV2.processCalculateAndCache -------> : item.id:", item.id, METHOD_NAME, CLASS_NAME);
        // lambda.invoke(params, function (err, data) {
        //     METHOD_NAME += ".lambda.invoked:";
        //     console.log("datadatadatadatadatadata: ", data, METHOD_NAME, CLASS_NAME);
        //     if (err) {
        //         console.log("ErrorErrorErrorErrorErrorError", err, METHOD_NAME, CLASS_NAME);
        //     } else {
        //         console.log("elseelseelseelse: processCalculateAndCache sucess! item.id ", item.id, " # data:", data, METHOD_NAME, CLASS_NAME);
        //         return data;
        //     }
        // });
    },
    awaitProcessCalculateAndCache: async (item) => {
        let METHOD_NAME = ":awaitProcessCalculateAndCache:";
        let lambda = new AWS.Lambda();
        const jsonString = JSON.stringify(item);
        console.log("Entered:Input:item:", item, METHOD_NAME, CLASS_NAME);

        let params = {
            FunctionName: ESTIMATOR_WHATIFS_API2_LAMBDA,
            InvocationType: 'RequestResponse',
            LogType: 'Tail',
            Payload: `{ "fun": "processCalculateAndCache", "fetchViaAPI": "false", "uid": "${item.id}", "dataCategory": "calculated_amounts", "input": ${jsonString}}`
        };
        console.log("Before invoke WhatIfScenarioLambdaV2.processCalculateAndCache -------> : item.id:", item.id, METHOD_NAME, CLASS_NAME);
        return await lambda.invoke(params).promise();
    },
    recalculateLifeInsuranceAmount: async (inputIdWithoutAddress, inputIdWithAddress) => {
        let METHOD_NAME = ":recalculateLifeInsuranceAmount:";
        let lambda = new AWS.Lambda();
        let item = { 'inputIdWithoutAddress': inputIdWithoutAddress, 'inputIdWithAddress': inputIdWithAddress }
        const jsonString = JSON.stringify(item);
        console.log("Entered:Input:item:", item, METHOD_NAME, CLASS_NAME);
        /*
        
        # Extension Lambda invoke testing for isAlive
        FUNCTION_NAME=WhatIfScenarioLambdaV2
        /usr/local/bin/aws lambda invoke --function-name $FUNCTION_NAME \
        --invocation-type RequestResponse \
        --cli-binary-format raw-in-base64-out \
        --payload '{ "fun": "recalculateLifeInsuranceAmount", "fetchViaAPI": "false", "dataCategory": "calculated_amounts", "input": { "inputIdWithoutAddress": "113186451a980bb180a0142ab9", "inputIdWithAddress": "113186451a980bb180a0142ab9-4529-Winona-Ct-Denver-CO"}}' response.json
        
        
        */
        let params = {
            FunctionName: ESTIMATOR_WHATIFS_API2_LAMBDA,
            InvocationType: 'RequestResponse',
            LogType: 'Tail',
            Payload: `{ "fun": "recalculateLifeInsuranceAmount", "fetchViaAPI": "false", "dataCategory": "calculated_amounts", "input": ${jsonString}}`
        };
        console.log("Before invoke WhatIfScenarioLambdaV2.processCalculateAndCache -------> : item.id:", item.id, METHOD_NAME, CLASS_NAME);
        return await lambda.invoke(params).promise();
    },
    getData: async (id) => {
        let METHOD_NAME = ":getData:";
        console.log("id:", id, METHOD_NAME, CLASS_NAME);
        await API.graphql(graphqlOperation(getAppData, { id: id })).then((response) => {
            METHOD_NAME += ".then:";
            let json;
            let chartDataArray = [];
            let jsonPlaceHolder = {};
            if (response != null && response.data != null && (json = response.data.getAppData) != null) {
                console.log("json: ", json, METHOD_NAME, CLASS_NAME);
                // let index = 0;
                Object.entries(json).forEach(([key, value]) => {
                    // if (key === 'lifeInsuranceAmount' && value == null) {
                    //     jsonPlaceHolder[key] = 0;
                    // } else {
                    jsonPlaceHolder[key] = value;
                    // }
                    // console.log("index:", index, " # key:", key, "value: ", value, METHOD_NAME, CLASS_NAME);
                    //   index += 1;
                });
                chartDataArray.push(jsonPlaceHolder);
                classChartDataArray = chartDataArray;
            }
        });
        return classChartDataArray;
    },
    saveAppDataAndCache: async (inputJson) => {
        let METHOD_NAME = ":saveAppDataAndCache:";
        console.log("^%^ Entered inputJson: ", inputJson, METHOD_NAME, CLASS_NAME);

        // Initialize the Amazon Cognito credentials provider
        AWS.config.region = 'us-west-2'; // Region
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:44ac9557-ce0d-49a6-a84c-995523a10152',
        });
        const lambda = new AWS.Lambda();

        let jsonString;
        let response = [];
        let jsonLocal = inputJson;
        jsonLocal.updatedAt = Utility.getCurrentDate("db_format");
        let jsonNoNull = {};
        Object.entries(jsonLocal).forEach(([key, value]) => {
            if (value != null && value.length > 0) {
                jsonNoNull[key] = value;
            }
        });
        jsonString = JSON.stringify(jsonNoNull);
        const params = {
            FunctionName: ESTIMATOR_WHATIFS_API1_LAMBDA,
            InvocationType: 'RequestResponse',
            LogType: 'Tail',
            Payload: `{ "fun": "saveAppData", "body": ${jsonString}}`
        };
        // DynamoDB Insert: saveAppData
        response = await lambda.invoke(params).promise();

        METHOD_NAME += ":setDataInCache:";
        let lambdaFunctionFunctionName = "setDataInCache";

        if (inputJson.dataCategory === GlobalConstantsVariables.getClientDataCategoryAll()) {
            // Client spefific data: clientId++All -> "{fullAddress, birthYear, age, etc}"
            // Object.entries(jsonLocal.cacheId).forEach(([key2, value2]) => { jsonLocal[key2] = value2; });
            const redisKey = inputJson.cacheId;
            if (jsonLocal.addresses != null) delete jsonLocal.addresses;
            if (jsonLocal.addressHistory != null) delete jsonLocal.addressHistory;
            let jsonLocalStrValue = JSON.stringify(jsonLocal);
            jsonLocalStrValue = jsonLocalStrValue.replace(/"/g, '__-__');

            let params = {
                FunctionName: ESTIMATOR_WHATIFS_API1_LAMBDA, InvocationType: 'RequestResponse',
                LogType: 'Tail',
                Payload: `{ "fun": "${lambdaFunctionFunctionName}", "parameters":{"key":"${redisKey}", "value":"${jsonLocalStrValue}"} }`
            };
            console.log("^%^ Before: setDataInCache jsonLocal:params: ", params, METHOD_NAME, CLASS_NAME);

            lambda.invoke(params, function (error, data) {
                METHOD_NAME += ".lambda.invoked:";
                if (error) {
                    console.log("^%^ error: ", error, METHOD_NAME, CLASS_NAME);
                } else {
                    console.log("^%^ data: ", data, METHOD_NAME, CLASS_NAME);
                }
            });
            // } else if (inputJson.dataCategory != null &&
            //     inputJson.dataCategory !== GlobalConstantsVariables.getClientDataCategory()) {
            //     const redisKey = inputJson.id;
            //     console.log("Debug saveCache Redis", METHOD_NAME, CLASS_NAME);

            //     let params = {
            //         FunctionName: ESTIMATOR_WHATIFS_API1_LAMBDA, InvocationType: 'RequestResponse',
            //         LogType: 'Tail',
            //         Payload: `{ "fun": "${lambdaFunctionFunctionName}", "parameters":{"key":"${redisKey}", "value":"${inputJson}"} }`
            //     };
            //     lambda.invoke(params);
        } else {
            Object.entries(inputJson).forEach(([key, value]) => {
                //let key = 'session-1601621751580-4329++mortgageAmount';
                let splitKey = key.indexOf('++') > -1 ? key.split('++')[1] : key;
                if (CAN_BE_CACHED_KEYS.indexOf(splitKey) > -1) {
                    const redisKey = `${inputJson.id}++${key}`;
                    lambdaFunctionFunctionName = "setDataInCache";

                    console.log("^%^ Debug setDataInCache: inputJson.id: ", `${inputJson.id} ${key}  ${value}`)

                    let params = {
                        FunctionName: ESTIMATOR_WHATIFS_API1_LAMBDA, InvocationType: 'RequestResponse',
                        LogType: 'Tail',
                        Payload: `{ "fun": "${lambdaFunctionFunctionName}", "parameters":{"key":"${redisKey}", "value":"${value}"} }`
                    };
                    lambda.invoke(params);
                } else {
                    console.log("^%^ cannot be cached list item:key: ", key, " # value: ", value, METHOD_NAME, CLASS_NAME);
                }
            });
        }
        return response;
    },
    getAndUpdateAddresses: async (clientId, addressObject, callback4AddressHistory) => {
        let METHOD_NAME = ":getAndUpdateAddresses:";
        console.log("Input:clientId: ", clientId, "#addressObject:", addressObject, METHOD_NAME, CLASS_NAME);

        API.graphql(graphqlOperation(getAppData, { id: `${clientId}-AddressHistory` })).then((response) => {
            METHOD_NAME += ".then:";
            let json;
            if (response != null && response.data != null && (json = response.data.getAppData) != null) {
                console.log("response.data.getAppData: json: ", json, METHOD_NAME, CLASS_NAME);
                let tempData = json.addresses;
                let addressesArray = [];
                if (tempData == null) {
                    addressesArray = [];
                    addressesArray.push(addressObject);
                } else if (typeof tempData === 'string') {
                    addressesArray = JSON.parse(tempData);
                    Object.entries(addressObject).forEach(([key, value]) => {
                        addressesArray[0][key] = value;
                    });
                } else {
                    console.log("Problem Problem", METHOD_NAME, CLASS_NAME);
                    console.log("Problem Problem Problem", METHOD_NAME, CLASS_NAME);
                    console.log("typeof tempData: ", typeof tempData, METHOD_NAME, CLASS_NAME);
                    console.log("error: tempData: ", tempData, METHOD_NAME, CLASS_NAME);
                    console.log("Problem Problem Problem", METHOD_NAME, CLASS_NAME);
                    console.log("Problem Problem Problem", METHOD_NAME, CLASS_NAME);
                }
                if (addressesArray[0] != null) {
                    callback4AddressHistory(Object.keys(addressesArray[0]), addressesArray[0]);
                }
                let toUpdateJson = {
                    id: `${clientId}-AddressHistory`, dataCategory: 'AddressHistory',
                    updatedAt: Utility.getCurrentDate('db_format'),
                    eventStr: 'updated_addreses', addresses: JSON.stringify(addressesArray)
                };
                console.log("Before: Update: toUpdateJson: ", toUpdateJson, METHOD_NAME, CLASS_NAME);
                API.graphql(graphqlOperation(updateAppData, { input: toUpdateJson }));
            } else {
                console.log("Error: response.data.getAppData:response: ", response, " # clientId: ", clientId, METHOD_NAME, CLASS_NAME);
                let addressesArray = [];
                addressesArray.push(addressObject);
                let toInsertJson = {
                    id: `${clientId}-AddressHistory`, dataCategory: 'AddressHistory',
                    updatedAt: Utility.getCurrentDate('db_format'),
                    eventStr: 'updated_addreses', addresses: JSON.stringify(addressesArray)
                };
                console.log("Before: createAppData: toInsertJson: ", toInsertJson, METHOD_NAME, CLASS_NAME);
                API.graphql(graphqlOperation(createAppData, { input: toInsertJson }))
            }
        });
    },
    updateAppDataAndCache: async (dataCategory, inputJson) => {
        let METHOD_NAME = ":updateAppDataAndCache:";
        console.log("^%^ Entered:inputJson: ", inputJson, METHOD_NAME, CLASS_NAME);
        let jsonLocal = inputJson;
        if (inputJson == null) return;

        jsonLocal.updatedAt = Utility.getCurrentDate("db_format");
        jsonLocal.dataCategory = dataCategory;

        // Dynamodb UPDATE call
        API.graphql(graphqlOperation(updateAppData, { input: jsonLocal })).then((updateDataResponse) => {
            METHOD_NAME += "updateAppData.then:";
            console.log("^%^ After graphqlOperation(updateAppData) id: ", inputJson.id, " successfully!", "# time: ", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
            let updatedJson;
            // If address details are not filled in invoke processCalculateAndCache again
            if (updateDataResponse != null && updateDataResponse.data != null && (updatedJson = updateDataResponse.data.updateAppData) != null) {
                console.log("^%^ updatedJson: ", updatedJson, METHOD_NAME, CLASS_NAME);
                if (updatedJson.dataCategory === GlobalConstantsVariables.getClientDataCategoryAll()) {
                    METHOD_NAME += ":setDataInCache";
                    const redisKey = updatedJson.cacheId;
                    if (updatedJson.addresses != null && updatedJson.addresses === "") { delete updatedJson.addresses; }
                    if (jsonLocal.addressHistory != null) delete jsonLocal.addressHistory;
                    let updateJsonNoNull = {};
                    Object.entries(updatedJson).forEach(([key, value]) => {
                        if (value != null && value.length > 0) {
                            updateJsonNoNull[key] = value;
                        }
                    });
                    console.log("^%^ updateJsonNoNull: ", updateJsonNoNull, METHOD_NAME, CLASS_NAME);

                    let jsonLocalStrValue = JSON.stringify(updateJsonNoNull);
                    jsonLocalStrValue = jsonLocalStrValue.replace(/"/g, '__-__').replace(/\\/g, '');
                    lambdaFunctionFunctionName = "setDataInCache";

                    let params = {
                        FunctionName: ESTIMATOR_WHATIFS_API1_LAMBDA, InvocationType: 'RequestResponse',
                        LogType: 'Tail',
                        Payload: `{ "fun": "${lambdaFunctionFunctionName}", "parameters":{"key":"${redisKey}", "value":"${jsonLocalStrValue}"} }`
                    };
                    console.log("^%^ Before cache insert:jsonLocalStrValue: ", jsonLocalStrValue, METHOD_NAME, CLASS_NAME);

                    lambda.invoke(params, function (error, data) {
                        METHOD_NAME += ".lambda.invoked:";
                        if (error) {
                            console.log("^%^ error: ", error, METHOD_NAME, CLASS_NAME);
                        } else {
                            console.log("^%^ data: ", data, METHOD_NAME, CLASS_NAME);
                        }
                    });
                }
                Object.entries(updatedJson).forEach(([key, value]) => {
                    if (key === 'calculatedCoverage' && value === '0') {
                        METHOD_NAME += ":updateAppData:awaitProcessCalculateAndCache:";
                        const jsonString = JSON.stringify(jsonLocal);
                        console.log("^%^ jsonString:", jsonString, METHOD_NAME, CLASS_NAME);

                        let params = {
                            FunctionName: ESTIMATOR_WHATIFS_API2_LAMBDA,
                            InvocationType: 'RequestResponse',
                            LogType: 'Tail',
                            Payload: `{ "fun": "processCalculateAndCache", "fetchViaAPI": "false", "uid": "${inputJson.id}", "dataCategory": "calculated_amounts", "input": ${jsonString}}`
                        };
                        console.log("^%^ Before invoke WhatIfScenarioLambdaV2.processCalculateAndCache -------> : inputJson.id:", inputJson.id, METHOD_NAME, CLASS_NAME);
                        lambda.invoke(params);
                    }
                });
            }
        });
        let lambda = new AWS.Lambda();
        let lambdaFunctionFunctionName = "";
        if (jsonLocal.dataCategory !== GlobalConstantsVariables.getClientDataCategoryAll()) {
            METHOD_NAME += ":setDataInCache:";
            lambdaFunctionFunctionName = "setDataInCache";
            //Add code to cache values into Redis
            //let key = 'session-1601621751580-4329++mortgageAmount';
            Object.entries(inputJson).forEach(([key, value]) => {
                if (CAN_BE_CACHED_KEYS.indexOf(key) > -1) {
                    const redisKey = `${inputJson.id}++${key}`;
                    let params = {
                        FunctionName: ESTIMATOR_WHATIFS_API1_LAMBDA,
                        InvocationType: 'RequestResponse',
                        LogType: 'Tail',
                        Payload: `{ "fun": "${lambdaFunctionFunctionName}", "parameters":{"key":"${redisKey}", "value":"${value}"} }`
                    };
                    console.log("^%^ Before lambda.invoke:setDataInCache:params: ", params, METHOD_NAME, CLASS_NAME);

                    lambda.invoke(params, function (error, data) {
                        METHOD_NAME += ".lambda.invoked:";
                        if (error) {
                            console.log("^%^ error: ", error, METHOD_NAME, CLASS_NAME);
                        } else {
                            console.log("^%^ data: ", data, METHOD_NAME, CLASS_NAME);
                        }
                    });
                } else {
                    console.log("^%^ cannot be cached list item:key: ", key, " # value: ", value, METHOD_NAME, CLASS_NAME);
                }
            });
        }
    },
    setDataInCache: (key, value) => {
        let METHOD_NAME = ":setDataInCache:";
        console.log("Input:key: ", key, " # value:", value, METHOD_NAME, CLASS_NAME);

        let lambdaFunctionFunctionName = "setDataInCache";
        let lambda = new AWS.Lambda();
        let params = {
            FunctionName: ESTIMATOR_WHATIFS_API1_LAMBDA, InvocationType: 'RequestResponse',
            LogType: 'Tail',
            Payload: `{ "fun": "${lambdaFunctionFunctionName}", "parameters":{"key":"${key}", "value":"${value}"} }`
        };
        console.log("Before cache insert", METHOD_NAME, CLASS_NAME);

        lambda.invoke(params, function (error, data) {
            METHOD_NAME += ".lambda.invoked:";
            if (error) {
                console.log("error: ", error, METHOD_NAME, CLASS_NAME);
            } else {
                console.log("data: ", data, METHOD_NAME, CLASS_NAME);
            }
        });
    },
    resetClassLevelVarValue: () => {
    },
    getCachedData: async (key) => {
        let METHOD_NAME = ":getCachedData:";

        let lambda = new AWS.Lambda();

        let params = {
            FunctionName: ESTIMATOR_WHATIFS_API1_LAMBDA,
            InvocationType: 'RequestResponse',
            LogType: 'Tail',
            Payload: '{ "fun": "getWhatIfScenarioDataFromCache", "parameters": { "key":"' + key + '"}}'
        };
        lambda.invoke(params, function (error, data) {
            METHOD_NAME += ".lambda.invoked:";
            console.log("data: ", data, METHOD_NAME, CLASS_NAME);
            console.log("debug getCachedData ", data)
            if (error) {
                console.log("error: ", error, METHOD_NAME, CLASS_NAME);
                return error;
            } else {
                let json = JSON.parse(data.Payload);
                let array = [];
                Object.entries(json.body).forEach(([key, value]) => {
                    if (key === 'input') {
                        array[0] = value;
                    } else if (key === 'output') {
                        array[1] = value;
                        if (value === '') {
                            array[1] = null;
                        }
                    }
                });
                classLevelHash[array[0]] = array[1];
            }
        });

        return classLevelHash;
    },
    getCachedData2: async (key) => {
        let lambda = new AWS.Lambda();
        let methodName = "getWhatIfScenarioDataFromCache";
        let params = {
            FunctionName: ESTIMATOR_WHATIFS_API1_LAMBDA,
            InvocationType: 'RequestResponse',
            LogType: 'Tail',
            Payload: '{ "fun": "' + methodName + '", "parameters": { "key":"' + key + '"}}'
        };
        return await lambda.invoke(params).promise();
    },
    getDwellingCost: async (inputAddressDeatils) => {
        let METHOD_NAME = ":getDwellingCost:";
        const jsonString = JSON.stringify(inputAddressDeatils);
        console.log("$$$ jsonString:: ", jsonString, METHOD_NAME, CLASS_NAME);

        let lambda = new AWS.Lambda();
        let params = {
            FunctionName: ESTIMATOR_WHATIFS_API2_LAMBDA,
            InvocationType: 'RequestResponse',
            LogType: 'Tail',
            Payload: `{ "fun": "getDwellingCost", "parameters": {"input": ${jsonString}}}`
        };
        console.log("$$$ Entered:params: ", params, METHOD_NAME, CLASS_NAME);

        return await lambda.invoke(params).promise();
    }, getLocalCacheFor: (id) => {
        let METHOD_NAME = ":getLocalCacheFor:";
        let outputHash = {};
        console.log("Entered:Input:id: ", id, METHOD_NAME, CLASS_NAME);

        Object.entries(classLevelHash).forEach(([key, value]) => {
            if (key.startsWith(id)) {
                const key2 = key.split('++')[1];
                outputHash[key2] = value;
                console.log("key2: ", key2, METHOD_NAME, CLASS_NAME);

            }
        });
        console.log("outputHash: ", outputHash, METHOD_NAME, CLASS_NAME);

        return outputHash;
    },
    getFormattedValue: (sliderName, value) => {
        if (sliderName === GlobalConstantsVariables.getPolicyTermLabel()) {
            return value;
        } else if (sliderName === GlobalConstantsVariables.getAgeLabel()) {
            return `${value} Years`;
        } else if (sliderName === GlobalConstantsVariables.getPropertyLabel()) {
            if (value <= 999) {
                return `$${value}K`;
            } else if (value > 999 && value <= 1499) {
                return `$1.00 M`;
            } else if (value >= 1500 && value < 1749) {
                return `$1.50 M`;
            } else if (value > 1750 && value < 2001) {
                return `$2.00 M`;
            }
        }
    },
    fetchAndSaveHomeInsurance: async (inputJson) => {
        let METHOD_NAME = ":fetchAndSaveHomeInsurance:";
        const jsonString = JSON.stringify(inputJson);
        let lambda = new AWS.Lambda();
        let params = {
            FunctionName: ESTIMATOR_WHATIFS_API2_LAMBDA,
            InvocationType: 'RequestResponse',
            LogType: 'Tail',
            Payload: `{ "fun": "fetchAndSaveHomeInsurance", "parameters": {"input": ${jsonString}}}`
        };
        console.log("$$$ Entered:params: ", params, METHOD_NAME, CLASS_NAME);
        return await lambda.invoke(params).promise();
    },
    // ComponentHelper.add2AgentList(this.state.id);
    add2AgentList(agentBrandingCode) {
        let METHOD_NAME = ":add2AgentList:";
        let id = GlobalConstantsVariables.getAgentDataCategoryAll();
        API.graphql(graphqlOperation(getAppData, { id: id })).then((response) => {
            METHOD_NAME += ".then:";
            let json;
            if (response != null && response.data != null && (json = response.data.getAppData) != null) {
                console.log("response.data.getAppData: json: ", json, METHOD_NAME, CLASS_NAME);
                let tempData = json.agentBrandingCodes || '';
                let delimiter = tempData === '' ? '' : '__-__';
                json.agentBrandingCodes = `${json.agentBrandingCodes}${delimiter}${agentBrandingCode}`;
                json.updatedAt = Utility.getCurrentDate("db_format");
                console.log("Before: Update: json: ", json, METHOD_NAME, CLASS_NAME);
                API.graphql(graphqlOperation(updateAppData, { input: json }));
            } else {
                console.log("Error: response.data.getAppData:response: ", response, " # id: ", id, METHOD_NAME, CLASS_NAME);
            }
        });
    },
    getAgentsList() {
        let METHOD_NAME = ":getAgentsListt:";
        let id = GlobalConstantsVariables.getAgentDataCategoryAll();
        return new Promise(function (resolve, reject) {
            API.graphql(graphqlOperation(getAppData, { id: id })).then((response) => {
                METHOD_NAME += ".then:";
                let json;
                if (response && response.data && (json = response.data.getAppData)) {
                    let agentBrandingCodes = json.agentBrandingCodes || '';
                    resolve(agentBrandingCodes.split('__-__'))
                    //API.graphql(graphqlOperation(updateAppData, { input: json }));
                } else {
                    console.log("Error: response.data.getAppData:response: ", response, " # id: ", id, METHOD_NAME, CLASS_NAME);
                }
            });
        })
    },
    getAgentData(agentBrandingCode) {
        const key = agentBrandingCode;
        return new Promise((resolve, reject) => {
            this.getData(key).then((array) => {
                let json;
                // console.log("%$%  array: ", array, METHOD_NAME, CLASS_NAME);
                if (array && array.length > 0 && (json = array[0])) {
                    resolve(json);
                }
            })
        })
    },
    doesAgentCodeExist(clientId, agentBrandingCode, callbackMethod) {
        let METHOD_NAME = ":doesAgentCodeExist:";
        console.log("%$% Entered:agentBrandingCode: ", agentBrandingCode, METHOD_NAME, CLASS_NAME);
        API.graphql(graphqlOperation(getAppData, { id: agentBrandingCode })).then((response) => {
            METHOD_NAME += ".then:";
            let json;
            if (response != null && response.data != null && (json = response.data.getAppData) != null) {
                console.log("%$% response.data.getAppData: json: ", json, METHOD_NAME, CLASS_NAME);
                API.graphql(graphqlOperation(updateAppData, { input: { id: clientId, agentBrandingCode: agentBrandingCode, eventStr: 'AddedagentBrandingCode' } }));
                const key4CacheId = `${clientId}++agentBrandingCode`;
                console.log("Before this.setDataInCache invoke", METHOD_NAME, CLASS_NAME);
                this.setDataInCache(key4CacheId, agentBrandingCode);
                console.log('*&* After setDataInCache', METHOD_NAME, CLASS_NAME);
                callbackMethod('agentBrandingCode', agentBrandingCode);
            } else {
                console.log("%$% Error: response.data.updateAppData:response: ", response, METHOD_NAME, CLASS_NAME);
            }
        });
    },
    // This method could be used to set Main state variables from DB Client Data
    getDataV3(id) {
        return API.graphql(graphqlOperation(getAppData, { id: id }));
    },
    // ComponentHelper.getDwellingCostRecursive(id, this.onChangeItem);
    getDwellingCostRecursive(id, callbackMethodObject) {
        let METHOD_NAME = ":getDataV3:";
        // we will try dwellingCost fetch from cache 
        // if not available -> wait 3 seconds and fetch again
        this.getCachedData2(`${id}++dwellingResult`).then((data) => {
            METHOD_NAME = `:getCachedData2.then:dwellingResult`;
            console.log("output:data1111: ", data, METHOD_NAME, CLASS_NAME);
            let json = JSON.parse(data.Payload);
            let cachedValue4 = json.body.output;
            if (cachedValue4 == null && cachedValue4.length === 0) {
                setTimeout(() => {
                    this.getCachedData2(`${id}++dwellingResult`).then((data) => {
                        METHOD_NAME = `:getCachedData2.then:dwellingResult`;
                        console.log("output:data222: ", data, METHOD_NAME, CLASS_NAME);
                        let json = JSON.parse(data.Payload);
                        let cachedValue4 = json.body.output;
                        if (cachedValue4 == null && cachedValue4.length === 0) {
                            callbackMethodObject(`NoDwellingCostFlag`, true);
                            //Gaurav TODO:
                            // invoke setState method to set a state variable to show error message
                            // This messaging for 2
                            // 1. getTotal should sum without homeInsuranceAmount (this will not show Recalcuating message)
                            // 2. ExtensionLabels to show Not enough data instead of 0% and $0/$NaN
                        }
                    });
                }, 3000);
            }
        });
    },
    // ComponentHelper.setAndGetDefaultValues(defaultValuesHash, 'livingsizeSqft', value);
    setAndGetDefaultValues(inputHash, key, value) {
        /*
        First set of the hash will set the into default
        Any time later the values will not be set
        Step 0: 
        The parameters will not have any value:
        Example: For My Home: defaults are from realtor.com
        if(!inputHash[key]) inputHash[key] = value from realtor.com
        */
        if (!inputHash[key]) inputHash[key] = value; //  from realtor.com;
        return inputHash;
    },
    getAppDataList(filterObject = {}, limitInt = 0) {
        let METHOD_NAME = ":getAppDataList:";
        //posts(filter: { title: { eq: "My Post!" } }) // "dataCategory": { "eq": "LifeInsurance"} eventStr: { "eq": "calculatedCoverage"}
        // console.log("Input:filterObject:", filterObject);
        // console.log("Input:filterObject != null:", filterObject != null);
        // console.log("Input:Object.keys(filterObject).length:", Object.keys(filterObject).length);

        let filterObjectLocal = filterObject != null && Object.keys(filterObject).length > 0 ? filterObject : { "dataCategory": { "eq": "LifeInsurance" } };
        let limitIntLocal = limitInt > 0 ? limitInt : 10;
        console.log("Input:limitIntLocal: ", limitIntLocal, "#filterObjectLocal:", filterObjectLocal, METHOD_NAME, CLASS_NAME);
        // query GetAppData($id: ID!) {
        // const filter: ModelUserFilterInput = { owner: { eq: username } }
        // const promise = API.graphql(graphqlOperation(gqlToString(queries.ListUsers), { "filter": filter, limit: 1 }))
        return new Promise(function (resolve, reject) {
            API.graphql(graphqlOperation(listAppDatas, { filter: filterObjectLocal, limit: ((limitIntLocal * 2) - 1) })).then((response, error) => {
                METHOD_NAME += ".then:";
                let json;
                // console.log("response: ", response, METHOD_NAME, CLASS_NAME);
                console.log("error: ", error, METHOD_NAME, CLASS_NAME);

                if (response != null && response.data != null && (json = response.data.listAppDatas.items) != null) {
                    console.log("json: ", json, METHOD_NAME, CLASS_NAME);
                    resolve(json)
                }
            });
        })
    },
    hubSpotSync: async (inputJson) => {
        let lambda = new AWS.Lambda();
        let methodName = "hubSpotLeadSync";
        let params = {
            FunctionName: ESTIMATOR_WHATIFS_API1_LAMBDA,
            InvocationType: 'RequestResponse',
            LogType: 'Tail',
            Payload: '{ "fun": "' + methodName + '", "body": '+ inputJson + '}'
        };
        return await lambda.invoke(params).promise();
    },
    hubSpotCheckEmail: async (inputJson) => {
        let lambda = new AWS.Lambda();
        let methodName = "checkEmail";
        let params = {
            FunctionName: ESTIMATOR_WHATIFS_API1_LAMBDA,
            InvocationType: 'RequestResponse',
            LogType: 'Tail',
            Payload: '{ "fun": "' + methodName + '", "body": ' + inputJson + '}'
        };
        return await lambda.invoke(params).promise();
    },
    hubSpotUpdate: async (inputJson) => {
        let lambda = new AWS.Lambda();
        let methodName = "hubSpotUpdate";
        let params = {
            FunctionName: ESTIMATOR_WHATIFS_API1_LAMBDA,
            InvocationType: 'RequestResponse',
            LogType: 'Tail',
            Payload: '{ "fun": "' + methodName + '", "body": ' + inputJson + '}'
        };
        //expected response in no content with status 204
        return await lambda.invoke(params).promise();
    },
    // updateAgentBrandingCode(inputJson) {
    //     let METHOD_NAME = ":updateAgentBrandingCode:";
    //     let lambda = new AWS.Lambda();
    //     let jsonLocal = inputJson;
    //     let lambdaFunctionFunctionName;
    //     GlobalConstantsVariables.setInCache('agentBrandingCode', inputJson.agentBrandingCode);
    //     API.graphql(graphqlOperation(updateAppData, { input: jsonLocal })).then((updateDataResponse) => {
    //         METHOD_NAME += "updateAppData.then:";
    //         console.log("^%^ After graphqlOperation(updateAppData) id: ", inputJson.id, " successfully!", "# time: ", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
    //         let updatedJson;
    //         // If address details are not filled in invoke processCalculateAndCache again
    //         if (updateDataResponse != null && updateDataResponse.data != null && (updatedJson = updateDataResponse.data.updateAppData) != null) {
    //             console.log("^%^ updatedJson: ", updatedJson, METHOD_NAME, CLASS_NAME);
    //             if (updatedJson.dataCategory === GlobalConstantsVariables.getClientDataCategoryAll()) {
    //                 METHOD_NAME += ":setDataInCache";
    //                 const redisKey = `${inputJson.id}+All`;
    //                 let updatedJson2 = {};
    //                 Object.entries(inputJson).forEach(([key, value]) => {
    //                     if (value != null) { updatedJson2[key] = value; }
    //                 });
    //                 let jsonLocalStrValue = JSON.stringify(updatedJson2);
    //                 jsonLocalStrValue = jsonLocalStrValue.replace(/"/g, '__-__').replace(/\\/g, '');
    //                 lambdaFunctionFunctionName = "setDataInCache";

    //                 let params = {
    //                     FunctionName: ESTIMATOR_WHATIFS_API1_LAMBDA, InvocationType: 'RequestResponse',
    //                     LogType: 'Tail',
    //                     Payload: `{ "fun": "${lambdaFunctionFunctionName}", "parameters":{"key":"${redisKey}", "value":"${jsonLocalStrValue}"} }`
    //                 };
    //                 console.log("^%^ Before cache insert:jsonLocalStrValue: ", jsonLocalStrValue, METHOD_NAME, CLASS_NAME);
    //                 lambda.invoke(params);
    //                 console.log("^%^ After cache insert:redisKey: ", redisKey, " # jsonLocalStrValue: ", jsonLocalStrValue, METHOD_NAME, CLASS_NAME);

    //             }
    //         }
    //     });
    // }


}

