import React from 'react';
import ReactAutocomplete from 'react-autocomplete';

export default class Autocomplete extends React.Component {

    constructor (props) {
      super(props)
      this.state = {
        value: '',
        options:[]
      }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps){
            this.setState({
                options: nextProps.options,
            })
        }
    }
  
    render() {
      return (
        <ReactAutocomplete
          items={this.state.options}
          shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
          getItemValue={item => item.label}
          renderItem={(item, highlighted) =>
            <div
              key={item.id}
              style={{ backgroundColor: highlighted ? '#eee' : 'transparent'}}
            >
              {item.label}
            </div>
          }
          value={this.state.value}
          onChange={e => this.setState({ value: e.target.value })}
          onSelect={value => this.setState({ value },() => this.props.onSelect(value))}
        />
      )
    }
  }
