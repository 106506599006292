import React from 'react';

import Utility from './Utility';

import ExtensionLabels from "./ExtensionLabels.component";

import BubbleMatch from './assets/bubble-match.svg';
import ChevronRight from './assets/ic-chevron-right-orange.svg';
import FastLogo from './assets/ic-fast-logo.svg';
import FlexiLogo from './assets/ic-flexi-logo.svg';
import AffordableLogo from './assets/ic-affordable-icon.svg';
import FastSocialLogo from './assets/ic-fast-social-icon.svg';
import FlexiSocialLogo from './assets/ic-flexi-social.svg';
import AffordableSocialLogo from './assets/ic-affordable-social.svg'
import PacLife from './assets/paclife-logo.jpg';
import Character3 from './assets/character-3.svg';
import IllustrationSmall from './assets/illustration-small-1.svg'
import AffordableTag from "./assets/ic-affordable-tag.svg";
import FastTag from "./assets/ic-fast-tag.svg";
import FlexiTag from "./assets/ic-flexi-tag.svg";

const CLASS_NAME = 'Step3Extension';

const date = new Date();
let [year, month, day] = [ date.getFullYear(), date.getMonth() + 1, date.getDate() ];
if(month < 10) month = `0${month}`;
if (day < 10) day = `0${day}`;
class Page3 extends React.Component {

    constructor(props) {
        super(props);
        let yob = year - this.props.age;
        console.log(`${yob}-${month}-${day}`);
        this.state = {
            gender: this.props.gender === 0 ? 'Female' : 'Male',
            smoker: this.props.smoker === 0 ? 'No' : 'Yes',
            name: 'Jonathan',
            weight: 135,
            birthDay: `${yob}-${month}-${day}`
        }
    }

    enterWeight = (e) => {
        let weight = e.target.value;
        if (isNaN(weight)) {
            weight = '';
        }
        this.setState({ weight: +weight });
        this.props.onChangeItem('weight', +weight);
    }

    enterHeight = (e) => {
        let METHOD_NAME = "enterHeight";
        let height = e.target.value;
        if (isNaN(height)) {
            height = '';
        }
        if (e.target.name.includes('In')) {
            height = height < 12 ? height : '';
        };
        console.log(CLASS_NAME, e.target.name, METHOD_NAME)
        this.setState({ [e.target.name]: height })
    }

    onChangeHeight(value) {
        const key = 'height';
        this.props.onChangeItem(key, value);
        this.setState({ [key]: value });
    }

    onChangeWeight(value) {
        const key = 'weight';
        this.setState({ [key]: value });
    }

    enterName = (e) => {
        let name = e.target.value;
        this.setState({ name });
    }

    setGender = (e) => {
        this.props.onChangeItem( 'gender' ,e)
    }
    setSmoker = (e) => {
        this.props.onChangeItem('smoker',e)
    }

    changeBirthDay = (e) => {
        let [yr, mm, dd] = e.target.value.split("-");
        this.setState({birthDay: `${yr}-${mm}-${dd}`});
    }

    setBirthDay = (e) => {
        e.persist();
        let [yr, mm, dd] = e.target.value.split("-");
        let date = new Date();
        let [year, month, day] = [ date.getFullYear(), date.getMonth() + 1, date.getDate() ];
        let age = year - yr;
        if(mm > month){
            age = age - 1; 
        };
        if(+mm === month && dd > day){
            age = age - 1;
        };
        console.log(age);
        this.props.onChangeItem('age', age);
    }

    changeTermDesired = (e) => {
        let termDesired = e.target.value;
        if(isNaN(termDesired)){
            return;
        }
        this.props.onChangeItem('termDesired', termDesired);
    }

    onAfterChangeLivingsizeSqft = (value) => {
        let METHOD_NAME = ":onAfterChangeLivingsizeSqft:";
        let input4AppDataPersistence = { id: this.props.id, stateCode: this.props.stateCode, livingsizeSqft: value };
        console.log("Entered:livingsizeSqft:value ", value, "# input4AppDataPersistence: ", input4AppDataPersistence, METHOD_NAME, CLASS_NAME);
        let utilityAmountLocal = Utility.calculateUtilityAmount(this.props.stateCode, value);
        this.props.onChangeItem('utilityAmount', utilityAmountLocal);
        this.pushDataInDBAndCache({ 'value': value, 'key': 'livingsizeSqft', keyInput: value });

        // ComponentHelper.calculateAndSaveUtilityAmount(input4AppDataPersistence, this.props.onChangeItem);
    }
    onChangeLivingsizeSqft = (value) => {
        this.setState({ 'livingsizeSqft': value });
        this.props.onChangeItem('livingsizeSqft', value);
    }
    changeCoverage = (e) => {
        let  calculatedCoverage = e.target.value;
        calculatedCoverage = calculatedCoverage.replace(/,/g,"").replace("M","");
        if(calculatedCoverage.includes(".")){
            calculatedCoverage = ''
        }
        this.props.onChangeItem('calculatedCoverage', calculatedCoverage);
    }

    render() {
        return (
            <div className="d-flex flex-row full-page">
                <div className='d-flex flex-column col1'>
                    <img src={BubbleMatch} alt="" />
                    <span> We matched you with some Bubbles </span>
                    <p>Based on your location and the details you just entered, our guidance platform fetched 3 appropriate Bubbles for you.</p>
                </div>
                <div className='d-flex flex-column col2'>
                    <p>
                        Thanks for that, {this.state.name}! Review and edit your info below, and check out the suggested Bubble.
                    </p>
                    <section>
                        <label>
                            Home Details
                        </label>
                        <div className="d-flex flex-column w-100">
                            <label className="label">
                                Home Address
                            </label>
                            <input type="text" value={this.props.address} />
                        </div>
                        <div className="d-flex flex-row">
                            <div className="d-flex flex-column">
                                <label className="label">
                                    Area
                                </label>
                                <div>
                                    <input type="text" size="1" value={this.props.livingsizeSqft} />
                                    <span> sq. ft</span></div>
                            </div>
                            <div className="d-flex flex-column home">
                                <label className="label">
                                    Home Type
                                </label>
                                <select>
                                    <option>
                                        Single Family
                                    </option>
                                    <option>
                                        Primary Residence
                                    </option>
                                    <option>
                                        Condo
                                    </option>
                                </select>
                            </div>

                        </div>
                    </section>
                    <section>
                        <label>
                            Personal Details
                        </label>
                    </section>

                    <div className="d-flex flex-row">
                        <div className="d-flex flex-column">
                            <label className="label">
                                Name
                                </label>
                            <input type="text" name="name" value={this.state.name} onChange={this.enterName} />
                        </div>
                        <div className="d-flex flex-column">
                            <label className="label">
                                Date of Birth
                                </label>
                            <input type="date" value={this.state.birthDay} name="birthDate" max={date} onBlur={this.setBirthDay} onChange={this.changeBirthDay} />
                        </div>

                    </div>

                    <div className="d-flex flex-row">
                        <div className="d-flex flex-column">
                            <label className="label">
                                Gender (at birth)
                            </label>
                            <select>
                                <option selected={this.props.gender === 1} onClick={() => this.setGender(1)}>Male</option>
                                <option selected={this.props.gender === 0}  onClick={() => this.setGender(0)}>Female</option>
                            </select>
                           {/* <input type="text" value={this.state.gender} /> */}
                        </div>
                        <div className="d-flex flex-column">
                            <label className="label">
                                Height
                            </label>
                            <div className='d-flex flex-row height'>
                                <input  value={this.state.heightFt} name="heightFt" onChange={this.enterHeight} /> <span>ft</span> 
                                <input value={this.state.heightIn} name="heightIn" onChange={this.enterHeight} /> <span>in</span>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-row">
                        <div className="d-flex flex-column">
                            <label className="label">
                                Weight
                            </label>
                            <div> <input type="text" size='2' name="weight" value={this.state.weight} onChange={this.enterWeight} /> <span>lbs</span> </div>
                        </div>
                        <div className="d-flex flex-column">
                            <label className="label">
                                Smoker
                            </label>
                            <select name="" id="">
                                <option selected={this.props.smoker === 1}  onClick={() => this.setSmoker(1)} value="">Yes</option>
                                <option selected={this.props.smoker === 0}  onClick={() => this.setSmoker(0)} value="">No</option>
                            </select>

                          {/*  <input type="text" value={this.state.smoker} /> */}
                        </div>

                    </div>

                    <div className="d-flex flex-row">
                        <div className="d-flex flex-column">
                            <label className="label">
                                Coverage Amount
                            </label>
                         <div className="d-flex flex-row coverage"> 
                            <span> $ </span> <input type="text" onChange={this.changeCoverage} value={Utility.getFormattedNumberValue(this.props.calculatedCoverage)} />     
                        </div> 
                        </div>
                        <div className="d-flex flex-column">
                            <label className="label">
                                Term Length
                            </label>
                            <div >
                                <input type="text" size="1" onChange={this.changeTermDesired} value={this.props.termDesired} />
                                <span>years</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-column col3 palegray'>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <div className="circle-last">
                            <div className="orange-circle"></div>
                        </div>
                        <div className="palegray-line"></div>
                        <div className="palegray-circle"></div>
                        <div className="palegray-line"></div>
                        <div className="palegray-circle"></div>
                    </div>
                    <div className="place-to-live">
                        That’s a great place to live!
                    </div>

                    <div className="d-flex flex-row location-card">
                        <img src={IllustrationSmall} alt=''/>
                        <div className="d-flex flex-column">
                            Location Matters
                            <p>
                            Inyo has a quality of life score that’s better than 90% of neighbouring counties in California. 
                            </p>
                        </div>
                    </div>

                    <div className="labels-card">
                    Predicted Monthly Costs for this Location

                    <ExtensionLabels id={this.props.id}
                                    extension={this.props.extension}
                                    stateCode={this.props.stateCode}
                                    livingsizeSqft={this.props.livingsizeSqft}
                                    utilityAmount={this.props.utilityAmount}
                                    propertyTaxAmount={this.props.propertyTaxAmount}
                                    mortgageAmount={this.props.mortgageAmount}
                                    homeInsuranceAmount={this.props.homeInsuranceAmount}
                                    lifeInsuranceAmount={this.props.lifeInsuranceAmount}
                                    displayPacLifeNextQuote={this.props.displayPacLifeNextQuote}
                                />

                    </div>

                </div>
                { false &&
                <div className='d-flex flex-column col3'>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <div className="orange-circle"></div>
                        <div className="orange-line"></div>
                        <div className="orange-circle"></div>
                        <div className="orange-line"></div>
                        <div className="circle-last">
                            <div className="orange-circle"></div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between labels2">
                        <span>Location Details</span>
                        <span>Customize</span>
                        <span>Your Bubble</span>
                    </div>

                    <span className="rec-bubble">Recommended Bubbles</span>

                    <span className="choices">Based on your choices, these are 3 recommended Bubbles</span>

                    <div className="insurcard d-flex flex-column">
                        <div className="d-flex flex-row row1">
                            <img src={FastLogo} alt="" />
                            <div className="d-flex flex-column">
                                <span className='label'>Fast</span>
                                <div className='social-logos-img'>
                                    <img src={FastSocialLogo} alt="" />
                                    <span className="info orange">90% in your age group chose this plan</span>
                                </div>
                                <span className="label-small">Immediate online bind</span>
                            </div>
                            <span className="learn orange">
                                Learn More <img src={ChevronRight} alt="" />
                            </span>
                        </div>
                        <div className="d-flex flex-column row2">
                            <div className="d-flex flex-row  justify-content-between align-items-end row3">
                                <span className='total'>Total Cost Per Month</span>
                                <span className='price'>$463</span>
                            </div>
                            <hr />
                            <div className="d-flex flex-row  justify-content-between row3 align-items-center">
                                <div className="info-all">
                                    <img src={PacLife} alt='' />
                                    <span className>
                                        Pac Life Next
                                    </span>
                                </div>
                                <span className="data-price">
                                    $47
                                </span>
                            </div>
                            <div className="d-flex flex-row justify-content-between row3 align-items-center">
                                <div className="info-all">
                                    <img src={PacLife} alt='' />
                                    <span>
                                        Pac Life Next
                                    </span>
                                </div>
                                <span className="data-price">
                                    $47
                                </span>

                            </div>
                        </div>
                    </div>

                    <div className="insurcard d-flex flex-column">
                        <div className="d-flex flex-row row1 blue-bg">
                            <img src={FlexiLogo} alt="" />
                            <div className="d-flex flex-column">
                                <span className='label'>Flexi</span>
                                <div className="social-logos-img">
                                    <img src={FlexiSocialLogo} alt='' />
                                    <span className="info">55% in your location chose this plan</span>
                                </div>
                                <span className="label-small">Allows addition of various life events</span>
                            </div>
                            <span className="learn orange">
                                Learn More <img src={ChevronRight} alt="" />
                            </span>
                        </div>
                        <div className="d-flex flex-column row2">
                            <div className="d-flex flex-row  justify-content-between align-items-end row3">
                                <span className='total'>Total Cost Per Month</span>
                                <span className='price'>$463</span>
                            </div>
                            <hr />
                            <div className="d-flex flex-row  justify-content-between row3 align-items-center">
                                <div className="info-all">
                                    <img src={PacLife} alt='' />
                                    <span className>
                                        Pac Life Next
                                    </span>
                                </div>
                                <span className="data-price">
                                    $47
                                </span>
                            </div>
                            <div className="d-flex flex-row justify-content-between row3 align-items-center">
                                <div className="info-all">
                                    <img src={PacLife} alt='' />
                                    <span>
                                        Pac Life Next
                                    </span>
                                </div>
                                <span className="data-price">
                                    $47
                                </span>

                            </div>
                        </div>
                    </div>
                    <div className="insurcard d-flex flex-column">
                        <div className="d-flex flex-row row1 green-bg">
                            <img src={AffordableLogo} alt="" />
                            <div className="d-flex flex-column">
                                <span className='label'>Affordable</span>
                                <div className="social-logos-img">
                                    <img src={AffordableSocialLogo} alt='' />
                                    <span className="info">40% in your age group chose this plan</span>
                                </div>
                                <span className="label-small">Lowest pricing in your area</span>
                            </div>
                            <span className="learn orange">
                                Learn More <img src={ChevronRight} alt="" />
                            </span>
                        </div>
                        <div className="d-flex flex-column row2">
                            <div className="d-flex flex-row  justify-content-between align-items-end row3">
                                <span className='total'>Total Cost Per Month</span>
                                <span className='price'>$463</span>
                            </div>
                            <hr />
                            <div className="d-flex flex-row  justify-content-between row3 align-items-center">
                                <div className="info-all">
                                    <img src={PacLife} alt='' />
                                    <span className>
                                        Pac Life Next
                                    </span>
                                </div>
                                <span className="data-price">
                                    $47
                                </span>
                            </div>
                            <div className="d-flex flex-row justify-content-between row3 align-items-center">
                                <div className="info-all">
                                    <img src={PacLife} alt='' />
                                    <span>
                                        Pac Life Next
                                    </span>
                                </div>
                                <span className="data-price">
                                    $47
                                </span>

                            </div>
                        </div>
                    </div>

                    <div className="custom-bubble">
                        <div className="text-center">Don’t see what you’re looking for?</div>
                        <button className='orangeGradient'>
                            Create your own Bubble
                        </button>
                        <img src={Character3} alt="" />
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default Page3;