import React from 'react';
import { Container, Row, Form } from 'react-bootstrap';

import HealthidsDataHandler from "./HealthCausesQuestionDataHandler";

export default class Healthids extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            question: '',
            idTag1: '',
            idTag2: '',
            idType: '',
            questionAgeTag4: '',
            questionAgeTag5: '',
            questionDependentQuestionId: '',
            questionRootQuestion: '',
            questionAnswerType: '',
            questionYesDependent: '',
            questionNoDependent: '',
            questionOrder: '',
            selectedIndex: "",
            isEditMethod: false,
            dataArray: []
        }
        this.listData();
        this.listData = this.listData.bind(this);
    }

    resetForm = () => {
        this.setState({
            id: '',
            question: '',
            idTag1: '',
            idTag2: '',
            idType: '',
            questionAgeTag4: '',
            questionAgeTag5: '',
            questionDependentQuestionId: '',
            questionRootQuestion: '',
            questionAnswerType: '',
            questionYesDependent: '',
            questionNoDependent: '',
            questionOrder: '',
            selectedIndex: "",
            isEditMethod: false
        })
    }

    saveData = () => {
        let data = {};
        data.id = this.state.id;
        data.question = this.state.question;
        data.idTag1 = this.state.idTag1;
        data.idTag2 = this.state.idTag2;
        data.idType = this.state.idType;
        data.questionAgeTag4 = this.state.questionAgeTag4;
        data.questionAgeTag5 = this.state.questionAgeTag5;
        data.questionDependentQuestionId = this.state.questionDependentQuestionId;
        data.questionRootQuestion = this.state.questionRootQuestion;
        data.questionAnswerType = this.state.questionAnswerType;
        data.questionYesDependent = this.state.questionYesDependent;
        data.questionNoDependent = this.state.questionNoDependent;
        data.questionOrder = this.state.questionOrder;

        if (this.state.isEditMethod) {
            //    let replaceObject = Object.assign({},this.state.dataArray[this.state.selectedIndex],data);
            let replaceObject = { ...this.state.dataArray[this.state.selectedIndex], ...data };

            let array = this.state.dataArray.map((obj, i) => {
                return i === +this.state.selectedIndex ? replaceObject : obj
            })
            this.setState({
                dataArray: array,
            }, () => this.resetForm())
        } else {
            this.setState({
                dataArray: [...this.state.dataArray, data],
            }, () => this.resetForm())
        }
    }

    editForm = (e) => {
        let i = e.target.dataset.index;

        this.setState({
            selectedIndex: i,
            isEditMethod: true,
            id: this.state.dataArray[i].id,
            question: this.state.dataArray[i].question,
            idTag1: this.state.dataArray[i].idTag1,
            idTag2: this.state.dataArray[i].idTag2,
            idType: this.state.dataArray[i].idType,
            questionAgeTag4: this.state.dataArray[i].questionAgeTag4,
            questionAgeTag5: this.state.dataArray[i].questionAgeTag5,
            questionDependentQuestionId: this.state.dataArray[i].questionDependentQuestionId,
            questionRootQuestion: this.state.dataArray[i].questionRootQuestion,
            questionAnswerType: this.state.dataArray[i].questionAnswerType,
            questionYesDependent: this.state.dataArray[i].questionYesDependent,
            questionNoDependent: this.state.dataArray[i].questionNoDependent,
            questionOrder: this.state.dataArray[i].questionOrder,
        })
    }

    listData() {
        // HealthidsDataHandler.listAndUpdateOneColumn();
        let fromIndex = 0;
        let toIndex = 25;
        HealthidsDataHandler.listData(fromIndex, toIndex).then((array) => {
            //  console.log("array: ", array, METHOD_NAME, CLASS_NAME);
            this.setState({ dataArray: array });
        });
    }
    render() {
        return (
            <>
                <Container>
                    Question
                </Container>
                <Container className='border border-dark rounded-lg p-3'>
                    {
                        this.state.isEditMethod ?
                            <>  Editing a record </> : <>Adding new record</>
                    }
                    <Form>
                         <Row className='m-2'>
                            <span> Id:  <input type='text'
                                value={this.state.id} onChange={(e) => (
                                    this.setState({
                                        id: e.target.value
                                    })
                                )} /> &nbsp;
                     </span>
                            <span> Question: <input type='text'
                                value={this.state.question} onChange={(e) => (
                                    this.setState({
                                        question: e.target.value
                                    })
                                )} /> &nbsp;</span>
                            <span> Cause Tag1: <input type='text'
                                value={this.state.idTag1} onChange={(e) => (
                                    this.setState({
                                        idTag1: e.target.value
                                    })
                                )} /> &nbsp;</span>

                            <span> Cause Tag2: <input type='text'
                                value={this.state.idTag2} onChange={(e) => (
                                    this.setState({
                                        idTag2: e.target.value
                                    })
                                )} /> &nbsp;</span>
                        </Row> 
                        <Row className='m-2'>
                            <span> Cause Type: <input type='text'
                                value={this.state.idType} onChange={(e) => (
                                    this.setState({
                                        idType: e.target.value
                                    })
                                )} /> &nbsp;</span>
                            <span> Age Tag4: <input type='text'
                                value={this.state.questionAgeTag4} onChange={(e) => (
                                    this.setState({
                                        questionAgeTag4: e.target.value
                                    })
                                )} /> &nbsp;</span>

                            <span> Age Tag5: <input type='text'
                                value={this.state.questionAgeTag5} onChange={(e) => (
                                    this.setState({
                                        questionAgeTag5: e.target.value
                                    })
                                )} /> &nbsp;</span>
                        </Row> 
                        <Row className='m-2'>
                            <span> Dependent Question Id: <input type='text'
                                value={this.state.questionDependentQuestionId} onChange={(e) => (
                                    this.setState({
                                        questionDependentQuestionId: e.target.value
                                    })
                                )} /> &nbsp;</span>

                            <span> Root Question: <input type='text'
                                value={this.state.questionRootQuestion} onChange={(e) => (
                                    this.setState({
                                        questionRootQuestion: e.target.value
                                    })
                                )} /> &nbsp;</span>

                            <span> Answer Type: <input type='text'
                                value={this.state.questionAnswerType} onChange={(e) => (
                                    this.setState({
                                        questionAnswerType: e.target.value
                                    })
                                )} /> &nbsp;</span>
                        </Row> 
                        <Row className='m-2'>
                            <span> Yes Dependent: <input type='text'
                                value={this.state.questionYesDependent} onChange={(e) => (
                                    this.setState({
                                        questionYesDependent: e.target.value
                                    })
                                )} /> &nbsp;</span>
                            <span> No Dependent: <input type='text'
                                value={this.state.questionNoDependent} onChange={(e) => (
                                    this.setState({
                                        questionNoDependent: e.target.value
                                    })
                                )} /> &nbsp;</span>
                            <span>  Order: <input type='text'
                                value={this.state.questionOrder} onChange={(e) => (
                                    this.setState({
                                       questionOrder: e.target.value
                                    })
                                )} /> &nbsp;</span>
                        </Row>
                    </Form>
                    <Row className='flex flex-row-reverse mr-4'>
                        <button className='buttonRounded' type='button' onClick={this.saveData}>
                            Save
                        </button>
                    </Row>
                </Container>

                <table className='mt-5 w-100' >
                    <thead>
                        <tr className='flex justify-content-around border border-dark'>
                            <td>Id</td>
                            <td>Question</td>
                            <td>Cause Tag1</td>
                            <td>Cause Tag2</td>
                            <td>Cause Type</td>
                            <td>Age Tag4</td>
                            <td>Age Tag5</td>
                            <td>Dependent Question Id</td>
                            <td>Root Question</td>
                            <td>Answer Type </td>
                            <td>Yes Dependent</td>
                            <td>No Dependent</td>
                            <td>Order</td>
                            <td>Actions</td>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            this.state.dataArray.map((d, i) => (
                                <tr key={i} className='flex justify-content-around border border-dark'>
                                    <td className='border border-dark'>{d.id}</td>
                                    <td className='border border-dark'>{d.question}</td>
                                    <td className='border border-dark'>{d.idTag1}</td>
                                    <td className='border border-dark'>{d.idTag2}</td>
                                    <td className='border border-dark'>{d.idType}</td>
                                    <td className='border border-dark'>{d.questionAgeTag4}</td>
                                    <td className='border border-dark'>{d.questionAgeTag5}</td>
                                    <td className='border border-dark'>{d.questionDependentQuestionId}</td>
                                    <td className='border border-dark'>{d.questionRootQuestion}</td>
                                    <td className='border border-dark'>{d.questionAnswerType} </td>
                                    <td className='border border-dark'>{d.questionYesDependent}</td>
                                    <td className='border border-dark'>{d.questionNoDependent}</td>
                                    <td className='border border-dark'>{d.questionOrder}</td>
                                    <td className='border border-dark'><button  className='buttonRounded' onClick={this.editForm} data-index={i}>Edit</button></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </>
        );
    }
}
