import React from "react";
import { Container, Col, Row, ProgressBar } from "react-bootstrap";

import SearchDetails from "./SearchDetails.component";
//import GooglePlacesAutocomplete from "react-google-places-autocomplete";;
import awsExports from "../aws-exports";
import { Amplify } from '@aws-amplify/core';
import { API, graphqlOperation } from '@aws-amplify/api';
//import { deviceDetect } from 'react-device-detect';

import AddressParser from 'parse-address';
import { BallRotate } from 'react-pure-loaders';
import GlobalConstants from "../GlobalConstantsVariables";
// import PrimaryResidenceDetails from "./PrimaryResidenceDetails.component";
import InsuranceFilter from "./InsuranceFilter.component";
import HomeInsuranceDetails from "./HomeInsuranceDetails.component";
//import IndividualDetails from "./IndividualDetails.component";
import IndividualDetailsV2 from "./IndividualDetailsV2.component";
import Questionnaire from "./QuestionGenerator.component";
import Step3Component from "./Step3Extenson.Component";
//import MortgageComponent from "./Mortgage.component";
import LifeInsuranceDetails from "./LifeInsuranceDetails.component"
import PropertyDetails from "./PropertyDetails.component";
import HeaderTopBar from "./HeaderV3.component";
import LifeInsurance from "./LifeInsurance.component";
import Register from './Register';
//import HeaderTopBar from "./HeaderTopBar.component";
//import IconSideBar from "./IconSidebar.component";
//import BottomBar from "./BottomBar.component";
import ComponentHelper from "./ComponentHelper";
//import ValueSlider from '../components/Slider.Component';
//import Charts from "./charts";
import BubbleDoughnut from "./BubbleDoughnut.component";
import ExtensionLabels from "./ExtensionLabels.component";
import { updateAppData } from '../graphql/mutations';
import Utility from "./Utility";

import arrow_back from "./assets/icon-back-white.svg";
// import arrow_back_grey from "./assets/icon-back-grey.svg";
// import life_inactive from "./assets/icon-li-inactive.svg"
// import life_active from "./assets/icon-li-active.svg"
// import home_inactive from "./assets/icon-hi-inactive.svg"
// import home_active from "./assets/icon-hi-active.svg"
// import profile from "./assets/icon-pi-active.jpg"
//import personal_inactive from "./assets/icon-pi-inactive.jpg";
import group2 from "./assets/group-2.svg";


import '../styles/extensionStylesheet.scss';
import "../App.scss";
//import WhatIfScenarioV3 from "./WhatIfScenarioV3.component";
import AgentHeader from "./HeaderAgentBranding.component";
Amplify.configure(awsExports);

const AWS_API_GATEWAY = 'https://qaubc7vbg1.execute-api.us-west-2.amazonaws.com'; // eslint-disable-line
const DOMAIN_NAME = "https://alpha.getmybubble.io"; // eslint-disable-line
const PROXY_URL = "https://cors-anywhere.herokuapp.com/"; // eslint-disable-line
let dbIdLocal = `session-${new Date().getTime()}-${Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000}`;
const year = new Date().getFullYear();
let classLevelSessionId = dbIdLocal;
let domain = "http://localhost:3000";
let classLevelItem;
let defaultValuesHash = {};
const CLASS_NAME = ":MainComponent3Column:";

//const localEverCookie = new EverCookie('homecopilot');  // 'bubble' is a placeholder uid which should stay same across sessions

function initializeUpdateClientData(inputJson) {
    let METHOD_NAME = ":initializeUpdateClientData";
    console.log("*&* Entered/Before getCachedData2:inputJson: ", inputJson, METHOD_NAME, CLASS_NAME);

    // Initialize client_id -> JSON {
    //   "cacheId": "1205b53167183a61923a12811a86141++All",
    //   "clientId": "1205b53167183a61923a12811a86141",
    //   "createdAt": "2020-10-05 16:08:22",
    //   "dataCategory": "client_data_all",
    //   "eventStr": "MainComponent3ColumnInsert",
    //   "id": "1205b53167183a61923a12811a86141",
    //   "uid": "3a539ab7-0498-4407-b0e1-9528dbb27b42"
    // }
    let localJson = inputJson;
    const key4CacheId = `${localJson.clientId}++All`;
    // If the Cached Data for clientId++All is missing means in INSERT DynamoDB
    ComponentHelper.getCachedData2(key4CacheId).then((response) => {
        METHOD_NAME += ":getCachedData2.then:";
        console.log("^%^ response: ", response, METHOD_NAME, CLASS_NAME);
        let json = JSON.parse(response.Payload);
        console.log("^%^ json.body.output: ", json.body.output, METHOD_NAME, CLASS_NAME);
        // If the Cached Data missing or empty
        if (json.body.output === "" || json.body.output === {}) {
            METHOD_NAME += ":emptyCache:";

            localJson.uid = localJson.clientId;
            localJson.id = localJson.clientId;
            localJson.cacheId = key4CacheId;
            localJson.dataCategory = GlobalConstants.getClientDataCategoryAll();
            localJson.createdAt = Utility.getCurrentDate('db_format');
            localJson.eventStr = CLASS_NAME.replace(/:/g, '') + 'Insert';
            ComponentHelper.saveAppDataAndCache(localJson);
        } else {
            // Update DynamoDB and Cache with current + input fullAddress
            METHOD_NAME += ":update.prepare:";
            // Cached data is String -> Json -> AddressStr -> Address JSON -> append fullAddress
            // append fullAddress -> AddressJSON -> String -> add to JSON Send for DynamoDB
            let cachedJsonStr = json.body.output.replace(/__-__/g, '"');
            console.log('*&* cachedJsonStr: ', cachedJsonStr, METHOD_NAME, CLASS_NAME);
            let cachedJson;
            try {
                cachedJson = JSON.parse(cachedJsonStr);
            } catch (error) {
                // handling: "adddddresses": "[{""createdAt"":"2020 - 11 - 13 16: 00: 10",""fullAddress"":"3156 Ramona St Palo Alto CA 94306"}]",
                cachedJsonStr = Utility.patchBrokenData1(cachedJsonStr);
                console.log('*&* cachedJsonStr2: ', cachedJsonStr, METHOD_NAME, CLASS_NAME);
                cachedJson = JSON.parse(cachedJsonStr);
            }
            cachedJson['health4LifeInsurance'] = +cachedJson['health4LifeInsurance']
            cachedJson['policyType'] = +cachedJson['policyType'];
            console.log('cachedJson: ', cachedJson, METHOD_NAME, CLASS_NAME);
            ComponentHelper.updateAppDataAndCache(GlobalConstants.getClientDataCategoryAll(), cachedJson);
        }
    });

}
function processAndCacheDwellingCost(fullAddress, bath, livingsizeSqft) {
    let METHOD_NAME = ":processAndCacheDwellingCost:";
    // Google API Response structure 7084 Horizon Circle, Windermere, FL, USA
    let splittedAddress = AddressParser.parseLocation(fullAddress);
    // { number: '1005', prefix: 'N', street: 'Gravenstein', type: 'Hwy', city: 'Sebastopol', state: 'CA', zip: '95472' }
    let bathFull = bath != null ? parseInt(bath.toString()) : '1';
    let bathHalf = bath.toString().endsWith('.5') ? 1 : 0;
    let inputAddressDetails = {
        "propnum": "0", "month": "1", "day": "1", "year": (new Date()).getFullYear(),
        "address": fullAddress, "zip": splittedAddress.zip, "quality_code": "1", "gla": livingsizeSqft,
        "bath_full": bathFull, "bath_half": bathHalf, "stories": "1", id: dbIdLocal
    };

    ComponentHelper.getDwellingCost(inputAddressDetails).then((response) => {
        METHOD_NAME += ":getDwellingCost.then:";
        console.log("*&* response: ", response, METHOD_NAME, CLASS_NAME);
        let json = JSON.parse(response.Payload);
        console.log("*&* json.body: ", json.body, METHOD_NAME, CLASS_NAME);
        if (json.body != null && json.body.output != null) {
            let value = json.body.output;
            let key = inputAddressDetails.address.replace(' ', '--');
            console.log("*&* key:", key, '# value: ', value, METHOD_NAME, CLASS_NAME);
            GlobalConstants.setInCache(key, value);
        } else {
            console.log("*&* FAILED to calculate Dwelling cost inputAddressDetails.address:", inputAddressDetails.address, METHOD_NAME, CLASS_NAME);
        }
    });
}
// Not used processAndCacheHomeInsuranceAmount(idLocal, listingPriceAmount, splittedAddress.zip, '');
// It is damaging: listingPrice cannot be used as Dwelling cost
function processAndCacheHomeInsuranceAmount(id, listingPriceAmount, zip, liabilityAmount) { // eslint-disable-line
    let METHOD_NAME = ":processAndCacheHomeInsuranceAmount:";
    let liabilityAmountLocal = liabilityAmount == null ? '' : liabilityAmount;
    console.log("Input:id:", id, " #listingPriceAmount :", listingPriceAmount, " #zip :", zip, " #liabilityAmount :", liabilityAmount, METHOD_NAME, CLASS_NAME);

    let inputJson = {
        "id": id, "liabilityAmount": liabilityAmountLocal,
        "dwelling_result": listingPriceAmount, "zip": zip
    };
    ComponentHelper.fetchAndSaveHomeInsurance(inputJson);
}
export default class MainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.dataPage2 = React.createRef();
        this.onChangeAmountsFromHash = this.onChangeAmountsFromHash.bind(this);
        this.onChangeItem = this.onChangeItem.bind(this);
        this.setFullAddress = this.setFullAddress.bind(this);
        this.setExtension = this.setExtension.bind(this);
        this.navigateToNextPage = this.navigateToNextPage.bind(this);
        this.setStateIdFromLocal = this.setStateIdFromLocal.bind(this);
        this.waitAndCallGetLifeInsuranceScore = this.waitAndCallGetLifeInsuranceScore.bind(this);
        this.getLifeInsuranceScore = this.getLifeInsuranceScore.bind(this);
        this.updateAddressDetailView = this.updateAddressDetailView.bind(this);
        this.onClickOfCustomize = this.onClickOfCustomize.bind(this);
        this.getNewTabWithMortgage = this.getNewTabWithMortgage.bind(this);
        this.onChangeBirthYear = this.onChangeBirthYear.bind(this);
        this.setStateAddressHistory = this.setStateAddressHistory.bind(this);
        let METHOD_NAME = ":constructor";
        let livingsizeSqftRounded;
        console.log("window.location.href: ", window.location.href, METHOD_NAME, CLASS_NAME);
        domain = `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}`;

        let intermediateState = {
            isLoading: true,
            coverageDesired: 800,
            calculatedCoverage: 0,
            stateCode: '',
            fullName: '',
            email: '',
            phone: '',
            selectedCoveragesArray: [],
            mortgage: 700,
            termDesired: 20,
            age: 35,
            bath: 1,
            gender: 0,
            health: 2,
            smoker: false,
            birthYear: year - 35,
            addressHistoryArray: [],
            visibleComponent: 3,
            insurance: 0,
            homeInsuranceAmount: "",
            utilityAmount: "",
            whatIfScenarioDataArray: [],
            charts: false,
            loanType: 30,
            extension: '0',
            extensionData: false,
            fullAddress: '',
            queryParamHash: '',
            address: false, addressdetails: true, activeView: true, skippedStep1: true,
            sessionId: classLevelSessionId,
            id: dbIdLocal,
            displayPacLifeNextQuote: !(domain.includes('localhost'))
        };
        // Display packLife quote in testing mode only.displayPacLifeNextQuote = TRUE
        const cipher = window.location.href.split('/')[2];
        console.log("cipher: ", cipher, " # domain: ", domain, METHOD_NAME, CLASS_NAME);
        console.log("this.props.search: ", this.props.location.search, METHOD_NAME, CLASS_NAME);
        let queryParameters;//  = this.props.location.search;
        queryParameters = this.props.match.params.docketId;
        console.log("queryParameters: ", queryParameters, METHOD_NAME, CLASS_NAME);

        if (queryParameters != null) {
            const { docketIdStr, extension, fullAddress,
                clientId, os, info, vendor, bed, bath, livingsizeSqft, yearBuilt, listingPriceAmount, sourceUrl, continuationId } = ComponentHelper.parseQueryParameters(queryParameters, cipher);
            console.log("$#$ listingPriceAmount:", listingPriceAmount, " # fullAddress: ", fullAddress, " # extension: ", extension, "#clientId:", clientId, "#continuationId:", continuationId, METHOD_NAME, CLASS_NAME);
            let idLocal, splittedAddress;
            if (fullAddress != null) {
                // Google API Response structure 7084 Horizon Circle, Windermere, FL, USA
                splittedAddress = AddressParser.parseLocation(fullAddress);
                // { number: '1005', prefix: 'N', street: 'Gravenstein', type: 'Hwy', city: 'Sebastopol', state: 'CA', zip: '95472' }

                initializeUpdateClientData({ clientId: clientId, fullAddress: fullAddress, dataCategory: GlobalConstants.getClientDataCategoryAll() });
                intermediateState.docketIdStr = docketIdStr;
                intermediateState.fullAddress = fullAddress;
                intermediateState.doorNumber = splittedAddress.number == null ? '' : splittedAddress.number;
                intermediateState.streetPrefix = splittedAddress.prefix == null ? '' : splittedAddress.prefix;
                intermediateState.street = splittedAddress.street;
                intermediateState.streetType = splittedAddress.type;
                intermediateState.city = splittedAddress.city;
                intermediateState.stateCode = splittedAddress.state;
                //'getQuotesFromPacLifeNext': ["DONE:solicitationState", "faceAmount", "riskClass", "gender", "premiumFrequency", "dateOfBirth", "riskClass", "productCode"
                GlobalConstants.setAPIInputJson(GlobalConstants.getPacLifeNextAPITag(), { 'stateCode': splittedAddress.state });
                // intermediateState.clientAddressHistory = {}
                // intermediateState.clientAddressHistory.createdAt = Utility.getCurrentDate('db_format')
                // intermediateState.clientAddressHistory.id = clientId;
                // intermediateState.clientAddressHistory.dataCategory = GlobalConstants.getClientDataCategoryAll();
                intermediateState.zip = splittedAddress.zip;
                intermediateState.extension = extension;
                intermediateState.localAddress = fullAddress;
                intermediateState.primaryResidence = "Yes";
                intermediateState.dataCategory = "extension_searched";
                intermediateState.eventStr = "extension_searched";
                intermediateState.searchString = this.props.location.search;
                intermediateState.clientId = clientId;
                intermediateState.os = os;
                intermediateState.info = info;
                intermediateState.vendor = vendor;
                intermediateState.yearBuilt = yearBuilt;
                intermediateState.bed = bed;
                intermediateState.bath = bath;
                this.setDefaultValues('yearBuilt', yearBuilt);
                this.setDefaultValues('bed', bed);
                this.setDefaultValues('bath', bath);
                livingsizeSqftRounded = (livingsizeSqft / 50).toFixed() * 50;
                intermediateState.livingsizeSqft = livingsizeSqftRounded;
                this.setDefaultValues('livingsizeSqft', livingsizeSqftRounded);
                intermediateState.listingPriceAmount = listingPriceAmount;
                if (listingPriceAmount) {
                    intermediateState.avmAmount = listingPriceAmount;
                    intermediateState.mortgageAmount = Utility.calculateMortgageAmount(listingPriceAmount, true, null, null);
                    this.setDefaultValues('avmAmount', listingPriceAmount);
                    this.setDefaultValues('listingPriceAmount', listingPriceAmount);
                    this.setDefaultValues('mortgageAmount', intermediateState.mortgageAmount);
                }
                intermediateState.sourceUrl = sourceUrl;
                intermediateState.continuationId = continuationId;
                // Making sure that valid continuationId id set
                if (continuationId != null && continuationId !== '0') {
                    intermediateState.sessionId = continuationId;
                    intermediateState.id = continuationId;
                    dbIdLocal = continuationId;
                }
                if (extension.toString() === '0')
                    intermediateState.visibleComponent = 3;
            }
            if ((continuationId === '0' || continuationId == null) && clientId != null) {
                idLocal = `${clientId}-${splittedAddress.number}-${splittedAddress.street}-${splittedAddress.type}-${splittedAddress.city}-${splittedAddress.state}`;
                intermediateState.sessionId = idLocal;
                intermediateState.id = idLocal;
                dbIdLocal = idLocal;
            } else if (continuationId != null && continuationId !== '0') {
                console.log('Continuation from extension for: continuationId:', continuationId, ' # clientId: ', clientId, METHOD_NAME, CLASS_NAME);
                idLocal = `${continuationId}-${splittedAddress.number}-${splittedAddress.street}-${splittedAddress.type}-${splittedAddress.city}-${splittedAddress.state}`;
                intermediateState.sessionId = idLocal;
                intermediateState.id = idLocal;
                dbIdLocal = idLocal;
            }
            if (fullAddress != null && bath != null && livingsizeSqft != null) processAndCacheDwellingCost(fullAddress, bath, livingsizeSqft);
            let key, value;
            key = `${idLocal}++livingsizeSqft`;
            value = livingsizeSqftRounded;
            ComponentHelper.setDataInCache(key, value);
            if (listingPriceAmount != null) {
                key = `${idLocal}++listingPriceAmount`;
                value = listingPriceAmount;
                ComponentHelper.setDataInCache(key, value);
            }
            if (fullAddress != null && fullAddress.trim().length > 0) {
                ComponentHelper.saveAppDataAndCache(intermediateState);
            }
        } else {
            console.log('this.props.location.search == null:', METHOD_NAME, CLASS_NAME);
        }

        this.state = intermediateState;
        console.log('Leaving', this.state, METHOD_NAME, CLASS_NAME);
    }

    setStateIdFromLocal = (value) => {
        this.setState({
            id: value
        })
    }
    setActive = (value) => {
        this.setState({
            activeItem: value
        })
    }

    onSearchDetailsChange = (e) => {
        this.setState({
            address: e.description
        })
    }

    onPrimaryResidenceDetailsChange = (e) => {
        this.setState(prevState => ({
            primaryResidence: !prevState.primaryResidence
        }));
    }

    onCoverageDesiredChange = (e) => {
        this.setState({
            coverageDesired: e
        });
    }

    onSelectedCoveragesArrayChange = (array) => {
        this.setState({
            selectedCoveragesArray: array
        })
    }

    onMortgageChange = (e) => {
        this.setState({
            mortgage: e
        });
    }

    onChangeAge = (age) => {
        let birthYear = Utility.getBirthYearFromAge(age);

        this.setState({
            age,
            birthYear
        })
    }

    onChangeBirthYear = (birthYear) => {
        let age = Utility.getAgeFromBirthYear(birthYear);
        console.log("onChangeBirthYear", CLASS_NAME, birthYear, age)

        this.setState({
            age,
            birthYear
        })
    }

    getAllHistoryAddresses = () => {
        let data = ComponentHelper.getCachedData2("clientAddressHistory");
        data.then((resp) => {
            console.log("Debug getAllHistoryAddresses", resp)
        })
    }

    getAddressHistory = () => {
        /*   let METHOD_NAME = ":getAddressHistory :";

           console.log("Debug getAddressHistory ", METHOD_NAME, CLASS_NAME);
           let addressHistorySet = new Set();

           let key = `${this.state.clientId}++All`;
           ComponentHelper.getCachedData2(key).then((response) => {
               METHOD_NAME += ":getCachedData2.then:";
               // console.log("response: ", response, METHOD_NAME, CLASS_NAME);
               let json = JSON.parse(response.Payload);
               console.log("json.body.output: ", json.body.output, METHOD_NAME, CLASS_NAME);
               METHOD_NAME += ":setState.prepare:";
               let stateInputJsonStr = json.body.output.replace(/__-__/g, '"');
               // Patch to fix failed addresses extraction:
               let alreadyJsonFlag = false;
               if (stateInputJsonStr.indexOf('_-_-_') === -1 && stateInputJsonStr.indexOf('"addresses":"[') !== -1 && stateInputJsonStr.indexOf(']"}') !== -1) {
                   stateInputJsonStr = stateInputJsonStr.replace('"addresses":"[', '"addresses":[').replace(']"}', ']}');
                   alreadyJsonFlag = true;
               }
               stateInputJsonStr = Utility.patchBrokenData1(stateInputJsonStr);
               let stateInputJson = JSON.parse(stateInputJsonStr);
               console.log("stateInputJson: ", stateInputJson, METHOD_NAME, CLASS_NAME);
               let addressesJsonStrLocal = stateInputJson['addresses'];
               if (addressesJsonStrLocal == null || addressesJsonStrLocal.length === 0) { addressesJsonStrLocal = '[]'; }
               let hist;
               if (!alreadyJsonFlag) {
                   hist = JSON.parse(addressesJsonStrLocal.replace(/_-_-_/g, '"'));
               } else {
                   hist = addressesJsonStrLocal;
               }
               hist.forEach((obj) => addressHistorySet.add(obj['fullAddress']));
               Object.entries(stateInputJson).forEach(([key, value]) => {
                   if (key.startsWith('address-') && !key.endsWith('createdAt')) {
                       addressHistorySet.add(value);
                   }
               });
               let addressHistoryArray = [...addressHistorySet];

               this.setState({ addressHistoryArray });
               console.log("addressHistoryArray:", addressHistoryArray, METHOD_NAME, CLASS_NAME);
           }); */
    }



    // add Searched Address to ClientSearchHistory
    updateClientSearchHistory = async (inputFullAddress) => {

        let addressHistoryArray = new Set([inputFullAddress]);
        this.state.addressHistoryArray.forEach((address) => { if (address) addressHistoryArray.add(address) })
        this.setState({ addressHistoryArray: [...addressHistoryArray] })

        let key = `${this.state.clientId}++All`;
        let METHOD_NAME = ":updateClientSearchHistory:";
        let response = await ComponentHelper.getCachedData2(key)//.then((response) => {
        METHOD_NAME += ":getCachedData2.then:";
        // console.log("response: ", response, METHOD_NAME, CLASS_NAME);
        let json = JSON.parse(response.Payload);
        console.log("json.body.output: ", json.body.output, METHOD_NAME, CLASS_NAME);
        METHOD_NAME += ":setState.prepare:";
        let cachedJsonStr = json.body.output.replace(/__-__/g, '"');

        let startindex = cachedJsonStr.indexOf('"addresses');
        let finalindex = cachedJsonStr.indexOf(`}]"`, startindex);
        const addressString = cachedJsonStr.substring(startindex + 12, finalindex + 3);
        console.log(addressString.split(`"`), METHOD_NAME, CLASS_NAME);
        let obj1 = {}
        let objectHelper = 0;
        let addressArray = [{ "createdAt": Utility.getCurrentDate('db_format'), "fullAddress": inputFullAddress }];

        addressString.split(`"`).forEach(string => {
            if (string.length > 3) {  // all important strings (fullAddress, createdAt, values) have length > 3
                if (objectHelper % 4 === 0) {
                    obj1 = { [string]: '' };        //(fullAddress / createdAt)
                } else if (objectHelper % 4 === 2) {
                    obj1[string] = '';            //(fullAddress / createdAt)
                } else if (objectHelper % 2 === 1) {
                    for (key in obj1) {
                        if (!obj1[key]) {
                            obj1[key] = string;   // values
                        }
                    }
                }
                if (objectHelper % 4 === 3) {
                    addressArray.push(obj1)       //push Object in Array
                }

                objectHelper++;
            }
        })

        console.log(addressArray, METHOD_NAME, CLASS_NAME)

        cachedJsonStr = cachedJsonStr.replace(addressString, '').replace('"addresses":,', '')
        let cachedJson = JSON.parse(cachedJsonStr); //
        cachedJson['fullAddress'] = inputFullAddress;
        cachedJson['addresses'] = Utility.stringifyAddresses(addressArray);
        cachedJson['updatedAt'] = Utility.getCurrentDate('db_format');
        console.log("cachedJson: ", cachedJson, METHOD_NAME, CLASS_NAME);
        ComponentHelper.updateAppDataAndCache(GlobalConstants.getClientDataCategoryAll(), cachedJson);
    }

    setStateAddressHistory(addressHistoryArray, addressHistory) {
        console.log("getAndUpdateAddresses:addressHistory: ", addressHistory, 'setStateAddressHistory', CLASS_NAME);
        this.setState({ addressHistoryArray: addressHistoryArray, addressHistory: addressHistory });
    }

    onTermDesiredChange = (e) => {
        this.setState({
            termDesired: e
        });
    }

    setVisibleComponent = (value) => {
        this.setState({
            visibleComponent: value
        })
    }

    navigateToNextPage = () => {
        let METHOD_NAME = ":navigateToNextPage";
        console.log("Entered", METHOD_NAME, CLASS_NAME);

        if (classLevelItem != null) {
            //one call to calculate and update AppData || Cache
            ComponentHelper.processAndCalculateAmounts(classLevelItem).then((response3) => {
                console.log("response3: ", response3, METHOD_NAME, CLASS_NAME);
            });
        }
        this.setVisibleComponent(3);
        //e.preventDefault();
        //e.stopPropagation();
    }

    getLifeInsuranceScore = async () => {
        let ageRange = Utility.getFormattedAge(this.state.age);
        let METHOD_NAME = "getLifeInsuranceScore:";
        this.setState({ isLoading: false });
        // let queryParameterArray = new Array();
        // let searchResults = null;
        // queryParameterArray.push(this.state.coverageDesired);
        // queryParameterArray.push(ageRange);
        // queryParameterArray.push(this.state.termDesired);
        // queryParameterArray.push(this.state.gender === 'O' ? 'M' : this.state.gender);
        // queryParameterArray.push(this.state.health);
        console.log(CLASS_NAME, METHOD_NAME, "health:", this.state.health);
        console.log(CLASS_NAME, METHOD_NAME, "gender:", this.state.gender);
        let response; // eslint-disable-line
        // let queryString;

        /*const searchParams = new URLSearchParams();
        searchParams.set('query', queryParameterArray.join("_"));
        this.props.history.push(`?${searchParams.toString()}`);*/

        // queryString = queryParameterArray.join("__");
        // console.log("queryString: ", queryString, CLASS_NAME, METHOD_NAME);
        // Patch avmAmount = 0 Issue
        // queryString = "300000__40 - 45__25__M__super_healthy";
        // 300000__40 - 45__25__M__super_healthy coverage	age	term	gender	super_healthy
        // 350000__31%20-%2035__35__M__healthy
        // 350000__31%20-%2035__20____super_healthy

        // if (queryString.indexOf("--") == -1) {
        //     let url = `${DOMAIN_NAME}/bubbleLifeInsurance/search/${queryString}`;
        //     response = await axios.get(url, {
        //         headers: {
        //             accept: "application/json",
        //             apikey: "b0ea821699eaeb01ebea45dc96a547e8",
        //         },
        //     }).then((response) => {
        //         METHOD_NAME += 'then:';
        //         console.log(CLASS_NAME, METHOD_NAME, "response: ", JSON.stringify(response, null, " "));
        //         console.log(response)
        //         searchResults = response.data.map((i) => (
        //             this.setState({
        //                 insurance: i.avg_output
        //             })
        //         ));
        //     }, (error) => {
        //         console.log(CLASS_NAME, METHOD_NAME, "error:", error);
        //     });
        // } else {
        //     console.log("bubbleLifeInsurance/search NOT invoked");
        // }
        // console.log("ageRange: ", ageRange, METHOD_NAME, CLASS_NAME);
        let json = {
            id: dbIdLocal,
            fullAddress: this.state.fullAddress,
            coverage: this.state.calculatedCoverage,
            termDesired: this.state.termDesired,
            primaryResidence: this.state.primaryResidence,
            gender: this.state.gender,
            age: ageRange,
            health: this.state.health,
            smoker: this.state.smoker,
            eventStr: "AddressDetailsFilledIn"
        };
        console.log("json: ", json, METHOD_NAME, CLASS_NAME);
        let chartDataArray = [];
        let jsonPlaceHolder = {};
        if (dbIdLocal.startsWith('0-')) return;
        API.graphql(graphqlOperation(updateAppData, { input: json })).then((updateDataResponse) => {
            METHOD_NAME = "updateAppData.then:";
            console.log("response2: ", JSON.stringify(updateDataResponse, null, " "), METHOD_NAME, CLASS_NAME);
            let updatedJson;

            if (updateDataResponse != null && updateDataResponse.data != null && (updatedJson = updateDataResponse.data.updateAppData) != null) {
                console.log("updatedJson: ", updatedJson, METHOD_NAME, CLASS_NAME);
                Object.entries(updatedJson).forEach(([key, value]) => {
                    if (key === 'lifeInsuranceAmount' && value == null) {
                        jsonPlaceHolder[key] = 0;
                    } else {
                        jsonPlaceHolder[key] = value;
                    }
                });

                jsonPlaceHolder['lifeInsurance'] = this.state.insurance;
                chartDataArray.push(jsonPlaceHolder);
                this.setState({
                    whatIfScenarioDataArray: chartDataArray,
                    extensionData: true
                });
                console.log("whatIfScenarioDataArray:", this.state.whatIfScenarioDataArray, METHOD_NAME, CLASS_NAME);
            }
        });
    }

    waitAndCallGetLifeInsuranceScore() {
        this.setState({
            isLoading: true
        });
        setTimeout(() => { this.getLifeInsuranceScore(); }, 1000);
    }
    updateAddressDetailView = (e) => {
        const METHOD_NAME = ":updateAddressDetailView";
        console.log("Entered", METHOD_NAME, CLASS_NAME);
        //e.preventDefault();
        //e.stopPropagation();
        this.setState({
            charts: true, extensionData: true
        });
        // this.getLifeInsuranceScore();
    };


    getIp = async () => {
        let url = 'http://ip-api.com/json';
        //city, isp :org, state: regionName, country, ipv4: query, timeZone: timezone, postalCode: zip

        try {
            let response = await fetch(url);
            let data = await response.text()//.then(( => {
            let body = JSON.parse(data);
            let deviceInfo = { ipv4: body['query'], city: body['city'], state: body['regionName'], country: body['country'], postalCode: body['zip'], isp: body['org'] };
            return deviceInfo;

        } catch (e) {
            console.log(e)
        }
    }

    onChangeDataArray = async (newName) => {
        let METHOD_NAME = ":onChangeDataArray:";
        console.log("newName:", newName, METHOD_NAME, CLASS_NAME);
        if (dbIdLocal != null && !dbIdLocal.startsWith('session')) {
            ComponentHelper.getData(dbIdLocal).then((array) => {
                console.log("array: ", array, METHOD_NAME, CLASS_NAME);
                this.setState({
                    whatIfScenarioDataArray: array,
                    coverageDesired: (Math.round(array[0].calculatedCoverage / 100000) * 100),
                    extensionData: true
                });
            });
        }
        console.log("this.state.whatIfScenarioDataArray: ", this.state.whatIfScenarioDataArray, METHOD_NAME, CLASS_NAME);
    }

    onChangeAmountsFromHash = async (dataCategory, inputJson) => {
        let METHOD_NAME = ":onChangeAmountsFromHash:";
        console.log("Input: dataCategory:", dataCategory, " # inputJson:", inputJson, METHOD_NAME, CLASS_NAME);
        let paramName, key, cachedValue, calculatedCoverageLocal;   // eslint-disable-line
        let jsonLocal = inputJson || {};
        calculatedCoverageLocal = jsonLocal.avmAmount != null ? Utility.calculateCoverage(jsonLocal.avmAmount) : 0;
        console.log("calculateAndSaveLifeInsuranceAmount:this.state.calculatedCoverage: ", this.state.calculatedCoverage, " # calculatedCoverageLocal:", calculatedCoverageLocal, METHOD_NAME, CLASS_NAME);
        if (jsonLocal.livingsizeSqft != null) {
            jsonLocal.isJsonReady4Persistence = false;
            // key = `${this.props.id}++utilityAmount`;
            // console.log("Input:utilityAmount:key: ", key, METHOD_NAME, CLASS_NAME);
            // ComponentHelper.getCachedData2(key).then((data) => {
            //     METHOD_NAME += ":utilityAmount:getCachedData2.then:";
            //     console.log("output:data: ", data, METHOD_NAME, CLASS_NAME);
            //     let json = JSON.parse(data.Payload);
            //     cachedValue = json.body.output;
            //     this.setState({ livingsizeSqft: jsonLocal.livingsizeSqft, 'utilityAmount': cachedValue });
            //     delete jsonLocal['livingsizeSqft'];
            //     console.log("jsonLocal: ", jsonLocal, METHOD_NAME, CLASS_NAME);
            // });
        } else if (calculatedCoverageLocal !== this.state.calculatedCoverage && calculatedCoverageLocal !== 0) {
            /* Logic: calculate coverage: Change coverage in state
               Invoke API to calculate
            */
            jsonLocal['calculatedCoverage'] = calculatedCoverageLocal;
            jsonLocal['clientId'] = this.state.clientId;
            jsonLocal['id'] = this.state.id;
            jsonLocal['stateCode'] = this.state.stateCode;
            console.log("input:jsonLocal.avmAmount: ", jsonLocal.avmAmount, " # calculatedCoverageLocal: ", calculatedCoverageLocal, METHOD_NAME, CLASS_NAME);
            ComponentHelper.calculateAndSaveLifeInsuranceAmount(jsonLocal, this.onChangeItem);
        } else {

        }
        this.setState(jsonLocal);
        if (!jsonLocal.isJsonReady4Persistence) return;
        console.log("this.state:", this.state, METHOD_NAME, CLASS_NAME);
        // For livingsizeSqft, mortgageAmount or coverageAmount need not go db-update
        let [jsonChecksSuccss, cleanedUpJson] = Utility.isJsonReady4Persistence(jsonLocal, this.state);
        const message = jsonChecksSuccss ? 'Before updateAppDataAndCache' : 'No updateAppDataAndCache done';
        console.log("cleanedUpJson:", cleanedUpJson, "# message: ", message, METHOD_NAME, CLASS_NAME);
        this.setState(jsonLocal);
        jsonLocal['id'] = this.state.id;
        if (jsonChecksSuccss) {
            ComponentHelper.updateAppDataAndCache(dataCategory, jsonLocal);
        }
    }
    onChangeItem = async (inputName, inputValue) => {
        let METHOD_NAME = ":onChangeItem:"
        let json = {};
        json[inputName] = inputValue;
        if (inputName === 'birthYear') {
            json['age'] = year - inputValue;
        } else if (inputName === 'fullAddress') {
            // console.log(")*) getAndUpdateAddresses:inputValue: ", inputValue, METHOD_NAME, CLASS_NAME);
            let domain = `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}`;
            let appVersion = "/v3";
            let uniqueExtensionHostId;
            let encryptedString;
            if (this.state.addressHistory != null) {
                uniqueExtensionHostId = this.state.addressHistory[inputValue].docketIdStr;
                // console.log(")*) uniqueExtensionHostId = docketIdStr: ", uniqueExtensionHostId, METHOD_NAME, CLASS_NAME);
                let splittedElements = uniqueExtensionHostId.split('__');
                splittedElements[0] = '0';
                uniqueExtensionHostId = splittedElements.join('__');
                const cipher = domain.split('/')[2];
                encryptedString = ComponentHelper.encryptCodes(uniqueExtensionHostId, cipher);
                let url = `${domain}${appVersion}/docketId=${encryptedString}/`;
                // console.log(")*) getAndUpdateAddresses:url ", url, METHOD_NAME, CLASS_NAME);
                // window.history.replaceState({}, "Get My Bubble", url);
                window.open(url, "_blank"); //to open new page
            } else {
                console.log(")*) this.state.docketIdStr == null", METHOD_NAME, CLASS_NAME);
            }
        }
        // console.log("Input:json: ", json, METHOD_NAME, CLASS_NAME);
        if (inputName === 'calculatedCoverage') {
            //'getQuotesFromPacLifeNext': ["DONE:solicitationState", "DONE:faceAmount", "riskClass", "gender", "premiumFrequency", "dateOfBirth", "productCode"
            // GlobalConstants.setAPIInputJson(GlobalConstants.getPacLifeNextAPITag(), { faceAmount: inputValue });
        }
        this.setState(json);
    }
    setFullAddress = (fullAddress) => {
        this.setState({
            address: fullAddress,
            fullAddress: fullAddress,
            visibleComponent: 1
        });
        console.log("After this.state.fullAddress: ", this.state.fullAddress, ":setFullAddress:", CLASS_NAME);

        // setTimeout(() => {
        //     this.setState({
        //         address: fullAddress,
        //         fullAddress: fullAddress,
        //         visibleComponent: 1
        //     })
        // }, 1);
    }

    setHomeAddress = e => {
        let fullAddress = e.description;
        this.onChangeItem('fullAddress', fullAddress)
    }

    setVieWExtension = (extension) => {
        this.setState({
            extension
        })
    }

    setExtension = (inputExtension) => {
        setTimeout(() => {
            this.navigateToNextPage();
            this.updateAddressDetailView();
            this.setState({
                extension: inputExtension,
                extensionData: true
            })
        }, 1);
    }

    setBrandingData = async () => {
        let METHOD_NAME = "setBrandingData";

        const key = this.state.agentBrandingCode;
        try {
            let array = await ComponentHelper.getData(key) //.then(array) =>
            METHOD_NAME += "agentBrandingCode.then:";
            let json;
            // console.log("%$%  array: ", array, METHOD_NAME, CLASS_NAME);
            if (array != null && array.length > 0 && (json = array[0]) != null) {
                // console.log("%$%  Agent Branding Data: json: ", json, METHOD_NAME, CLASS_NAME);
                this.setState({ 'brokerLogoS3Link': json.potraitImage })
                this.setState({ 'brokerName': json.agencyName })
                this.setState({ 'brokerWebSite': json.website });
                if (json.firstName && json.lastName) {
                    this.setState({ 'agentName': `${json.firstName} ${json.lastName}` })
                }
                console.log(METHOD_NAME, CLASS_NAME, this.state.agentBrandingCode,)
            }
        } catch (e) {
            console.log(e)
        }
    }

    changeName = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    changeEmail = (e) => {
        let email = e.target.value;
        this.setState({ [e.target.name]: e.target.value });
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emailValid = regex.test(String(email).toLowerCase());
        this.setState({ emailValid });
    }

    // changePhoneNumber = (e) => {
    //     let phone = e.target.value;
    //     this.setState({ [e.target.name]: phone });
    //     //   const regex = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    //     //   let phoneValid = regex.test(String(phoneNumber));
    //     this.setState({ phoneValid: true })
    // }

    changePhoneNumber = (e) => {

        if (this.state.phone.length === 12) {
            this.setState({ phoneValid: true })
        }

        let phone = e.target.value;
        this.setState({ [e.target.name]: phone });

        if (this.state.phone.length === 3) {
            this.setState({
                phone: phone + "-",
                phoneValid: false
            })
            return;
        }

        if (this.state.phone.length === 7) {
            this.setState({
                phone: phone + "-"
            })

            return;
        }

    }

    hubSpotSaveLead = async () => {
        let x = ['email', 'city', 'zip', 'firstname', 'lastname'];
        let vid;
        let hubSpotData = {};

        x.forEach(key => {
            if (this.state[key])
                hubSpotData[key] = this.state[key]
        });

        hubSpotData['state'] = this.state.stateCode;
        hubSpotData['address'] = this.state.fullAddress;
        hubSpotData['customerId'] = this.state.clientId;
        hubSpotData['phone'] = this.state.phone.replace(/-/g, '');

        let { city, state, zip } = AddressParser.parseLocation(this.state.fullAddress);
        hubSpotData['address'] = hubSpotData['address'].replace(city, '').replace(state, '').replace(zip, '').trim();

        let postBody = { properties: [] };

        Object.keys(hubSpotData).forEach(key => {
            postBody.properties.push({
                property: key,
                value: hubSpotData[key]
            })
        })

        const response = await ComponentHelper.hubSpotCheckEmail(JSON.stringify(this.state.email));
        let data = JSON.parse(response.Payload);
        if (data.errorType) {
            console.log("new contact");
            try {
                const response = await ComponentHelper.hubSpotSync(JSON.stringify(postBody));
                let data = JSON.parse(response.Payload)
                console.log(data)
            } catch (error) {
                console.log(error)
            }
        } else {
            vid = data.body.response.data.vid;
            console.log(vid, "vid");
            postBody.vid = vid;
            try {
                const response = await ComponentHelper.hubSpotUpdate(JSON.stringify(postBody));
                let data = JSON.parse(response.Payload)
                console.log(data)
            } catch (error) {
                console.log(error)
            }
        }

    }

    submitForm = async (e) => {
        e.preventDefault();
        this.hubSpotSaveLead();
        let timeZone = new Date().getTimezoneOffset();
        let [hr, min] = `${timeZone / 60}`.split('.');
        min = min * 6;
        let jsonLocal = { name: this.state.fullName, phone: this.state.phone.replace(/-/g, ''), email: this.state.email };
        for (const [key, value] in jsonLocal) {
            localStorage.setItem(key, JSON.stringify(value));
        }

        jsonLocal = { ...jsonLocal, timeZone: `${hr}:${min}` };
        jsonLocal['id'] = this.state.clientId + '-lead';
        jsonLocal.dataCategory = GlobalConstants.getClientDataCategoryAll();
        jsonLocal.createdAt = Utility.getCurrentDate('db_format');
        console.log(jsonLocal.id);
        ComponentHelper.saveUpdateClientData(jsonLocal);
        this.setVieWExtension(4);
    }

    changeFormConsent = () => {
        let formCheck = this.state.formCheck;
        this.setState({ formCheck: !formCheck })
    }

    resetToDefaultValues = () => {
        let METHOD_NAME = ':resetToDefaultValues:'
        for (const key in defaultValuesHash) {
            this.setState({
                [key]: defaultValuesHash[key]
            })
        }
        console.log(METHOD_NAME, 'reset to default')
    }

    setDefaultValues = (key, value) => {
        let METHOD_NAME = ":setDefaultValues:";
        defaultValuesHash[key] = value;
        console.log(METHOD_NAME, key, value)
    }

    componentDidMount() {
        let METHOD_NAME = ":componentDidMount:";
        // console.log("%$% Input:this.state: ", this.state, METHOD_NAME, CLASS_NAME);
        // Using getAndUpdateAddresses to set the fullAddress into addressHistory
        let addressJson = {};
        this.getAllDatafromCache()
        addressJson[this.state.fullAddress] = { "docketIdStr": this.state.docketIdStr, "createdAt": Utility.getCurrentDate('db_format') };
        console.log('Before getAndUpdateAddresses', METHOD_NAME, CLASS_NAME);
        ComponentHelper.getAndUpdateAddresses(this.state.clientId, addressJson, this.setStateAddressHistory);
        console.log('after getAndUpdateAddresses:clientId:', this.state.clientId, '# addressJson', addressJson, METHOD_NAME, CLASS_NAME);

        ComponentHelper.awaitProcessCalculateAndCache(this.state).then((output) => {
            METHOD_NAME += "awaitProcessCalculateAndCache.then:";
            console.log("*&* outputoutputoutputoutputoutput: ", output, METHOD_NAME, CLASS_NAME);
            //Failed processCalculateAndCache for sessionId: session-1601209848709-6319  Successfully processed records.
            this.setState({ calculatedAmountsFlag: output.Payload });
        });

        ComponentHelper.getDwellingCostRecursive(this.state.id, this.onChangeItem);
        const key4CacheId = `${this.state.clientId}++agentBrandingCode`;
        console.log("*&* Before this.getCachedData2 invoke", METHOD_NAME, CLASS_NAME);
        ComponentHelper.getCachedData2(key4CacheId).then((response) => {
            METHOD_NAME = `:getCachedData2.then2:agentBrandingCode`;
            console.log("*&* output:response: ", response, METHOD_NAME, CLASS_NAME);
            let json2 = JSON.parse(response.Payload);
            let agentBrandingCodeLocal = json2.body.output;
            console.log('*&* agentBrandingCodeLocal: ', agentBrandingCodeLocal, METHOD_NAME, CLASS_NAME);
            if (agentBrandingCodeLocal !== "") {
                this.setState({ 'agentBrandingCode': agentBrandingCodeLocal, isLoading: false }, this.setBrandingData);
            } else {
                this.setState({ 'agentBrandingCode': null, isLoading: false });
            }

        });

        // console.log("%$% Before getDataV3 Call ", METHOD_NAME, CLASS_NAME);
        // NOT WORKING DUE TO DYNAMO_DB inconsistent writes-reads
        // ComponentHelper.getDataV3(this.state.clientId).then((response) => {
        //     METHOD_NAME += ".then:";
        //     let json;
        //     console.log("%$% getDataV3.then:response: ", response, METHOD_NAME, CLASS_NAME);

        //     if (response != null && response.data != null && (json = response.data.getAppData) != null) {
        //         // console.log("%$% response.data.getAppData: json: ", json, METHOD_NAME, CLASS_NAME);
        //         this.setState({
        //             agentBrandingCode: json.agentBrandingCode,
        //             isLoading: false
        //         }, this.setBrandingData);
        //         // console.log("%$% After setBrandingData", METHOD_NAME, CLASS_NAME);
        //     } else {
        //         console.log("Error: response.data.getAppData:response: ", response, " # this.state.clientId: ", this.state.clientId, METHOD_NAME, CLASS_NAME);
        //     }
        // });

        if (['true', '1', true, 1].indexOf(this.state.extension) > -1) {
            this.setExtension(this.state.extension);
        }

        //        this.setState({
        //            extension: "-1"
        //        })
    }

    componentWillReceiveProps(nextProps) {
        let METHOD_NAME = ":componentWillReceiveProps:";

        if (nextProps.query !== this.props.query) {
            this.setState({ query: nextProps.query });
            let fullAddress; // eslint-disable-line
            const cipher = window.location.href.split('/')[2];
            console.log("cipher: ", cipher, " # domain: ", domain, METHOD_NAME, CLASS_NAME);
            console.log("this.props.location.search: ", this.props.location.search, METHOD_NAME, CLASS_NAME);

            let queryParamsHash = ComponentHelper.parseQueryParameters(this.props.location.search, cipher);
            fullAddress = queryParamsHash.fullAddress?.trim() || 'fffffff';
        }
    }
    onClickOfCustomize = (e) => {
        this.setState({ extension: '2' });
    }

    getAllDatafromCache = () => {
        let METHOD_NAME = ":getAllDatafromCache:";
        const redisKey = `${this.state.clientId}++All`;
        const keysToLookForPart1 = ['termDesired', 'smoker', 'gender', 'birthYear', 'age', 'health'];
        ComponentHelper.getCachedData2(redisKey).then((response) => {
            METHOD_NAME = ':getAllDatafromCache:';
            let json = JSON.parse(response.Payload);
            let values = json.body.output;
            let responseStr = String();
            responseStr = values.replace(/__-__/g, `"`);
            const clientInfo = JSON.parse(responseStr);

            keysToLookForPart1.forEach((key) => {
                console.log(METHOD_NAME, 'setting state for', key, 'value:', clientInfo[key]);
                if (clientInfo[key])
                    this.setState({ [key]: clientInfo[key] })
            })
        })// end of response
        // For Property specifics calculated values fetch it from independent cache
        const keysToLookForPart2 = ['docketIdStr', 'mortgageAmount', 'calculatedCoverage', 'livingsizeSqft', 'avmAmount', 'propertyTaxAmount'];
        keysToLookForPart2.forEach((paramName) => {
            METHOD_NAME = ':keysToLookForPart2.forEach:getAllDatafromCache:';
            let redisKey2 = `${this.state.id}++${paramName}`;
            ComponentHelper.getCachedData2(redisKey2).then((response2) => {
                METHOD_NAME = ':getCachedData2(redisKey).then:';
                let json = JSON.parse(response2.Payload);
                let output = json.body.output;
                let input = json.body.input;
                console.log(")*) input: ", input, "# output:", output, METHOD_NAME, CLASS_NAME);
                this.setState({ [input]: output });
            });// end of response2
        }); //end of keysToLookForPart2 loop
    }

    getNewTabWithMortgage = () => {
        let METHOD_NAME = ":getNewTabWithMortgage:";
        let json = {
            id: this.state.id,
            fullAddress: this.state.address,
            coverage: this.state.coverageDesired,
            termDesired: this.state.termDesired,
            gender: this.state.gender,
            age: this.state.age,
            birthYear: this.state.birthYear,
            health: this.state.health,
            smoker: this.state.smoker,
            eventStr: "IndividualDetailsFilledin",
            dataCategory: GlobalConstants.getClientDataCategoryAll()
        };// route to new page by changing window.location
        ComponentHelper.saveAppDataAndCache(this.state);

        console.log("json: ", json, METHOD_NAME, CLASS_NAME);
        let chartDataArray = [];
        let jsonPlaceHolder = {};
        API.graphql(graphqlOperation(updateAppData, { input: json })).then((updateDataResponse) => {
            METHOD_NAME = "updateAppData.then:";
            console.log("response2: ", JSON.stringify(updateDataResponse, null, " "), METHOD_NAME, CLASS_NAME);
            let updatedJson;

            if (updateDataResponse != null && updateDataResponse.data != null && (updatedJson = updateDataResponse.data.updateAppData) != null) {
                console.log("updatedJson: ", updatedJson, METHOD_NAME, CLASS_NAME);
                Object.entries(updatedJson).forEach(([key, value]) => {
                    if (key === 'lifeInsuranceAmount' && value == null) {
                        jsonPlaceHolder[key] = 0;
                    } else {
                        jsonPlaceHolder[key] = value;
                    }
                });

                jsonPlaceHolder['lifeInsurance'] = this.state.insurance;
                chartDataArray.push(jsonPlaceHolder);
                this.setState({
                    whatIfScenarioDataArray: chartDataArray,
                    extensionData: true
                });
                // console.log(this.state.whatIfScenarioDataArray);
                console.log("whatIfScenarioDataArray:", this.state.whatIfScenarioDataArray, METHOD_NAME, CLASS_NAME);
            }
        });
        // domain = "https://v2.getmybubble.io";
        // TODO Replace following with actual from the extension
        let appVersion = "/v3";
        let bedBathSqftArray = [this.state.bed, this.state.bath.replace('.', '!'), this.state.livingsizeSqft, this.state.listingPriceAmount];
        console.log("### bedBathSqftArray: ", bedBathSqftArray, METHOD_NAME, CLASS_NAME);
        //extension, fullAddress, clientId, os, info, vendor, source1, sourceUrl, continuationId
        const uniqueExtensionHostId = this.state.docketIdStr.replace(/^1__/g, '0__');
        // const uniqueExtensionHostId = `0__${this.state.fullAddress}__${this.state.clientId}__${this.state.os}__${this.state.info}__${this.state.vendor}__${bedBathSqftArray.join('.')}__Internal__${this.state.id}`;
        let encryptedString = uniqueExtensionHostId;
        console.log("uniqueExtensionHostId: ", uniqueExtensionHostId, METHOD_NAME, CLASS_NAME);
        console.log("domain: ", domain, METHOD_NAME, CLASS_NAME);
        const cipher = domain.split('/')[2];
        console.log("cipher: ", cipher, METHOD_NAME, CLASS_NAME);
        encryptedString = ComponentHelper.encryptCodes(uniqueExtensionHostId, cipher);
        let url = `${domain}${appVersion}/docketId=${encryptedString}/`;
        console.log("url: ", url, METHOD_NAME, CLASS_NAME);
        window.open(url, "_blank"); //to open new page
    }
    render() {
        const footer = <div>
            <ul className="d-flex footer-small flex-row">
                <a role="button" href="https://stage-www.getmybubble.io/company-information/" target="_blank" rel="noopener noreferrer">
                    About Bubble
                </a>
                <a role="button" href="https://stage-www.getmybubble.io/faqPage3" target="_blank" rel="noopener noreferrer">
                    FAQs
                </a>
            </ul>
            <div className="b-label">
                Powered by Bubble Technologies Inc.
                            </div>
        </div>
        const totalAmount = (+this.state.mortgageAmount + +this.state.utilityAmount + +this.state.propertyTaxAmount + +this.state.lifeInsuranceAmount + +this.state.homeInsuranceAmount).toFixed()

        this.renderComponent = (visibleComp) => {
            switch (Number(visibleComp)) {
                case 0:
                    return (
                        <Row>
                            <Col md={12}>
                                <SearchDetails
                                    onHomeAddressSearch={(e) => this.onSearchDetailsChange(e)}
                                    fullAddress={this.state.fullAddress} />
                            </Col>
                            <Col md={12} className="text-right mt-2">
                                <button className="buttonRounded"
                                    onClick={(e) => this.navigateToNextPage(e)}>Next</button>
                            </Col>
                        </Row>
                    )

                case 1:
                    return (<>
                        <Row>
                            <Col md={6}>
                                <LifeInsuranceDetails
                                    id={this.state.id}
                                    clientId={this.state.clientId}
                                    smoker={Number(this.state.smoker)}
                                    health={Number(this.state.health)}
                                    gender={this.state.gender}
                                    age={this.state.age}
                                    lifeScore={this.state.lifeScore}
                                    address={this.state.fullAddress}
                                    birthYear={this.state.birthYear}
                                    termDesired={this.state.termDesired}
                                    calculatedCoverage={this.state.calculatedCoverage}
                                    onChangeItem={this.onChangeItem}
                                    onChangeAmountsFromHash={this.onChangeAmountsFromHash}
                                    fullAddress={this.state.fullAddress}

                                />
                                {  /*    <MortgageComponent
                                    id={this.state.id}
                                    avmAmount={this.state.avmAmount || 0}
                                    coverageDesired={this.state.coverageDesired}
                                    utilityAmount={this.state.utilityAmount}
                                    propertyTaxAmount={this.state.propertyTaxAmount}
                                    loanType={this.state.loanType}
                                    listingPriceAmount={this.state.listingPriceAmount}
                                    mortgageAmount={this.state.mortgageAmount}
                                    homeInsuranceAmount={this.state.homeInsuranceAmount}
                                    lifeInsuranceAmount={this.state.lifeInsuranceAmount}
                                    interestRate={this.state.interestRate}
                                    mortgageWasInitiated={this.state.mortgageWasInitiated}
                                    onChangeAmountsFromHash={this.onChangeAmountsFromHash}
                                    refreshDataArrayLink={this.onChangeDataArray}
                                    onChangeItem={this.onChangeItem}
                                    resetToDefaultValues={this.resetToDefaultValues}
                                    setDefaultValues={this.setDefaultValues}
                                />
                           */ }
                            </Col>
                        </Row>
                    </>
                    )

                case 2:
                    return (<Row>
                        <Col md={6}>
                            <InsuranceFilter
                                id={this.state.id}
                                coverageDesired={this.state.coverageDesired}
                                mortgage={this.state.mortgage}
                                termDesired={this.state.termDesired}
                                selectedCoveragesArray={this.state.selectedCoveragesArray}
                                onCoverageDesiredDetails={(e) => this.onCoverageDesiredChange(e)}
                                onMortgageDetails={(e) => this.onMortgageChange(e)}
                                onTermDesiredDetails={(e) => this.onTermDesiredChange(e)}
                                onSelectedCoveragesArrayChange={this.onSelectedCoveragesArrayChange}
                                refreshDataArrayLink={this.onChangeDataArray}
                                onChangeItem={this.onChangeItem}
                            />
                        </Col>
                        <Col md={6}>
                            <Col className="p-1 bubble-doughnut">
                                <BubbleDoughnut
                                    id={this.state.id}
                                    onChangeAmountsFromHash={this.onChangeAmountsFromHash}
                                    onChangeItem={this.onChangeItem}
                                    fullAddress={this.state.fullAddress}
                                    bed={this.state.bed}
                                    bath={this.state.bath}
                                    stateCode={this.state.stateCode}
                                    livingsizeSqft={this.state.livingsizeSqft}
                                    listingPriceAmount={this.state.listingPriceAmount}
                                    mortgageAmount={this.state.mortgageAmount}
                                    propertyTaxAmount={this.state.propertyTaxAmount}
                                    lifeInsuranceAmount={this.state.lifeInsuranceAmount}
                                    utilityAmount={this.state.utilityAmount}
                                    totalAmount={this.state.totalAmountDoughNut}
                                    homeInsuranceAmount={this.state.homeInsuranceAmount}
                                    calculatedCoverage={this.state.calculatedCoverage}
                                    doughnutWidth={75}
                                    doughnutHeight={75}
                                />
                                <ExtensionLabels id={this.state.id}
                                    extension={this.state.extension}
                                    stateCode={this.state.stateCode}
                                    livingsizeSqft={this.state.livingsizeSqft}
                                    utilityAmount={this.state.utilityAmount}
                                    propertyTaxAmount={this.state.propertyTaxAmount}
                                    mortgageAmount={this.state.mortgageAmount}
                                    homeInsuranceAmount={this.state.homeInsuranceAmount}
                                    lifeInsuranceAmount={this.state.lifeInsuranceAmount}
                                    displayPacLifeNextQuote={this.state.displayPacLifeNextQuote}
                                    notEnoughDataAvailableFlag={this.state.notEnoughDataAvailableFlag}
                                />
                            </Col>
                        </Col>
                        {/* <div style={{ width: "350px", height: "350px" }}>

                                <Charts
                                    updateView={this.updateChartsView}
                                    returnView={this.returnChatsView}
                                    activeView={this.state.charts}
                                    fullAddress={this.state.fullAddress}
                                    whatIfScenarioDataArray={this.state.whatIfScenarioDataArray}
                                    refreshDataArrayLink={this.onChangeDataArray}
                                    sessionId={dbIdLocal}
                                    id={this.state.id}
                                    value={this.state}
                                    {...this.state}
                                    onChangeItem={this.onChangeItem}
                                    onChangeAmountsFromHash={this.onChangeAmountsFromHash}
                                    ref={(Charts) => { window.Charts = Charts }}
                                />
                            </div> */}

                        <Col md={12} className="text-right mt-2">
                            <button className="buttonRounded"
                                onClick={(e) => this.updateAddressDetailView(e)}>Get Details</button>
                        </Col>
                    </Row>)

                case 3:
                    return (<Row>
                        <Col md={6}>
                            <IndividualDetailsV2
                                id={this.state.id}
                                clientId={this.state.clientId}
                                stateCode={this.state.stateCode}
                                extension={this.state.extension}
                                birthYear={this.state.birthYear}
                                smoker={Number(this.state.smoker)}
                                health={Number(this.state.health)}
                                termDesired={this.state.termDesired}
                                individualFullItem={this.state.individualFullItem}
                                onChangeBirthYear={this.onChangeBirthYear}
                                onChangeItem={this.onChangeItem}
                                refreshDataArrayLink={this.onChangeDataArray}
                                age={this.state.age}
                                livingsizeSqft={this.state.livingsizeSqft}
                                bed={this.state.bed}
                                bath={this.state.bath}
                                yearBuilt={this.state.yearBuilt}
                                listingPriceAmount={this.state.listingPriceAmount}
                                lifeInsuranceAmount={this.state.lifeInsuranceAmount}
                                calculatedCoverage={this.state.calculatedCoverage}
                                mortgageAmount={this.state.mortgageAmount}
                                onChangeAmountsFromHash={this.onChangeAmountsFromHash}
                                utilityAmount={this.state.utilityAmount}
                                propertyTaxAmount={this.state.propertyTaxAmount}
                                defaultValuesHash={defaultValuesHash}
                                resetToDefaultValues={this.resetToDefaultValues}
                                setDefaultValues={this.setDefaultValues}
                            />
                        </Col>
                    </Row >)

                case 4:
                    return (<Row>
                        <Col md={6}>
                            <Questionnaire />
                        </Col>
                        <Col md={6}>
                        </Col>
                    </Row >)

                case 5:
                    return (<Row>
                        <Col md={6}>
                            <PropertyDetails
                                bed={this.state.bed}
                                bath={this.state.bath}
                                utilityAmount={this.state.utilityAmount}
                                propertyTaxAmount={this.state.propertyTaxAmount}
                                onChangeItem={this.onChangeItem} />
                        </Col>
                    </Row >)

                case 6:
                    return (<Row>
                        <Col md={6}>
                            <HomeInsuranceDetails
                                fullAddress={this.state.fullAddress}
                                livingsizeSqft={this.state.livingsizeSqft}
                                yearBuilt={this.state.yearBuilt}
                                bed={this.state.bed}
                                bath={this.state.bath}
                                id={this.state.id}
                                avmAmount={this.state.avmAmount || 0}
                                coverageDesired={this.state.coverageDesired}
                                utilityAmount={this.state.utilityAmount}
                                propertyTaxAmount={this.state.propertyTaxAmount}
                                loanType={this.state.loanType}
                                listingPriceAmount={this.state.listingPriceAmount}
                                mortgageAmount={this.state.mortgageAmount}
                                homeInsuranceAmount={this.state.homeInsuranceAmount}
                                lifeInsuranceAmount={this.state.lifeInsuranceAmount}
                                interestRate={this.state.interestRate}
                                onChangeAmountsFromHash={this.onChangeAmountsFromHash}
                                refreshDataArrayLink={this.onChangeDataArray}
                                onChangeItem={this.onChangeItem}
                                resetToDefaultValues={this.resetToDefaultValues}
                                setDefaultValues={this.setDefaultValues}
                            />
                            {/* <WhatIfScenarioV3
                                id={this.state.id}
                                birthYear={this.state.birthYear}
                                age={this.state.age}
                                onChangeAge={this.onChangeAge}
                                onChangeItem={this.onChangeItem}
                                calculatedCoverage={this.state.calculatedCoverage}
                            /> */}
                        </Col>
                    </Row >)

                case 7:
                    return (
                        <Row>
                            <Col md={6}>
                                <LifeInsurance />
                            </Col>
                            <Col md={6} className="chart-fullscreen insurance-desc">
                                <div className="text-center big-text">
                                    Insurance products that complement your life
                            </div>
                                <div className="text-center w-75 small-text">
                                    Next by Pacific Life can help you reach
                                    financial goals while you achieve your personal ones.
                                    Redefine your insurance alongside your ever-changing life.
                                    What’s your next adventure?
                            </div>
                            </Col>
                        </Row>
                    )

                default:
                    break;
            }
        }
        // console.log("this.state: ", this.state, ":render():", CLASS_NAME);
        const { extension, extensionData } = this.state;
        let urlAddress=this.state.doorNumber+'+'+this.state.street.replace(' ','+')+'+'+this.state.streetType+'%2C'+'+'+this.state.city+'%2C'+'+'+this.state.stateCode+'+'+this.state.zip+'%2C'+'+USA'+'&zip='+this.state.zip;
        return (
            <Container fluid>
                <div className="row justify-content-center align-items-center"><BallRotate
                    color={'#7c648c'}
                    loading={this.state.isLoading}
                /></div>
                {['false', '0', false, 0].indexOf(extension) > -1
                    &&
                    <div className="full-scren">
                        <HeaderTopBar
                            brokerName={this.state.brokerName}
                            address={this.state.fullAddress}
                            agentName={this.state.agentName}
                            brokerLogoS3Link={this.state.brokerLogoS3Link}
                        />
                        <Step3Component
                            onChangeItem={this.onChangeItem}
                            address={this.state.fullAddress}
                            gender={Number(this.state.gender)}
                            smoker={this.state.smoker}
                            age={this.state.age}
                            termDesired={this.state.termDesired}
                            calculatedCoverage={this.state.calculatedCoverage}
                            livingsizeSqft={this.state.livingsizeSqft} />

                        <div>  {     /*
                        <div className="col-md-12 full-screen d-flex">
                            <div className="w-50">
                                <div className={this.state.visibleComponent === 6 ? "li-active" : "li-inactive"}   >
                                    <div className="class-label">
                                        <div className="img-circle">
                                            <img src={this.state.visibleComponent === 6 ? home_active : home_inactive} alt="" />
                                        </div>
                                        Home Insurance Details

                                        <div className="arrow-turn" onClick={() => {
                                                let visibleComponent = this.state.visibleComponent === 6 ? 0 : 6;
                                                this.setState({ visibleComponent});
                                            }}>
                                            <img src={arrow_back_grey} alt=""/>
                                        </div>
                                    </div>
                                    {
                                    this.state.visibleComponent === 6   &&  <div className="visible-item">
                                        {this.renderComponent(this.state.visibleComponent)}
                                    </div>
                                    }
                                <div>

                                </div>
                                </div>
                                <div  className={this.state.visibleComponent === 1 ? "li-active" : "li-inactive"}   >
                                    <div className="class-label">
                                        <div className="img-circle">
                                            <img src={this.state.visibleComponent === 1 ? life_active : life_inactive} alt="" />
                                        </div>
                                        Life Insurance Details
                                        <div className="arrow-turn" onClick={() => {
                                                let visibleComponent = this.state.visibleComponent === 1 ? 0 : 1;
                                                this.setState({ visibleComponent});
                                            }}>
                                            <img src={arrow_back_grey} alt=""/>
                                        </div>
                                    </div>
                                   {
                                   this.state.visibleComponent === 1 &&   <div className="visible-item">
                                        {this.renderComponent(this.state.visibleComponent)}
                                    </div>
                                    }
                                </div>
                                <div  className={this.state.visibleComponent === 3 ? "li-active" : "li-inactive" }   >
                                    <div className="class-label">
                                        <div className="img-circle">
                                            <img src={this.state.visibleComponent === 3 ? profile : personal_inactive} alt="" />
                                        </div>
                                        Personal Details
                                        <div className="arrow-turn" onClick={() => {
                                                let visibleComponent = this.state.visibleComponent === 3 ? 0 : 3;
                                                this.setState({ visibleComponent});
                                            }}>
                                            <img src={arrow_back_grey} alt=""/>
                                        </div>
                                    </div>
                                   {
                                   this.state.visibleComponent === 3 && <div className="visible-item">
                                        {this.renderComponent(this.state.visibleComponent)}
                                    </div>
                                    }
                                </div>
                            </div>

                            <div className="w-50">
                                <div className="chart-fullscreen">
                                    <div className="text-center style">
                                        Estimated Monthly Expenses
                                    </div>
                                    <Col className="p-1 fs-doughnut">
                                <div className="donut-wrapper">
                                    <BubbleDoughnut
                                        id={this.state.id}
                                        utilityAmount={this.state.utilityAmount}
                                        onChangeAmountsFromHash={this.onChangeAmountsFromHash}
                                        onChangeItem={this.onChangeItem}
                                        fullAddress={this.state.fullAddress}
                                        bed={this.state.bed}
                                        bath={this.state.bath}
                                        stateCode={this.state.stateCode}
                                        livingsizeSqft={this.state.livingsizeSqft}
                                        listingPriceAmount={this.state.listingPriceAmount}
                                        mortgageAmount={this.state.mortgageAmount}
                                        propertyTaxAmount={this.state.propertyTaxAmount}
                                        lifeInsuranceAmount={this.state.lifeInsuranceAmount}
                                        totalAmount={this.state.totalAmountDoughNut}
                                        homeInsuranceAmount={this.state.homeInsuranceAmount}
                                        calculatedCoverage={this.state.calculatedCoverage}
                                        doughnutWidth={85}
                                        doughnutHeight={65}
                                    />
                                    </div>
                                <ExtensionLabels id={this.state.id}
                                    extension={this.state.extension}
                                    stateCode={this.state.stateCode}
                                    livingsizeSqft={this.state.livingsizeSqft}
                                    utilityAmount={this.state.utilityAmount}
                                    propertyTaxAmount={this.state.propertyTaxAmount}
                                    mortgageAmount={this.state.mortgageAmount}
                                    homeInsuranceAmount={this.state.homeInsuranceAmount}
                                    lifeInsuranceAmount={this.state.lifeInsuranceAmount}
                                    displayPacLifeNextQuote={this.state.displayPacLifeNextQuote}
                                />
                            </Col>
                                </div>
                            </div>

                        </div>
              */  }
                        </div>
                    </div>
                }
                {/* {(this.state.agentBrandingCode == null && this.state.isLoading === false)

                    && <Row>
                        <Register
                            clientId={this.state.clientId}
                            setViewExtension={this.setVieWExtension}
                            onChangeItem={this.onChangeItem} />
                    </Row>
                } */}
                {/*/this.state.agentBrandingCode && */}
                {extension === '1' && extensionData
                    && <Row>
                        <Col md={12} className="ext-step-1">
                            <div className="extension-header">
                                <AgentHeader brokerName={this.state.brokerName} brokerWebSite={this.state.brokerWebSite} address={this.state.fullAddress} agentName={this.state.agentName} brokerLogoS3Link={this.state.brokerLogoS3Link} />
                            </div>
                            <span className="infolabel">Estimated Monthly Expenses</span>
                            {/*  <GooglePlacesAutocomplete
                                    placeholder="Your home address"
                                    onSelect={this.setSearchAdress}
                                    componentRestrictions={{ country: "us" }}
                                    minLengthAutocomplete={3}
                                    initialValue={this.state.fullAddress}
                                /> */}
                            {/*    <input value={this.state.fullAddress} onChange={() => null} className="google-places-autocomplete__input" /> */}

                            {
                                (this.state.notEnoughDataAvailable) &&
                                <div className="text-danger">
                                    Error....
                                    Not enought data to service your address
                                </div>
                            }
                            <Col className="p-1 bubble-doughnut2">
                                <div className="donut-wrapper">
                                    <BubbleDoughnut
                                        id={this.state.id}
                                        onChangeAmountsFromHash={this.onChangeAmountsFromHash}
                                        onChangeItem={this.onChangeItem}
                                        fullAddress={this.state.fullAddress}
                                        bed={this.state.bed}
                                        bath={this.state.bath}
                                        stateCode={this.state.stateCode}
                                        livingsizeSqft={this.state.livingsizeSqft}
                                        listingPriceAmount={this.state.listingPriceAmount}
                                        mortgageAmount={this.state.mortgageAmount}
                                        propertyTaxAmount={this.state.propertyTaxAmount}
                                        lifeInsuranceAmount={this.state.lifeInsuranceAmount}
                                        homeInsuranceAmount={this.state.homeInsuranceAmount}
                                        calculatedCoverage={this.state.calculatedCoverage}
                                        utilityAmount={this.state.utilityAmount}
                                        totalAmount={this.state.totalAmountDoughNut}
                                        doughnutWidth={75}
                                        doughnutHeight={55}
                                        donutWasMounted={this.state.donutWasMounted}
                                        ext0={true}
                                    />
                                </div>
                            </Col>
                            <ExtensionLabels id={this.state.id}
                                stateCode={this.state.stateCode}
                                livingsizeSqft={this.state.livingsizeSqft}
                                utilityAmount={this.state.utilityAmount}
                                propertyTaxAmount={this.state.propertyTaxAmount}
                                mortgageAmount={this.state.mortgageAmount}
                                homeInsuranceAmount={this.state.homeInsuranceAmount}
                                lifeInsuranceAmount={this.state.lifeInsuranceAmount}
                                displayPacLifeNextQuote={false}
                                notEnoughDataAvailableFlag={this.state.notEnoughDataAvailableFlag}
                            />
                            <Col md={12} className="text-center">
                                <div className="orangeGradient">
                                    <button onClick={(e) => this.onClickOfCustomize(e)}>Personalize
                                    </button>
                                </div>
                            </Col>
                            <p className="small fs10">
                                Costs are estimates only and actual costs may vary based on the above and other individual attributes.
                                Mortgage costs assume a 30-year fixed loan on 80% of the home value estimate
                            </p>
                            <div className="footer-sm">
                                {footer}
                            </div>
                            {/* <Col>
                                <div className="text-right">
                                    <span className="chart-header-purple">Powered by Bubble</span>
                                </div>
                            </Col> */}
                            {/* Use it in case of Agent-    d-Extension <HeaderTopBar {...this.state} className="bg-white text-dark" /> */}
                        </Col>
                    </Row>
                }
                {extension === '2'
                    &&
                    <div className="ext-2">
                        <div className="extension-header header2">
                            <AgentHeader brokerName={this.state.brokerName} address={this.state.fullAddress} agentName={this.state.agentName} brokerLogoS3Link={this.state.brokerLogoS3Link} />
                        </div>
                        <div className="d-flex infolabel l2">
                            <div onClick={() => this.setExtension("1")} role="button">
                                <img src={arrow_back} alt="back" />
                            </div>
                            Customise your quote
                        </div>
                        <div className="color-bar">
                            <ProgressBar>
                                <ProgressBar now={this.state.mortgageAmount / totalAmount * 100} variant="mortgageAmount" label={(this.state.mortgageAmount / totalAmount * 100).toFixed() + '%'} />
                                <ProgressBar now={this.state.propertyTaxAmount / totalAmount * 100} variant="propertyTaxAmount" label={(this.state.propertyTaxAmount / totalAmount * 100).toFixed() + '%'} />
                                <ProgressBar now={this.state.utilityAmount / totalAmount * 100} variant="utilityAmount" label={(this.state.utilityAmount / totalAmount * 100).toFixed() + '%'} />
                                <ProgressBar now={this.state.lifeInsuranceAmount / totalAmount * 100} variant="lifeInsuranceAmount" label={(this.state.lifeInsuranceAmount / totalAmount * 100).toFixed() + '%'} />
                                <ProgressBar now={this.state.homeInsuranceAmount / totalAmount * 100} variant="homeInsuranceAmount" label={(this.state.homeInsuranceAmount / totalAmount * 100).toFixed() + '%'} />
                            </ProgressBar>
                        </div>
                        {/*  <GooglePlacesAutocomplete
                                    placeholder="Your home address"
                                    onSelect={this.setSearchAdress}
                                    componentRestrictions={{ country: "us" }}
                                    minLengthAutocomplete={3}
                                    initialValue={this.state.fullAddress}
                                /> */}
                        {/*   <div className="text-left">
                                <span className="chart-header-purple">Personalize your Monthly Home Expense</span>
                            </div>
                             <input value={this.state.fullAddress} onChange={() => null} className="google-places-autocomplete__input" /> */}
                        <div>        {/*
                        <div className="sm-header pers d-flex flex-row">
                            <span>
                                <img role="button" onClick={() => this.setState({ extension: "1" })} src={arrow_back} alt={"back"} />
                            </span>
                            <div className="d-flex w-50">
                                <div className={this.state.ChartorData === "Data" ? "t-light col-5 pt-1" : "t-bold col-5 pt-1"} role="button"
                                    onClick={() => this.setState({ ChartorData: "Chart" })}>
                                    Chart
                                        </div>
                                <div className="col-3 h5 font-weight-light"> | </div>
                                <div className={this.state.ChartorData === "Data" ? "t-bold pt-1" : "t-light pt-1"} role="button"
                                    onClick={() => this.setState({ ChartorData: "Data" })}>
                                    Data
                                </div>
                                {/* <br />
                                    <span className="info">
                                        Powered by Bubble Insurance Expense Calculator
                                    </span>
                            </div>
                            {/* <img src={familyImage} alt="family" className="family-image" />
                        </div>
                        <div id="helper-div"
                            onMouseOut={(e) => {
                                this.setState({ leftY: e.pageY }, () => {
                                    if (this.state.leftY > this.state.enteredY) {
                                        this.dataPage2.current.style.zIndex = 1;
                                    } else {
                                        this.dataPage2.current.style.zIndex = -1;
                                    }
                                })

                            }}
                            onMouseEnter={(e) => {
                                this.setState({ enteredY: e.pageY })
                            }}
                        >
                        </div>
                        <Col className="p-1 bubble-doughnut4" ref={this.dataPage2}>
                            <div className="smaller-donut">
                                {(!this.state.ChartorData || this.state.ChartorData === "Chart") &&
                                    <BubbleDoughnut
                                        id={this.state.id}
                                        onChangeAmountsFromHash={this.onChangeAmountsFromHash}
                                        onChangeItem={this.onChangeItem}
                                        fullAddress={this.state.fullAddress}
                                        bed={this.state.bed}
                                        bath={this.state.bath}
                                        stateCode={this.state.stateCode}
                                        livingsizeSqft={this.state.livingsizeSqft}
                                        listingPriceAmount={this.state.listingPriceAmount}
                                        mortgageAmount={this.state.mortgageAmount}
                                        utilityAmount={this.state.utilityAmount}
                                        propertyTaxAmount={this.state.propertyTaxAmount}
                                        lifeInsuranceAmount={this.state.lifeInsuranceAmount}
                                        homeInsuranceAmount={this.state.homeInsuranceAmount}
                                        calculatedCoverage={this.state.calculatedCoverage}
                                        totalAmount={this.state.totalAmountDoughNut}
                                        doughnutWidth={85}
                                        doughnutHeight={65}
                                    />
                                }
                                {
                                    this.state.ChartorData === "Data" &&
                                    <div className="data-page2">
                                        <ExtensionLabels id={this.state.id}
                                            stateCode={this.state.stateCode}
                                            livingsizeSqft={this.state.livingsizeSqft}
                                            utilityAmount={this.state.utilityAmount}
                                            propertyTaxAmount={this.state.propertyTaxAmount}
                                            mortgageAmount={this.state.mortgageAmount}
                                            homeInsuranceAmount={this.state.homeInsuranceAmount}
                                            lifeInsuranceAmount={this.state.lifeInsuranceAmount}
                                            notEnoughDataAvailableFlag={this.state.notEnoughDataAvailableFlag}
                                            displayPacLifeNextQuote={false}
                                        />
                                    </div>
                                }
                            </div>
                        </Col>
                    */}    </div>
                        <Col md={12} xs={12}>
                            <IndividualDetailsV2
                                id={this.state.id}
                                clientId={this.state.clientId}
                                stateCode={this.state.stateCode}
                                birthYear={this.state.birthYear}
                                termDesired={this.state.termDesired}
                                livingsizeSqft={this.state.livingsizeSqft}
                                bed={this.state.bed}
                                bath={this.state.bath}
                                health={Number(this.state.health)}
                                gender={Number(this.state.gender)}
                                smoker={this.state.smoker}
                                index={this.state.lifeCoverageIndex}
                                yearBuilt={this.state.yearBuilt}
                                listingPriceAmount={this.state.listingPriceAmount}
                                lifeInsuranceAmount={this.state.lifeInsuranceAmount}
                                mortgageAmount={this.state.mortgageAmount}
                                utilityAmount={this.state.utilityAmount}
                                calculatedCoverage={this.state.calculatedCoverage}
                                onChangeAmountsFromHash={this.onChangeAmountsFromHash}
                                onChangeItem={this.onChangeItem}
                            />
                        </Col>
                        
                        {!localStorage.getItem('name') &&
                            <Col md={12} className="text-center">
                                 <div className="orangeGradient">
                                    <button onClick={() => {
                                    window.open("https://app.getmybubble.com/prefillResumeFlowHoi?full_address="+urlAddress+"&lead_source=Extension&city="+this.state.city+"&state="+this.state.stateCode+"&country=USA&address="+this.state.doorNumber+'+'+this.state.street.replace(' ','+')+'+'+this.state.streetType);
                                    }}>Get Insurance</button>
                                </div>
                            </Col>
                        }
                        {/* <div className="footer-sm">
                        <div className="close2">s
                            <span className="info">
                                Powered by Bubble Home Co-Pilot
                            </span>
                        </div>
                        */}
                        <div className="footer-sm">
                            {footer}
                        </div>
                        {/* <Col>
                            <div className="text-right">
                                <span className="chart-header-purple">Powered by Bubble</span>
                            </div>
                        </Col> */}
                    </div >
                }
                {
                    extension === 3 &&
                    <div className='ext-3'>
                        <div className="d-flex palegray">
                            Tell us a bit about yourself
                            </div>
                        <form id="contactForm" className="d-flex flex-column">
                            <input onChange={this.changeName}
                                type="text"
                                name='firstname'
                                minLength={2}
                                maxLength={30}
                                placeholder="First Name"
                                value={this.state.firstname} required />
                            <input onChange={this.changeName}
                                type="text"
                                name='lastname'
                                minLength={2}
                                maxLength={30}
                                placeholder="Last Name"
                                value={this.state.lastname} required />
                            <input onChange={this.changeEmail}
                                type="email"
                                name="email"
                                value={this.state.email}
                                placeholder="Email ID" required />
                            <input onChange={this.changePhoneNumber} onKeyPress={(event) => (event.charCode >= 48 && event.charCode <= 57)}
                                type="text"
                                name="phone"
                                value={this.state.phone}
                                placeholder="Phone Number" required />
                            <button type="submit" onClick={this.submitForm} disabled={!(this.state.emailValid && this.state.phoneValid && this.state.formCheck)}>Submit</button>
                            <div className="d-flex align-items-center">
                                <input type="checkbox" onChange={this.changeFormConsent} value={this.state.formCheck} required />
                                <span>I authorize a relationship manager from Bubble Technologies Inc. to contact me</span>
                            </div>
                        </form>
                    </div>
                }
                {
                    extension === 4 &&
                    <div className='ext-4'>
                        <img src={group2} alt="" />
                        <span className="thanks">
                            Thank you!
                        </span>
                        <span className="info">
                            Someone from our team will be in touch with you as soon as possible!
                        </span>
                        <button className="orangeGradient" onClick={() => {
                            this.setVieWExtension('1')
                        }}> Okay </button>
                    </div>
                }
            </Container>
        )
    }
}