import React from "react";
import { Button, Container, Row, Col, ListGroup } from "react-bootstrap";
import BubbleDoughnut from "./BubbleDoughnut.component";
import GlobalVars from "../GlobalConstantsVariables";
import ComponentHelper from "./ComponentHelper";

const FINAL_COUNT = 3;

let checkPointArray = [];
const CLASS_NAME = ":Charts";
let lifeInsuranceText = '';

export default class Charts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			address: true,
			addressdetails: false,
			Charts: false,
			debugMode: false,
			id: this.props.id,
			isLoading: true,
			avmAmount: this.props.avmAmount,
			coverageDesired: this.props.coverageDesired,
			utilityAmount: this.props.utilityAmount,
			propertyTaxAmount: this.props.propertyTaxAmount,
			mortgageAmount: this.props.mortgageAmount,
			homeInsuranceAmount: this.props.homeInsuranceAmount,
			lifeInsuranceAmount: this.props.lifeInsuranceAmount,
			currentCount: 2
		};
	}
	fetchCalculatedFields(id) {
		let METHOD_NAME = ":fetchCalculatedFields:";
		console.log("Entered:id: ", id, METHOD_NAME, CLASS_NAME);
		if (id == null || id === '') {
			return;
		}

		if (checkPointArray.length > 1) {
			this.props.refreshDataArrayLink("whatIfScenarioDataArray");
			checkPointArray.push("whatIfScenarioDataArray");
		} else if (checkPointArray.length === FINAL_COUNT) {
			clearInterval(this.intervalId);
		}

		console.log("Entered:this.state.calculatedCoverage: ", this.state.calculatedCoverage, METHOD_NAME, CLASS_NAME);
		console.log("Entered:whatIfScenarioDataArray: ", this.props.whatIfScenarioDataArray, METHOD_NAME, CLASS_NAME);
		console.log("Entered:this.state.homeInsuranceAmount: ", this.state.homeInsuranceAmount, METHOD_NAME, CLASS_NAME);

		let paramName = 'avmAmount';
		let key = `${id}++${paramName}`;
		ComponentHelper.getCachedData(key).then((hash) => {
			METHOD_NAME += `:${paramName}:then:`
			console.log("AFTER getCachedData:hash:", hash, METHOD_NAME, CLASS_NAME);
			this.setState({ [paramName]: hash[key] });
			// this.props.onChangeItem(paramName, hash[key]);
			paramName = 'mortgageAmount';
			key = `${id}++${paramName}`;
			ComponentHelper.getCachedData(key).then((hash) => {
				METHOD_NAME += `:${paramName}:then:`
				console.log("AFTER getCachedData:hash:", hash, METHOD_NAME, CLASS_NAME);
				this.setState({ [paramName]: hash[key] });
				// this.props.onChangeItem(paramName, hash[key]);

				paramName = 'calculatedCoverage';
				key = `${id}++${paramName}`;
				ComponentHelper.getCachedData(key).then((hash) => {
					METHOD_NAME += `:${paramName}:then:`
					console.log("AFTER getCachedData:hash:", hash, METHOD_NAME, CLASS_NAME);
					this.setState({ [paramName]: hash[key] });
					// this.props.onChangeItem(paramName, hash[key]);
					paramName = 'utilityAmount';
					key = `${id}++${paramName}`;
					ComponentHelper.getCachedData(key).then((hash) => {
						METHOD_NAME += `:${paramName}:then:`
						console.log("AFTER getCachedData:hash:", hash, METHOD_NAME, CLASS_NAME);
						this.setState({ [paramName]: hash[key] });
						// this.props.onChangeItem(paramName, hash[key]);

						paramName = 'propertyTaxAmount';
						key = `${id}++${paramName}`;
						ComponentHelper.getCachedData(key).then((hash) => {
							METHOD_NAME += `:${paramName}:then:`
							console.log("AFTER getCachedData:hash:", hash, METHOD_NAME, CLASS_NAME);
							this.setState({ [paramName]: hash[key] });
							// this.props.onChangeItem(paramName, hash[key]);

							paramName = 'homeInsuranceAmount';
							key = `${id}++${paramName}`;
							ComponentHelper.getCachedData(key).then((hash) => {
								METHOD_NAME += `:${paramName}:then:`
								console.log("AFTER getCachedData:hash:", hash, METHOD_NAME, CLASS_NAME);
								this.setState({ [paramName]: hash[key] });
								// this.props.onChangeItem(paramName, hash[key]);

								paramName = 'lifeInsuranceAmount';
								key = `${id}++${paramName}`;
								ComponentHelper.getCachedData(key).then((hash) => {
									METHOD_NAME += `:${paramName}:then:`
									console.log("AFTER getCachedData:hash:", hash, METHOD_NAME, CLASS_NAME);
									this.setState({ isLoading: false, [paramName]: hash[key] });
									// this.props.onChangeItem(paramName, hash[key]);
								});
							});
						});
					});
				});
			});
		});
	}
	timer = () => {
		const METHOD_NAME = ":timer:";
		this.setState({ isLoading: true });
		let localCurrentCount;
		console.log("localCurrentCount:", localCurrentCount, " # this.state.homeInsuranceAmount: ", this.state.homeInsuranceAmount, METHOD_NAME, CLASS_NAME);
		if (this.props.address === '' || this.props.address == null) {
			this.setState({
				homeInsuranceAmount: ''
			});
			return;
		}
		localCurrentCount = this.state.currentCount - 1;
		this.setState({
			currentCount: localCurrentCount,
			homeInsuranceAmount: this.state.homeInsuranceAmount + '.'
		});
		this.fetchCalculatedFields(this.props.id);
		if (this.state.currentCount < 1) {
			clearInterval(this.intervalId);
			this.setState({ isLoading: false });
		}
	}
	componentDidMount() {
		// React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
		this.intervalId = setInterval(this.timer.bind(this), 2000);
	}
	componentWillUnmount() {
		// React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
		clearInterval(this.intervalId);
	}
	// componentDidUpdate() {
	// 	const METHOD_NAME = ":componentDidUpdate:";
	// 	// console.log("Entered:updateView:", updateView, METHOD_NAME, CLASS_NAME);
	// 	if (loadCounter < DATA_LOAD_TRIES) {
	// 		this.props.refreshDataArrayLink("");
	// 		// takeABreak(); // Some APIs take 1-2 seconds to execute.
	// 		loadCounter += 1;
	// 	}
	// 	console.log("Leaving", METHOD_NAME, CLASS_NAME);
	// }

	// componentDidMount = () => {
	// 	this.setState({
	// 		isLoading: false,
	// 	});
	// }

	onChangeDataArray(e) {
		const METHOD_NAME = ":onChangeChartData:";
		console.log("Entered", METHOD_NAME, CLASS_NAME);
		this.props.refreshDataArrayLink("sss");
		console.log("Leaving", METHOD_NAME, CLASS_NAME);
	}

	getThousandsSeparatedStr = () => {
		let num = parseFloat(this.props.mortgageAmount) + parseFloat(this.props.homeInsuranceAmount) + parseFloat(this.props.utilityAmount) + parseFloat(this.props.lifeInsuranceAmount) + parseFloat(this.props.propertyTaxAmount);
		let outputVar = null;
		if (num === null || num === undefined) {
			outputVar = '';
		} else if (num.toString() !== null) {
			var num_parts = num.toString().split(".");
			num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			//outputVar = `$${num_parts.join(".")}`;
			outputVar = `$${num_parts[0]}`;
		} else { outputVar = num; }
		return outputVar;
	}


	returnLifeInsuranceVal = () => {
		let outputPercentage = 1;
		let finalPercent = ` ${outputPercentage}% `;
		let dollarText = ` ($${this.props.value.insurance.toFixed(1)})`;
		lifeInsuranceText = `Life Insurance ${finalPercent} ${dollarText} of total cost`;
		return lifeInsuranceText;
	}

	render() {
		const METHOD_NAME = ":render";
		console.log("this.state:", this.state, METHOD_NAME, CLASS_NAME);
		console.log("this.props.activeView:", this.props.activeView, METHOD_NAME, CLASS_NAME);
		let localArray = this.props.whatIfScenarioDataArray;
		if (localArray != null && localArray.length > 0) {
			console.log("localArray:", localArray, METHOD_NAME, CLASS_NAME);
		} else {

		}
		const extension = this.props;
		return (
			<Container fluid>
				{this.props.activeView && (<React.Fragment>
					<Row>
						<Col xs={12} md={2} className='d-none'>
							Map View / Additional Data
								</Col>
						{/*<Col xs={12} md={6}>
							 <WhatIfScenarioV3
								value={this.props}
							/>
						</Col>*/}
						<Col xs={12} md={12}>
							<p className={extension ? 'd-none' : "d-block"}>Estimated Monthly expenses for <span className='text-default-color'>{this.props.whatIfScenarioDataArray != null && this.props.whatIfScenarioDataArray.length > 0 ? this.props.address : "Dummy address"} </span> is {this.getThousandsSeparatedStr()}</p>
							<BubbleDoughnut
								{...this.state}
								fromChartsHash={this.state}
								refreshDataArrayLink={this.props.onChangeDataArray}
							/>
						</Col>
					</Row>
					<Row className={extension ? 'd-none' : "d-block"}>
						<Col className="text-left">
							<Button
								variant="primary"
								type="submit"
								onClick={this.props.returnView}
								className="float-right"
							>
								Back
								</Button>
						</Col>
					</Row>
					<Row className={extension ? 'd-none' : "d-block"}>
						<Col className="text-left mt-2">
							<Button
								variant="primary"
								onClick={this.onChangeDataArray.bind(this)}
								className="float-right"
							>
								Refresh
								</Button>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className={GlobalVars.isDebugMode() ? "customTextField" : "hiddenDiv"}>
								<ListGroup>
									{this.props.whatIfScenarioDataArray.map(function (d, i) {
										return (
											<ListGroup.Item key={d}>
												{i} : {d[0]} : {d[1]}
											</ListGroup.Item>
										);
									})}
								</ListGroup>
							</div>
						</Col>
					</Row>
				</React.Fragment>
				)
				}
			</Container>
		);
	}
}
