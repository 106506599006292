import React from "react";

import { BallRotate } from 'react-pure-loaders';

import Upload2S3 from "./Upload2S3.function";
import GlobalConstantsVariables from "../GlobalConstantsVariables";
import ComponentHelper from "./ComponentHelper";

const CLASS_NAME = "AgentDetail.Component";
 
class AgentDetail extends React.Component {

    
    constructor() {
        super()
        this.state = {
            id: Math.random().toString(36).substr(3, 6).replace(/0/g, '1').replace(/o/g, 'p'),
            potraitImage: null,
            firstName: "",
            lastName: "",
            phoneNumber: "",
            emailId: "",
            licenseNumber: "",
            agencyName: "",
            eventStr: "AgentDataSave",
            activeFlag: true,
            website: '',
            expiresAt: '',
            dataCategory: GlobalConstantsVariables.getAgentDataCategoryAll()
        }

        this.agentData = Object.assign({}, this.state);
        this.setStateID = this.setStateID.bind(this);
    }

    changeHandler = (event) => {
        console.log("In change handler : ", event.target.value);
        this.setState({ [event.target.name]: event.target.value })
    }

    submitHandler = (event) => {
        event.preventDefault();

        if (!this.state.potraitImage) {
            this.setState({ fileSubmission: true });
            return;
        }

        console.log("Json Data >>>>>>>> ");
        this.setState({ isLoading: true })
        this.setState({ [event.target.name]: event.target.value });
        console.log("Submitted this.state:", JSON.stringify(this.state), CLASS_NAME);

        if (this.state.potraitImage.length > 0) {

            for (const key in this.agentData) {
                this.agentData[key] = this.state[key];
            }

            ComponentHelper.saveAppDataAndCache(this.agentData).then((response) => {
                this.setState({ id: response.id, displayOutput: true, isLoading: false });
            });
            ComponentHelper.add2AgentList(this.state.id);
            console.log("this.agentData:", this.agentData);
        }
    }
    setStateID(json) {
        this.setState({ id: json.id });
    }

    onUploadFileCompleted = (uploadedFileURL) => {
        this.setState({ potraitImage: uploadedFileURL })
        console.log('onUploadFile ', uploadedFileURL)
    }

    onFileSelected = (selectedFileName) => {
        this.setState({ selectedFileName })
    }

    render() {
        return (
            <div className="registration-page">
                <div className="container mt-2">
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <Upload2S3
                            id={this.state.id}
                            isPropsPassed={true}
                            onFileSelected={this.onFileSelected}
                            selectedFileName={this.state.selectedFileName}
                            onUploadFileCompleted={this.onUploadFileCompleted} />
                    </div>
                    {(this.state.fileSubmission && !this.state.potraitImage && !this.state.selectedFileName) &&
                        <div className="text-center h5 font-weight-bold mt-1 mb-1">
                            Please upload image
                        </div>}
                    <form onSubmit={this.submitHandler} id="agent-form">
                        <div>
                            <div className="d-flex justify-content-center mt-2">
                                <label className="col-4">
                                    First Name :
                                </label>
                                <span> <input type="text" name="firstName" onChange={this.changeHandler} size='35' required /></span>
                            </div>
                            <div className="d-flex  justify-content-center mt-2">
                                <label className="col-4">
                                    Last Name :
                                </label>
                                <span> <input type="text" name="lastName" onChange={this.changeHandler} size='35' required /></span>
                            </div>
                            <div className="d-flex  justify-content-center mt-2">
                                <label className="col-4">
                                    Phone Number :
                                </label>
                                <span> <input type="text" name="phoneNumber" onChange={this.changeHandler} size='35' required /></span>
                            </div>
                            <div className="d-flex  justify-content-center mt-2">
                                <label className="col-4">
                                    Email ID :
                                </label>
                                <span> <input type="email" name="emailId" onChange={this.changeHandler} size='35' required /></span>
                            </div>
                            <div className="d-flex  justify-content-center mt-2">
                                <label className="col-4">
                                    License Number :
                                </label>
                                <span> <input type="text" name="licenseNumber" onChange={this.changeHandler} size='35' required /></span>
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <label className="col-4">
                                    Agency Name  :
                                </label>
                                <span> <input type="text" name="agencyName" onChange={this.changeHandler} size='35' required /></span>
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <label className="col-4">
                                    Website  :
                                </label>
                                <span> <input type="text" name="website" onChange={this.changeHandler} value={this.state.website} size='35' required /></span>
                            </div>
                            <div className="d-flex flex-row align-items-center justify-content-center">
                                <button type="submit" className="buttonRounded mt-2" >Sign Up </button>
                            </div>
                        </div>
                    </form>

                </div>
                <div className="mt-2">
                    {(this.state.displayOutput) &&
                        <div className="d-flex flex-row align-items-center justify-content-center mt-3">
                            <div className="col-6 h6">
                                Registration Code for Extension Marketing Campiagn:
                        </div>
                            <span className="h5 mr-2"><input size="6" onChange={null} value={this.agentData.id} /></span>
                            <button className="buttonRounded" onClick={() => navigator.clipboard.writeText(this.agentData.id)}>Copy</button>
                        </div>
                    }
                    <div className="d-flex justify-content-center mt-3">
                        <BallRotate
                            color={'#7c648c'}
                            loading={this.state.isLoading}
                        />
                    </div>
                    {
                        (this.state.displayOutput)
                        && <div className="text-center">
                            <div className="mt-3 font-weight-bold h3">
                                Signup Successful!
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

}

export default AgentDetail;