import React from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

import question_mark from "./assets/help_question_mark.svg"
import pacilife_logo from "./assets/pacific_logo.png"
import GlobalConstants from "../GlobalConstantsVariables";

export default class LifeInsurance extends React.Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="insurance-page">
                <Row className="font-weight-bold">
                        <Col xs={8}>
                            <span className="form-label">
                                <span className="bs-rounded-circle lifeInsurance">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;PacLife-Next Life Insurance
                                </span>
                            <>
                                <OverlayTrigger
                                    placement={'right'}
                                    overlay={
                                        <Tooltip>
                                            {GlobalConstants.getHelpTooltipText("lifeInsurance")}
                                        </Tooltip>
                                    }
                                >
                                    <img src={question_mark} role="button" className="img-help" alt={'help'} />
                                </OverlayTrigger>
                                <img src={pacilife_logo} alt="pacilife_logo" className="paci_logo"/>
                            </>

                        </Col>
                        <Col xs={2} className='d-flex justify-content-end'>
                            <span className="form-label"><span>&nbsp;-</span></span>
                        </Col><Col xs={2} className='d-flex justify-content-end'>
                            <span className="form-label"><span>&nbsp;${this.state.pacLifeNextPremium != null ? parseFloat(this.state.pacLifeNextPremium).toFixed() : ''}</span></span>
                        </Col>
                    </Row>
            </div>
        );
    }
}