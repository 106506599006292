import React from "react";
//import NumberFormat from "react-number-format";
import Slider from "rc-slider";
//import ComponentHelper from "./ComponentHelper";
import Utility from "./Utility";

import male_active from "./assets/icon-male-active.svg"
import female_active from "./assets/icon-female-active.svg"
import male_inactive from "./assets/icon-male-inactive.svg"
import female_inactive from "./assets/icon-female-inactive.svg"

import tick_active from "./assets/icon-tick-active.svg"
import cross_active from "./assets/icon-cross-active.svg"
import tick_inactive from "./assets/icon-tick-inactive.svg"
import cross_inactive from "./assets/icon-cross-inactive.svg"

const CLASS_NAME = "IndividualDetailsFullScreen";

export default class IndividualDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: 0,
            weight: "",
            heightFt: "",
            heightIn: ""
        }
    }

    componentWillUnmount() {
        clearInterval(this.endTimer);
    }

    setGender = (e) => {
        this.props.onAfterChangeGender(e)
    }

    setSmoker = (e) => {
        this.props.onChangeSmoker(e)
    }

    onSliderChange = (index) => {
        this.props.onSliderChange(index)
    }

    onSliderAfterChange = (index) => {
        this.props.onSliderAfterChange(index)
    }

    enterWeight = (e) => {
        let weight = e.target.value.replace(/;/g,'').replace(/</g,"",).replace(/>/g,'')
        if(isNaN(weight)){
            weight = '';
        }
        this.setState({ weight })
    }

    enterHeight = (e) => {
        let METHOD_NAME = "enterHeight";
        let height = e.target.value.replace(/;/g,'').replace(/</g,"",).replace(/>/g,'')
        if(isNaN(height)){
            height = '';
        }
        if(e.target.name.includes('In')){
            height = height < 12 ? height : '';
        };
        console.log(CLASS_NAME, e.target.name, METHOD_NAME)
        this.setState({ [e.target.name] : height })
    }

    render() {
        // console.log('yearBuilt: ', this.props.yearBuilt, METHOD_NAME, CLASS_NAME);
        const termMarks = {
            10: { style: { color: '#7f6289', }, label: <span>10</span>, },
            15: { style: { color: '#7f6289', }, label: <span>15</span>, },
            20: { style: { color: '#7f6289', }, label: <span>20</span>, },
            25: { style: { color: '#7f6289', }, label: <span>25</span>, },
            30: { style: { color: '#7f6289', }, label: <span>30</span>, },
        };
        const birthMarks = {
            1970: { style: { color: '#40454c', fontSize: "10px", opacity: "0.8", fontFamily: "Poppins", left: "1%" }, label: <span>1970</span>, },
            1999: { style: { color: '#40454c', fontSize: "10px", opacity: "0.8", fontFamily: "Poppins", left: "98%"  }, label: <span>1999</span>, },
        };
        const coverageMarks = {
            1: { style: { color: '#000', fontSize: "10px" }, label: <span>$100K</span>, },
            12: { style: { color: '#000', fontSize: "10px" }, label: <span>$2M</span>, },
        }
        let healthLocal;
        // this.props.health === 0 ? 'Iffy' : (this.props.health === 2 ? 'Rocking' : 'Perfect')
        switch (this.props.health) {
            case 0: healthLocal = 'Iffy'; break;
            case 1: healthLocal = 'Good'; break;
            case 2: healthLocal = 'Perfect'; break;
            case 3: healthLocal = 'Rocking'; break;
            default: healthLocal = 'Good'; break;
        }
        return (
            <div className="personal-details">
                <div className="d-flex">
                    <div className="d-flex flex-column ">
                        <span className="attr-label"> Gender  (at birth)</span>

                        <div className="d-flex align-items-center gender-block">
                            {
                                this.props.gender === 1 ?
                                    <>
                                        <div className="gender active ml-1" onClick={() => this.setGender(1)}>   <img src={male_active} alt="" /> Male   </div>
                                        <div className="gender" onClick={() => this.setGender(0)}>  <img src={female_inactive} alt="" /> Female </div>
                                    </> : <>
                                        <div className="gender" onClick={() => this.setGender(1)}><img src={male_inactive} alt="" />Male </div>
                                        <div className="gender active" onClick={() => this.setGender(0)}><img src={female_active} alt="" />Female </div>
                                    </>
                            }
                        </div>
                    </div>
                    <div className="d-flex flex-column physical">
                        <span className="attr-label"> Physical Attributes </span>
                        <div className="d-flex">
                            <div className="phy-attr height">
                                <input value={this.state.heightFt} name="heightFt" onChange={this.enterHeight} /> ft
                                <input value={this.state.heightIn} name="heightIn" onChange={this.enterHeight}/> in
                            </div>
                            <div className="phy-attr">
                                <input value={this.state.weight} size="2" onChange={this.enterWeight} /> lbs
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column fs-slider-block">
                    <div className="d-flex flex-row">
                        <span className="slider-label">
                            Birth Year:
                        </span>
                        <div className="label">
                            {this.props.birthYear === -1 ? 'Unknown?' : this.props.birthYear}
                        </div>
                    </div>
                    <div className="slider-aligned">
                        <Slider
                            min={1970}
                            max={(new Date().getFullYear()) - 21}
                            marks={birthMarks}
                            onAfterChange={this.props.onAfterChangeBirthYear}
                            onChange={this.props.onChangeBirthYear}
                            value={this.props.birthYear}
                        />
                    </div>
                </div>
                <div className="d-flex flex-column fs-slider-block">
                    <div className="d-flex flex-row">
                        <span className="slider-label">
                            Health Condition:
                        </span>
                        <div className="label">
                            {healthLocal}
                        </div>
                    </div>
                    <div className="slider-aligned">
                        <Slider
                            min={0}
                            max={3}
                            onAfterChange={this.props.onAfterChangeHealth}
                            onChange={this.props.onChangeHealth}
                            value={this.props.health}
                            defaultValue={1}
                        />
                    </div>
                </div>



                { false &&
                    <div className="column-2">
                        <div className='d-flex flex-row justify-content-around indi-full-screen'>
                            <span className={this.props.individualFullItem === 0 ? "active mr-1 ml-1" : "mr-1 ml-1"} role="button" onClick={() => this.props.onChangeItem('individualFullItem', 0)}>My Details</span>
                            <span className={this.props.individualFullItem === 1 ? "active mr-1 ml-1" : "mr-1 ml-1"} role="button" onClick={() => this.props.onChangeItem('individualFullItem', 1)}>My Home</span>
                            <span className={this.props.individualFullItem === 2 ? "active mr-1 ml-1" : "mr-1 ml-1"} role="button" onClick={() => this.props.onChangeItem('individualFullItem', 2)}>My Life</span>
                        </div>
                        <div>
                            {
                                this.props.individualFullItem === 0 ?
                                    <>
                                        <div className="d-flex flex-column fs-slider-block">
                                            <div className="d-flex flex-row justify-content-between">
                                                <span className="slider-label">
                                                    Birth Year
                                        </span>
                                                <div className="label">
                                                    {this.props.birthYear === -1 ? 'Unknown?' : this.props.birthYear}
                                                </div>
                                            </div>
                                            <div className="slider-aligned">
                                                <Slider
                                                    min={1970}
                                                    max={(new Date().getFullYear()) - 21}
                                                    marks={birthMarks}
                                                    onAfterChange={this.props.onAfterChangeBirthYear}
                                                    onChange={this.props.onChangeBirthYear}
                                                    value={this.props.birthYear}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column fs-slider-block">
                                            <div className="d-flex flex-row justify-content-between">
                                                <span className="slider-label">
                                                    Gender (at birth)
                                        </span>
                                                <div className="label hidden">
                                                    {this.props.gender === -1 ? 'Unknown?' : this.props.gender === 0 ? 'Female' : this.props.gender === 1 ? 'Male' : 'Other'}
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center w-100 pl-2 ml-2 pt-1">
                                                {
                                                    this.props.gender === 1 ?
                                                        <>
                                                            <div className="gender active ml-1" onClick={() => this.setGender(1)}>   <img src={male_active} alt="" /> Male   </div>
                                                            <div className="gender" onClick={() => this.setGender(0)}>  <img src={female_inactive} alt="" /> Female </div>
                                                        </> : <>
                                                            <div className="gender" onClick={() => this.setGender(1)}><img src={male_inactive} alt="" />Male </div>
                                                            <div className="gender active" onClick={() => this.setGender(0)}><img src={female_active} alt="" />Female </div>
                                                        </>
                                                }
                                                {/* {<div className="col-3"> <input type="radio" value='2' name="gender" 
                                            checked={ Number(this.props.gender) === 2}/> Other </div>} */}
                                            </div>
                                            {/* {  <div className="slider-aligned">
                                        <Slider
                                            min={-1}
                                            max={2}
                                            onAfterChange={this.props.onAfterChangeGender}
                                            onChange={this.props.onChangeGender}
                                            value={this.props.gender}
                                            defaultValue={0}
                                        />
                                    </div>} */}
                                        </div>
                                        <div className="d-flex flex-column fs-slider-block">
                                            <div className="d-flex flex-row justify-content-between">
                                                <span className="slider-label">
                                                    Health Condition
                                        </span>
                                                <div className="label">
                                                    {healthLocal}
                                                </div>
                                            </div>
                                            <div className="slider-aligned">
                                                <Slider
                                                    min={0}
                                                    max={3}
                                                    onAfterChange={this.props.onAfterChangeHealth}
                                                    onChange={this.props.onChangeHealth}
                                                    value={this.props.health}
                                                    defaultValue={1}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column fs-slider-block">
                                            <div className="d-flex flex-row justify-content-between">
                                                <span className="slider-label">
                                                    Smoked in last 5 years
                                        </span>
                                                <div className="label hidden">
                                                    {this.props.smoker === -1 ? 'Unknown?' : this.props.smoker === 1 ? "Yes" : "No"}
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center w-100 pl-2 ml-2 pt-1">
                                                {/* {      <div className="col-3"> <input type="radio" value="-1" name="smoker" 
                                            checked={ Number(this.props.smoker) === -1}/> Unknown </div>} */}{/*
                                        <div className="col-3"> <input type="radio" value='0' name="smoker" onChange={() => null}
                                            checked={this.props.smoker === 0} /> No </div>
                                        <div className="col-3"> <input type="radio" value='1' name="smoker" onChange={() => null}
                                            checked={this.props.smoker === 1} /> Yes </div>  */}
                                                {
                                                    this.props.smoker === 1 ?
                                                        <>
                                                            <div className="gender active" onClick={() => this.setSmoker(1)}>   <img src={tick_active} alt="" /> Yes   </div>
                                                            <div className="gender" onClick={() => this.setSmoker(0)}>  <img src={cross_inactive} alt="" /> No </div>
                                                        </> : <>
                                                            <div className="gender" onClick={() => this.setSmoker(1)}><img src={tick_inactive} alt="" /> Yes </div>
                                                            <div className="gender active" onClick={() => this.setSmoker(0)}><img src={cross_active} alt="" /> No </div>
                                                        </>
                                                }
                                            </div>
                                            {/* { <div className="slider-aligned">
                                        <Slider
                                            min={-1}
                                            max={1}
                                            onAfterChange={this.props.onAfterChangeSmoker}
                                            onChange={this.props.onChangeSmoker}
                                            value={this.props.smoker}
                                        />
                                    </div>} */}
                                        </div>
                                        {/* <div className='d-flex flex-row align-items-end '>
                                    <div className="slider-aligned padding22">
                                        Life Insurance Amount
                                        <Slider
                                            onChange={this.props.onChangeCoverage}
                                            value={this.props.coverageDesired}
                                            marks={coverageMarks}
                                            min={100}
                                            max={1000}
                                        />
                                    </div>
                                    <div className='d-flex flex-row pt-5' >
                                        <FormGroup controlId="this.props.coverageDesired" >
                                            ${this.props.coverageDesired}K
                                            </FormGroup>
                                    </div>
                                </div>
                                </div>*/}
                                    </> :
                                    <>
                                        {this.props.individualFullItem === 1 ?
                                            <>
                                                <div className="d-flex flex-column fs-slider-block">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <span className="slider-label">
                                                            Living Area
                                                </span>
                                                        <div className="label">
                                                            {Utility.getFormattedNumberValue(this.props.livingsizeSqft)} Sq Ft &nbsp;
                                                </div>
                                                    </div>
                                                    <div className="slider-aligned">
                                                        <Slider
                                                            min={100}
                                                            max={10000}
                                                            step={100}
                                                            onAfterChange={this.props.onAfterChangeLivingsizeSqft}
                                                            onChange={this.props.onChangeLivingsizeSqft}
                                                            value={this.props.livingsizeSqft}
                                                            defaultValue={this.props.livingsizeSqft}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column fs-slider-block">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <span className="slider-label">
                                                            Bedrooms
                                                </span>
                                                        <div className="label">
                                                            {this.props.bed} &nbsp;
                                                </div>
                                                    </div>
                                                    <div className="slider-aligned">
                                                        <Slider
                                                            value={this.props.bed}
                                                            defaultValue={this.props.bed}
                                                            min={0}
                                                            max={15}
                                                            step={1}
                                                            onChange={this.props.onChangeBedrooms}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column fs-slider-block">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <span className="slider-label">
                                                            Bathrooms
                                                </span>
                                                        <div className="label">
                                                            {this.props.bath} &nbsp;
                                                </div>
                                                    </div>
                                                    <div className="slider-aligned">
                                                        <Slider
                                                            value={this.props.bath}
                                                            defaultValue={this.props.bath}
                                                            min={0}
                                                            max={15}
                                                            step={0.5}
                                                            onChange={this.props.onChangeBathrooms}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="d-flex flex-column fs-slider-block">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <span className="slider-label">
                                                            Year Built
                                                        </span>
                                                        <div className="label">
                                                            {this.props.yearBuilt} &nbsp;
                                                </div>
                                                    </div>
                                                    <div className="slider-aligned">
                                                        <Slider
                                                            value={this.props.yearBuilt}
                                                            defaultValue={this.props.yearBuilt}
                                                            min={1950}
                                                            max={2015}
                                                            step={1}
                                                            onChange={this.props.onChangeYearBuilt}
                                                        />
                                                    </div>
                                                </div>


                                                {/*    <div className='d-flex flex-row align-items-end mb-3'>
                                            <div className="slider-aligned fs-slider-input">
                                                Living Area
                                                <Slider value={this.props.livingsizeSqft} min={800} max={1800}
                                                    step={50} onChange={this.props.onChangeLivingsizeSqft} />
                                            </div>
                                            <div className='d-flex flex-column m-2 slider-info-text' >
                                                <FormGroup controlId="this.props.avmAmount" >
                                                    <NumberFormat className="bubble-input" placeholder="Living Area" displayType={'input'}
                                                        thousandSeparator={true} suffix={" sq ft"} onChange={this.props.onChangeLivingsizeSqft} value={this.props.livingsizeSqft} />
                                                </FormGroup>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-row align-items-end mb-3'>
                                            <div className="slider-aligned fs-slider-input">
                                                Bedrooms
                                            <Slider value={this.props.bedroom} min={1} max={15}
                                                    step={1} onChange={this.props.onChangeBedrooms} />
                                            </div>
                                            <div className='d-flex flex-column m-2 slider-info-text' >
                                                <FormGroup controlId="this.props.bedroom" >
                                                    <NumberFormat className="bubble-input" placeholder="Bedrooms" displayType={'input'}
                                                        onChange={this.props.onChangeBedrooms} value={this.props.bedroom} />
                                                </FormGroup>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-row align-items-end mb-3'>
                                            <div className="slider-aligned fs-slider-input">
                                                Bathrooms
                                            <Slider value={this.props.bathroom} min={1} max={15}
                                                    step={1} onChange={this.props.onChangeBathrooms} />
                                            </div>
                                            <div className='d-flex flex-column m-2 slider-info-text' >
                                                <FormGroup controlId="this.props.bathroom" >
                                                    <NumberFormat
                                                        className="bubble-input"
                                                        placeholder="Bathrooms"
                                                        displayType={'input'}
                                                        onChange={this.props.onChangeBathrooms}
                                                        value={this.props.bathroom} />
                                                </FormGroup>
                                            </div>
                                </div>  */}
                                            </> :
                                            <>
                                                {/* <div className='d-flex flex-row align-items-end mb-3'>
                                            <div className="slider-aligned padding22">
                                                Term Length
                                        </div>
                                            <div className='d-flex flex-column m-2 slider-info-text' >
                                                <FormGroup controlId="this.props.termLength" >
                                                    {65 - this.props.age}  Years
                                            </FormGroup>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-row align-items-end mb-3'>
                                            <div className="slider-aligned padding22">
                                                Coverage
                                            <Slider value={this.props.coverage} min={0} max={1000}
                                                    step={50} onChange={this.props.onChangeCoverage} />
                                            </div>
                                            <div className='d-flex flex-column m-2 slider-info-text' >
                                                <FormGroup controlId="this.props.coverage" >
                                                    <NumberFormat
                                                        className="bubble-input"
                                                        placeholder="Coverage"
                                                        displayType={'input'}
                                                        onChange={this.onChangeCoverage}
                                                        value={this.props.coverage} />
                                                </FormGroup>
                                            </div>
                                        </div> */}
                                                <div className="d-flex flex-column fs-slider-block">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <span className="slider-label">
                                                            Life Coverage
                                            </span>
                                                        <div className="label">
                                                            ${Utility.getFormattedNumberValue(this.props.calculatedCoverage)}  &nbsp;
                                                </div>
                                                    </div>
                                                    <div className="slider-aligned">
                                                        <Slider
                                                            onAfterChange={this.onSliderAfterChange}
                                                            onChange={this.onSliderChange}
                                                            value={this.props.index}
                                                            marks={coverageMarks}
                                                            min={1}
                                                            max={12}
                                                            step={1}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column fs-slider-block">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <span className="slider-label">
                                                            Term Desired
                                                </span>
                                                        <div className="label">
                                                            {this.props.termDesired} Years
                                        </div>
                                                    </div>
                                                    <div className="slider-aligned">
                                                        <Slider
                                                            onAfterChange={this.props.onAfterChangeTermDesired}
                                                            onChange={this.props.onChangeTermDesired}
                                                            value={this.props.termDesired}
                                                            marks={termMarks}
                                                            min={10}
                                                            max={30}
                                                            step={5}
                                                        />
                                                    </div>
                                                </div>


                                                {/* <div className='d-flex flex-row align-items-end mb-3'>
                                            <div className="slider-aligned padding22">
                                                Est. life insurance premium
                                            </div>
                                            <div className='d-flex flex-column m-2 slider-info-text' >
                                                <FormGroup controlId="this.props.lifeInsurancePremium" >
                                                    $ 47
                                            </FormGroup>
                                            </div>
                                        </div> */}
                                            </>}
                                    </>
                            }
                        </div>
                        <div className="w-100 resetBtnDiv1">
                            <button className="orangeGradient" onClick={this.props.resetToDefaultValues}>
                                Reset All
                    </button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}