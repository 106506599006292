import React from "react";
import { Popover, Form, Row, Col, Button } from "react-bootstrap";

function EmailPopover() {
    return <Popover id="popover-basic" >
        <Popover.Title as="h3">Send Quote to Your Email Address</Popover.Title>
        <Popover.Content>
            <Form.Group controlId="insuranceAddressForm.Age">
                <Form.Label>Your Email Address</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Your Email Address"
                    size="sm"
                    name="Email" />
            </Form.Group>
            <Row>
                <Col>
                    <Button
                        variant="secondary"
                        className="float-right mr-2"
                        size="sm"
                    >
                        Send Email
								</Button>
                </Col>
            </Row>
        </Popover.Content>
    </Popover >;
}

export default EmailPopover;