import React from "react";

import Slider from "rc-slider";
import { FormGroup } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { BallRotate } from 'react-pure-loaders';

//import ComponentHelper from "./ComponentHelper";

const CLASS_NAME = "PropertyDetails"

class PropertyDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            bed: this.props.bed,
            bath: this.props.bath,
            utilityAmount: this.props.utilityAmount,
            propertyTaxAmount: this.props.propertyTaxAmount
        }
    }

    onChangeLivingsizeSqft = value => {

        if (isNaN(value)) {
            //value comes from NumberFormat input 
            value = value.target.value
        }

        if (!value || value < 1) {
            return;
        }

        this.setState({
            livingsizeSqft: value
        })
    }

    onPropertyTaxChange = e => {
        let METHOD_NAME = "onPropertyTaxChange";
        let propertyTaxAmount = e.target.value;
        console.log(CLASS_NAME, METHOD_NAME, "Entered:Input: propertyTax:", propertyTaxAmount)

        propertyTaxAmount = parseInt(propertyTaxAmount.replace('$', '').replace(',', '').replace(',', ''));

        if (!propertyTaxAmount || propertyTaxAmount < 1) {
            return;
        }

        const key = "propertyTaxAmount"
        this.props.onChangeItem(key, propertyTaxAmount);
        this.setState({
            propertyTaxAmount
        })
    }

    onChangeUtilityAmount = e => {
        let METHOD_NAME = "onUtilityAmountChange";
        let utilityAmount = e.target.value;
        console.log(CLASS_NAME, METHOD_NAME, "Entered:Input: utilityAmount:", utilityAmount)
        utilityAmount = parseInt(utilityAmount.replace('$', '').replace(',', '').replace(',', ''));

        if (!utilityAmount || utilityAmount < 1) {
            return;
        }

        const key = "utilityAmount"
        this.props.onChangeItem(key, utilityAmount);
        this.setState({
            utilityAmount
        })
    }

    onHOInsuranceAmountChange = e => {
        let METHOD_NAME = "onHOInsuranceAmountChange";
        let homeOwnerInsuranceAmount = e.target.value;
        console.log(CLASS_NAME, METHOD_NAME, "Entered:Input: utilityAmount:", homeOwnerInsuranceAmount)

        homeOwnerInsuranceAmount = parseInt(homeOwnerInsuranceAmount.replace('$', '').replace(',', '').replace(',', ''));

        if (!homeOwnerInsuranceAmount || homeOwnerInsuranceAmount < 1) {
            return;
        }

        this.setState({ homeOwnerInsuranceAmount });
    }

    onHOAAssuranceFeeChange = e => {
        let METHOD_NAME = "onHOAAssuranceFeeChange";
        let HOAAssuranceFees = e.target.value;
        console.log(CLASS_NAME, METHOD_NAME, "Entered:Input: utilityAmount:", HOAAssuranceFees)

        HOAAssuranceFees = parseInt(HOAAssuranceFees.replace('$', '').replace(',', '').replace(',', ''));

        if (!HOAAssuranceFees || HOAAssuranceFees < 1) {
            return;
        }

        this.setState({ HOAAssuranceFees });
    }

    onChangebeds = value => {

        if (isNaN(value)) {
            //value comes from NumberFormat input 
            value = value.target.value
        }

        if (!value || value < 1) {
            return;
        }

        this.setState({
            bed: value
        })
    }

    onChangebaths = value => {

        if (isNaN(value)) {
            //value comes from NumberFormat input 
            value = value.target.value
        }

        if (!value || value < 1) {
            return;
        }

        this.setState({
            bath: value
        })
    }

    render() {
        return (
            <div>
                <div className='d-flex flex-row justify-content-between'>
                    <div className='d-flex flex-row align-items-end mb-3'>
                        <div className="slider-aligned">
                            Living Area
                        <Slider value={this.state.livingsizeSqft} min={800} max={1800}
                                step={50} onChange={this.onChangeLivingsizeSqft} />
                        </div>
                        <div className='d-flex flex-column m-2 slider-info-text' >
                            <FormGroup controlId="this.state.avmAmount" >
                                <NumberFormat className="bubble-input" placeholder="Living Area" displayType={'input'}
                                    thousandSeparator={true} suffix={" sq ft"} onChange={this.onChangeLivingsizeSqft} value={this.state.livingsizeSqft} />
                            </FormGroup>
                        </div>
                    </div>

                    <div className='d-flex flex-row align-items-end mb-3'>
                        <div className="slider-aligned">
                            beds
                        <Slider value={this.state.bed} min={1} max={15}
                                step={1} onChange={this.onChangebeds} />
                        </div>
                        <div className='d-flex flex-column m-2 slider-info-text' >
                            <FormGroup controlId="this.state.bed" >
                                <NumberFormat className="bubble-input" placeholder="beds" displayType={'input'}
                                    onChange={this.onChangebeds} value={this.state.bed} />
                            </FormGroup>
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row justify-content-between'>
                    <div className='d-flex flex-row align-items-end mb-3'>
                        <div className="slider-aligned">
                            baths
                            <Slider value={this.state.bath} min={1} max={15}
                                step={1} onChange={this.onChangebaths} />
                        </div>
                        <div className='d-flex flex-column m-2 slider-info-text' >
                            <FormGroup controlId="this.state.bath" >
                                <NumberFormat
                                    className="bubble-input"
                                    placeholder="baths"
                                    displayType={'input'}
                                    onChange={this.onChangebaths}
                                    value={this.state.bath} />
                            </FormGroup>
                        </div>
                    </div>
                    <div className='flex flex-row align-items-end mb-3 d-none'>
                        {/*This block is to maintain alignment*/}
                        <div className="slider-aligned">
                            baths
                        <Slider value={this.state.bath} min={1} max={15}
                                step={1} onChange={this.onChangebaths} />
                        </div>
                        <div className='d-flex flex-column m-2 slider-info-text' >
                            <FormGroup controlId="this.state.bath" >
                                <NumberFormat
                                    className="bubble-input"
                                    placeholder="baths"
                                    displayType={'input'}
                                    onChange={this.onChangebaths}
                                    value={this.state.bath} />
                            </FormGroup>
                        </div>
                    </div>
                </div>
                {/* 
                <div className='d-flex flex-row justify-content-between'>
                    <div className='d-flex flex-row align-items-end mb-3'>
                        <div className="slider-aligned">
                            <FormLabel><small><span className="mortgage bs-rounded-circle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></small>&nbsp;Property Tax</FormLabel>
                        </div>
                        <div className='d-flex flex-column m-2 slider-info-text' >
                            <FormGroup controlId="this.state.propertyTax" >
                                <NumberFormat
                                    thousandSeparator={true}
                                    className="bubble-input"
                                    placeholder="Property Tax"
                                    displayType={'input'}
                                    prefix={"$"}
                                    onChange={this.onPropertyTaxChange}
                                    value={this.state.propertyTaxAmount} />
                            </FormGroup>
                        </div>
                    </div>

                    <BallRotate
                        color={'#7c648c'}
                        loading={this.state.isLoading}
                    />

                    <div className='d-flex flex-row align-items-end mb-3'>
                        <div className="slider-aligned">
                            <FormLabel>
                                <small>
                                    <span className="homeInsurance bs-rounded-circle">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </small>&nbsp;
                                Utilities &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
                                {/* Extra Spaces for ALignment 
                            </FormLabel>
                        </div >
                <div className='d-flex flex-column m-2 slider-info-text' >
                    <FormGroup controlId="this.state.propertyTax" >
                        <NumberFormat
                            thousandSeparator={true}
                            className="bubble-input"
                            placeholder="Utilities"
                            displayType={'input'}
                            prefix={"$"}
                            onChange={this.onChangeUtilityAmount}
                            value={this.state.utilityAmount} />
                    </FormGroup>
                </div>
            </div >

                </div >
        {/* <div className='d-flex flex-row justify-content-between'>

                    <div className='d-flex flex-row align-items-end mb-3'>
                        <div className="slider-aligned">
                            <FormLabel><small><span className="lifeInsurance bs-rounded-circle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></small>&nbsp;HO Insurance</FormLabel>
                        </div>
                        <div className='d-flex flex-column m-2 slider-info-text' >
                            <FormGroup controlId="this.state.propertyTax" >
                                <NumberFormat
                                    thousandSeparator={true}
                                    className="bubble-input"
                                    placeholder="HO Insurance"
                                    displayType={'input'}
                                    prefix={"$"}
                                    onChange={this.onHOInsuranceAmountChange}
                                    value={this.state.homeOwnerInsuranceAmount} />
                            </FormGroup>
                        </div>
                    </div>

                    <div className='d-flex flex-row align-items-end mb-3'>
                        <div className="slider-aligned">
                            <FormLabel><small><span className="mortgage bs-rounded-circle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></small>&nbsp;HOA Assurance Fees</FormLabel>
                        </div>
                        <div className='d-flex flex-column m-2 slider-info-text' >
                            <FormGroup controlId="this.state.HOAAssuranceFees" >
                                <NumberFormat
                                    placeholder="HOA Assurance Fees"
                                    className="bubble-input"
                                    displayType={'input'}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    onChange={this.onHOAAssuranceFeeChange}
                                    value={this.state.HOAAssuranceFees} />
                            </FormGroup>
                        </div>
                    </div>

                </div> */}
                <BallRotate
                    color={'#7c648c'}
                    loading={this.state.isLoading}
                />

            </div >
        )
    }
}

export default PropertyDetails;