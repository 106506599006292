import { set, get } from "idb-keyval";
import Utility from "./components/Utility";
const CLASS_NAME = "GlobalConstantsVariables:";
const V2_APP_PREFIX = "/v2";

const AWS_SERVER_HEADERS = {
    'accept': 'application/json',
    'x-api-key': 'AdPhiUp7P54WsqUGQ0wM49crOApjg3on9sphXezV',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
};
//let sessionId = 'DEFAULTSESSIONID';
//let isDebug = true;
let source1 = "realtor.com/realestateandhomes-detail";
let fullAddress = "DEFAULTADDRESSFROM-GlobalConstantsVariables";
let INTEREST_RATE_PRIMARY_RESIDENCE = 3;
let INTEREST_RATE_NOT_PRIMARY_RESIDENCE = 3.5;
const HELP_TOOLTIP_TEXT = {
    "monthlyEstmated": "Total Monthly Expense Due",
    "mortgageAmount": "Total morgtgage amount due", "propertyTaxAmount": "Total property tax amount due",
    "homeInsuranceAmount": "Total home insurance amount due", "utilities": "Total utilities due", "lifeInsurance": "Total life insurance amount due"
}
const LIFE_INSURANCE_FIELDS = ['birthYear', 'age', 'gender', 'health', 'smoker', 'height', 'weight', 'livingsizeSqft', 'calculatedCoverage', 'termDesired'];
const AGE = "Age (20 - 65)";
const POLICY_TERM = "Policy Term";
const PROPERTY = "Property";
const DATA_RELEASE_VERSION = "v3";
const CLIENT_DATA_CATEGORY = "client_data";
const CLIENT_DATA_CATEGORY_ALL = "client_data_all";
const AGENT_DATA_CATEGORY_ALL = "agent_data_all";
const BACK9_API = 'getQuotesFromBack9';
const PACLIFE_NEXT_API = 'getQuotesFromPacLifeNext';
const QUOTES_KEY_SEQUENCE_HASH = {
    'getQuotesFromBack9': ["state", "face_amounts", "health", "gender", "mode", "birthdate", "smoker", "product_categories"],
    'getQuotesFromPacLifeNext': ["solicitationState", "faceAmount", "riskClass", "gender", "premiumFrequency", "dateOfBirth", "productCode", "premiumDuration"]
};
const PACLIFE_NEXT_API_INPUT = {};           //eslint-disable-line
const BACK9_API_INPUT = {};                  //eslint-disable-line


function getFromCacheV3(key) {
    return get(key);
}
function setInCacheV3(key, value) {
    // Put the object into storage
    set(key, value);
}
function getPresets4APIInput(apiMethodName) {
    let outputJson = {};
    switch (apiMethodName) {
        case PACLIFE_NEXT_API:
            outputJson = {
                premiumFrequency: 'Monthly', productCode: 'FLEXT65'
            };
            break;
        default:
            break;
    }
    return outputJson;
}
function areAllInputsAvailableFunc(apiMethodName, json) {
    /* let apiMethodName, json;
const QUOTES_KEY_SEQUENCE_HASH = {
    'getQuotesFromBack9': ["state", "face_amounts", "health", "gender", "mode", "birthdate", "smoker", "product_categories"],
    'getQuotesFromPacLifeNext': ["solicitationState", "faceAmount", "riskClass", "gender", "premiumFrequency", "dateOfBirth", "productCode", "premiumDuration"]
};
*/
    if (json == null) {
        [apiMethodName, json] = ['getQuotesFromPacLifeNext', {
            "premiumFrequency": "Monthly",
            "productCode": "FLEXT65",
            "solicitationState": "CA",
            "faceAmount": 1000000,
            "premiumDuration": 27,
            "dateOfBirth": "1982-01-01",
            "riskClass": "PreferredPlusNonTobacco"
        }];
    }
    let sequence = QUOTES_KEY_SEQUENCE_HASH[apiMethodName];
    let keys2 = Object.keys(json);
    sequence.forEach((key) => {
        console.log(":key:", key, "#!sequence.includes(key): ", !sequence.includes(key));
        if (!keys2.includes(key)) {
            console.log("Problem:key:", key);
            return false;
        }
    });
    return true;
}
export default class GlobalConstantsVariables {
    // constructor() {
    //     this.isDebug = true;
    //     this.sessionId = 'DEFAULTSESSIONID';
    // }
    // GlobalConstantsVariables.getSessionId()
    static getAgeLabel() { return AGE; }
    static getPolicyTermLabel() { return POLICY_TERM; }
    static getPropertyLabel() { return PROPERTY; }
    static getDataReleaseVersion() { return DATA_RELEASE_VERSION; }
    static getLifeInsuranceFieldsArray() { return LIFE_INSURANCE_FIELDS; }
    static getInterestRatePrimaryResidence() { return INTEREST_RATE_PRIMARY_RESIDENCE; }
    static getInterestRateNotPrimaryResidence() { return INTEREST_RATE_NOT_PRIMARY_RESIDENCE; }
    static getClientDataCategory() { return CLIENT_DATA_CATEGORY; }
    static getClientDataCategoryAll() { return CLIENT_DATA_CATEGORY_ALL; }
    static getAgentDataCategoryAll() { return AGENT_DATA_CATEGORY_ALL; }

    static getBack9APITag() { return BACK9_API; }
    static getPacLifeNextAPITag() { return PACLIFE_NEXT_API; }
    static getQuotesKeySequence(apiMethodName) {
        return QUOTES_KEY_SEQUENCE_HASH[apiMethodName];
    }
    static areAllInputsAvailable(apiMethodName, json) { return areAllInputsAvailableFunc(apiMethodName, json); }
    static getFromCache(key) { return getFromCacheV3(key); }
    static setInCache(key, value) {
        // Put the object into storage
        setInCacheV3(key, value);
    }
    static getAPIInputJson(apiMethodName) { return getFromCacheV3(apiMethodName); }
    static setAPIInputJson(apiMethodName, inputJson) {
        // TODO This method has hardwired attribute names for PacLife Next API
        let METHOD_NAME = ":setAPIInputJson:";
        let intermediateJson = {};
        console.log('@@@inputJson: ', inputJson, METHOD_NAME, CLASS_NAME);

        Object.entries(inputJson).forEach(([key, value]) => {
            console.log('@@@key: ', key, " # value: ", value, METHOD_NAME, CLASS_NAME);

            //'getQuotesFromPacLifeNext': ["DONE:solicitationState", "DONE:faceAmount", "riskClass", "DONE: gender", "premiumFrequency", "DONE: dateOfBirth", "productCode"
            switch (key) {
                case 'stateCode':
                    key = 'solicitationState'; break;
                case 'calculatedCoverage':
                    key = 'faceAmount';
                    if ((value.toString()).length < 5)
                        value = parseInt(`${value}000`);
                    else
                        value = parseInt(value);
                    break;
                case 'smoker':
                    key = 'riskClass';
                    value = value ? 'StandardTobacco' : 'PreferredPlusNonTobacco'; break;
                case 'birthYear':
                    key = 'dateOfBirth';
                    // let value = 1985;
                    let premiumDuration = 65 - Utility.getAgeFromBirthYear(value);
                    intermediateJson['premiumDuration'] = premiumDuration;
                    value = `${value}-01-01`;
                    break;
                case 'gender':
                    key = 'gender';
                    value = value === 0 ? 'Female' : 'Male';
                    break;
                default:
                    key = ''
                    break;
            }
            if (key.length > 0) intermediateJson[key] = value;
        });
        console.log('@@@intermediateJson:intermediateJson: ', intermediateJson, METHOD_NAME, CLASS_NAME);

        getFromCacheV3(apiMethodName).then((hash) => {
            METHOD_NAME = `${METHOD_NAME}:getFromCacheV3:then`;
            console.log('@@@hash:hash: ', hash, METHOD_NAME, CLASS_NAME);
            if (hash == null) {
                hash = getPresets4APIInput(apiMethodName);
            } else if (hash.premiumFrequency == null) { // Patch for few edge cases
                console.log('@@@@hash:getPresets4APIInput(apiMethodName): ', getPresets4APIInput(apiMethodName), METHOD_NAME, CLASS_NAME);
                Object.entries(getPresets4APIInput(apiMethodName)).forEach(([key, value]) => {
                    hash[key] = value;
                });
            }
            Object.entries(intermediateJson).forEach(([key, value]) => {
                hash[key] = value;
            });
            setInCacheV3(apiMethodName, hash);
            console.log('@@@Output:hash: ', hash, METHOD_NAME, CLASS_NAME);
        });
    }
    // GlobalConstantsVariableshelpTooltipText('mortgageAmount') 
    static getHelpTooltipText(key) {
        return HELP_TOOLTIP_TEXT[key];
    }
    static getSessionId() { return this.sessionId; }
    // GlobalConstantsVariables.setSessionId()
    static setSessionId(inputSessionId) {
        this.sessionId = inputSessionId;
    }
    // GlobalConstantsVariables.isDebugMode()
    static isDebugMode() {
        const METHOD_NAME = ":isDebugMode:";
        console.log('Entered', METHOD_NAME, CLASS_NAME)
        return this.isDebug;
    }
    // GlobalConstantsVariables.isDebugMode()
    static setDebugMode(inputFlag) {
        this.isDebug = inputFlag;
    }
    // GlobalConstantsVariables.getV2AppPrefix()
    static getV2AppPrefix() {
        return V2_APP_PREFIX;
    }
    // GlobalConstantsVariables.getAWSServerHeaders()
    static getAWSServerHeaders() {
        return AWS_SERVER_HEADERS;
    }
    // GlobalConstantsVariables.getSource1()
    static getSource1() {
        return source1;
    }
    static setSource1(input) {
        source1 = input;
    }
    // GlobalConstantsVariables.getSource1()
    static getFullAddress() {
        return fullAddress;
    }
    static setFullAddress(input) {
        fullAddress = input;
    }
}
