import React, { Component } from 'react';
//import Toggle from '../components/Toggle.Component';
import Slider from 'rc-slider';
import ComponentHelper from "./ComponentHelper";
import GlobalVariables from "../GlobalConstantsVariables";
import IndividualDetailsFullScreen from "./IndividualDetailsFullScreen";
import Utility from "./Utility";

import male_active from "./assets/icon-male-active.svg"
import female_active from "./assets/icon-female-active.svg"
import male_inactive from "./assets/icon-male-inactive.svg"
import female_inactive from "./assets/icon-female-inactive.svg"
import home from "./assets/home.png"
import profile from "./assets/profile.png"

import tick_active from "./assets/icon-tick-active.svg"
import cross_active from "./assets/icon-cross-active.svg"
import tick_inactive from "./assets/icon-tick-inactive.svg"
import cross_inactive from "./assets/icon-cross-inactive.svg"


const CLASS_NAME = ":IndividualDetailsV2:";
const indexToCoverage = [0, 100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000, 1500000, 2000000] //map coverage values to slider
let age18 = new Date().getFullYear() - 18;
let idLocal = 'idLocalShridhar';
let clientIdLocal = 'clientIdLocalShridhar';
export default class IndividualDetails extends Component {
    constructor(props) {
        super(props);
        let METHOD_NAME = ":constructor:";
        let intermediateState = {
            id: null,
            clientId: null,
            isActive: false,
            birthYearArray: [],
            activeItem: 0,
            mortgageAmount: this.props.mortgageAmount,
            livingsizeSqft: this.props.livingsizeSqft,
            calculatedCoverage: this.props.calculatedCoverage,
            index: this.props.index || indexToCoverage.indexOf(this.props.calculatedCoverage),
            termDesired: this.props.termDesired,
            birthYear: this.props.birthYear,
            bath: this.props.bath || 1,
            bed: this.props.bed || 1,
            age: Utility.getAgeFromBirthYear(this.props.birthYear),
            gender: this.props.gender || 0,
            health: this.props.health || 1,
            yearBuilt: this.props.yearBuilt || 1970,
            smoker: this.props.smoker || 0,
            height: -1,
            weight: -1,
        };
        this.setStateFromCache = this.setStateFromCache.bind(this);
        this.pushDataInDBAndCache = this.pushDataInDBAndCache.bind(this);
        this.refreshDoughnut = this.refreshDoughnut.bind(this);
        this.onChangeMortgage = this.onChangeMortgage.bind(this);
        this.onAfterChangeMortgage = this.onAfterChangeMortgage.bind(this);
        this.onChangeLivingsizeSqft = this.onChangeLivingsizeSqft.bind(this);
        this.onAfterChangeLivingsizeSqft = this.onAfterChangeLivingsizeSqft.bind(this);
        this.onChangeBirthYear = this.onChangeBirthYear.bind(this);
        this.onAfterChangeBirthYear = this.onAfterChangeBirthYear.bind(this);
        this.onChangeGender = this.onChangeGender.bind(this);
        this.onAfterChangeGender = this.onAfterChangeGender.bind(this);
        this.onChangeHealth = this.onChangeHealth.bind(this);
        this.onAfterChangeHealth = this.onAfterChangeHealth.bind(this);
        this.onChangeSmoker = this.onChangeSmoker.bind(this);
        this.onAfterChangeSmoker = this.onAfterChangeSmoker.bind(this);
        this.onAfterChangeCoverage = this.onAfterChangeCoverage.bind(this);
        this.onChangeCoverage = this.onChangeCoverage.bind(this);
        this.onChangeTermDesired = this.onChangeTermDesired.bind(this);
        this.processLifeInsuranceAmount = this.processLifeInsuranceAmount.bind(this);

        // Logic: expecting id and clientId from parent or sibling (this.props.id, this.props.clientId)
        // if not use localvalues
        intermediateState.id = this.props.id != null ? this.props.id : idLocal;
        intermediateState.clientId = this.props.clientId != null ? this.props.clientId : clientIdLocal;
        let key;
        key = `${intermediateState.clientId}++All`;

        ComponentHelper.getCachedData2(key).then((response) => {
            METHOD_NAME += ":getCachedData2.then:";
            console.log("response: ", response, METHOD_NAME, CLASS_NAME);
            let json = JSON.parse(response.Payload);
            // console.log("json.body.output: ", json.body.output, METHOD_NAME, CLASS_NAME);
            if (json.body.output === "" || json.body.output === {}) {
                METHOD_NAME += ":emptyCache:";
                let inputJson = {};
                inputJson.clientId = intermediateState.clientId;
                inputJson.id = intermediateState.clientId;
                inputJson.cacheId = intermediateState.clientId + "++All";
                inputJson.dataCategory = GlobalVariables.getClientDataCategoryAll();
                inputJson.eventStr = CLASS_NAME.replace(/:/g, '') + 'Insert';
                ComponentHelper.saveAppDataAndCache(inputJson);
                this.setStateFromCache(inputJson);
            } else {
                METHOD_NAME += ":setState.prepare:";
                let stateInputJsonStr = json.body.output.replace(/__-__/g, '"');
                // Patch to fix failed addresses extraction:
                if (stateInputJsonStr.indexOf('_-_-_') === -1 && stateInputJsonStr.indexOf('"addresses":"[') !== -1 && stateInputJsonStr.indexOf(']"}') !== -1) {
                    stateInputJsonStr = stateInputJsonStr.replace('"addresses":"[', '"addresses":[').replace(']"}', ']}');
                }
                stateInputJsonStr = Utility.patchBrokenData1(stateInputJsonStr);
                let stateInputJson = JSON.parse(stateInputJsonStr);

                this.storeCacheValueinState(stateInputJson)
                let jsonConfirmed = {};
                Object.entries(stateInputJson).forEach(([key, value]) => {
                    if (key === 'birthYear') {
                        jsonConfirmed['birthYear'] = value;
                        jsonConfirmed['age'] = Utility.getAgeFromBirthYear(value);
                        intermediateState['birthYear'] = value;
                        intermediateState['age'] = Utility.getAgeFromBirthYear(value);
                        // console.log('jsonConfirmed[birthYear]: ', stateInputJson['birthYear'], 'type:', typeof (stateInputJson['birthYear']), METHOD_NAME, CLASS_NAME);
                    } else {
                        jsonConfirmed[key] = value;
                        intermediateState[key] = value;
                    }
                });
                GlobalVariables.setAPIInputJson(GlobalVariables.getPacLifeNextAPITag(), jsonConfirmed);
                // console.log('@@@stateInputJson: ', stateInputJson, METHOD_NAME, CLASS_NAME);
            }

        });
        this.state = intermediateState;
        console.log('@@@this.state: ', this.state, METHOD_NAME, CLASS_NAME);

    }
    setStateFromCache(inputJson) {
        this.setState(inputJson);
    }
    storeCacheValueinState = (obj) => {
        //     const { termDesired, calculatedCoverage } = obj;
        //     this.tempObject['termDesired'] = termDesired;
        //     this.tempObject['calculatedCoverage'] = calculatedCoverage;
        if (!(this.props.termDesired)&& obj && isFinite(obj['termDesired']))
            this.props.onChangeItem('termDesired', Number(obj['termDesired']));
        if (!isNaN(this.props.mortgageAmount) && obj && isFinite(obj['mortgageAmount'])) {
            this.props.onChangeItem('mortgageAmount', +obj['mortgageAmount']);
        }
        if (!isNaN(this.state.calculatedCoverage) && obj && isFinite(obj['calculatedCoverage']))
            this.setState({ 'calculatedCoverage': +obj['calculatedCoverage'] })
    }

    resetToDefaultValues = () => {
        for (const key in this.props.defaultValuesHash) {
            this.setState({
                [key]: this.props.defaultValuesHash[key]
            })
        }
        this.props.resetToDefaultValues();
    }

    pushDataInDBAndCache(inputJson) {
        let METHOD_NAME = ":pushDataInDBAndCache:";
        let stateJson = {};
        let key, value;
        key = inputJson.key; value = inputJson.value;
        delete inputJson.key; delete inputJson.value;
        let localState = this.state;
        localState[key] = value;
        // UI Specific parameters to cache
        stateJson.age = localState.age;
        stateJson.ageBand = localState.ageBand;
        stateJson.birthYear = localState.birthYear;
        stateJson.gender = localState.gender;
        stateJson.health = localState.health;
        stateJson.smoker = localState.smoker;
        stateJson.termDesired = localState.termDesired;
        // stateJson. = this.state.
        stateJson.clientId = this.state.clientId;
        stateJson.id = this.state.clientId;
        stateJson.cacheId = this.state.clientId + "++All";
        stateJson.eventStr = CLASS_NAME.replace(/:/g, '') + 'Update';
        stateJson[key] = value;
        if (stateJson.birthYear != null && stateJson.age == null) {
            stateJson.age = Utility.getAgeFromBirthYear(stateJson.birthYear);
            this.setState({ age: stateJson.age });
        }
        console.log("%$% stateJson:", stateJson, METHOD_NAME, CLASS_NAME);
        try {
            ComponentHelper.updateAppDataAndCache(GlobalVariables.getClientDataCategoryAll(), stateJson);
        } catch (error) {
            METHOD_NAME += ":updateAppDataAndCache:Failed:";
            console.log("%$% error.code: ", error.code, " # stateJson:", stateJson, METHOD_NAME, CLASS_NAME);
            if (error.code === 'ConditionalCheckFailedException') {
                ComponentHelper.saveAppDataAndCache(GlobalVariables.getClientDataCategoryAll(), stateJson);
            } else {
                console.log("%$% PROBLEM PROBLEM Need to handle this code", METHOD_NAME, CLASS_NAME);
            }
        }
        GlobalVariables.setAPIInputJson(GlobalVariables.getPacLifeNextAPITag(), inputJson);
        // ComponentHelper.updateAppDataAndCache("IndividualUpdates", json4DyanoDB); sdsfsdf
        if (this.props.onChangeAmountsFromHash != null) {
            this.refreshDoughnut(key, value);
        }

    }

    componentWillUnmount(){
        clearInterval(this.endTimer);
    }

    refreshDoughnut(changedInputParamName, inputValue) {
        let METHOD_NAME = `:refreshDoughnut:${changedInputParamName}:`;
        let paramName, key, calculatedCoverageLocal;
        console.log("%$% Input:changedInputParamName:", changedInputParamName, "# inputValue:", inputValue, METHOD_NAME, CLASS_NAME);
        console.log("%$% listingPriceAmount:", this.props.listingPriceAmount, METHOD_NAME, CLASS_NAME);
        /*
        Life Insurance field received:
         1. re-calculate life insurance 
         2. Update AppData
        */
        let inputKeyLocal = changedInputParamName;
        if (GlobalVariables.getLifeInsuranceFieldsArray().indexOf(inputKeyLocal) > -1) {
            if (inputKeyLocal === 'calculatedCoverage') {
                // if (true) {
                METHOD_NAME += ":calculatedCoverage:inputValue:" + inputValue;
                calculatedCoverageLocal = inputValue != null && inputValue.toString().length < 4 ? `${inputValue}000` : inputValue;
                calculatedCoverageLocal = typeof calculatedCoverageLocal === 'string' ? Number(calculatedCoverageLocal) : calculatedCoverageLocal;
                calculatedCoverageLocal = Math.round((calculatedCoverageLocal/100000))*100000;

                //DATA_RELEASE_VERSION__stateCode__gender__health4LifeInsurance__termDesired__smoker__age__coverageAmount
                // 'data_category': 'app_constant', 'Poor': 3, 'Average': 2, 'Good': 1, 'Excellent': 0
                // IN____________600000
                // v2__stateCode__1__1__20__0__35__coverageAmount
                console.log("%$% this.props.stateCode:", this.props.stateCode, " # key: ", key, " # calculatedCoverageLocal: ", calculatedCoverageLocal, METHOD_NAME, CLASS_NAME);
                let genderLocal = this.state.gender && this.state.gender !== -1 ? this.state.gender : 1;
                let healthLocal = this.state.health && this.state.health !== -1 ? this.state.health : 2;
                switch (healthLocal) {
                    case 0: healthLocal = 3; break;
                    case 1: healthLocal = 2; break;
                    case 2: healthLocal = 1; break;
                    case 3: healthLocal = 0; break;
                    default: healthLocal = 2; break;
                }
                let termDesiredLocal = this.state.termDesired != null ? this.state.termDesired : 20;
                let smokerLocal = this.state.smoker != null && this.state.smoker !== -1 ? this.state.smoker : 1;
                let ageLocal = this.state.birthYear != null && this.state.birthYear !== -1 ? this.state.birthYear : 1990;
                ageLocal = Utility.getAgeFromBirthYear(this.state.birthYear);
                console.log("ageLocal:", ageLocal, METHOD_NAME, CLASS_NAME);
                ageLocal = ageLocal != null && ageLocal.toString().length > 0 ? ageLocal : 30;

                const idForLifeInsuranceAmount = `${GlobalVariables.getDataReleaseVersion()}__${this.props.stateCode}__${genderLocal}__${healthLocal}__${termDesiredLocal}__${smokerLocal}__${ageLocal}__${calculatedCoverageLocal}`;
                // make sure proper address is keyed and is saved in DB to fetch lifeInsuranceAmount
                ComponentHelper.getData(idForLifeInsuranceAmount).then((array) => {
                    METHOD_NAME += ":getData:than:"
                    if (array != null && array.length > 0) {
                        console.log("%$% array: ", array, " #idForLifeInsuranceAmount: ", idForLifeInsuranceAmount, METHOD_NAME, CLASS_NAME);

                        let lifeInsuranceAmount;
                        lifeInsuranceAmount = array[0].lifeInsuranceAmount != null ? array[0].lifeInsuranceAmount : array[0].premiumAmountPerMonth;
                        let inputJson = { 'id': this.props.id, 'lifeInsuranceAmount': lifeInsuranceAmount, 'calculatedCoverage': calculatedCoverageLocal };

                        if (inputKeyLocal === 'birthYear') {
                            inputJson['age'] = ageLocal;
                            inputJson['ageBand'] = Utility.getFormattedAge(inputValue);
                        } else {
                            inputJson[inputKeyLocal] = inputValue;
                        }
                        this.props.onChangeAmountsFromHash('IndividualDetails', inputJson);
                        console.log("%$% lifeInsuranceAmount:", lifeInsuranceAmount, METHOD_NAME, CLASS_NAME);
                        this.setState({ 'lifeInsuranceAmount': lifeInsuranceAmount, 'calculatedCoverage': calculatedCoverageLocal });
                    }
                });
            } else {
                if (this.props.listingPriceAmount == null) {
                    key = `${this.props.id}++calculatedCoverage`;
                    ComponentHelper.getCachedData2(key).then((data) => {
                        METHOD_NAME += ":calculatedCoverage:getCachedData2.then:" + paramName;
                        console.log("%$% data: ", data, METHOD_NAME, CLASS_NAME);
                        let json = JSON.parse(data.Payload);
                        calculatedCoverageLocal = json.body.output;
                        this.setState({ coverage: calculatedCoverageLocal, calculatedCoverage: calculatedCoverageLocal });
                        //DATA_RELEASE_VERSION__stateCode__gender__health4LifeInsurance__termDesired__smoker__age__coverageAmount
                        // IN____________600000
                        // v2__stateCode__1__1__20__0__35__coverageAmount
                        console.log("%$% this.props.stateCode:", this.props.stateCode, " # key: ", key, " # calculatedCoverageLocal: ", calculatedCoverageLocal, METHOD_NAME, CLASS_NAME);
                        this.processLifeInsuranceAmount(calculatedCoverageLocal);
                    });

                } else {
                    // calculate coverageAmount from listingPrice if available
                    let cachedValue1 = Utility.calculateCoverage(this.props.listingPriceAmount);
                    this.setState({ 'calculatedCoverage': cachedValue1 });
                    this.props.onChangeItem('calculatedCoverage', cachedValue1);
                    console.log('%$% calculatedCoverage: ', cachedValue1, METHOD_NAME, CLASS_NAME);
                    //'getQuotesFromPacLifeNext': ["DONE:solicitationState", "DONE:faceAmount", "riskClass", "gender", "premiumFrequency", "dateOfBirth", "productCode"
                    GlobalVariables.setAPIInputJson(GlobalVariables.getPacLifeNextAPITag(), { 'calculatedCoverage': cachedValue1 });
                    this.processLifeInsuranceAmount(inputKeyLocal, inputValue, cachedValue1);
                }
            }
        }
    }
    processLifeInsuranceAmount(inputKeyLocal, inputValue, calculatedCoverageAmount) {
        let METHOD_NAME = ":processLifeInsuranceAmount:";
        let genderLocal = this.state.gender != null && this.state.gender !== -1 ? this.state.gender : 1;
        let healthLocal = this.state.health != null && this.state.health !== -1 ? this.state.health : 2;
        switch (healthLocal) {
            case 0: healthLocal = 3; break;
            case 1: healthLocal = 2; break;
            case 2: healthLocal = 1; break;
            case 3: healthLocal = 0; break;
            default: healthLocal = 2; break;
        }
        let termDesiredLocal = this.state.termDesired != null ? this.state.termDesired : 20;
        let smokerLocal = this.state.smoker != null && this.state.smoker !== -1 ? this.state.smoker : 1;
        let ageLocal = this.state.birthYear != null && this.state.birthYear !== -1 ? this.state.birthYear : 1990;
        ageLocal = Utility.getAgeFromBirthYear(this.state.birthYear);
        console.log("ageLocal:", ageLocal, METHOD_NAME, CLASS_NAME);
        ageLocal = ageLocal != null && ageLocal.toString().length > 0 ? ageLocal : 30;

        const idForLifeInsuranceAmount = `${GlobalVariables.getDataReleaseVersion()}__${this.props.stateCode}__${genderLocal}__${healthLocal}__${termDesiredLocal}__${smokerLocal}__${ageLocal}__${calculatedCoverageAmount}`;
        // make sure proper address is keyed and is saved in DB to fetch lifeInsuranceAmount
        ComponentHelper.getData(idForLifeInsuranceAmount).then((array) => {
            METHOD_NAME += ":getData:than:"
            if (array != null && array.length > 0) {
                console.log("array: ", array, METHOD_NAME, CLASS_NAME);
                let lifeInsuranceAmount;
                lifeInsuranceAmount = array[0].lifeInsuranceAmount != null ? array[0].lifeInsuranceAmount : array[0].premiumAmountPerMonth;
                let inputJson = { 'id': this.props.id, 'lifeInsuranceAmount': lifeInsuranceAmount, 'calculatedCoverage': calculatedCoverageAmount };

                if (inputKeyLocal === 'birthYear') {
                    inputJson['age'] = ageLocal;
                    inputJson['ageBand'] = Utility.getFormattedAge(inputValue);
                } else {
                    inputJson[inputKeyLocal] = inputValue;
                }
                this.props.onChangeAmountsFromHash('IndividualDetails', inputJson);
                console.log("lifeInsuranceAmount:", lifeInsuranceAmount, METHOD_NAME, CLASS_NAME);
                this.setState({ 'lifeInsuranceAmount': lifeInsuranceAmount, 'calculatedCoverage': calculatedCoverageAmount });
            }
        });
    }
    onAfterChangeMortgage(value) {
        let METHOD_NAME = ":onAfterChangeMortgage:";
        let keyInput = 'mortgageAmount';
        console.log("Entered:Mortgage:value ", value, METHOD_NAME, CLASS_NAME);
        this.pushDataInDBAndCache({ 'value': value, 'key': keyInput, keyInput: value });
    }
    onChangeMortgage(value) {
        this.setState({ 'mortgageAmount': value });
        this.props.onChangeItem('mortgageAmount', value);
    }
    onAfterChangeLivingsizeSqft(value) {
        let METHOD_NAME = ":onAfterChangeLivingsizeSqft:";
        let input4AppDataPersistence = { id: this.props.id, stateCode: this.props.stateCode, livingsizeSqft: value };
        console.log("Entered:livingsizeSqft:value ", value, "# input4AppDataPersistence: ", input4AppDataPersistence, METHOD_NAME, CLASS_NAME);
        let utilityAmountLocal = Utility.calculateUtilityAmount(this.props.stateCode, value);
        this.props.onChangeItem('utilityAmount', utilityAmountLocal);
        this.pushDataInDBAndCache({ 'value': value, 'key': 'livingsizeSqft', keyInput: value });

        // ComponentHelper.calculateAndSaveUtilityAmount(input4AppDataPersistence, this.props.onChangeItem);
    }
    onChangeLivingsizeSqft(value) {
        this.setState({ 'livingsizeSqft': value });
        this.props.onChangeItem('livingsizeSqft', value);
    }
    onChangeYearBuilt = e => {
        const key = 'yearBuilt';
        let yearBuilt = e;

        if (isNaN(e)) {
            //value comes from NumberFormat input 
            yearBuilt = e.target.value
        }

        this.setState({ [key]: yearBuilt });
        this.props.onChangeItem(key, yearBuilt);

    }
    onChangeBathrooms = e => {
        const key = 'bath';
        let bathroomValue = e;

        if (isNaN(e)) {
            //value comes from NumberFormat input 
            bathroomValue = e.target.value
        }
        if (!bathroomValue || bathroomValue < 1) {
            return;
        }
        this.setState({ [key]: bathroomValue });
        this.props.onChangeItem(key, bathroomValue);
    }
    onChangeBedrooms = e => {
        const key = 'bed';
        let bedroomValue = e;
        if (isNaN(e)) {
            //value comes from NumberFormat input 
            bedroomValue = e.target.value
        }
        if (!bedroomValue || bedroomValue < 1) {
            return;
        }
        this.setState({ [key]: bedroomValue });
        this.props.onChangeItem(key, bedroomValue);
    }

    onAfterChangeBirthYear(inputValue) {
        let METHOD_NAME = ":onAfterChangeBirthYear:";
        console.log("Entered:birthYear:inputValue ", inputValue, METHOD_NAME, CLASS_NAME);

        let ageLocal = Utility.getAgeFromBirthYear(inputValue);
        console.log("ageLocal:", ageLocal, METHOD_NAME, CLASS_NAME);
        ageLocal = ageLocal && ageLocal.toString().length > 0 ? ageLocal : 30;
        let stateJson = { 'key': 'birthYear', 'value': inputValue, 'birthYear': inputValue, 'age': ageLocal, 'ageBand': Utility.getFormattedAge(inputValue) };
        this.setState({ age: ageLocal }, () => this.pushDataInDBAndCache(stateJson));
    }
    onChangeBirthYear(value) {
        const key = 'birthYear';
        let ageLocal = Utility.getAgeFromBirthYear(value);
        this.setState({ 'age': ageLocal, 'birthYear': value });
        this.props.onChangeItem(key, value);
        this.props.onChangeItem('age', ageLocal);
    }
    onAfterChangeGender(value) {
        let METHOD_NAME = ":onAfterChangeGender:";
        const key = 'gender';
        this.setState({ [key]: value });
        this.props.onChangeItem(key, value);
        console.log("Entered:gender:value ", value, METHOD_NAME, CLASS_NAME);
        this.pushDataInDBAndCache({ 'value': value, 'key': 'gender', 'gender': value });
        // // this.refreshDoughnut(key, value);
    }
    onChangeGender(value) {
        let METHOD_NAME = ":onChangeGender:";
        const key = 'gender';
        this.setState({ [key]: value });
        this.props.onChangeItem(key, value);
        console.log("Entered:gender:value ", value, METHOD_NAME, CLASS_NAME);
        this.pushDataInDBAndCache({ 'value': value, 'key': 'gender', 'gender': value });
    }
    onAfterChangeHealth(value) {
        let METHOD_NAME = ":onAfterChangeHealth:";
        console.log("Entered:health:value: ", value, METHOD_NAME, CLASS_NAME);
        this.pushDataInDBAndCache({ 'value': value, 'key': 'health', 'health': value });
        // // this.refreshDoughnut(key, value);
    }
    onChangeHealth(value) {
        const key = 'health';
        this.setState({ [key]: value });
        this.props.onChangeItem(key, value);
    }
    onAfterChangeSmoker(value) {
        let METHOD_NAME = ":onAfterChangeSmoker:";
        console.log("Entered:Smoker:value ", value, METHOD_NAME, CLASS_NAME);
        this.pushDataInDBAndCache({ 'value': value, 'key': 'smoker', 'smoker': value });
        // // this.refreshDoughnut(key, value);
    }
    onAfterChangeTermDesired = (value) => {
        let METHOD_NAME = ":onAfterChangeTermDesired:";
        console.log("%$% Entered:Term Desired:value ", value, METHOD_NAME, CLASS_NAME);
        this.pushDataInDBAndCache({ 'value': value, 'key': 'termDesired', 'termDesired': value });
    }
    onChangeSmoker(value) {
        const key = 'smoker';
        //   value = value === 0 ? false : true
        this.setState({ [key]: value });
        this.onAfterChangeSmoker(value);
        this.props.onChangeItem(key, value);
    }
    onChangeHeight(value) {
        const key = 'height';
        this.props.onChangeItem(key, value);
        this.setState({ [key]: value });
    }
    onChangeWeight(value) {
        const key = 'weight';
        this.setState({ [key]: value });
    }

    onSliderChange = (index) => {
        let coverage = indexToCoverage[index];
        this.setState({ index })
        this.onChangeCoverage(coverage)
    }

    onSliderAfterChange = (index) => {
        let coverage = indexToCoverage[index];
        this.props.onChangeItem("lifeCoverageIndex", +index)
        this.onAfterChangeCoverage(coverage)
    }
    onAfterChangeCoverage(inputValue) {
        let METHOD_NAME = ":onAfterChangeCoverage:";
        console.log("Entered:calculatedCoverage:value ", inputValue, METHOD_NAME, CLASS_NAME);
        this.pushDataInDBAndCache({ 'value': inputValue, 'key': 'calculatedCoverage', 'calculatedCoverage': inputValue });
        let stateJson = { 'key': 'calculatedCoverage', 'value': inputValue, 'calculatedCoverage': inputValue };
        this.pushDataInDBAndCache(stateJson);
        const hash = ComponentHelper.getLocalCacheFor(this.state.id);
        // console.log("BEFORE this.stateeeeeeeee:", this.state, METHOD_NAME, CLASS_NAME);
        // console.log("BEFORE hashhashhash stateeeeeeeee:", hash, METHOD_NAME, CLASS_NAME);
        this.setState(hash);
        hash['id'] = this.state.id;
        hash['calculatedCoverage'] = inputValue;
        this.props.onChangeAmountsFromHash('StepDougnutChart', hash);
    }
    onChangeCoverage(value) {
        const key = 'calculatedCoverage';
        // console.log("onChangeCoverage:key:", key, " #value: ", value);
        this.setState({ [key]: value });
        this.props.onChangeItem(key, value);
    }
    onAfterChangeTermDesired(inputValue) {
        let METHOD_NAME = ":onAfterChangeTermDesired:";
        console.log("Entered:: ", inputValue, METHOD_NAME, CLASS_NAME);
        let stateJson = { 'key': 'termDesired', 'value': inputValue, 'termDesired': inputValue };
        this.pushDataInDBAndCache(stateJson);
        const hash = ComponentHelper.getLocalCacheFor(this.state.id);
        hash['id'] = this.state.id;
        hash['termDesired'] = inputValue;
        this.props.onChangeAmountsFromHash('StepDougnutChart', hash);
    }
    onChangeTermDesired(value) {
        const key = 'termDesired';
        this.setState({ [key]: value });
        this.props.onChangeItem(key, value);
    }
    mapMortgage = () => {
        
    }
    componentDidMount() {
        let METHOD_NAME = ":componentDidMount:";
        console.log("Entered", METHOD_NAME, CLASS_NAME);
        if (this.props.livingsizeSqft == null && this.props.livingsizeSqft === '0') {
            ComponentHelper.getCachedData2(`${this.props.id}++livingsizeSqft`).then((response) => {
                METHOD_NAME += ":livingsizeSqft:getCachedData2.then:";
                console.log("response: ", response, METHOD_NAME, CLASS_NAME);
                let json = JSON.parse(response.Payload);
                console.log("json.body.output: ", json.body.output, METHOD_NAME, CLASS_NAME);
                this.setState({
                    'livingsizeSqft': json.body.output
                });
            });
        } else if (this.props.livingsizeSqft != null) {
            console.log("this.props.livingsizeSqft != null ", METHOD_NAME, CLASS_NAME);
            this.setState({
                'livingsizeSqft': this.props.livingsizeSqft
            });
        }
        let counter = 0;
        this.endTimer = setInterval(() => {  //calculatedCoverage is not recieved immidiately
            if (this.state.calculatedCoverage > 0 || counter > 10)  //counter to prevent infinite loop if calculatedCoverage is not present  
                clearInterval(this.endTimer)
            counter++;
            let index = indexToCoverage.indexOf(this.state.calculatedCoverage);
            this.setState({ index })
        }, 1000)
    }
    componentDidUpdate(prevState, prevProps) {
        // we access props with this.props
        // and state with this.state

        // prevState contains state before update
        // prevProps contains props before update
     //   if (this.props.mortgageAmount && this.props.mortgageAmount !== this.state.mortgageAmount) {
     //       this.setState({ 'mortgageAmount': this.props.mortgageAmount });
     //   }
        if (this.props.calculatedCoverage && prevProps.calculatedCoverage !== this.state.calculatedCoverage) {
            this.setState({ 'calculatedCoverage': this.props.calculatedCoverage });
            // this.state.calculatedCoverage > 1000 ? this.state.calculatedCoverage / 1000 : this.state.calculatedCoverage
        }
        if (this.props.livingsizeSqft && this.props.livingsizeSqft !== this.state.livingsizeSqft) {
            this.setState({
                'livingsizeSqft': this.props.livingsizeSqft
            });
        }
    }
    render() {
        let METHOD_NAME = ":render:";
        console.log('yearBuilt: ', this.props.yearBuilt, METHOD_NAME, CLASS_NAME);
        console.log('state:birthYear: ', this.state.birthYear, METHOD_NAME, CLASS_NAME);
        console.log('birthYear: ', this.props.birthYear, METHOD_NAME, CLASS_NAME);
        console.log('state:gender: ', this.state.gender, METHOD_NAME, CLASS_NAME);
        console.log('gender: ', this.props.gender, METHOD_NAME, CLASS_NAME);
        console.log('@@@this.state: ', this.state, METHOD_NAME, CLASS_NAME);

        const coverageMarks = {
            1: { label: <span>$100K</span>, },
            12: { style: { left: "90%"  }, label: <span>$2M</span>, },
        }
        const termMarks = {
            10: {  label: <span>10</span>, },
            30: { style: { left: "90%" }, label: <span>30</span>, },
        };
        const healthMarks = {
            0: {  label: <span>Iffy</span>, },
            1: { style: {  left: "30%" }, label: <span>Good</span>, },
            2: { style: {  left: "60%" }, label: <span>Perfect</span>, },
            3: { style: {  left: "90%" }, label: <span>Rocking</span>, },
        };
        const birthMarks = {
            1970: { style: { color: '#000', fontSize: "10px" }, label: <span>1970</span>, },
            [age18] : { style: { color: '#000', fontSize: "10px", left: "90%" }, label: <span>{age18}</span>, },
        };
        // mortgageAmountLocal = this.props.mortgageAmount != null ? this.props.mortgageAmount : this.state.mortgageAmount;
        // mortgageAmountLocal = (// mortgageAmountLocal / 100).toFixed() * 100;
        return (
            /*<OverlayTrigger
                trigger="click"
                placement={'bottom'}
                overlay={
                    <Popover id={`popover - positioned - ${ 'bottom' } `}>
                        <Popover.Content>
                            <div style={{ background: '#F6F6F6' }} className='popDown-content'>
                                <label>
                                    Age
                                    </label>
                                <span style={{ width: '80%' }}>
                                    <ValueSlider min={25} max={75} units={'years'} onChange={this.props.onIndividualAgeDetails} value={this.props.age} />
                                </span>
         
                                <label>
                                    Gender
                                    </label>
                                <select
                                    style={styles}
                                    name="gender"
                                    placeholder='gender'
                                    value={this.state.gender}
                                    onChange={this.props.onIndividualDetails}>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                                <label>
                                    Health
                                    </label>
                                <select
                                    style={styles}
                                    name="health"
                                    placeholder='health'
                                    value={this.state.health}
                                    onChange={this.props.onIndividualDetails}>
                                    <option value="Atheltic">Atheltic</option>
                                    <option value="Good">Good</option>
                                    <option value="Okayish">Okayish</option>
                                </select>
                                <label>
                                    Smoker
                                    </label>
                                <Toggle onChange={this.props.onSmokerDetails} value={this.props.smoker} />
                            </div>
                        </Popover.Content>
                    </Popover>
                }
            >
                <div
                    className={this.state.isActive ? "active1 dropbtn" : "dropbtn"}
                    onClick={this.changeActiveItem}
                    style={activeItem === 0 || activeItem === 3 ? { textAlign: 'center' } : { pointerEvents: 'none' }}
                >
                    Individual Details
              </div>
            </OverlayTrigger>*/

            // id="individualDetails" 
            <>
                {
                    this.props.extension ?
                        <IndividualDetailsFullScreen
                            mortgageAmount={this.state.mortgageAmount}
                            calculatedCoverage={Number(this.state.calculatedCoverage)}
                            onSliderAfterChange={this.onSliderAfterChange}
                            onSliderChange={this.onSliderChange}
                            index={this.state.index}
                            onChangeCoverage={this.onChangeCoverage}
                            onAfterChangeCoverage={this.onAfterChangeCoverage}
                            gender={Number(this.state.gender)}
                            onChangeYearBuilt={this.onChangeYearBuilt}
                            onChangeGender={this.onChangeGender}
                            onAfterChangeGender={this.onAfterChangeGender}
                            age={this.state.age}
                            birthYear={this.props.birthYear}
                            onChangeBirthYear={this.onChangeBirthYear}
                            onAfterChangeBirthYear={this.onAfterChangeBirthYear}
                            health={Number(this.props.health)}
                            onChangeHealth={this.onChangeHealth}
                            onAfterChangeHealth={this.onAfterChangeHealth}
                            smoker={Number(this.props.smoker)}
                            onChangeSmoker={this.onChangeSmoker}
                            onAfterChangeSmoker={this.onAfterChangeSmoker}
                            termDesired={this.props.termDesired}
                            onAfterChangeTermDesired={this.onAfterChangeTermDesired}
                            onChangeTermDesired={this.onChangeTermDesired}
                            livingsizeSqft={this.state.livingsizeSqft}
                            onChangeLivingsizeSqft={this.onChangeLivingsizeSqft}
                            onAfterChangeLivingsizeSqft={this.onAfterChangeLivingsizeSqft}
                            onChangeBedrooms={this.onChangeBedrooms}
                            bed={this.state.bed}
                            bath={this.state.bath}
                            yearBuilt={this.state.yearBuilt}
                            onChangeItem={this.props.onChangeItem}
                            individualFullItem={this.props.individualFullItem || 0}
                            listingPriceAmount={this.state.listingPriceAmount}
                            onChangeBathrooms={this.onChangeBathrooms}
                            refreshDoughnut={this.refreshDoughnut}
                            pushDataInDBAndCache={this.pushDataInDBAndCache}
                            resetToDefaultValues={this.resetToDefaultValues}
                            defaultValuesHash={this.props.defaultValuesHash}
                        />
                        :
                        <div className="individualDetails">
                            {/* {<div className="donut-row">
                                   <BubbleDoughnut
                                       id={this.props.id}
                                       onChangeAmountsFromHash={this.props.onChangeAmountsFromHash}
                                       lifeInsuranceAmount={this.props.lifeInsuranceAmount}
                                       mortgageAmount={this.props.mortgageAmount}
                                       utilityAmount={this.props.utilityAmount}
                                       calculatedCoverage={this.props.calculatedCoverage}
                                       doughnutWidth={"600%"}
                                       doughnutHeight={"600%"}
                                   />
                               </div>} */}

                            {/* {<div className="d-flex flex-row ">
                                           <div className="w-31px">
                                               <hr />
                                           </div>
                                           <span>
                                               My Home
                                       </span>
                                           <div className="w-31px">
                                               <hr />
                                           </div>
                           </div>} */}
                            <div className="d-flex">
                                <img src={home} alt=""/>
                                <div className="label-bold">My Home</div>
                            </div>

                            <div className="d-flex flex-row justify-content-between">
                                <div className="ss-slider">
                                    <div className="d-flex mt-1">
                                        <span>Mortgage: &nbsp; </span>
                                        <div> {isNaN(this.state.mortgageAmount) ? "Select" : ` $${Utility.getFormattedNumberValue(this.state.mortgageAmount === -1 ? 'Select?' : this.state.mortgageAmount) || 0}`}<span>/mo</span> </div>
                                    </div>
                                    <div className="mt-2">
                                        <Slider
                                            min={100}
                                            step={500}
                                            max={11600}
                                            onAfterChange={this.onAfterChangeMortgage}
                                            onChange={this.onChangeMortgage}
                                            value={this.state.mortgageAmount}
                                            defaultValue={this.state.mortgageAmount} />
                                    </div>
                                </div>
                                <div className="ss-slider">
                                    <div className="d-flex mt-1">
                                        <span>Living Area: &nbsp;</span>
                                        <div>{Utility.getFormattedNumberValue(this.state.livingsizeSqft === -1 ? 'Select?' : this.state.livingsizeSqft)} <span>sq. ft</span> </div>
                                    </div>
                                    <div className="mt-2">
                                        <Slider
                                            label={` `}
                                            min={100}
                                            max={10000}
                                            step={100}
                                            onAfterChange={this.onAfterChangeLivingsizeSqft}
                                            onChange={this.onChangeLivingsizeSqft}
                                            value={this.state.livingsizeSqft}
                                            defaultValue={this.state.livingsizeSqft} />
                                    </div>
                                </div>
                            </div>

                            <div className="label-bold l2"></div>
                            <div className="d-flex">
                                <img className="l2" src={profile} alt=""/>
                                <div className="label-bold">My Profile</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-column">
                                    <div className="ss-slider">
                                        <div className="d-flex mt-1">
                                            <span>Birth Year: &nbsp;</span>
                                            <div>{this.state.birthYear === -1 ? 'Select?' : this.state.birthYear}</div>
                                        </div>
                                        <div className="mt-1">
                                            <Slider
                                                min={1970}
                                                max={age18}
                                                marks={birthMarks}
                                                onAfterChange={this.onAfterChangeBirthYear}
                                                onChange={this.onChangeBirthYear}
                                                value={this.state.birthYear}
                                                defaultValue={this.state.birthYear} />
                                        </div>
                                    </div>
                                    <span className='ss-slider h-60px'>
                                        <div className="d-flex mt-1">
                                            <span>Term Desired: &nbsp;</span>
                                            <div>{this.props.termDesired} Years</div>
                                        </div>
                                        <div className="mt-1">
                                            <Slider
                                                onAfterChange={this.onAfterChangeTermDesired}
                                                onChange={this.onChangeTermDesired}
                                                value={this.props.termDesired}
                                                marks={termMarks}
                                                min={10}
                                                max={30}
                                                step={5}
                                            />
                                        </div>
                                    </span>
                                    <div className='ss-slider h-60px'>
                                        <div className="d-flex mt-1">
                                            <span>Life Coverage: &nbsp;</span>
                                            <div>${Utility.getFormattedNumberValue(this.state.calculatedCoverage)}</div>
                                        </div>
                                        <div className="mt-1">
                                            <Slider
                                                onAfterChange={this.onSliderAfterChange}
                                                onChange={this.onSliderChange}
                                                value={this.state.index}
                                                marks={coverageMarks}
                                                min={1}
                                                max={12}
                                                step={1}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex flex-column">
                                    <div className="ss-slider">
                                        <div className="d-flex justify-content-between mt-1">
                                            <span>Gender (at birth):</span>
                                            {/* {  <div>{this.state.gender === -1 ? 'Select?' : (this.state.gender === 0 ? 'Female' : (this.state.gender === 1 ? 'Male' : 'Other'))}</div>} */}
                                        </div>
                                        <div className="d-flex img-radio">
                                        {
                                            this.state.gender === 1 ?
                                            <>
                                                <div className="gender active" onClick={() => this.onAfterChangeGender(1)}>   <img src={male_active} alt=""/> Male   </div>
                                                <div className="gender" onClick={() => this.onAfterChangeGender(0)}>  <img src={female_inactive} alt=""/> Female </div>
                                            </>    :    <>
                                                <div className="gender" onClick={() => this.onAfterChangeGender(1)}><img src={male_inactive} alt=""/>Male </div>
                                                <div className="gender active" onClick={() => this.onAfterChangeGender(0)}><img src={female_active} alt=""/>Female </div>
                                            </>
                                        }
                                        </div>
                                    </div>
                                    <div className='ss-slider'>
                                        <div className="d-flex justify-content-between mt-1">
                                            <span>Smoked in last 5 years:</span>
                                            {/* {   <div>{this.state.smoker === -1 ? 'Select?' : (this.state.smoker === 1 ? "Yes" : "No")}</div>} */}
                                        </div>
                                        <div className="d-flex img-radio">
                                        {this.state.smoker === 1 ?
                                            <>
                                                <div className="gender active" onClick={() => this.onChangeSmoker(1)}>   <img src={tick_active} alt=""/> Yes   </div>
                                                <div className="gender" onClick={() => this.onChangeSmoker(0)}>  <img src={cross_inactive} alt=""/> No </div>
                                            </>    :    <>
                                                <div className="gender" onClick={() => this.onChangeSmoker(1)}><img src={tick_inactive} alt=""/> Yes </div>
                                                <div className="gender active" onClick={() => this.onChangeSmoker(0)}><img src={cross_active} alt=""/> No </div>
                                            </>
                                        }  
                                        </div>
                                    </div>
                                    <div className='ss-slider'>
                                        <div className="d-flex justify-content-between mt-1">
                                            <span>Health Condition:</span>
                                            {/* {<div>{this.state.health === -1 ? 'Select?' : (this.state.health === 0 ? 'Iffy' : (this.state.health === 2 ? 'Excellent' : 'Good'))}</div>} */}
                                        </div>
                                        <div className="mt-1">
                                            <Slider
                                                min={0}
                                                max={3}
                                                marks={healthMarks}
                                                onAfterChange={this.onAfterChangeHealth}
                                                onChange={this.onChangeHealth}
                                                value={this.state.health} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
                {/* 
              {/* 
               {/* 
               <div className='d-flex flex-row justify-content-between'>
                   <div className='healthInfo'>
                       Life Insurance: ${this.state.lifeInsuranceAmount || this.props.lifeInsuranceAmount}
                   </div>
                   <div className='healthInfo'>
                       Coverage : ${this.state.calculatedCoverage || this.props.calculatedCoverage}
                   </div>
               </div> */}
            </>)
    }
}
