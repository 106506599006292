import React from "react";
import AgentDetail from "./AgentDetail.component";
import ComponentHelper from "./ComponentHelper";
import GlobalConstantsVariables from "../GlobalConstantsVariables";
const CLASS_NAME = ":Register:";
class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            registrationKey: "",
            firstTimeUserFlag: "false",
            agentBrandingCodes: "",
        }
    }


    changeHandler = (event) => {
        console.log("In change hander : ", event.target.value);
        this.setState({ [event.target.name]: event.target.value })
    }

    submitHandler = (event) => {
        let METHOD_NAME = ":submitHandler:";
        console.log("Form Submitted !!!")
        event.preventDefault();
        let key = this.state.registrationKey;
        console.log("*&* key value ..", key);
        console.log("*&* this.state.registrationKey ..", this.state.registrationKey);
        console.log("*&* this.state.agentBrandingCodes: ", this.state.agentBrandingCodes);
        /* Logic:
        Show extension 1 
        Check if agentBrandingCode is null -> Show Register Screen
        User enters agentBrandingCode -> set it in 'clientId-> DB data' -> Show extension 1
        Next time: Get the 'clientId-> DB data' -> is agentBrandingCode not null show extension 1 else show Register Page

        At Code level:

        */
        ComponentHelper.doesAgentCodeExist(this.props.clientId, key, this.props.onChangeItem);
        if (key.length > 0) {
            const dataCategory = GlobalConstantsVariables.getClientDataCategoryAll();
            let json4UpdateClientData = { dataCategory: dataCategory, cacheId: `${this.props.clientId}++All`, id: this.props.clientId, agentBrandingCode: key, eventStr: 'agentBrandingCodeAdded' };
            console.log("*&* json4UpdateClientData: ", json4UpdateClientData, METHOD_NAME, CLASS_NAME);
            // In header make following call and popluate nameq and image
            ComponentHelper.getData(key).then((array) => {
                METHOD_NAME += "agentBrandingCode.then:";
                let json;
                console.log("*&* array: ", array, METHOD_NAME, CLASS_NAME);
                if (array != null && array.length > 0 && (json = array[0]) != null) {
                    console.log("*&* Agent Branding Data: json: ", json, METHOD_NAME, CLASS_NAME);
                    this.props.onChangeItem('brokerLogoS3Link', json.potraitImage)
                    this.props.onChangeItem('brokerName', json.agencyName)
                    if (json.firstName && json.lastName) {
                        this.props.onChangeItem('agentName', `${json.firstName} ${json.lastName}`)
                    }
                } else {
                    console.log("*&* Error: this.props.clientId: ", this.props.clientId, " # key: ", key, METHOD_NAME, CLASS_NAME)

                    //   Add following data from json to state variable using this.props.onChangeItem
                    //   agencyName: String
                    //   createdAt: String
                    //   dataCategory: String
                    //   emailId: String
                    //   eventStr: String
                    //   firstName: String
                    //   lastName: String
                    //   licenseNumber: String
                    //   phoneNumber: String
                    //   potraitImage: String
                }
            });
            /// Test Code ends
            //      alert("Register Successfully");
        } else {
            //       alert(this.state.registrationError);
            this.setState({ registrationState: false })
        }
    }

    render() {
        return (
            <div className="registration-page">
                {
                    this.state.pageShown !== "signUp" ?

                        <form onSubmit={this.submitHandler} className="d-flex flex-column align-items-center">
                            <div className="container d-flex flex-column align-items-center">
                                <span className="mt-4">
                                    First time use! Please provide Registration key:
                                </span>
                                <span className="mt-4">
                                    <input
                                        type="type"
                                        name="registrationKey"
                                        placeholder="Enter key"
                                        value={this.state.registrationKey}
                                        onChange={this.changeHandler} required />
                                </span>
                                <button onClick={this.submitHandler}
                                    className="buttonRounded mt-2" >Register
                                </button>
                                {this.state.registrationState !== undefined &&
                                    (this.state.registrationState === false ?
                                        <>
                                            Registration failed <br />
                                        Wrong Key Entered
                                    </> :
                                        <>
                                            Registration Successful.
                                    </>)
                                }
                            </div>
                        </form>
                        : <AgentDetail />
                }
            </div>
        )
    }
}


export default Register;
