/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAppData = /* GraphQL */ `
  query GetAppData($id: ID!) {
    getAppData(id: $id) {
      id
      uid
      clientId
      cacheId
      dataCategory
      fullAddress
      addresses
      eventStr
      primaryResidence
      gender
      age
      birthDate
      birthYear
      health
      smoker
      coverage
      termDesired
      createdAt
      updatedAt
      propertyTaxAmount
      avmAmount
      mortgageAmount
      calculatedCoverage
      homeInsuranceAmount
      lifeInsuranceAmount
      utilityAmount
      healthCause
      cause
      county
      ageBand
      deathsOfAgeGroup
      populationOfAgeGroup
      totalDeaths
      totalDeathsOfAgeGroup
      totalPopulation
      countyCode
      question
      questionCauseTag1
      questionCauseTag2
      questionCauseType
      questionAgeTag4
      questionAgeTag5
      questionDependentQuestionId
      questionRootQuestion
      questionAnswerType
      questionYesDependent
      questionNoDependent
      questionOrder
      stateCode
      health4LifeInsurance
      policyType
      premiumAmountPerMonth
      livingsizeSqft
      agencyName
      emailId
      firstName
      lastName
      licenseNumber
      phoneNumber
      potraitImage
      agentBrandingCodes
      agentBrandingCode
      genericPlaceHolder1
      genericPlaceHolder2
      genericPlaceHolder3
    }
  }
`;
export const listAppDatas = /* GraphQL */ `
  query ListAppDatas(
    $filter: ModelAppDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uid
        clientId
        cacheId
        dataCategory
        fullAddress
        addresses
        eventStr
        primaryResidence
        gender
        age
        birthDate
        birthYear
        health
        smoker
        coverage
        termDesired
        createdAt
        updatedAt
        propertyTaxAmount
        avmAmount
        mortgageAmount
        calculatedCoverage
        homeInsuranceAmount
        lifeInsuranceAmount
        utilityAmount
        healthCause
        cause
        county
        ageBand
        deathsOfAgeGroup
        populationOfAgeGroup
        totalDeaths
        totalDeathsOfAgeGroup
        totalPopulation
        countyCode
        question
        questionCauseTag1
        questionCauseTag2
        questionCauseType
        questionAgeTag4
        questionAgeTag5
        questionDependentQuestionId
        questionRootQuestion
        questionAnswerType
        questionYesDependent
        questionNoDependent
        questionOrder
        stateCode
        health4LifeInsurance
        policyType
        premiumAmountPerMonth
        livingsizeSqft
        agencyName
        emailId
        firstName
        lastName
        licenseNumber
        phoneNumber
        potraitImage
        agentBrandingCodes
        agentBrandingCode
        genericPlaceHolder1
        genericPlaceHolder2
        genericPlaceHolder3
      }
      nextToken
    }
  }
`;
