import React from "react";
import { Row, Col, Container, Form } from "react-bootstrap";
import { BallRotate } from 'react-pure-loaders';
import { JSONToHTMLTable } from '@kevincobain2000/json-to-html-table'

import QuotesHelper from "./QuotesHelper";

const CLASS_NAME = "Quotes";

class Quotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quotesArray: [],
            quotesArrayPacLifeNext: [],
            inputJson4Quotes: { 'quotes2': '' },
            face_amounts: [],
            gender: 'Female',
            birthdate: '1990-01-01',
            stateCode: 'CA',
            health: '1',
            smoker: 'Never',
            mode: '12',
            product_categories: [],
            amount: '',
            smoke_last_use_date: '',
            smoke_frequency: '',
            smoke_category: ''
        }
        this.fetchQuotes = this.fetchQuotes.bind(this);
        this.saveFormData = this.saveFormData.bind(this);
    }

    fetchQuotes = async (inputJson) => {
        let METHOD_NAME = "fetchQuotes";
        if (inputJson.quotes2 != null) {
            return;
        }
        let localJson = inputJson;

        this.setState({ inputJson4Quotes: localJson });
        console.log("localJsonlocalJsonlocalJsonlocalJsonlocalJson:", localJson, METHOD_NAME, CLASS_NAME);
        console.log("inputJson4Quotes:", this.state.inputJson4Quotes, METHOD_NAME, CLASS_NAME);

        QuotesHelper.getQuotesFromBack9(localJson)
            .then(response => {
                let array = [];
                console.log("output responseresponseresponse:", response, METHOD_NAME, CLASS_NAME);
                const payload = JSON.parse(response.Payload);
                console.log("PayloadPayloadPayloadPayload:", payload, METHOD_NAME, CLASS_NAME);
                // if (payload.body == null) {
                //     array = [];
                // } else {
                //     const array3 = payload.body.output[0];
                //     for (let index = 0; index < array3.length; index += 1) {
                //         array[index] = array3[index];
                //         console.log("array[index]:", array[index], METHOD_NAME, CLASS_NAME);
                //     }
                // }
                if (payload == null || payload.body == null) {
                    array = [];
                } else {
                    const hash = payload.body != null && payload.body.output != null ? payload.body.output : payload.body;
                    // const hash = {
                    //     id: 'CA.500000~600000.5.Male.12.1990-05-11.Never.10YearTerm',
                    //     dataCategory: 'quotes_back9',
                    //     stateCode: 200,
                    //     error: [],
                    //     'output.0.0.carrier.id': 19,
                    //     'output.0.0.carrier.name': 'Principal National',
                    //     'output.0.0.carrier.avatar': 'https: //d1usw6tyldpxhi.cloudfront.net/principal_square_avatar.png',
                    //     'output.0.0.carrier.strife_disclosure': 'Principal National Life Insurance Co,711 High Street, Des Moines, IA 50392',
                    //     'output.0.0.carrier.am_best_rating': null,
                    //     'output.0.0.carrier.established_year': 1967,
                    //     'output.0.0.carrier.approval_time': 19,
                    //     'output.0.0.product.id': 131,
                    //     'output.0.0.product.name': '10 Year Term',
                    //     'output.0.0.product.plan_type': 'TRM',
                    //     'output.0.0.product.product_details': null,
                    //     'output.0.0.product.conversion': 'Conversion Extension Rider: To any permanent product by end of term and age 70. Standard Conversion: 7 years',
                    //     'output.0.0.product.carrier_e_delivery': true,
                    //     'output.0.0.product.product_guide': 'https: //strife.s3-us-west-2.amazonaws.com/content/consumer_product_guides/PrincipalTerm.pdf',
                    //     'output.0.0.product.riders.ltc_rider': null,
                    //     'output.0.0.product.riders.chronic_illness': false,
                    //     'output.0.0.product.riders.no_lapse_guarantee': false,
                    //     'output.0.0.product.riders.critical_illness_rider': false,
                    //     'output.0.0.product.payment_profile.name': 'EFT',
                    //     'output.0.0.product.payment_profile.required': false,
                    //     'output.0.0.product.temporary_insurance_profiles.0.max_face_amount': 1000000,
                    //     'output.0.0.product.temporary_insurance_profiles.0.max_face_amount_if_existing_coverage_from_same_carrier': 500000,
                    //     'output.0.0.product.temporary_insurance_profiles.0.max_face_amount_to_bind': 100000000,
                    //     'output.0.0.product.temporary_insurance_profiles.0.min_bind_age_in_days': 0,
                    //     'output.0.0.product.temporary_insurance_profiles.0.max_bind_age_in_days': 100000,
                    //     'output.0.0.product.temporary_insurance_profiles.0.days_of_coverage': 75,
                    //     'output.0.0.product.temporary_insurance_profiles.0.complete': true,
                    //     'output.0.0.face_amount': 700000,
                    //     'output.0.0.birthdate': '1990-05-11',
                    //     'output.0.0.category': '10 Year Level Term Guaranteed',
                    //     'output.0.0.original_category': '3: 3#30',
                    //     'output.0.0.product_category': '10 Year Term',
                    //     'output.0.0.premium': 17.16,
                    //     'output.0.0.annual_premium': 199.53,
                    //     'output.0.0.mode': 12,
                    //     'output.0.0.health': 5,
                    //     'output.0.0.health_class': 'Super Preferred non-tobacco',
                    //     'output.0.0.health_rating': 'P+',
                    //     'output.0.0.ambest': 'A+',
                    //     'output.0.0.validated_rating': 'Super Preferred',
                    //     'output.0.0.validated_rating_error': null,
                    //     'output.0.0.validated_build': null,
                    //     'output.0.0.raw_data.carrier': 'Principal National Life Insurance Co',
                    //     'output.0.0.raw_data.product': '10-Year Term (03/2020)',
                    //     'output.0.1.carrier.id': 19,
                    //     'output.0.1.carrier.name': 'Principal National',
                    //     'output.0.1.carrier.avatar': 'https: //d1usw6tyldpxhi.cloudfront.net/principal_square_avatar.png',
                    //     'output.0.1.carrier.strife_disclosure': 'Principal National Life Insurance Co,711 High Street, Des Moines, IA 50392',
                    //     'output.0.1.carrier.am_best_rating': null,
                    //     'output.0.1.carrier.established_year': 1967,
                    //     'output.0.1.carrier.approval_time': 19,
                    //     'output.0.1.product.id': 131,
                    //     'output.0.1.product.name': '10 Year Term',
                    //     'output.0.1.product.plan_type': 'TRM',
                    //     'output.0.1.product.product_details': null,
                    //     'output.0.1.product.conversion': 'Conversion Extension Rider: To any permanent product by end of term and age 70. Standard Conversion: 7 years',
                    //     'output.0.1.product.carrier_e_delivery': true,
                    //     'output.0.1.product.product_guide': 'https: //strife.s3-us-west-2.amazonaws.com/content/consumer_product_guides/PrincipalTerm.pdf',
                    //     'output.0.1.product.riders.ltc_rider': null,
                    //     'output.0.1.product.riders.chronic_illness': false,
                    //     'output.0.1.product.riders.no_lapse_guarantee': false,
                    //     'output.0.1.product.riders.critical_illness_rider': false,
                    //     'output.0.1.product.payment_profile.name': 'EFT',
                    //     'output.0.1.product.payment_profile.required': false,
                    //     'output.0.1.product.temporary_insurance_profiles.0.max_face_amount': 1000000,
                    //     'output.0.1.product.temporary_insurance_profiles.0.max_face_amount_if_existing_coverage_from_same_carrier': 500000,
                    //     'output.0.1.product.temporary_insurance_profiles.0.max_face_amount_to_bind': 100000000,
                    //     'output.0.1.product.temporary_insurance_profiles.0.min_bind_age_in_days': 0,
                    //     'output.0.1.product.temporary_insurance_profiles.0.max_bind_age_in_days': 100000,
                    //     'output.0.1.product.temporary_insurance_profiles.0.days_of_coverage': 75,
                    //     'output.0.1.product.temporary_insurance_profiles.0.complete': true,
                    //     'output.0.1.face_amount': 800000,
                    //     'output.0.1.birthdate': '1990-05-11',
                    //     'output.0.1.category': '10 Year Level Term Guaranteed',
                    //     'output.0.1.original_category': '3: 3#30',
                    //     'output.0.1.product_category': '10 Year Term',
                    //     'output.0.1.premium': 18.69,
                    //     'output.0.1.annual_premium': 217.32,
                    //     'output.0.1.mode': 12,
                    //     'output.0.1.health': 5,
                    //     'output.0.1.health_class': 'Super Preferred non-tobacco',
                    //     'output.0.1.health_rating': 'P+',
                    //     'output.0.1.ambest': 'A+',
                    //     'output.0.1.validated_rating': 'Super Preferred',
                    //     'output.0.1.validated_rating_error': null,
                    //     'output.0.1.validated_build': null,
                    //     'output.0.1.raw_data.carrier': 'Principal National Life Insurance Co',
                    //     'output.0.1.raw_data.product': '10-Year Term (03/2020)',
                    //     'input.quotes.0.face_amounts.0': 700000,
                    //     'input.quotes.0.face_amounts.1': 800000,
                    //     'input.quotes.0.gender': 'Male',
                    //     'input.quotes.0.birthdate': '1990-05-11',
                    //     'input.quotes.0.state': 'CA',
                    //     'input.quotes.0.health': 5,
                    //     'input.quotes.0.smoker': 'Never',
                    //     'input.quotes.0.mode': 12,
                    //     'input.quotes.0.product_categories.0': '10 Year Term'
                    // };
                    console.log('hashhashhashhashhashhashhashhash: ', hash);

                    const keysArray = Object.keys(hash);
                    const indexArray = [];
                    // Home many quotes are available in response
                    Object.keys(keysArray).forEach((key) => {
                        console.log('keysArray[key]: ', keysArray[key]);
                        if (keysArray[key].endsWith('.carrier.id') && keysArray[key].startsWith('output.0.')) {
                            indexArray.push(keysArray[key].replace(/.carrier.id/g, '').replace(/output.0./g, ''));
                        }
                    });
                    const keysArraysOfArray = [];
                    let localArray;
                    // Get the keys into arrays
                    for (let counter = 0; counter < indexArray.length; counter += 1) {
                        let prefix = `output.0.${indexArray[counter]}`;
                        localArray = [];
                        // eslint-disable-next-line no-loop-func
                        Object.keys(keysArray).forEach((key) => {
                            if (keysArray[key].startsWith(prefix)) {
                                localArray.push(keysArray[key]);
                            }
                        });
                        keysArraysOfArray.push(localArray);
                    }
                    // Iterate over data and prepare quotesArray
                    for (let counter2 = 0; counter2 < keysArraysOfArray.length; counter2 += 1) {
                        let localArray = keysArraysOfArray[counter2];
                        let hash2 = {};
                        for (let counter3 = 0; counter3 < localArray.length; counter3 += 1) {
                            let key = localArray[counter3];
                            const tempStr = `output.0.${counter2}.`;
                            key = key.replace(tempStr, '');
                            hash2[key] = hash[localArray[counter3]];
                        }
                        console.log("hash2['face_amount']:", hash2['face_amount'], METHOD_NAME, CLASS_NAME);
                        array.push(hash2);
                    }
                }//end of output processing into Array
                this.setState({ quotesArray: array, isLoading: false });
                console.log("this.state.quotesArray:", this.state.quotesArray, METHOD_NAME, CLASS_NAME);
            });
    };
    componentDidMount() {
        // React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
        if (this.state.inputJson4Quotes.quotes2 == null) {
            this.fetchQuotes(this.state.inputJson4Quotes);
        }
    }

    handleProductCategoriesChange = (e) => {
        let selected = e.target.value;
        let index = this.state.product_categories.indexOf(selected);

        if (index === -1) {  //value is selected already
            this.setState({
                product_categories: [...this.state.product_categories, selected]
            })

        } else { //value is not selected 
            this.setState({
                product_categories: this.state.product_categories.filter((_, i) => i !== index),
            })
        }
    }

    handleFaceAmountChange = (e) => {
        let selected = Number(e.target.value);
        let index = this.state.face_amounts.indexOf(selected);

        if (index === -1) {  //value is selected already
            this.setState({
                face_amounts: [...this.state.face_amounts, selected]
            })

        } else { //value is not selected 
            this.setState({
                face_amounts: this.state.face_amounts.filter((_, i) => i !== index),
            })
        }
    }

    saveFormData = () => {
        const METHOD_NAME = ':saveFormData:';
        let data = { ...this.state }
        // delete data.inputJson4Quotes;
        // delete data.smoke_category;
        // delete data.smoke_frequency;
        // delete data.smoke_last_use_date;

        if (this.state.smoker && !(this.state.smoker.toLowerCase().startsWith("n"))) {
            const usage = {
                category: this.state.smoke_category,
                frequency: this.state.smoke_frequency,
                last_use_date: this.state.smoke_last_use_date
            }
            data = { ...data, usage }
        }
        let localJson = {
            "quotes": [{
                "face_amounts": this.state.face_amounts,
                "gender": this.state.gender,
                "birthdate": this.state.birthdate,
                "state": this.state.stateCode,
                "health": this.state.health,
                "smoker": this.state.smoker,
                "mode": this.state.mode,
                "product_categories": ["10 Year Term"]
            }]
        };
        this.setState({ isLoading: true, inputJson4Quotes: localJson });
        console.log("Before:fetchQuotes: inputJson4Quotes: ", this.state.inputJson4Quotes, METHOD_NAME, CLASS_NAME);
        this.fetchQuotes(localJson);
        console.log("After fetchQuotes", METHOD_NAME, CLASS_NAME);

        console.log(data, METHOD_NAME, CLASS_NAME);

        // this.resetForm();
    }

    resetForm = () => {
        this.setState({
            quotesArray: [],
            inputJson4Quotes: { 'quotes2': '' },
            face_amounts: [],
            gender: 'Female',
            birthdate: '1990-01-01',
            stateCode: 'CA',
            health: '1',
            smoker: 'Never',
            mode: '12',
            product_categories: [],
            amount: "",
            smoke_last_use_date: "",
            smoke_frequency: "",
            smoke_category: ""
        })
    }

    render() {
        const coveragesText = ['100K', '200K', '300K', '400K', '500K', '600K', '700K', '800K', '900K', '1.0M', '1.5M', '2.0M'];
        const values = [100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000, 1500000, 2000000];

        const productCategoriesText = [`Accidental Death`, `10 Year Non Medical Term`, `10 Year Term`, `15 Year Non Medical Term`,
            `15 Year Return of Premium Term`, `15 Year Term`, `20 Year Non Medical Term`, `20 Year Return of Premium Term`,
            `20 Year Term`, `25 Year Non Medical Term`, `25 Year Return of Premium Term`, `25 Year Term`,
            `30 Year Non Medical Term`, `30 Year Return of Premium Term`, `30 Year Term`, `To Age 95`, `To Age 100`, `To Age 120`]

        const faceAmountBoxes = coveragesText.map(
            (text, i) => (
                <div key={i} className='border checkbox-w16'>
                    <label className='checkbox-handle'>
                        <input
                            type='checkbox'
                            className="checkbox-hidden"
                            defaultChecked={this.state.face_amounts.includes(values[i])}
                            onChange={this.handleFaceAmountChange}
                            value={values[i]}
                        />
                        <div className='checkbox-custom'></div>
                    </label>
                    <span> ${text} </span>
                </div>
            )
        )
        const productCategoryBoxes = productCategoriesText.map(
            (text, i) => (
                <div key={i} className='border checkbox-w16'>
                    <label className='checkbox-handle'>
                        <input
                            type='checkbox'
                            defaultChecked={this.state.product_categories.includes(productCategoriesText[i])}
                            onChange={this.handleProductCategoriesChange}
                            value={productCategoriesText[i]}
                            className="checkbox-hidden"
                        />
                        <div className='checkbox-custom'></div>
                    </label>
                    <span> {text} </span>
                </div>
            )
        )

       // const width = '80px';
        // const quotesAll = this.state.quotesArray.map((quote, i) =>
      /*  const quotesAll = [].map((quote, i) =>
            <Row key={i} className='h5 text-dark font-weight-bold'>
                <Col>
                    {quote['carrier.name']}
                </Col>
                <Col>
                    <img src={`${quote['carrier.avatar']}`} style={{ width }}
                        alt={quote['carrier.name']} />
                </Col>
                <Col>
                    {quote['product.name']}
                </Col>
                <Col>
                    {quote['product_category']}
                </Col>
                <Col>
                    ${quote['premium']}
                </Col>
                <Col>
                    {quote['validated_rating']}
                </Col>
                <Col>
                    {quote['health']}
                </Col>
                <Col>
                    {quote['health_class']}
                </Col>
                <Col>
                    ${quote['face_amount']}
                </Col>
            </Row> ) */
        return (
            <Container>
                <Row>
                    <Col>Reference Documentation: <a href="https://docs.back9ins.com/#getting-quotes">
                        https://docs.back9ins.com/#getting-quotes</a> <br />
                    </Col>
                </Row>
                <Container className='border border-dark rounded-lg p-3 mt-2'>
                    <Form>
                        <span className="mt-1">Face Amount:{this.state.face_amounts.toString(', ')}
                            <div className="multi-select-box">
                                {faceAmountBoxes}
                            </div>
                        </span>

                        <Row className='m-2'>
                            <span>
                                gender: <input type='text' size='20'
                                    value={this.state.gender} onChange={(e) => (
                                        this.setState({
                                            gender: e.target.value
                                        })
                                    )} /> &nbsp;
                            </span>
                            <span>
                                birthdate: <input type='text' size='20' placeholder='yyyy-mm-dd'
                                    value={this.state.birthdate} onChange={(e) => (
                                        this.setState({
                                            birthdate: e.target.value
                                        })
                                    )} /> &nbsp;
                            </span>
                            <span>
                                state:  <input type='text' size='7'
                                    value={this.state.stateCode} onChange={(e) => (
                                        this.setState({
                                            stateCode: e.target.value
                                        })
                                    )} /> &nbsp;
                            </span>
                        </Row>
                        <Row className='m-2'>
                            <span>
                                health: <input type='text' size='10'
                                    value={this.state.health} onChange={(e) => (
                                        this.setState({
                                            health: e.target.value
                                        })
                                    )} /> &nbsp;
                            </span>
                            <span>
                                mode: <input type='text' size='10'
                                    value={this.state.mode} onChange={(e) => (
                                        this.setState({
                                            mode: e.target.value
                                        })
                                    )} /> &nbsp;
                            </span>
                            <span>
                                smoker: <input type='text' size='10'
                                    value={this.state.smoker} onChange={(e) => (
                                        this.setState({
                                            smoker: e.target.value
                                        })
                                    )} /> &nbsp;
                            </span>
                        </Row>
                        <Row className='m-2'>
                            {
                                this.state.smoker && !(this.state.smoker.toLowerCase().startsWith("n")) &&
                                <div>
                                    <span className='m-2'>
                                        last_use_date: <input type="text" value={this.state.smoke_last_use_date}
                                            onChange={(e) => {
                                                this.setState({
                                                    smoke_last_use_date: e.target.value
                                                })
                                            }} />
                                    </span>
                                    <span className='m-2'>
                                        frequency: <input type="text" value={this.state.smoke_frequency}
                                            onChange={(e) => {
                                                this.setState({
                                                    smoke_frequency: e.target.value
                                                })
                                            }} />
                                    </span>
                                    <span className='m-2'>
                                        category: <input type="text" value={this.state.smoke_category}
                                            onChange={(e) => {
                                                this.setState({
                                                    smoke_category: e.target.value
                                                })
                                            }} />
                                    </span>
                                </div>
                            }
                        </Row>

                        <span className="m-1">
                            Product_ Categories: {this.state.product_categories.toString(', ')}
                            <div className="multi-select-box" >
                                {productCategoryBoxes}
                            </div>
                        </span>

                    </Form>
                    <Row className='flex flex-row-reverse mr-4'>
                        <button type='button' className='buttonRounded' onClick={this.saveFormData}>
                            Save
                        </button>
                    </Row>
                </Container>
                <BallRotate
                    color={'#7c648c'}
                    loading={this.state.isLoading}
                />
                {/* {
            <Row className='text-mutedb h6 mt-2'>
                <Col>
                    Insurer
                </Col>
                <Col>
                    Image
                </Col>
                <Col>
                    Product Name
                </Col>
                <Col>
                    Product Category
                </Col>
                <Col>
                    Premium
                </Col>
                <Col>
                    Validated Rating
                </Col>
                <Col>
                    Health 1-5
                </Col>
                <Col>
                    Health Class
                </Col>
                <Col>
                    Coverage
                </Col>
            </Row>
            {quotesAll}} */}
                <JSONToHTMLTable data={{ 'data': this.state.quotesArray }} tableClassName="table table-sm" />

                Paclife:
                <JSONToHTMLTable data={{ 'data': this.state.quotesArrayPacLifeNext }} tableClassName="table table-sm" />
            </Container>
        )
    }
}

export default Quotes;