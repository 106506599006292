import React  from "react";
import { Button, Form, Container, Col, Row, Card, OverlayTrigger, ListGroup } from "react-bootstrap";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import loader from "../purple-line-loader1.svg";
import { BallRotate } from 'react-pure-loaders';
import EmailPopover from './EmailPopover';
import GlobalVars from "../GlobalConstantsVariables";
import ComponentHelper from "./ComponentHelper";

const minLengthAutocomplete = 3;

let CLASS_NAME = 'Address Details';
const FINAL_COUNT = 3;
let checkPointArray = [];
export default class AddressDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			data: "",
			fullAddress: "",
			dbId: '',
			calculatedCoverage: '',
			homeInsuranceAmount: 'Calculating ',
			currentCount: 4
		};
		this.fetchCalculatedFields = this.fetchCalculatedFields.bind(this);
		this.reactReference = React.createRef();
	}
	fetchCalculatedFields(id) {
		let METHOD_NAME = ":fetchCalculatedFields:";
		console.log("Entered:id: ", id, METHOD_NAME, CLASS_NAME);
		if (id == null || id === '') {
			return;
		}
		console.log("Entered:this.state.calculatedCoverage: ", this.state.calculatedCoverage, METHOD_NAME, CLASS_NAME);
		console.log("Entered:this.state.homeInsuranceAmount: ", this.state.homeInsuranceAmount, METHOD_NAME, CLASS_NAME);
		let paramStr, key;
		paramStr = 'calculatedCoverage';
		key = `${this.props.id}++${paramStr}`;
		this.setState({ isLoading: true });
		if (this.state.calculatedCoverage === '') {
			ComponentHelper.getCachedData(key).then((hash) => {
				METHOD_NAME += ":calculatedCoverage:then:"
				console.log("AFTER getCachedData:hash:", hash, METHOD_NAME, CLASS_NAME);
				if (hash != null && hash[key] != null) {
					let calculatedCoverageRound = Math.round(hash[key] / 100000) * 100000;
					this.setState({
						isLoading: false,
						[paramStr]: calculatedCoverageRound
					});
					this.props.onChangeItem({ [paramStr]: hash[key] });
					checkPointArray.push(paramStr);
				}
			});
		}
		paramStr = 'homeInsuranceAmount';
		key = `${this.props.id}++${paramStr}`;
		ComponentHelper.getCachedData(key).then((hash) => {
			METHOD_NAME += `:${paramStr}:then:`;
			console.log("AFTER getCachedData:hash:", hash, METHOD_NAME, CLASS_NAME);
			if (hash != null && hash[key] != null) {
				this.setState({
					isLoading: false,
					[paramStr]: hash[key]
				});
				checkPointArray.push(paramStr);
				this.props.onChangeItem({ [paramStr]: hash[key] });
			}
		});

		if (checkPointArray.length > 1) {
			this.props.refreshDataArrayLink("AfterCheckpoint2FromSection2");
		} else if (checkPointArray.length === FINAL_COUNT) {
			clearInterval(this.intervalId);
		}
	}
	timer() {
		const METHOD_NAME = ":timer:";
		let localCurrentCount;
		console.log("localCurrentCount:", localCurrentCount, " # this.state.homeInsuranceAmount: ", this.state.homeInsuranceAmount, METHOD_NAME, CLASS_NAME);
		if (this.props.fullAddress === '' || this.props.fullAddress == null) {
			this.setState({
				homeInsuranceAmount: ''
			});
			return;
		}
		localCurrentCount = this.state.currentCount - 1;
		this.setState({
			currentCount: localCurrentCount,
			homeInsuranceAmount: this.state.homeInsuranceAmount + '.'
		});
		this.fetchCalculatedFields(this.props.id);
		if (this.state.currentCount < 1) {
			clearInterval(this.intervalId);
			if (this.state.homeInsuranceAmount.startsWith('Calculating')) {
				this.setState({
					homeInsuranceAmount: this.state.homeInsuranceAmount + '?'
				});
			}
		}
	}
	componentDidMount() {
		// React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
		this.intervalId = setInterval(this.timer.bind(this), 2000);
	}
	componentWillUnmount() {
		// React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
		clearInterval(this.intervalId);
	}
	render() {
		return (
			<React.Fragment>
				{this.props.activeView && (
					<Container className="mt-2">
						<Row className="mt-2">
							<Col>
								<Button
									variant="primary"
									type="submit"
									onClick={this.props.returnView}
									className="float-left"
								>
									Back
							</Button>
								<Button
									variant="primary"
									type="submit"
									onClick={this.props.updateView}
									className="float-right"
								>
									Get Estimate
							</Button>
								<OverlayTrigger trigger="click" placement="right" overlay={EmailPopover}>
									<Button
										variant="primary"
										className="float-right mr-2"
									>
										Save Quote
								</Button>
								</OverlayTrigger>
							</Col>
						</Row>
						<Row className="mt-2">
							<Col>
								<p>Home Address : {this.props.fullAddress}</p>
								<p>ID : {this.props.id}</p>
								<p>Home Insurance Premium : {this.state.homeInsuranceAmount}</p>
							</Col>
						</Row>

						<BallRotate
							color={'#7c648c'}
							loading={this.props.isLoading}
						/>
						<Row>
							<Col className="mb-2">
								<GooglePlacesAutocomplete
									placeholder="Your home address"
									loader={<img src={loader} alt='Loader' />}
									ref={(instance) => {
										this.reactReference = instance;
									}}
									onSelect={({ description }) => {
										let METHOD_NAME = "onSelect:";
										console.log(
											"description:",
											description,
											METHOD_NAME,
											CLASS_NAME
										);
										this.setState({
											data: description,
											fullAddress: description,
										});
									}}
									componentRestrictions={{ country: "us" }}
									minLengthAutocomplete={minLengthAutocomplete}
									initialValue={this.props.fullAddress}
								/>
							</Col>
						</Row>
						<Row>
							<Col xs={12} md={6}>
								<Card>
									<Card.Header className="text-center">Life Insurance</Card.Header>
									<Card.Body>

										<Form.Group controlId="insuranceAddressForm.CoverageDesired">
											<Form.Label>Coverage Desired</Form.Label>
											<Form.Control
												as="select"
												size="sm"
												custom
												name="CoverageDesired"
												placeholder='red'
												value={this.state.calculatedCoverage}
												onChange={this.props.addressDetailsUpdate}
											>
												<option value="0">Please Select</option>
												<option value="100000">$100,000</option>
												<option value="200000">$200,000</option>
												<option value="300000">$300,000</option>
												<option value="400000">$400,000</option>
												<option value="500000">$500,000</option>
												<option value="600000">$600,000</option>
												<option value="700000">$700,000</option>
												<option value="800000">$800,000</option>
												<option value="900000">$900,000</option>
												<option value="1000000">$1,000,000</option>
												<option value="1250000">$1,250,000</option>
												<option value="1500000">$1,500,000</option>
												<option value="1750000">$1,750,000</option>
												<option value="2000000">$2,000,000</option>
											</Form.Control>
										</Form.Group>
										<Form.Group controlId="insuranceAddressForm.Termdesired">
											<Form.Label>Term desired</Form.Label>
											<Form.Control
												as="select"
												size="sm"
												custom
												name="Termdesired"
												value={this.props.value.Termdesired}
												onChange={this.props.addressDetailsUpdate}
											>
												<option value="0">Please Select</option>
												<option value="10">10 Years</option>
												<option value="15">15 Years</option>
												<option value="20">20 Years</option>
												<option value="25">25 Years</option>
												<option value="30">30 Years</option>
												<option value="35">35 Years</option>
												<option value="40">40 Years</option>
											</Form.Control>
										</Form.Group>
									</Card.Body>
								</Card>
								<Card className="mt-2">
									<Card.Header className="text-center">Home Insurance</Card.Header>
									<Card.Body>
										<Form.Group controlId="insuranceAddressForm.PrimaryResidence">
											<Form.Label>Primary Residence</Form.Label>
											<Form.Control
												as="select"
												size="sm"
												custom
												name="PrimaryResidence"
												value={this.props.value.PrimaryResidence}
												onChange={this.props.addressDetailsUpdate}
											>
												<option value="0">Please Select</option>
												<option value="Yes">Primary residence</option>
												<option value="Secondary">Secondary / Vacation</option>
												<option value="Investment">Investment property</option>
											</Form.Control>
										</Form.Group>

									</Card.Body>
								</Card>
							</Col>

							<Col xs={12} md={6}>
								<Card>
									<Card.Header className="text-center">Individual Details</Card.Header>
									<Card.Body>
										<Form.Group controlId="insuranceAddressForm.Gender">
											<Form.Label>Gender</Form.Label>
											<Form.Control
												as="select"
												size="sm"
												custom
												name="Gender"
												value={this.props.value.Gender}
												onChange={this.props.addressDetailsUpdate}
											>
												<option value="S">Please Select</option>
												<option value="M">Male</option>
												<option value="F">Female</option>
												<option value="O">Other</option>
											</Form.Control>
										</Form.Group>
										<Form.Group controlId="insuranceAddressForm.Age">
											<Form.Label>Birth Year(ex. 1990)</Form.Label>
											<Form.Control
												type="text"
												placeholder="Enter year of birth"
												size="sm"
												name="Age"
												value={this.props.value.Age}
												onChange={this.props.addressDetailsUpdateAge} />
										</Form.Group>
										<Form.Group controlId="insuranceAddressForm.Health">
											<Form.Label>Health</Form.Label>
											<Form.Control
												as="select"
												size="sm"
												custom
												name="Health"
												value={this.props.value.Health}
												onChange={this.props.addressDetailsUpdate}
											>
												<option value="0">Please Select</option>
												<option value="super_healthy">Excellent</option>
												<option value="healthy">Good</option>
												<option value="healthy_ish">Iffy</option>
											</Form.Control>
										</Form.Group>

										<Form.Group controlId="insuranceAddressForm.Smoker">
											<Form.Label>Smoker</Form.Label>
											<Form.Control
												as="select"
												size="sm"
												custom
												name="Smoker"
												value={this.props.value.Smoker}
												onChange={this.props.addressDetailsUpdate}
											>
												<option value="0">Please Select</option>
												<option value="Yes">Yes</option>
												<option value="No">No</option>
											</Form.Control>
										</Form.Group>
									</Card.Body>
								</Card>
							</Col>
						</Row>

						{/* <Row><Col></Col></Row> <Row><Col></Col></Row>
						<Row><Col></Col></Row> <Row><Col></Col></Row> */}

					</Container>
				)
				}
				<Row>
					<Col>
						<div className={GlobalVars.isDebugMode() ? "customTextField" : "hiddenDiv"}>
							<ListGroup>
								{
									this.props.whatIfScenarioDataArray && this.props.whatIfScenarioDataArray.map(function (d, i) {
										return (
											<ListGroup.Item key={d}>
												{i} : {d[0]} : {d[1]}
											</ListGroup.Item>
										);
									})
								}
							</ListGroup>
						</div>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}