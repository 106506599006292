import React, { Component } from 'react';
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import loader from "../purple-line-loader1.svg";

const minLengthAutocomplete = 3;

export default class SearchDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            fullAddress: "",
        };
        this.reactReference = React.createRef();
    }
    render() {
        return (
            <React.Fragment>
                <div id='address-search' className="column-2">
                    <GooglePlacesAutocomplete
                        placeholder="Your home address"
                        loader={<img src={loader} alt={""} />}
                        ref={(instance) => {
                            this.reactReference = instance;
                        }}
                        onSelect={this.props.onHomeAddressSearch}
                        componentRestrictions={{ country: "us" }}
                        minLengthAutocomplete={minLengthAutocomplete}
                        initialValue={this.props.fullAddress}
                    />
                </div>
            </React.Fragment>
        )
    }
}