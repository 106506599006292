import React, { Component } from 'react';

class MultiSelectBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCoveragesArray: [],
            selectedCoveragesString: '',
            countOfChecked: 0
        }
    }

    setCoveragesString = () => {
        const values = this.state.selectedCoveragesArray.sort((a, b) => a - b)
        let str = values.reduce((acc, val) => acc + val + "_", '')
        str = str.substring(0,str.length-1)
        this.setState({
            selectedCoveragesString: str
        },() => this.props.onSubmit(values))
        console.log(str)
    }

    handleChange = (e) => {
        let selected =  Number(e.target.value);
        let index = this.state.selectedCoveragesArray.indexOf(selected);

        if (index === -1) {  //value is selected already
            if (this.state.countOfChecked === 5) {  //setting maximum no of values to be selected
                e.target.checked = false;
                return;
            }

            this.setState({
                selectedCoveragesArray: [...this.state.selectedCoveragesArray, selected],
                countOfChecked: this.state.countOfChecked + 1
            }, this.setCoveragesString)

        } else { //value is not selected 
            this.setState({
                selectedCoveragesArray: this.state.selectedCoveragesArray.filter((_, i) => i !== index),
                countOfChecked: this.state.countOfChecked - 1
            }, this.setCoveragesString)
        }
    }

    submitValues = () => {
        this.props.onSubmit(this.state.selectedCoveragesArray);
    }

    componentDidMount(){
        this.setState({
            selectedCoveragesArray:this.props.selectedArray,
            countOfChecked:this.props.selectedArray.length
        })
    }

    
    render() {
        const coveragesText = ['100K', '200K', '300K', '400K', '500K', '600K', '700K', '800K', '900K', '1.0M', '1.5M', '2.0M'];
        const values = [100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000, 1500000, 2000000];
        const { selectedArray } = this.props;

        const boxes = coveragesText.map(
            (text, i) => (
                        <div key={i} className='border w-25 pl-2'>
                           <label className='checkbox-handle'>
                               <input 
                               type='checkbox'
                               className="checkbox-hidden" 
                               onChange={this.handleChange} 
                               value={values[i]}
                               defaultChecked={selectedArray.includes(values[i])}/>
                               <div className='checkbox-custom'></div>
                           </label>
                                <span> ${text} </span>
                        </div>
                    )
            )

        return (
            <div className='mt-1 w-100'>
                    <div className='border p-2'>
                        Select Coverage Values (max 5)
                    </div>
                    <div className="multi-select-box">
                        {boxes}
                    </div>
            </div>
        )
    }

}

export default MultiSelectBox;