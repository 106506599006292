/* eslint react/no-multi-comp: 0, max-len: 0 */
import React from 'react';
import Slider from 'rc-slider';
import {  Form, Row, Col} from "react-bootstrap";
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
// import BubbleChartJS from './BubbleChartJS.component';
// import BubbleSlider from './BubbleSlider.component';

import Utility from "./Utility";

const CLASS_NAME = "CoverageComparisonV3.Component:";
const color = ['blue', 'green', 'purple', 'orange', 'yellow', 'black'];

const _defaultpremiumOption = 'M';
const _defaultAge = 35;
const _defaultMinAge = 20;
const _defaultMaxAge = 66;
const _defaultStep4Age = 1;
const _defaultMinCoverage = 100;
const _defaultMaxCoverage = 2000;
const _defaultStep4Coverage = 100;
const AGE = "Age (20 - 65)";
const POLICY_TERM = "Policy Term";
const COVERAGE = "Coverage";
const PREMIUM = "Premium";
const UNCHECKED_COVERAGE = "unselectedCoverage";
const CHECKED_COVERAGE = "selectedCoverage";

let ageValue;
let policyTerm;
let coverageValue;
let globalScalesData;
let globalAnnotationData;
let globalChartData;

// Required for checkbox
const _defaultCoverageArray = [100, 600, 700]; // Values are in Ks

let checkedCoverageArray = [..._defaultCoverageArray];
let unCheckedCoverageArray = [200, 300, 400, 500, 800, 900, 1000, 1500, 2000];
let _defaultCoverageCheckboxIndicatorArray = [CHECKED_COVERAGE, UNCHECKED_COVERAGE, UNCHECKED_COVERAGE, UNCHECKED_COVERAGE, UNCHECKED_COVERAGE, CHECKED_COVERAGE
    , CHECKED_COVERAGE, UNCHECKED_COVERAGE, UNCHECKED_COVERAGE, UNCHECKED_COVERAGE, UNCHECKED_COVERAGE, UNCHECKED_COVERAGE];

function ageFormatter(v) {  //eslint-disable-line
    return `Age: ${v}`;
}

function getFormattedValue(sliderName, value) {
    // console.log("Input:value:", value, " # getFormattedValue");
    if (sliderName === AGE) {
        ageValue = value;
        return ageValue;
    } else if (sliderName === POLICY_TERM) {
        policyTerm = value;
        return `${policyTerm} Years`;
    } else if (sliderName === PREMIUM) {
        let premiumAmount = value;
        return `$${premiumAmount}`;
    } else if (sliderName === COVERAGE) {
        // console.log("typeof value:", typeof value, " # getFormattedValue");
        if (typeof value === 'string') {
            value = parseInt(value);
        }
        coverageValue = value;
        if (value <= 999) {
            return `$${coverageValue}K`;
        } else if (value > 999 && value <= 1499) {
            return `$1.0M`;
        } else if (value >= 1500 && value < 1749) {
            return `$1.5M`;
        } else if (value > 1750 && value < 2001) {
            return `$2M`;
        }
    }
};

const age_premium_equation = [{      //eslint-disable-line
    coverage: 900000, policy_term: 20,
    age_premium: {
        age_21: 62.2486, age_22: 61.4662, age_23: 60.6718, age_24: 59.9254, age_25: 59.287,
        age_26: 58.8166, age_27: 58.5742, age_28: 58.6198, age_29: 59.0134, age_30: 59.815,
        age_31: 61.0846, age_32: 62.8822, age_33: 65.2678, age_34: 68.3014, age_35: 72.043,
        age_36: 76.5526, age_37: 81.8902, age_38: 88.1158, age_39: 95.2894, age_40: 103.471,
        age_41: 112.7206, age_42: 123.0982, age_43: 134.6638, age_44: 147.4774, age_45: 161.599,
        age_46: 177.0886, age_47: 194.0062, age_48: 212.4118, age_49: 232.3654, age_50: 253.927,
        age_51: 277.1566, age_52: 302.1142, age_53: 328.8598, age_54: 357.4534, age_55: 387.955,
        age_56: 420.4246, age_57: 454.9222, age_58: 491.5078, age_59: 530.2414, age_60: 571.183,
        age_61: 614.3926, age_62: 659.9302, age_63: 707.8558, age_64: 758.2294, age_65: 811.111
    }
}];

//const SliderWithTooltip = createSliderWithTooltip(Slider);

let options = {      //eslint-disable-line
    aspectRatio: 1,
    legend: false,
    tooltips: false,

    elements: {
        point: {
            backgroundColor: 'blue',

            borderColor: 'green',

            borderWidth: 2,
        },

        hoverBackgroundColor: 'transparent',

        hoverBorderColor: 'green',
    },

    hoverBorderWidth: 4,

    radius: function (context) {
        var value = { x: -149.8681841563786, y: -149.8681841563786, v: 0.5272633744855968 };
        var size = 1800;
        var base = Math.abs(value.v) / 1000;
        return (size / 24) * base;
    }
};

let age1;

function coverageCheckboxesUpdates(inputCoverage, inputCoverageArray, inputCheckboxIndicator) {
    let METHOD_NAME = "coverageCheckboxesUpdates:";
    console.log("Entered", METHOD_NAME, CLASS_NAME);
    let checkboxIndicator = null;
    let outputArray = [...inputCoverageArray];
    /*
        Logic: deal with inputCoverage if it is in checkedArray then uncheck it
        if it is in unCheckedArray then uncheck it
        Make sure set the variable to indicate
    */

    console.log("checkedCoverageArray.indexOf(inputCoverage):", checkedCoverageArray.indexOf(inputCoverage), METHOD_NAME, CLASS_NAME);
    console.log("unCheckedCoverageArray.indexOf(inputCoverage):", unCheckedCoverageArray.indexOf(inputCoverage), METHOD_NAME, CLASS_NAME);
    checkboxIndicator = [...inputCheckboxIndicator];
    let index = 0;
    switch (inputCoverage) {
        case 1000:
            index = 9;
            break;
        case 1500:
            index = 10;
            break;
        case 2000:
            index = 11;
            break;
        default:
            index = ((inputCoverage / 100) - 1);
            break;
    }

    if (checkedCoverageArray.indexOf(inputCoverage) === -1) {
        // Add
        checkedCoverageArray.push(inputCoverage);
        checkboxIndicator[index] = CHECKED_COVERAGE;
        console.log("if loop: checkboxIndicator:", checkboxIndicator, METHOD_NAME, CLASS_NAME);
        outputArray.push(inputCoverage);
        checkedCoverageArray = [...outputArray];
        console.log("BEFORE unCheckedCoverageArray.indexOf(inputCoverage):", unCheckedCoverageArray.indexOf(inputCoverage), METHOD_NAME, CLASS_NAME);
        for (let i = 0; i < unCheckedCoverageArray.length; i += 1) {
            if (unCheckedCoverageArray[i] === inputCoverage) {
                unCheckedCoverageArray.splice(i, 1);
                break;
            }
        }
        console.log("AFTER unCheckedCoverageArray.indexOf(inputCoverage):", unCheckedCoverageArray.indexOf(inputCoverage), METHOD_NAME, CLASS_NAME);

    } else if (unCheckedCoverageArray.indexOf(inputCoverage) === -1) {
        // Remove
        for (let i = 0; i < inputCoverageArray.length; i += 1) {
            if (inputCoverageArray[i] === inputCoverage) {
                outputArray.splice(i, 1);
                break;
            }
        }
        for (let i = 0; i < checkedCoverageArray.length; i += 1) {
            if (checkedCoverageArray[i] === inputCoverage) {
                checkedCoverageArray.splice(i, 1);
                break;
            }
        }
        unCheckedCoverageArray.push(inputCoverage);

        checkboxIndicator[index] = UNCHECKED_COVERAGE;
    }
    console.log("index: ", index, "checkboxIndicator:", checkboxIndicator, METHOD_NAME, CLASS_NAME);
    console.log("outputArray:", outputArray, METHOD_NAME, CLASS_NAME);
    return [checkboxIndicator, outputArray];
}

export default class CoverageComparisonV3 extends React.Component {
    constructor(props) {
        super(props);

        this.onCoverageClick = this.onCoverageClick.bind(this);
        this.onAgeSliderChange = this.onAgeSliderChange.bind(this);
        this.onChangeDropdown1 = this.onChangeDropdown1.bind(this);
        this.onChangeCoverage = this.onChangeCoverage.bind(this);
        let localCoverageArray = _defaultCoverageArray;
        // for (let loopCounter = 0; loopCounter < _defaultCoverageArray.length; loopCounter += 1) {
        //         coverageCheckboxesUpdates();
        // }
        // getPremiumForPolicyTerm(age, inputCoverageArray, policyTerm, premiumOption = 'M')
        let data10 = Utility.getPremiumForPolicyTerm(_defaultAge, localCoverageArray, 10, _defaultpremiumOption);
        let data20 = Utility.getPremiumForPolicyTerm(_defaultAge, localCoverageArray, 20, _defaultpremiumOption);
        let data30 = Utility.getPremiumForPolicyTerm(_defaultAge, localCoverageArray, 30, _defaultpremiumOption);
        let policyTermWiseData = Utility.reorganizeCoverageWiseData(localCoverageArray, data10, data20, data30);
        this.globalChartLabels = {};

        let datasetsArray = [];
        let labels = [];
        for (let coverageCounter = 0; coverageCounter < localCoverageArray.length; coverageCounter += 1) {
            let formattedCoverage = getFormattedValue(COVERAGE, localCoverageArray[coverageCounter]);
            // console.log("Input:this.state.coverageArray[coverageCounter]:", this.state.coverageArray[coverageCounter], "formattedCoverage: ", formattedCoverage, CLASS_NAME, METHOD_NAME);
            labels.push(formattedCoverage + "(10,20,30 Years)");
            datasetsArray[coverageCounter] = {
                label: `${formattedCoverage}`,
                // backgroundColor: 'rgba(75,192,192,1)',
                borderColor: color[coverageCounter],
                borderWidth: 2,
                data: policyTermWiseData[localCoverageArray[coverageCounter]]
            };
            // console.log("policyTermWiseData[", localCoverageArray[coverageCounter], "]: ", policyTermWiseData[localCoverageArray[coverageCounter]], METHOD_NAME, CLASS_NAME);
        }

        globalChartData = {
            labels: labels,
            datasets: datasetsArray
        };
        globalScalesData = {
            xAxes: [{ ticks: { maxRotation: 90, minRotation: 80 } }],
            yAxes: [{ ticks: { beginAtZero: true, stepSize: 10, min: 0, max: 1400 } }]
        };
        globalAnnotationData = {


        };
        this.state = {
            checkboxIndicator: _defaultCoverageCheckboxIndicatorArray,

            coverageArray: _defaultCoverageArray,
            selectedCoverageArray:[],
            premium_option: 0,//_defaultpremiumOption,
            age_value: this.props.age,
            age_min: _defaultMinAge,
            age_max: _defaultMaxAge,
            age_step: _defaultStep4Age,
            coverage_value: this.props.coverage,
            coverage_min: _defaultMinCoverage,
            coverage_max: _defaultMaxCoverage,
            coverage_step: _defaultStep4Coverage,
            labels: labels,
            datadata: data20,
            chartData: globalChartData,
            scalesData: globalScalesData,
            annotationData: globalAnnotationData
            // sliderName: props.sliderName,
            // value: props.value, min: props.min, max: props.max,
            // step: props.step,
        };
    }

    handleCoverageArrayChange = (e) => {
        let selected = Number(e.target.value);
        let index = this.state.selectedCoverageArray.indexOf(selected);

        if (index === -1) {  //value is selected already
            if (this.state.selectedCoverageArray.length === 5) {  //setting maximum no of values to be selected
                e.target.checked = false;
                return;
            }
            this.setState({
                selectedCoverageArray: [...this.state.selectedCoverageArray, selected]
            })
        } else { //value is not selected 
            this.setState({
                selectedCoverageArray: this.state.selectedCoverageArray.filter((_, i) => i !== index),
            })
        }
    }

    onPremiumOptionSliderChange = (value) => {
        let METHOD_NAME = ":onPremiumOptionSliderChange:";
        this.setState({
            premium_option: value
        })
        console.log(METHOD_NAME, CLASS_NAME, value)
        let dropdownValue = value === 0 ? "M" : (value === 1 ? "Y" : "T");
        this.onChangeDropdown1(dropdownValue)
    }

    getFormattedPremiumOption = (value) => {
        switch (Number(value)) {
            case 0:
                return "Monthly Premium Mode";
            case 1:
                return "Yearly Premium Mode";
            case 2:
                return "Total Policy Term Permium Mode"; 
            default:
                break;
        }

    }
    onChangeCoverage(e) {

        let options = e.target.options;
        let value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        // console.log("value:", value, CLASS_NAME, METHOD_NAME);
        this.setState({ coverageArray: value });
        // coverageArray = value;
        // console.log("this.state.coverageArray:", this.state.coverageArray, CLASS_NAME, METHOD_NAME);
        let localCoverageArray = value;
        // getPremiumForPolicyTerm(age, inputCoverageArray, policyTerm, premiumOption = 'M')
        let data10 = Utility.getPremiumForPolicyTerm(this.state.age_value, localCoverageArray, 10, this.state.premium_option);
        let data20 = Utility.getPremiumForPolicyTerm(this.state.age_value, localCoverageArray, 20, this.state.premium_option);
        let data30 = Utility.getPremiumForPolicyTerm(this.state.age_value, localCoverageArray, 30, this.state.premium_option);

        let policyTermWiseData = Utility.reorganizeCoverageWiseData(localCoverageArray, data10, data20, data30);

        let datasetsArray = [];
        let labels = [];
        for (let coverageCounter = 0; coverageCounter < localCoverageArray.length; coverageCounter += 1) {
            let formattedCoverage = getFormattedValue(COVERAGE, localCoverageArray[coverageCounter]);
            // console.log("Input:this.state.coverageArray[coverageCounter]:", this.state.coverageArray[coverageCounter], "formattedCoverage: ", formattedCoverage, CLASS_NAME, METHOD_NAME);
            labels.push(formattedCoverage + "(10,20,30 Years)");
            datasetsArray[coverageCounter] = {
                label: `${formattedCoverage}`,
                // backgroundColor: 'rgba(75,192,192,1)',
                borderColor: color[coverageCounter],
                borderWidth: 2,
                data: policyTermWiseData[localCoverageArray[coverageCounter]]
            };
            // console.log("policyTermWiseData[", localCoverageArray[coverageCounter], "]: ", policyTermWiseData[localCoverageArray[coverageCounter]], METHOD_NAME, CLASS_NAME);
        }

        globalChartData = {
            labels: labels,
            datasets: datasetsArray
        };
        this.globalChartLabels = labels;
        globalScalesData = null;
        let maxYAxisValue = data30[data30.length - 1] + 50;
        maxYAxisValue = (maxYAxisValue / 50).toFixed() * 50;
        maxYAxisValue = maxYAxisValue == null || maxYAxisValue <= 900 ? 900 : maxYAxisValue;
        globalScalesData = {
            xAxes: [{ ticks: { maxRotation: 90, minRotation: 80 } }],
            yAxes: [{ ticks: { beginAtZero: true, stepSize: 10, min: 0, max: maxYAxisValue } }]
        };// , scalesData: globalScalesData

        this.setState({ age1: age1, scalesData: globalScalesData, chartData: globalChartData, annotationData: globalAnnotationData });
    }

    onChangeDropdown1(e) {
        let METHOD_NAME = "onChangeDropdown1";
        let premiumOption = e //.target.value;
        console.log(METHOD_NAME, "premiumOption: ", premiumOption);
        globalChartData = null;
        this.setState({ chartData: null });
        // getPremiumForPolicyTerm(age, inputCoverageArray, policyTerm, premiumOption = 'M')
        let data10 = Utility.getPremiumForPolicyTerm(this.state.age_value, this.state.coverageArray, 10, premiumOption);
        let data20 = Utility.getPremiumForPolicyTerm(this.state.age_value, this.state.coverageArray, 20, premiumOption);
        let data30 = Utility.getPremiumForPolicyTerm(this.state.age_value, this.state.coverageArray, 30, premiumOption);
        let datasetsArray = [];
        let labels = [];
        let localCoverageArray = this.state.coverageArray;
        let policyTermWiseData = Utility.reorganizeCoverageWiseData(localCoverageArray, data10, data20, data30);

        for (let coverageCounter = 0; coverageCounter < localCoverageArray.length; coverageCounter += 1) {
            let formattedCoverage = getFormattedValue(COVERAGE, localCoverageArray[coverageCounter]);
            // console.log("Input:this.state.coverageArray[coverageCounter]:", this.state.coverageArray[coverageCounter], "formattedCoverage: ", formattedCoverage, CLASS_NAME, METHOD_NAME);
            labels.push(formattedCoverage + "(10,20,30 Years)");
            datasetsArray[coverageCounter] = {
                label: `${formattedCoverage}`,
                // backgroundColor: 'rgba(75,192,192,1)',
                borderColor: color[coverageCounter],
                borderWidth: 2,
                data: policyTermWiseData[localCoverageArray[coverageCounter]]
            };
            // console.log("policyTermWiseData[", localCoverageArray[coverageCounter], "]: ", policyTermWiseData[localCoverageArray[coverageCounter]], METHOD_NAME, CLASS_NAME);
        }

        globalChartData = {
            labels: labels,
            datasets: datasetsArray
        };
        this.globalChartLabels = labels;
        globalScalesData = null;
        let maxYAxisValue = data30[data30.length - 1] + 50;
        maxYAxisValue = (maxYAxisValue / 50).toFixed() * 50;
        maxYAxisValue = maxYAxisValue == null || maxYAxisValue <= 900 ? 900 : maxYAxisValue;
        globalScalesData = {
            xAxes: [{ ticks: { maxRotation: 90, minRotation: 80 } }],
            yAxes: [{ ticks: { beginAtZero: true, stepSize: 10, min: 0, max: maxYAxisValue } }]
        };// , scalesData: globalScalesData

        this.setState({ age1: age1, scalesData: globalScalesData, chartData: globalChartData, annotationData: globalAnnotationData });
    }
    onAgeSliderChange(value) {
        let METHOD_NAME = "onAgeSliderChange";
        age1 = value;
        console.log(METHOD_NAME, "age1: ", age1);
        console.log("this.state.coverageArray:", this.state.coverageArray, CLASS_NAME, METHOD_NAME);

        globalChartData = null;
        this.setState({ chartData: null });
        // getPremiumForPolicyTerm(age, inputCoverageArray, policyTerm, premiumOption = 'M')
        let data10 = Utility.getPremiumForPolicyTerm(age1, this.state.coverageArray, 10, this.state.premium_option);
        let data20 = Utility.getPremiumForPolicyTerm(age1, this.state.coverageArray, 20, this.state.premium_option);
        let data30 = Utility.getPremiumForPolicyTerm(age1, this.state.coverageArray, 30, this.state.premium_option);
        let datasetsArray = [];
        let labels = [];
        let localCoverageArray = this.state.coverageArray;
        let policyTermWiseData = Utility.reorganizeCoverageWiseData(localCoverageArray, data10, data20, data30);

        for (let coverageCounter = 0; coverageCounter < localCoverageArray.length; coverageCounter += 1) {
            let formattedCoverage = getFormattedValue(COVERAGE, localCoverageArray[coverageCounter]);
            // console.log("Input:this.state.coverageArray[coverageCounter]:", this.state.coverageArray[coverageCounter], "formattedCoverage: ", formattedCoverage, CLASS_NAME, METHOD_NAME);
            labels.push(formattedCoverage + "(10,20,30 Years)");
            datasetsArray[coverageCounter] = {
                label: `${formattedCoverage}`,
                // backgroundColor: 'rgba(75,192,192,1)',
                borderColor: color[coverageCounter],
                borderWidth: 2,
                data: policyTermWiseData[localCoverageArray[coverageCounter]]
            };
            // console.log("policyTermWiseData[", localCoverageArray[coverageCounter], "]: ", policyTermWiseData[localCoverageArray[coverageCounter]], METHOD_NAME, CLASS_NAME);
        }

        globalChartData = {
            labels: labels,
            datasets: datasetsArray
        };
        this.globalChartLabels = labels;

        globalScalesData = null;
        let maxYAxisValue = data30[data30.length - 1] + 50;
        maxYAxisValue = (maxYAxisValue / 50).toFixed() * 50;
        maxYAxisValue = maxYAxisValue == null || maxYAxisValue <= 900 ? 900 : maxYAxisValue;
        globalScalesData = {
            xAxes: [{ ticks: { maxRotation: 90, minRotation: 80 } }],
            yAxes: [{ ticks: { beginAtZero: true, stepSize: 10, min: 0, max: maxYAxisValue } }]
        };// , scalesData: globalScalesData
        this.setState({ age_value: value, scalesData: globalScalesData, chartData: globalChartData });
    }
    onCoverageClick(e) {
        let METHOD_NAME = "onCoverageClick";
        let coverageInt = parseInt(e.target.firstChild.innerHTML);
        console.log("td.firstChild: ", coverageInt, METHOD_NAME, CLASS_NAME);
        let array = coverageCheckboxesUpdates(coverageInt, this.state.coverageArray, this.state.checkboxIndicator);
        this.setState({ checkboxIndicator: array[0], coverageArray: array[1] });
        console.log("this.state.coverageArray:", this.state.coverageArray, "array[1]:", array[1], CLASS_NAME, METHOD_NAME);
    }

    render() {
        const coveragesText = ['100K', '200K', '300K', '400K', '500K', '600K', '700K', '800K', '900K', '1.0M', '1.5M', '2.0M'];
        const values = [100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000, 1500000, 2000000];

        const coveragesBox = coveragesText.map(
            (text, i) => (
                <div key={i} className='border checkbox-ccv3'>
                    <label className='checkbox-handle'>
                        <input
                            type='checkbox'
                            className="checkbox-hidden"
                            defaultChecked={this.state.selectedCoverageArray.includes(values[i])}
                            onChange={this.handleCoverageArrayChange}
                            value={values[i]}
                        />
                        <div className='checkbox-custom'></div>
                    </label>
                    <span> ${text} </span>
                </div>
            )
        )
        return (
            <div>
                <Form>
                    <div>
                        <center>
                            <h1>Coverage Comparison</h1>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <span>{AGE}</span><br />
                                    <span>{getFormattedValue(AGE, this.state.age_value)}</span><br />
                                    <Slider value={this.state.age_value} min={this.state.age_min} max={this.state.age_max} onChange={this.onAgeSliderChange} /><br />
                                </div>
                                    <div className='mt-1 w-100'>
                                        <div className="multi-select-box">
                                            <div className="ccv3">Select Coverage Values (max 5)</div>
                                            {coveragesBox}
                                        </div>
                                    </div>
                                <div>
                                {/*
                                <div class="col-md-auto table-responsive-sm">
                                    <table class="w-25" className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th colSpan="6">Select Coverage Values (max 5)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                        <tbody> 
                                            <tr>
                                                <td onClick={this.onCoverageClick}>
                                                    <div class="hiddenDiv">100</div>
                                                    <small><span class={this.state.checkboxIndicator[0]}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        &nbsp;$100K</small></td>
                                                <td onClick={this.onCoverageClick}>
                                                    <div class="hiddenDiv">200</div>
                                                    <small><span class={this.state.checkboxIndicator[1]}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        &nbsp;$200K</small></td>
                                                <td onClick={this.onCoverageClick}>
                                                    <div class="hiddenDiv">300</div>
                                                    <small><span class={this.state.checkboxIndicator[2]}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        &nbsp;$300K</small></td>
                                                <td onClick={this.onCoverageClick}>
                                                    <div class="hiddenDiv">400</div>
                                                    <small><span class={this.state.checkboxIndicator[3]}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        &nbsp;$400K</small></td>
                                                <td onClick={this.onCoverageClick}>
                                                    <div class="hiddenDiv">500</div>
                                                    <small><span class={this.state.checkboxIndicator[4]}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        &nbsp;$500K</small></td>
                                                <td onClick={this.onCoverageClick}>
                                                    <div class="hiddenDiv">600</div>
                                                    <small><span class={this.state.checkboxIndicator[5]}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        &nbsp;$600K</small></td>
                                            </tr>
                                            <tr>
                                                <td onClick={this.onCoverageClick}>
                                                    <div class="hiddenDiv">700</div>
                                                    <small><span class={this.state.checkboxIndicator[6]}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        &nbsp;$700K</small></td>
                                                <td onClick={this.onCoverageClick}>
                                                    <div class="hiddenDiv">800</div>
                                                    <small><span class={this.state.checkboxIndicator[7]}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        &nbsp;$800K</small></td>
                                                <td onClick={this.onCoverageClick}>
                                                    <div class="hiddenDiv">900</div>
                                                    <small><span class={this.state.checkboxIndicator[8]}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        &nbsp;$900K</small></td>
                                                <td onClick={this.onCoverageClick}>
                                                    <div class="hiddenDiv">1000</div>
                                                    <small><span class={this.state.checkboxIndicator[9]}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        &nbsp;$1.00M</small></td>
                                                <td onClick={this.onCoverageClick}>
                                                    <div class="hiddenDiv">1500</div>
                                                    <small><span class={this.state.checkboxIndicator[10]}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        &nbsp;$1.50M</small></td>
                                                <td colSpan="4" onClick={this.onCoverageClick}><div class="hiddenDiv">2000</div>
                                                    <small onClick={this.onCoverageClick}><div class="hiddenDiv">2000</div><span class={this.state.checkboxIndicator[11]}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                        &nbsp;$2.00M</small>
                                                </td>
                                            </tr>
                                       </tbody>
                                    </table>
                                </div>
                              */} 
                              </div>
                              <Col>
                                    <Row>
                                        <span>Premium Option</span>
                                    </Row>
                                    <Row>
                                        <span>{this.getFormattedPremiumOption(this.state.premium_option)}</span><br /><br />
                                    </Row>
                                    <Row>
                                        <div className="col-md-4 col-sm-6">
                                            <Slider onChange={this.onPremiumOptionSliderChange}
                                            value={this.state.premium_option} min={0} step={1} max={2}/>
                                        </div>
                                    </Row>
                              </Col>
                          {/*    <div class="col-md-4 col-sm-6">
                                    <FormGroup controlId="this.state.premium_option" >
                                        <FormLabel>Premium Option</FormLabel>
                                        <FormControl as="select" onChange={this.onChangeDropdown1} value={this.state.premium_option}>
                                            <option value='O'>Select</option>
                                            <option value="M">Monthly Premium Mode</option>
                                            <option value="Y">Yearly Premium Mode</option>
                                            <option value="T">Total Policy Term Permium Mode</option>
                                        </FormControl>
                                    </FormGroup>
                            </div>   */}
                            </div>
                            <div><Row>&nbsp;</Row></div>
                            <div className="whatif-chart-container">
                                <Bar
                                    data={this.state.chartData}
                                    options={{
                                        title: {
                                            display: true,
                                            text: 'Premiums Policy Terms Chart',
                                            fontSize: 20
                                        },
                                        legend: {
                                            display: true,
                                            position: 'right'
                                        },
                                        scales: {
                                            yAxes: [{
                                                ticks: {
                                                    // Include a dollar sign in the ticks
                                                    callback: function (value, index, values) {
                                                        return Utility.getThousandsSeparatedStr(value);
                                                    }
                                                }
                                            }]
                                        },
                                        annotation: {
                                            annotations: [
                                                {
                                                    type: 'line',
                                                    mode: 'vertical',
                                                    scaleID: 'x-axis-0',
                                                    value: this.state.age_value,
                                                    borderColor: 'black',
                                                    borderWidth: 2
                                                }]
                                        }
                                    }}

                                />
                            </div>
                        </center>
                    </div>
                </Form>
            </div >
        );
    }
}