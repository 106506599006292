import React, { Component } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';


class ValueSlider extends Component {
    render() {
        const { min, max, step, value, marks } = this.props;
        return (
            <div className="w-90">
                <span className='mb-1'>
                    {this.props.label}
                </span>
                <Slider
                    value={value}
                    min={min}
                    max={max}
                    step={step}
                    marks={marks ? marks : {}}
                    onAfterChange={this.props.onAfterChange}
                    onChange={this.props.onChange} /><br />
            </div>
        )
    }
}

export default ValueSlider;
