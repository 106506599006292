import { Amplify } from '@aws-amplify/core';
import { API, graphqlOperation } from '@aws-amplify/api';
import { createAppData } from '../graphql/mutations';
import { listAppDatas, getAppData } from '../graphql/queries';
import { updateAppData } from '../graphql/mutations';
import awsExports from "../aws-exports";

Amplify.configure(awsExports);

const CLASS_NAME = ":AppDatasQuestionDataHandler:";
let dbId;
let AppDataArray;
let AppData;
let classLevelArray;         //eslint-disable-line
export default {
    getDbId: () => {
        const METHOD_NAME = ":getDbId:";
        console.log('Entered', METHOD_NAME, CLASS_NAME)
        return dbId;
    },
    setDbId: (inputFlag) => {
        dbId = inputFlag;
    },
    saveData: async (json) => {
        let METHOD_NAME = ":saveData:";
        console.log('json:', json, METHOD_NAME, CLASS_NAME);

        await API.graphql(graphqlOperation(createAppData, { input: json })).then((response2) => {
            METHOD_NAME = "API.graphql.then:";
            console.log("response2: ", JSON.stringify(response2, null, " "), METHOD_NAME, CLASS_NAME);
            let item = {};
            if (response2 != null && response2.data != null && (item = response2.data.createWhatIfScenarioData) != null) {
                console.log("item.id: ", item.id, METHOD_NAME, CLASS_NAME);
                dbId = item.id;
            }
        });
        return dbId;
    },
    updateData: async (json) => {
        let METHOD_NAME = ":updateData:";
        console.log('json:', json, METHOD_NAME, CLASS_NAME);

        await API.graphql(graphqlOperation(updateAppData, { input: json })).then((response2) => {
            METHOD_NAME = "API.graphql.then:";
            console.log("response2: ", JSON.stringify(response2, null, " "), METHOD_NAME, CLASS_NAME);
            let item = {};
            if (response2 != null && response2.data != null && (item = response2.data.createWhatIfScenarioData) != null) {
                console.log("item.id: ", item.id, METHOD_NAME, CLASS_NAME);
                dbId = item.id;
            }
        });
        return dbId;
    },
    getData: async (id) => {
        let METHOD_NAME = ":getData:";
        console.log("id:", id, METHOD_NAME, CLASS_NAME);
        await API.graphql(graphqlOperation(getAppData, { id: id })).then((response) => {
            METHOD_NAME += ".then:";
            let json;
            if (response != null && response.data != null && (json = response.data.getAppData) != null) {
                console.log("json: ", json, METHOD_NAME, CLASS_NAME);
                AppData = json;
            }
        });
        return AppData;
    },
    listData: async (fromIndex, toIndex) => {
        let METHOD_NAME = ":listData:";
        console.log("Entered", METHOD_NAME, CLASS_NAME);
        await API.graphql(graphqlOperation(listAppDatas, { limit: toIndex })).then((response) => {
            METHOD_NAME += ".then:";
            let json;
            if (response != null && response.data != null && (json = response.data.listAppDatas) != null) {
                console.log("json: ", json, METHOD_NAME, CLASS_NAME);
                AppDataArray = json.items;
            }
        });
        return AppDataArray;
    },
    listAndUpdateOneColumn: async () => {
        let METHOD_NAME = ":listAndUpdateOneColumn:";
        console.log("Entered", METHOD_NAME, CLASS_NAME);
        await API.graphql(graphqlOperation(listAppDatas, { limit: 10 })).then((response) => {
            METHOD_NAME += ".then:";
            let json;
            if (response != null && response.data != null && (json = response.data.listAppDatas) != null) {
                console.log("json: ", json, METHOD_NAME, CLASS_NAME);
                for (let index = 0; index < json.items.length; index++) {
                    console.log(json.items[index], METHOD_NAME, CLASS_NAME);
                    let localJson = json.items[index];
                    localJson._lastChangedAt = 1599601437256;
                    this.updateData(localJson);
                }
            }
        });
        return AppDataArray;
    },
    resetClassLevelVarValue: () => {
        classLevelArray = null;
    },
}
