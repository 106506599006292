import React from 'react';
import { Container, Row , Form } from 'react-bootstrap';

import HealthCausesDataHandler from "./HealthCausesQuestionDataHandler";
import AutoComplete from './AutoComplete.component';

export default class HealthCauses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cause: '',
            county: '',
            ageBand: '',
            deathsOfAgeGroup: '',
            populationOfAgeGroup: '',
            totalDeaths: '',
            totalDeathsOfAgeGroup: '',
            totalPopulation: '',
            countyCode: '',
            searchProperty: 'age',
            selectedId: "",
            isEditMethod: false,
            optionsSet: [],
            optionsArray: [],
            dataArray: []
        }
        this.listData();
        this.listData = this.listData.bind(this);
    }

    resetForm = () => {
        this.setState({
            cause: '',
            county: '',
            ageBand: '',
            deathsOfAgeGroup: '',
            populationOfAgeGroup: '',
            totalDeaths: '',
            totalDeathsOfAgeGroup: '',
            totalPopulation: '',
            countyCode: '',
            selectedId: "",
            isEditMethod: false
        })
    }

    componentDidMount() {
        let searchableValues = new Set();
        this.state.dataArray.forEach((d) => searchableValues.add(d[this.state.searchProperty]));
        let values = []
        for (let option of searchableValues) {
            if (option)
                values.push(option)
        }
        this.setState({
            optionsSet: values
        })
    }

    searchByValue = (e) => {
        const property = e.target.value;
        let i = 0;
        let searchableValues = new Set();
        this.state.dataArray.forEach((d) => searchableValues.add(d[property]));
        let values = []
        for (let option of searchableValues) {
            if (option) {
                values.push({ label: option, id: i })
                i++;
            }
        }
        this.setState({
            optionsSet: values,
            searchProperty: property
        })
    }

    saveData = () => {
        let data = {};
        data.age = this.state.cause;
        data.gender = this.state.county;
        data.ageBand = this.state.ageBand;
        data.deathsOfAgeGroup = this.state.deathsOfAgeGroup;
        data.populationOfAgeGroup = this.state.populationOfAgeGroup;
        data.totalDeaths = this.state.totalDeaths;
        data.totalDeathsOfAgeGroup = this.state.totalDeathsOfAgeGroup;
        data.totalPopulation = this.state.totalPopulation;
        data.countyCode = this.state.countyCode;
        
        let i =  this.state.dataArray.findIndex((d) => d.id === this.state.selectedId)

        if (this.state.isEditMethod) {
            //    let replaceObject = Object.assign({},this.state.dataArray[this.state.selectedIndex],data);
            let replaceObject = { ...this.state.dataArray[i], ...data };

            let array = this.state.dataArray.map((obj,index) => {
                return i === index  ? replaceObject : obj
            })
            this.setState({
                dataArray: array,
                optionsArray: array
            }, () => this.resetForm())
        } else {
            this.setState({
                dataArray: [...this.state.dataArray, data],
            }, () => this.resetForm())
        }
    }

    editForm = (e) => {
        let i =  this.state.dataArray.findIndex((d) => d.id === e.target.dataset.id)

        this.setState({
            selectedId: this.state.dataArray[i].id,
            isEditMethod: true,
            cause: this.state.dataArray[i].age,
            county: this.state.dataArray[i].gender,
            ageBand: this.state.dataArray[i].ageBand,
            deathsOfAgeGroup: this.state.dataArray[i].deathsOfAgeGroup,
            populationOfAgeGroup: this.state.dataArray[i].populationOfAgeGroup,
            totalDeaths: this.state.dataArray[i].totalDeaths,
            totalDeathsOfAgeGroup: this.state.dataArray[i].totalDeathsOfAgeGroup,
            totalPopulation: this.state.dataArray[i].totalPopulation,
            countyCode: this.state.dataArray[i].countyCode,
        })
    }

    setValue = (value) => {
        console.log(this.state.searchProperty + " " + value)
        const newArray = this.state.dataArray.filter((d) => d[this.state.searchProperty] === value)
        this.setState({optionsArray:newArray})
    }

    async listData() {
        // HealthCausesDataHandler.listAndUpdateOneColumn();
        let METHOD_NAME = ":listData:";
        let fromIndex = 0;
        let toIndex = 25;
        try{
        var array = await HealthCausesDataHandler.listData(fromIndex, toIndex);
        console.log("array: ", array, METHOD_NAME);
        this.setState({ dataArray: array, optionsArray: array });
        }
        catch(e){
            console.log(e)
        }        
    }

    render() {

        return (
            <>
                <Container>
                    Health Causes
                </Container>
                <Container className='border border-dark rounded-lg p-3'>
                    <div className="pr-3">
                        <Row className='m-2'>
                            <span className='mr-2'>
                                Search By:
                            </span>
                            <select 
                                className="dropdown-select"
                                name="searchOption"
                                placeholder='gender'
                                value={this.state.gender}
                                onClick={this.searchByValue}>
                                <option value="cause">Cause</option>
                                <option value="county">County</option>
                                <option value="ageBand">Age Band</option>
                            </select>
                        </Row>
                        <Row className='m-2'>
                            <span className='mr-4'>
                                Value:
                            </span>
                            <AutoComplete onSelect={this.setValue} options={this.state.optionsSet} />
                        </Row>
                        <Row className='flex flex-row-reverse'>
                            <button className='buttonRounded'>Search</button>
                        </Row>
                    </div>
                </Container>
                
                <Container className='border border-dark rounded-lg p-3 mt-2'>
                    <Form>
                        <Row className='m-2 '>
                            <span>cause: <input type='text' size='60'
                                value={this.state.cause} onChange={(e) => (
                                    this.setState({
                                        cause: e.target.value
                                    })
                                )} /> &nbsp;
                        </span>
                            <span>
                                county: <input type='text' size='60'
                                    value={this.state.county} onChange={(e) => (
                                        this.setState({
                                            county: e.target.value
                                        })
                                    )} /> &nbsp;
                        </span>
                        </Row>
                        <Row className='m-2 '>
                            <span>age_band:  <input type='text' size='7'
                                value={this.state.ageBand} onChange={(e) => (
                                    this.setState({
                                        ageBand: e.target.value
                                    })
                                )} /> &nbsp;
                        </span>
                            <span>
                                deaths_of_age_group: <input type='text' size='10'
                                    value={this.state.deathsOfAgeGroup} onChange={(e) => (
                                        this.setState({
                                            deathsOfAgeGroup: e.target.value
                                        })
                                    )} /> &nbsp;
                        </span>
                            <span>
                                population_of_age_group: <input type='text'  size='10'
                                    value={this.state.populationOfAgeGroup} onChange={(e) => (
                                        this.setState({
                                            populationOfAgeGroup: e.target.value
                                        })
                                    )} /> &nbsp;
                        </span>
                            <span>
                                total_deaths: <input type='text'  size='10'
                                    value={this.state.totalDeaths} onChange={(e) => (
                                        this.setState({
                                            totalDeaths: e.target.value
                                        })
                                    )} /> &nbsp;
                        </span>
                        </Row>
                    </Form>
                    <Row className='m-2'>
                        <span>
                            total_deaths_of_age_group: <input type='text'  size='10'
                                value={this.state.totalDeathsOfAgeGroup} onChange={(e) => (
                                    this.setState({
                                        totalDeathsOfAgeGroup: e.target.value
                                    })
                                )} /> &nbsp;
                        </span>
                        <span>
                            total_population: <input type='text'  size='10'
                                value={this.state.totalPopulation} onChange={(e) => (
                                    this.setState({
                                        totalPopulation: e.target.value
                                    })
                                )} /> &nbsp;
                        </span>
                        <span>
                            county_code: <input type='text'  size='10'
                                value={this.state.countyCode} onChange={(e) => (
                                    this.setState({
                                        countyCode: e.target.value
                                    })
                                )} /> &nbsp;
                        </span>
                    </Row>
                    <Row className='flex flex-row-reverse mr-4'>
                        <button type='button' className='buttonRounded' onClick={this.saveData}>
                            Save
                        </button>
                    </Row>
                </Container>

   

                <table className='w-100 mt-5' >
                    <thead>
                        <tr className='border border-dark'>
                            <td>cause</td>
                            <td>county</td>
                            <td>age_band</td>
                            <td>deaths_of_age_group</td>
                            <td>populationOfAgeGroup</td>
                            <td>totalDeaths </td>
                            <td>totalDeaths_of_age_group</td>
                            <td>totalPopulation</td>
                            <td>countyCode</td>
                            <td>Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.optionsArray.map((d, i) => (
                                <tr className='border border-dark' key={d.id}>
                                    <td className='border border-dark'>{d.age}</td>
                                    <td className='border border-dark'>{d.gender}</td>
                                    <td className='border border-dark'>{d.ageBand}</td>
                                    <td className='border border-dark'>{d.deathsOfAgeGroup}</td>
                                    <td className='border border-dark'>{d.populationOfAgeGroup}</td>
                                    <td className='border border-dark'>{d.totalDeaths} </td>
                                    <td className='border border-dark'>{d.totalDeathsOfAgeGroup}</td>
                                    <td className='border border-dark'>{d.totalPopulation}</td>
                                    <td className='border border-dark'>{d.countyCode}</td>
                                    <td className='border border-dark'>
                                        <button className='buttonRounded' onClick={this.editForm} data-id={d.id}>Edit</button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </>
        );
    }
}
