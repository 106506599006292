import React from "react";
import Slider from "rc-slider";
import {parseAddress} from "parse-address";

import ComponentHelper from "./ComponentHelper";
import Utility from "./Utility";
import GlobalVariables from "../GlobalConstantsVariables";

import SVgEditable from "./SVGEditable.img";
const PASSCODESHA2048 = '$W%Ld0os$3';
const CLASS_NAME = ":LifeInsuranceDetails:";
const indexToCoverage = [0, 100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000, 1500000, 2000000] //map coverage values to slider

export default class LifeInsuranceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            calculatedCoverage: this.props.calculatedCoverage,
            age: this.props.age,
            birthYear: this.props.birthYear,
            gender: this.props.gender,
            smoker: this.props.smoker,
            clientId: this.props.clientId,
            termDesired: this.props.termDesired,
            hazardScore: "Great",
            exerciseAccess: "Good",
            foodEnvironment: "Very high",
            familyUnity: "Average",
            medianIncome: "120 K"
        }
        this.pushDataInDBAndCache = this.pushDataInDBAndCache.bind(this);
    }

    getLifeScore = async() =>{
  //      let get1= '1109209134190200080178226153161152208', get2 = '1101169', get3 = '1087140082128153', get4='1084';
    let get1, get2, get3, get4;

    let { state, city } = parseAddress(this.props.address);
    let ageLower = 5 * parseInt(this.props.age/5);
    get3 = 1 + ComponentHelper.encryptCodes(`${ageLower}-${ageLower + 5}`, PASSCODESHA2048)//,,'ageRange' , METHOD_NAME);
    get2 = 1 + ComponentHelper.encryptCodes(state, PASSCODESHA2048)//,  ,'state' , METHOD_NAME);
    get4 = 1 + ComponentHelper.encryptCodes(this.props.gender, PASSCODESHA2048)//,  ,'gender' , METHOD_NAME);
    get1 = 1 + ComponentHelper.encryptCodes(city, PASSCODESHA2048)//, get4 ,'gender' , METHOD_NAME);
        let url = `https://api2.getmybubble.io/fetch1?data_category=LifeScore&version=v6&get1=${get1}&get2=${get2}&get3=${get3}&get4=${get4}`;
        try{
        let resposne = await fetch(url);
        let body = await resposne.json();
        const { put1 } = JSON.parse(body.output);
        let docketIdStr = ComponentHelper.getDecryptedData(put1.substring(1), PASSCODESHA2048);
        this.props.onChangeItem('lifeScore', parseInt(docketIdStr));
        }catch(e){
            console.log(e);
        }
    }

    componentDidMount(){
        let counter = 0;
         if(!this.props.lifeScore)
         this.getLifeScore();

        this.endTimer = setInterval(() => {  //calculatedCoverage is not recieved immidiately
            if (this.state.calculatedCoverage > 0 || counter > 10)  //counter to prevent infinite loop if calculatedCoverage is not present  
                clearInterval(this.endTimer)
            counter++;
            let index = indexToCoverage.indexOf(this.state.calculatedCoverage);
            this.setState({ index })
        }, 1000)
    }

    pushDataInDBAndCache(inputJson) {
        let METHOD_NAME = ":pushDataInDBAndCache:";
        let stateJson = {};
        let key, value;
        key = inputJson.key; value = inputJson.value;
        delete inputJson.key; delete inputJson.value;
        let localState = this.state;
        localState[key] = value;
        // UI Specific parameters to cache
        stateJson.age = localState.age;
        stateJson.ageBand = localState.ageBand;
        stateJson.birthYear = localState.birthYear;
        stateJson.gender = localState.gender;
        stateJson.health = localState.health;
        stateJson.smoker = localState.smoker;
        stateJson.termDesired = localState.termDesired;
        // stateJson. = this.state.
        stateJson.clientId = this.state.clientId;
        stateJson.id = this.state.clientId;
        stateJson.cacheId = this.state.clientId + "++All";
        stateJson.eventStr = CLASS_NAME.replace(/:/g, '') + 'Update';
        stateJson[key] = value;
        if (stateJson.birthYear != null && stateJson.age == null) {
            stateJson.age = Utility.getAgeFromBirthYear(stateJson.birthYear);
            this.setState({ age: stateJson.age });
        }
        console.log("%$% stateJson:", stateJson, METHOD_NAME, CLASS_NAME);
        try {
            ComponentHelper.updateAppDataAndCache(GlobalVariables.getClientDataCategoryAll(), stateJson);
        } catch (error) {
            METHOD_NAME += ":updateAppDataAndCache:Failed:";
            console.log("%$% error.code: ", error.code, " # stateJson:", stateJson, METHOD_NAME, CLASS_NAME);
            if (error.code === 'ConditionalCheckFailedException') {
                ComponentHelper.saveAppDataAndCache(GlobalVariables.getClientDataCategoryAll(), stateJson);
            } else {
                console.log("%$% PROBLEM PROBLEM Need to handle this code", METHOD_NAME, CLASS_NAME);
            }
        }
        GlobalVariables.setAPIInputJson(GlobalVariables.getPacLifeNextAPITag(), inputJson);
        // ComponentHelper.updateAppDataAndCache("IndividualUpdates", json4DyanoDB); sdsfsdf
        if (this.props.onChangeAmountsFromHash != null) {
            this.refreshDoughnut(key, value);
        }

    }

    refreshDoughnut(changedInputParamName, inputValue) {
        let METHOD_NAME = `:refreshDoughnut:${changedInputParamName}:`;
        let paramName, key, calculatedCoverageLocal;
        console.log("%$% Input:changedInputParamName:", changedInputParamName, "# inputValue:", inputValue, METHOD_NAME, CLASS_NAME);
        console.log("%$% listingPriceAmount:", this.props.listingPriceAmount, METHOD_NAME, CLASS_NAME);
        /*
        Life Insurance field received:
         1. re-calculate life insurance 
         2. Update AppData
        */
        let inputKeyLocal = changedInputParamName;
        if (GlobalVariables.getLifeInsuranceFieldsArray().indexOf(inputKeyLocal) > -1) {
            if (inputKeyLocal === 'calculatedCoverage') {
                // if (true) {
                METHOD_NAME += ":calculatedCoverage:inputValue:" + inputValue;
                calculatedCoverageLocal = inputValue != null && inputValue.toString().length < 4 ? `${inputValue}000` : inputValue;
                calculatedCoverageLocal = typeof calculatedCoverageLocal === 'string' ? Number(calculatedCoverageLocal) : calculatedCoverageLocal;
                calculatedCoverageLocal = Math.round((calculatedCoverageLocal/100000))*100000;

                //DATA_RELEASE_VERSION__stateCode__gender__health4LifeInsurance__termDesired__smoker__age__coverageAmount
                // 'data_category': 'app_constant', 'Poor': 3, 'Average': 2, 'Good': 1, 'Excellent': 0
                // IN____________600000
                // v2__stateCode__1__1__20__0__35__coverageAmount
                console.log("%$% this.props.stateCode:", this.props.stateCode, " # key: ", key, " # calculatedCoverageLocal: ", calculatedCoverageLocal, METHOD_NAME, CLASS_NAME);
                let genderLocal = this.state.gender && this.state.gender !== -1 ? this.state.gender : 1;
                let healthLocal = this.state.health && this.state.health !== -1 ? this.state.health : 2;
                switch (healthLocal) {
                    case 0: healthLocal = 3; break;
                    case 1: healthLocal = 2; break;
                    case 2: healthLocal = 1; break;
                    case 3: healthLocal = 0; break;
                    default: healthLocal = 2; break;
                }
                let termDesiredLocal = this.state.termDesired != null ? this.state.termDesired : 20;
                let smokerLocal = this.state.smoker != null && this.state.smoker !== -1 ? this.state.smoker : 1;
                let ageLocal = this.state.birthYear != null && this.state.birthYear !== -1 ? this.state.birthYear : 1990;
                ageLocal = Utility.getAgeFromBirthYear(this.state.birthYear);
                console.log("ageLocal:", ageLocal, METHOD_NAME, CLASS_NAME);
                ageLocal = ageLocal != null && ageLocal.toString().length > 0 ? ageLocal : 30;

                const idForLifeInsuranceAmount = `${GlobalVariables.getDataReleaseVersion()}__${this.props.stateCode}__${genderLocal}__${healthLocal}__${termDesiredLocal}__${smokerLocal}__${ageLocal}__${calculatedCoverageLocal}`;
                // make sure proper address is keyed and is saved in DB to fetch lifeInsuranceAmount
                ComponentHelper.getData(idForLifeInsuranceAmount).then((array) => {
                    METHOD_NAME += ":getData:than:"
                    if (array != null && array.length > 0) {
                        console.log("%$% array: ", array, " #idForLifeInsuranceAmount: ", idForLifeInsuranceAmount, METHOD_NAME, CLASS_NAME);

                        let lifeInsuranceAmount;
                        lifeInsuranceAmount = array[0].lifeInsuranceAmount != null ? array[0].lifeInsuranceAmount : array[0].premiumAmountPerMonth;
                        let inputJson = { 'id': this.props.id, 'lifeInsuranceAmount': lifeInsuranceAmount, 'calculatedCoverage': calculatedCoverageLocal };

                        if (inputKeyLocal === 'birthYear') {
                            inputJson['age'] = ageLocal;
                            inputJson['ageBand'] = Utility.getFormattedAge(inputValue);
                        } else {
                            inputJson[inputKeyLocal] = inputValue;
                        }
                        this.props.onChangeAmountsFromHash('IndividualDetails', inputJson);
                        console.log("%$% lifeInsuranceAmount:", lifeInsuranceAmount, METHOD_NAME, CLASS_NAME);
                        this.setState({ 'lifeInsuranceAmount': lifeInsuranceAmount, 'calculatedCoverage': calculatedCoverageLocal });
                    }
                });
            } else {
                if (this.props.listingPriceAmount == null) {
                    key = `${this.props.id}++calculatedCoverage`;
                    ComponentHelper.getCachedData2(key).then((data) => {
                        METHOD_NAME += ":calculatedCoverage:getCachedData2.then:" + paramName;
                        console.log("%$% data: ", data, METHOD_NAME, CLASS_NAME);
                        let json = JSON.parse(data.Payload);
                        calculatedCoverageLocal = json.body.output;
                        this.setState({ coverage: calculatedCoverageLocal, calculatedCoverage: calculatedCoverageLocal });
                        //DATA_RELEASE_VERSION__stateCode__gender__health4LifeInsurance__termDesired__smoker__age__coverageAmount
                        // IN____________600000
                        // v2__stateCode__1__1__20__0__35__coverageAmount
                        console.log("%$% this.props.stateCode:", this.props.stateCode, " # key: ", key, " # calculatedCoverageLocal: ", calculatedCoverageLocal, METHOD_NAME, CLASS_NAME);
                        this.processLifeInsuranceAmount(calculatedCoverageLocal);
                    });

                } else {
                    // calculate coverageAmount from listingPrice if available
                    let cachedValue1 = Utility.calculateCoverage(this.props.listingPriceAmount);
                    this.setState({ 'calculatedCoverage': cachedValue1 });
                    this.props.onChangeItem('calculatedCoverage', cachedValue1);
                    console.log('%$% calculatedCoverage: ', cachedValue1, METHOD_NAME, CLASS_NAME);
                    //'getQuotesFromPacLifeNext': ["DONE:solicitationState", "DONE:faceAmount", "riskClass", "gender", "premiumFrequency", "dateOfBirth", "productCode"
                    GlobalVariables.setAPIInputJson(GlobalVariables.getPacLifeNextAPITag(), { 'calculatedCoverage': cachedValue1 });
                    this.processLifeInsuranceAmount(inputKeyLocal, inputValue, cachedValue1);
                }
            }
        }
    }

    processLifeInsuranceAmount(inputKeyLocal, inputValue, calculatedCoverageAmount) {
        let METHOD_NAME = ":processLifeInsuranceAmount:";
        let genderLocal = this.state.gender != null && this.state.gender !== -1 ? this.state.gender : 1;
        let healthLocal = this.state.health != null && this.state.health !== -1 ? this.state.health : 2;
        switch (healthLocal) {
            case 0: healthLocal = 3; break;
            case 1: healthLocal = 2; break;
            case 2: healthLocal = 1; break;
            case 3: healthLocal = 0; break;
            default: healthLocal = 2; break;
        }
        let termDesiredLocal = this.state.termDesired != null ? this.state.termDesired : 20;
        let smokerLocal = this.state.smoker != null && this.state.smoker !== -1 ? this.state.smoker : 1;
        let ageLocal = this.state.birthYear != null && this.state.birthYear !== -1 ? this.state.birthYear : 1990;
        ageLocal = Utility.getAgeFromBirthYear(this.state.birthYear);
        console.log("ageLocal:", ageLocal, METHOD_NAME, CLASS_NAME);
        ageLocal = ageLocal != null && ageLocal.toString().length > 0 ? ageLocal : 30;

        const idForLifeInsuranceAmount = `${GlobalVariables.getDataReleaseVersion()}__${this.props.stateCode}__${genderLocal}__${healthLocal}__${termDesiredLocal}__${smokerLocal}__${ageLocal}__${calculatedCoverageAmount}`;
        // make sure proper address is keyed and is saved in DB to fetch lifeInsuranceAmount
        ComponentHelper.getData(idForLifeInsuranceAmount).then((array) => {
            METHOD_NAME += ":getData:than:"
            if (array != null && array.length > 0) {
                console.log("array: ", array, METHOD_NAME, CLASS_NAME);
                let lifeInsuranceAmount;
                lifeInsuranceAmount = array[0].lifeInsuranceAmount != null ? array[0].lifeInsuranceAmount : array[0].premiumAmountPerMonth;
                let inputJson = { 'id': this.props.id, 'lifeInsuranceAmount': lifeInsuranceAmount, 'calculatedCoverage': calculatedCoverageAmount };

                if (inputKeyLocal === 'birthYear') {
                    inputJson['age'] = ageLocal;
                    inputJson['ageBand'] = Utility.getFormattedAge(inputValue);
                } else {
                    inputJson[inputKeyLocal] = inputValue;
                }
                this.props.onChangeAmountsFromHash('IndividualDetails', inputJson);
                console.log("lifeInsuranceAmount:", lifeInsuranceAmount, METHOD_NAME, CLASS_NAME);
                this.setState({ 'lifeInsuranceAmount': lifeInsuranceAmount, 'calculatedCoverage': calculatedCoverageAmount });
            }
        });
    }

    onChangeTermDesired = (value) => {
        const key = 'termDesired';
        this.setState({ [key]: value });
        this.props.onChangeItem(key, value);
    }

    onAfterChangeTermDesired = (inputValue) => {
        let METHOD_NAME = ":onAfterChangeTermDesired:";
        console.log("Entered:: ", inputValue, METHOD_NAME, CLASS_NAME);
        let stateJson = { 'key': 'termDesired', 'value': inputValue, 'termDesired': inputValue };
        this.pushDataInDBAndCache(stateJson);
        const hash = ComponentHelper.getLocalCacheFor(this.state.id);
        hash['id'] = this.state.id;
        hash['termDesired'] = inputValue;
        this.props.onChangeAmountsFromHash('StepDougnutChart', hash);
    }

    onSliderChange = (index) => {
        let coverage = indexToCoverage[index];
        this.setState({ index })
        this.onChangeCoverage(coverage)
    }

    onSliderAfterChange = (index) => {
        let coverage = indexToCoverage[index];
        this.onAfterChangeCoverage(coverage)
    }
    onChangeCoverage(value) {
        const key = 'calculatedCoverage';
        // console.log("onChangeCoverage:key:", key, " #value: ", value);
        this.setState({ [key]: value });
        this.props.onChangeItem(key, value);
    }
    onAfterChangeCoverage = (inputValue) => {
        let METHOD_NAME = ":onAfterChangeCoverage:";
        console.log("Entered:calculatedCoverage:value ", inputValue, METHOD_NAME, CLASS_NAME);
        this.pushDataInDBAndCache({ 'value': inputValue, 'key': 'calculatedCoverage', 'calculatedCoverage': inputValue });
        let stateJson = { 'key': 'calculatedCoverage', 'value': inputValue, 'calculatedCoverage': inputValue };
        this.pushDataInDBAndCache(stateJson);
        const hash = ComponentHelper.getLocalCacheFor(this.state.id);
        // console.log("BEFORE this.stateeeeeeeee:", this.state, METHOD_NAME, CLASS_NAME);
        // console.log("BEFORE hashhashhash stateeeeeeeee:", hash, METHOD_NAME, CLASS_NAME);
        this.setState(hash);
        hash['id'] = this.state.id;
        hash['calculatedCoverage'] = inputValue;
        this.props.onChangeAmountsFromHash('StepDougnutChart', hash);
    }

    render() {
        const termMarks = {
            10: { style: { color: '#7f6289', }, label: <span>10</span>, },
            15: { style: { color: '#7f6289', }, label: <span>15</span>, },
            20: { style: { color: '#7f6289', }, label: <span>20</span>, },
            25: { style: { color: '#7f6289', }, label: <span>25</span>, },
            30: { style: { color: '#7f6289', }, label: <span>30</span>, },
        }; 
        const coverageMarks = {
            1: { style: { color: '#000', fontSize: "10px" }, label: <span>$100K</span>, },
            12: { style: { color: '#000', fontSize: "10px" }, label: <span>$2M</span>, },
        }
        return (
        <div className="life-insurance">
            <div className="d-flex scores">
                <div className="hazard">
                    <span className="info">
                        {this.props.fullAddress.substring(0,20)}
                    </span>
               <div className="d-flex">
                    <div className="svg-edit mt-2">
                            <SVgEditable score="A+" color="00a12f" endAngle="325"/>
                    </div>

                    <div className="d-flex flex-column">
                        <span className="label">Life Score</span>
                        <span  className="final-score">{this.state.hazardScore}</span>
                        <span> {this.props.lifeScore}/900 </span>
                    </div>
               </div>                
                </div>
                
                <div className=" d-none details">
                    <span className="info">
                        Life Score Details
                    </span>
                    <div className="">d-flex flex-row scores-row
                        <div className="d-flex flex-column foodenv">
                            <span className="label">Food Environment...</span>
                            <span>{this.state.foodEnvironment}</span>
                        </div>
                        <div className="d-flex flex-column famunity">
                            <span className="label">Family Unity</span>
                            <span>{this.state.familyUnity}</span>
                        </div>
                        <div className="d-flex flex-column exer">
                            <span className="label">Access to exerc...</span>
                            <span>{this.state.exerciseAccess}</span>
                        </div>
                        <div className="d-flex flex-column medincome">
                            <span className="label">Median Income</span>
                            <span>$ {this.state.medianIncome}</span>
                        </div>
                    </div>
                </div>

            </div>

            <div className="d-flex flex-column fs-slider-block">
                <div className="d-flex flex-row justify-content-between">
                    <span className="slider-label">
                        Life Coverage
                    </span>
                    <div className="label">
                        ${Utility.getFormattedNumberValue(this.props.calculatedCoverage)}  &nbsp;
                    </div>
                </div>
                <div className="slider-aligned">
                    <Slider
                        onAfterChange={this.onSliderAfterChange}
                        onChange={this.onSliderChange}
                        value={this.state.index}
                        marks={coverageMarks}
                        min={1}
                        max={12}
                        step={1}
                    />
                </div>
            </div>
            <div className="d-flex flex-column fs-slider-block">
                <div className="d-flex flex-row justify-content-between">
                    <span className="slider-label">
                        Term Desired
                    </span>
                    <div className="label">
                        {this.props.termDesired} Years
                    </div>
                </div>
                <div className="slider-aligned">
                    <Slider
                        onAfterChange={this.onAfterChangeTermDesired}
                        onChange={this.onChangeTermDesired}
                        value={this.props.termDesired}
                        marks={termMarks}
                        min={10}
                        max={30}
                        step={5}
                    />
                </div>
            </div>

        </div>);
    }
}