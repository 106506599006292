import React, { useRef, useState } from "react";
import S3 from "react-aws-s3";

const CLASS_NAME = ":Upload2S3:";
//This component has dependency on root folder: .env file
// function Upload() {
const Upload = React.forwardRef((props, ref) => {

    let METHOD_NAME = ":Upload:";
    const fileInput = useRef();

    const [fileName, changeFileName] = useState(null);
    const [fileUploadStatus, changeFileUploadStatus] = useState(null);

    const imageSelected = e => {
        console.log(e)

        let file = fileInput.current.files[0];

        if(!file){
            return;
        }

        if(file['type'].indexOf('image') === -1){
            fileInput.current.files = null;
            console.log("Image Not Selected")
            return;
        }

        changeFileName(file['name']);

        console.log(file);
      //  console.log((file['size'] /1024).toFixed(2) , " kB")
        props.onFileSelected(file['name'])
    }

    const handleClick = (event) => {
        console.log(fileInput)
        METHOD_NAME += ":handleClick:";
        console.log("Entered", METHOD_NAME, CLASS_NAME);

        event.preventDefault();

        let file = fileInput.current.files[0];

        if (!file) {
            return;
        }

        changeFileUploadStatus("uploading")
        let uploadedFileURL;
        let fileName = fileInput.current.files[0].name;
        console.log("fileName: ", fileName);
        let newFileName = fileInput.current.files[0].name.replace(/\..+$/, "");
        newFileName = `${newFileName}-${props.id}.${fileName.split('.')[1]}`;
        console.log("New name: newFileName: ", newFileName);
        const config = {
            bucketName: process.env.REACT_APP_BUCKET_NAME,
            dirName: process.env.REACT_APP_DIR_NAME /* optional */,
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_ACCESS_KEY
        };
        console.log("config: ", config, METHOD_NAME, CLASS_NAME);

        const ReactS3Client = new S3(config);
        ReactS3Client.uploadFile(file, newFileName).then((data) => {
            METHOD_NAME += ":uploadFile.then:";
            console.log("data: ", data, METHOD_NAME, CLASS_NAME);
            if (data.status === 204) {
                console.log("success");
                uploadedFileURL = `${data['location']}`
                console.log(`public File name: ${uploadedFileURL}`);
                if (props.isPropsPassed) 
                { 
                    props.onUploadFileCompleted(uploadedFileURL); 
                    changeFileUploadStatus("done")
                };
                
            } else {
                console.log("fail");
            }
        });
        console.log("After uploadFile", METHOD_NAME, CLASS_NAME);
    };
    return (
        <>
            <form onSubmit={handleClick}>
                <div className="d-flex flex-row justify-content-center mt-2">
                    <span className="mr-2">{props.selectedFileName ? 'Image Selected' : 'Agent Portrait:'}</span>
                    <label className="custom-file">
                        {props.selectedFileName ? `${fileName}` : 'Choose file'}
                        <input type='file'  accept=".png, .jpg, .jpeg" onChange={imageSelected} ref={fileInput} name="agentImage" />
                    </label>
                    <button type='submit' className='buttonRounded ml-2'>Upload</button>
                </div>
                {fileUploadStatus &&
                    <div className="h6 font-weight-bold">
                        File name: {props.selectedFileName} {fileUploadStatus === "done" ? "Upload Succesful!" : "Upload in progress!"}
                    </div>
                }
            </form>
        </>
    );
});

export default Upload;