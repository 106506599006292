import React from "react";
import Address from "./address";
import AddressDetails from "./addressdetails";
import Charts from "./charts";
import ComponentHelper from "./ComponentHelper";
import "bootstrap/dist/css/bootstrap.css";
import "../App.scss";
import axios from 'axios';
import awsExports from "./../aws-exports";
import { Amplify } from '@aws-amplify/core';
import { API, graphqlOperation } from '@aws-amplify/api';
import { updateAppData } from '../graphql/mutations';

Amplify.configure(awsExports);

const AWS_API_GATEWAY = 'https://qaubc7vbg1.execute-api.us-west-2.amazonaws.com';		 //eslint-disable-line
const DOMAIN_NAME = "https://alpha.getmybubble.io";										  //eslint-disable-line
const PROXY_URL = "https://cors-anywhere.herokuapp.com/";									 //eslint-disable-line
let dbIdLocal = `session-${new Date().getTime()}-${Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000}`;

const CLASS_NAME = ":MainComponent";
let mainComponentFullAddress = null;
let mainComponentChartData = {};

let mainComponentCallBack1 = function (error, response) {		 //eslint-disable-line
	let METHOD_NAME = "mainComponentCallBack1:";
	if (error) {
		console.log(CLASS_NAME, METHOD_NAME, "error:", error); // an error occurred
		// Using this keyword to access AWS.Response object and properties
		console.log(METHOD_NAME, "Response data and headers: ", JSON.stringify(this.httpResponse));
	} else {
		if (response != null && response.Items != null && response.Items[0] != null) {
			const json = response.Items[0];
			console.log("json: ", json, METHOD_NAME, CLASS_NAME);
			Object.entries(json).forEach(([key, value]) => {
				mainComponentChartData[key] = value;
				// console.log("key:", key, "value: ", value, METHOD_NAME, CLASS_NAME);
			});
			// this.setState({
			// 	whatIfScenarioDataArray: chartDataArray,
			// });}
		}
	}
}
function getFormattedAge(birthYear) {
	var birth = new Date(birthYear);
	let check = new Date();
	let milliDay = 1000 * 60 * 60 * 24;
	let ageInDays = (check - birth) / milliDay;
	let ageInYears = Math.floor(ageInDays / 365);
	let roundedAge = Math.round(ageInYears / 10) * 10;
	let ageRange = `${roundedAge} - ${roundedAge + 5}`;
	return ageRange;
}
let domain;
export default class MainComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			address: true,
			addressdetails: false,
			Charts: false,
			insurance: 0,
			fullAddress: '',
			whatIfScenarioDataArray: [],
			dbId: '',
			CoverageDesired: '',
			Termdesired: '',
			PrimaryResidence: '',
			Gender: '',
			Age: '',
			Health: '',
			Smoker: '',
			isLoading: false,
			propertyTaxAmount: 0,
			mortgageAmount: 0,
			homeInsuranceAmount: 'Loading...',
			utilityAmount: 0,
			isFullAddress: false,
			skippedStep1: false
		};

		this.updateAddressView = this.updateAddressView.bind(this);
		this.updateAddressDetailView = this.updateAddressDetailView.bind(this);
		this.returnAddressDetailsView = this.returnAddressDetailsView.bind(this);
		this.returnChatsView = this.returnChatsView.bind(this);
		this.onChangeDataArray = this.onChangeDataArray.bind(this);
		this.onInputsChangeAge = this.onInputsChangeAge.bind(this);

		let METHOD_NAME = ":constructor";
		console.log("this.props.search: ", this.props.location.search, METHOD_NAME, CLASS_NAME);
		domain = `${window.location.href.split('/')[0]}//${window.location.href.split('/')[2]}`
		const cipher = window.location.href.split('/')[2];
		console.log("cipher: ", cipher, " # domain: ", domain, METHOD_NAME, CLASS_NAME);

		let queryParamsHash = ComponentHelper.parseQueryParameters(this.props.location.search, cipher);
		console.log("mainComponentFullAddress: ", mainComponentFullAddress, " # queryParamsHash.fullAddress: ", queryParamsHash.fullAddress, " # queryParamsHash: ", queryParamsHash, METHOD_NAME, CLASS_NAME);
		let fullAddress;
		if (mainComponentFullAddress == null && (fullAddress = queryParamsHash.fullAddress) != null &&
			fullAddress.trim().length > 0) {
			this.setState({
				fullAddress: fullAddress
			});
			mainComponentFullAddress = queryParamsHash.fullAddress;
			ComponentHelper.saveDataAndCalculateAmounts(fullAddress, dbIdLocal, queryParamsHash.extension).then((idFromDynamoDB) => {
				dbIdLocal = idFromDynamoDB;
				console.log("idFromDynamoDB: ", idFromDynamoDB, METHOD_NAME, CLASS_NAME);
				this.setState({
					address: false, addressdetails: true, activeView: true, dbId: idFromDynamoDB, skippedStep1: true
				});
			});
		} else {
			console.log('this.state.fullAddress != null:', this.state.fullAddress, METHOD_NAME, CLASS_NAME);
		}
	}

	updateAddressView = (e) => {
		// const searchParams = new URLSearchParams();
		// searchParams.set('fullAddress', e.target.firstChild.innerHTML);
		// this.props.history.push(`?${searchParams.toString()}`);

		let METHOD_NAME = ":updateAddressView";
		console.log("Entered", METHOD_NAME, CLASS_NAME);
		// Make sure we invoke create-db-record only once
		let fullAddress = 'dddd';//This intialization is intentional to know what's going wrong
		// Making sure saving data in db when valid fullAddress exists
		if (mainComponentFullAddress == null && (fullAddress = e.target.firstChild.innerHTML) != null &&
			fullAddress.trim().length > 0) {
			mainComponentFullAddress = fullAddress;
			const extensionLocal = '0';
			ComponentHelper.saveDataAndCalculateAmounts(fullAddress, dbIdLocal, extensionLocal).then((idFromDynamoDB) => {
				console.log("idFromDynamoDB: ", idFromDynamoDB, METHOD_NAME, CLASS_NAME);
				this.setState({
					address: false, addressdetails: true, activeView: true, dbId: idFromDynamoDB, skippedStep1: false
				});
				dbIdLocal = idFromDynamoDB;
				mainComponentFullAddress = fullAddress;
			});
		} else {
			console.log('this.state.fullAddress != null:', this.state.fullAddress, METHOD_NAME, CLASS_NAME);
		}
		e.preventDefault();
		e.stopPropagation();
	}

	getLifeInsuranceScore = async () => {
		let ageRange = getFormattedAge(this.state.Age);
		let METHOD_NAME = "getLifeInsuranceScore:";
		this.setState({ isLoading: true });
		let queryParameterArray = [];
		let searchResults = null;				 //eslint-disable-line
		let coverage = this.state.CoverageDesired.toString().length === 3 ? this.state.CoverageDesired.toString() + '000' : this.state.CoverageDesired.toString();
		queryParameterArray.push(coverage);
		queryParameterArray.push(ageRange);
		queryParameterArray.push(this.state.Termdesired);
		queryParameterArray.push(this.state.Gender === 'O' ? 'M' : this.state.Gender);
		queryParameterArray.push(this.state.Health);
		console.log(CLASS_NAME, METHOD_NAME, "health:", this.state.Health);
		console.log(CLASS_NAME, METHOD_NAME, "gender:", this.state.Gender);
		let response;							 //eslint-disable-line
		let queryString;

		/*const searchParams = new URLSearchParams();
		searchParams.set('query', queryParameterArray.join("_"));
		this.props.history.push(`?${searchParams.toString()}`);*/

		queryString = queryParameterArray.join("__");
		console.log("queryString: ", queryString, CLASS_NAME, METHOD_NAME);
		// Patch avmAmount = 0 Issue
		// queryString = "300000__40 - 45__25__M__super_healthy";
		// 300000__40 - 45__25__M__super_healthy coverage	age	term	gender	super_healthy
		// 350000__31%20-%2035__35__M__healthy
		// 350000__31%20-%2035__20____super_healthy

		if (queryString.indexOf("--") === -1) {
			let url = `${DOMAIN_NAME}/bubbleLifeInsurance/search/${queryString}`;
			response = await axios.get(url, {
				headers: {
					accept: "application/json",
					apikey: "b0ea821699eaeb01ebea45dc96a547e8",
				},
			}).then((response) => {
				METHOD_NAME += 'then:';
				console.log(CLASS_NAME, METHOD_NAME, "response: ", JSON.stringify(response, null, " "));
				console.log(response)
				searchResults = response.data.map((i) => (
					this.setState({
						insurance: i.avg_output
					})
				));
			}, (error) => {
				console.log(CLASS_NAME, METHOD_NAME, "error:", error);
			});
		} else {
			console.log("bubbleLifeInsurance/search NOT invoked");
		}
		console.log("ageRange: ", ageRange, METHOD_NAME, CLASS_NAME);
		let json = {
			id: dbIdLocal,
			fullAddress: this.state.fullAddress,
			coverage: this.state.CoverageDesired,
			termDesired: this.state.Termdesired,
			primaryResidence: this.state.PrimaryResidence,
			gender: this.state.Gender,
			age: ageRange,
			health: this.state.Health,
			smoker: this.state.Smoker,
			eventStr: "AddressDetailsFilledIn"
		};
		console.log("json: ", json, METHOD_NAME, CLASS_NAME);
		let chartDataArray = [];
		API.graphql(graphqlOperation(updateAppData, { input: json })).then((updateDataResponse) => {
			METHOD_NAME = "updateAppData.then:";
			console.log("response2: ", JSON.stringify(updateDataResponse, null, " "), METHOD_NAME, CLASS_NAME);
			let updatedJson;
			if (updateDataResponse != null && updateDataResponse.data != null && (updatedJson = updateDataResponse.data.updateAppData) != null) {
				console.log("updatedJson: ", updatedJson, METHOD_NAME, CLASS_NAME);
				Object.entries(updatedJson).forEach(([key, value]) => {
					if (key === 'lifeInsuranceAmount' && value == null) {
						chartDataArray.push([key, 0]);
					} else {
						chartDataArray.push([key, value]);
					}
				});
				chartDataArray.push(['lifeInsurance', this.state.insurance])
				this.setState({
					whatIfScenarioDataArray: chartDataArray
				});
				console.log("whatIfScenarioDataArray:", this.state.whatIfScenarioDataArray)
			}
		});
	}

	//updateInputValue = e => this.setState({ inputValue: e.target.value });

	updateAddressDetailView = (e) => {
		const METHOD_NAME = ":updateAddressDetailView";
		console.log("Entered", METHOD_NAME, CLASS_NAME);
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			addressdetails: false,
			charts: true,
			fullAddress: mainComponentFullAddress
		});
		this.getLifeInsuranceScore();
	};

	onChangeDataArray = async (newName) => {
		let METHOD_NAME = ":onChangeDataArray:";
		console.log("newName:", newName, METHOD_NAME, CLASS_NAME);
		if (dbIdLocal != null && !dbIdLocal.startsWith('session')) {
			ComponentHelper.getData(dbIdLocal).then((array) => {
				console.log("array: ", array, METHOD_NAME, CLASS_NAME);
				this.setState({
					whatIfScenarioDataArray: array
				});
				this.setState({
					CoverageDesired: (Math.round(array[0].calculatedCoverage / 100000) * 100000),
					coverageDesired: (Math.round(array[0].calculatedCoverage / 100000) * 100000)

				});
			});
		}
	}

	updateChartsView = (val) => {

		this.setState({
			addressdetails: false,
			charts: true,
			insurance: val,
		});
	};

	returnAddressDetailsView = () => {
		this.setState({
			address: true,
			addressdetails: false,
		});
	};

	returnChatsView = () => {
		this.setState({
			addressdetails: true,
			charts: false,
		});
	};

	setParams = (query) => {
		const searchParams = new URLSearchParams();
		searchParams.set("query", query);
		return searchParams.toString();
	}

	onInputsChange = (e) => {
		const { target: { name, value } } = e;
		this.setState({ [name]: value })
	}

	onInputsChangeAge = (e) => {
		this.setState({ Age: e.target.value })
	}

	getParams = (params) => {
		const searchParams = new URLSearchParams(params);
		return {
			query: searchParams.get('fullAddress') || '',
		};
	}

	componentWillMount() {
		/*this.unlisten = this.props.history.listen((location, action) => {
			console.log(this.props.match.params)
			let urlParams = this.getParams(location.search);
			let splitParams = urlParams.query.split('_');
			this.setState({
				CoverageDesired: splitParams[0]
			});
		});*/
		let urlParams = this.getParams(this.props.location.search);
		let splitParams = urlParams.query.split('_');
		if (splitParams[0].length > 1) {
			this.setState({
				//isFullAddress: false,
				address: false,
				addressdetails: true,
				fullAddress: splitParams[0]
			});
			mainComponentFullAddress = splitParams[0];
		}
	}
	/*componentWillUnmount() {
		this.unlisten();
	}*/

	componentWillReceiveProps(nextProps) {
		let METHOD_NAME = ":componentWillReceiveProps:";
		if (nextProps.query !== this.props.query) {
			this.setState({ query: nextProps.query });
			let fullAddress;

			const cipher = window.location.href.split('/')[2];
			console.log("cipher: ", cipher, " # domain: ", domain, METHOD_NAME, CLASS_NAME);
	
			let queryParamsHash = ComponentHelper.parseQueryParameters(this.props.location.search, cipher);				fullAddress = queryParamsHash.fullAddress?.trim() || 'fffffff';
			mainComponentFullAddress = fullAddress;
			// /////////// Method logic ///////////
			// Check for fullAddress param availability
			// Assume address is formatted in google api-response's style (Iteration 1)
			// invoke google API to get the formatted address (Iteration 2)
			// After Iteration 2 -> Address not valid -> address change the <address component updateView=true => SHOW IT
			// invoke compoHelper.saveAndPopulateData(fullAddress);
			// If valid address change the <address component updateView=false => HIDE IT and addressDetail=true => SHOW IT
			// /////////// Method logic ends ///////////
			//ComponentHelper.saveDataAndCalculateAmounts(fullAddress);

			//return this.searchRepositories(nextProps.query);
		}
	}
	onChangeItem = async (name, value) => {
		let METHOD_NAME = ":onChangeItem:";
		console.log("name:", name, " # value: ", value, METHOD_NAME, CLASS_NAME);
		this.setState({ [name]: value });
	}
	render() {
		return (
			<React.Fragment>
				{/*{(() => {
					if (isFullAddress) {
						return <Address
							updateView={this.updateAddressView}
							activeView={this.state.address}
						/>
					} else {
						return <AddressDetails
							updateView={this.updateAddressDetailView}
							activeView={this.state.addressdetails}
							returnView={this.returnAddressDetailsView}
							fullAddress={mainComponentFullAddress}
							sessionId={dbIdLocal}
							id={this.state.dbId}
							value={this.state}
							addressDetailsUpdate={this.onInputsChange}
							addressDetailsUpdateAge={this.onInputsChangeAge}
						/>
					}
				})()}*/}
				<Address
					updateView={this.updateAddressView}
					activeView={this.state.address}
				/>
				<AddressDetails
					updateView={this.updateAddressDetailView}
					activeView={this.state.addressdetails}
					returnView={this.returnAddressDetailsView}
					fullAddress={mainComponentFullAddress}
					sessionId={dbIdLocal}
					id={this.state.dbId}
					value={this.state}
					addressDetailsUpdate={this.onInputsChange}
					addressDetailsUpdateAge={this.onInputsChangeAge}
					refreshDataArrayLink={this.onChangeDataArray}
					skippedStep1={this.state.skippedStep1}
					onChangeItem={this.onChangeItem}

				/>
				<Charts
					updateView={this.updateChartsView}
					returnView={this.returnChatsView}
					activeView={this.state.charts}
					fullAddress={mainComponentFullAddress}
					whatIfScenarioDataArray={this.state.whatIfScenarioDataArray}
					refreshDataArrayLink={this.onChangeDataArray}
					sessionId={dbIdLocal}
					id={this.state.dbId}
					value={this.state}
					ref={(Charts) => { window.Charts = Charts }}
				/>
			</React.Fragment>
		);
	}
}
