import React from "react";
import { Switch, Route } from 'react-router-dom';
import MainComponent from "./maincomponent";
import MainComponent3Column from "./MainComponentV3.component"
import CostEstimatorV2 from "./CostEstimatorV2.component";
import Clock from "./Clock.component";
import HealthCause from "./HealthCauseV2.component";
import QuestionBank from "./QuestionBankV1.component";
import Questionnaire from "./QuestionGenerator.component";
import Quotes from "./Quotes.component";
import IndividualDeatils from "./IndividualDetails.component";
import CoverageComparisonV3 from "./CoverageComparisonV3.component";
//import AgentDetail from "./AgentDetail.component"
import UploadPhoto from "./upload-photo";
import DataRetrivalForm from "./GetSavedData.component";
//import AllAgentsData from "./AllAgentsData.component"

const Home = () => (
    <Switch>
        <Route path='/v1' component={CostEstimatorV2}></Route>
        <Route path='/v2' component={MainComponent}></Route>
        <Route path="/v3/docketId=:docketId" component={MainComponent3Column}></Route>
        <Route path='/clock' component={Clock}></Route>
        <Route path='/healthcauses' component={HealthCause}></Route>
        <Route path='/questions' component={QuestionBank}></Route>
        <Route path='/questionnaire' component={Questionnaire}></Route>
        <Route path='/quotes' component={Quotes}></Route>
        <Route path='/individualDeatils' component={IndividualDeatils}></Route>
        <Route path='/upload' component={UploadPhoto}></Route>
        <Route path='/' component={CoverageComparisonV3}></Route>
        {/* <Route path='/agentDetails' component={AgentDetail}></Route> */}
        <Route path='/loadData' component={DataRetrivalForm}></Route>
      {/*   <Route path='/allAgentData' component={AllAgentsData}></Route> */}
    </Switch>
);

export default Home;
