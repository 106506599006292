/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAppData = /* GraphQL */ `
  mutation CreateAppData(
    $input: CreateAppDataInput!
    $condition: ModelAppDataConditionInput
  ) {
    createAppData(input: $input, condition: $condition) {
      id
      uid
      clientId
      cacheId
      dataCategory
      fullAddress
      addresses
      eventStr
      primaryResidence
      gender
      age
      birthDate
      birthYear
      health
      smoker
      coverage
      termDesired
      createdAt
      updatedAt
      propertyTaxAmount
      avmAmount
      mortgageAmount
      calculatedCoverage
      homeInsuranceAmount
      lifeInsuranceAmount
      utilityAmount
      healthCause
      cause
      county
      ageBand
      deathsOfAgeGroup
      populationOfAgeGroup
      totalDeaths
      totalDeathsOfAgeGroup
      totalPopulation
      countyCode
      question
      questionCauseTag1
      questionCauseTag2
      questionCauseType
      questionAgeTag4
      questionAgeTag5
      questionDependentQuestionId
      questionRootQuestion
      questionAnswerType
      questionYesDependent
      questionNoDependent
      questionOrder
      stateCode
      health4LifeInsurance
      policyType
      premiumAmountPerMonth
      livingsizeSqft
      agencyName
      emailId
      firstName
      lastName
      licenseNumber
      phoneNumber
      potraitImage
      agentBrandingCodes
      agentBrandingCode
      genericPlaceHolder1
      genericPlaceHolder2
      genericPlaceHolder3
    }
  }
`;
export const updateAppData = /* GraphQL */ `
  mutation UpdateAppData(
    $input: UpdateAppDataInput!
    $condition: ModelAppDataConditionInput
  ) {
    updateAppData(input: $input, condition: $condition) {
      id
      uid
      clientId
      cacheId
      dataCategory
      fullAddress
      addresses
      eventStr
      primaryResidence
      gender
      age
      birthDate
      birthYear
      health
      smoker
      coverage
      termDesired
      createdAt
      updatedAt
      propertyTaxAmount
      avmAmount
      mortgageAmount
      calculatedCoverage
      homeInsuranceAmount
      lifeInsuranceAmount
      utilityAmount
      healthCause
      cause
      county
      ageBand
      deathsOfAgeGroup
      populationOfAgeGroup
      totalDeaths
      totalDeathsOfAgeGroup
      totalPopulation
      countyCode
      question
      questionCauseTag1
      questionCauseTag2
      questionCauseType
      questionAgeTag4
      questionAgeTag5
      questionDependentQuestionId
      questionRootQuestion
      questionAnswerType
      questionYesDependent
      questionNoDependent
      questionOrder
      stateCode
      health4LifeInsurance
      policyType
      premiumAmountPerMonth
      livingsizeSqft
      agencyName
      emailId
      firstName
      lastName
      licenseNumber
      phoneNumber
      potraitImage
      agentBrandingCodes
      agentBrandingCode
      genericPlaceHolder1
      genericPlaceHolder2
      genericPlaceHolder3
    }
  }
`;
export const deleteAppData = /* GraphQL */ `
  mutation DeleteAppData(
    $input: DeleteAppDataInput!
    $condition: ModelAppDataConditionInput
  ) {
    deleteAppData(input: $input, condition: $condition) {
      id
      uid
      clientId
      cacheId
      dataCategory
      fullAddress
      addresses
      eventStr
      primaryResidence
      gender
      age
      birthDate
      birthYear
      health
      smoker
      coverage
      termDesired
      createdAt
      updatedAt
      propertyTaxAmount
      avmAmount
      mortgageAmount
      calculatedCoverage
      homeInsuranceAmount
      lifeInsuranceAmount
      utilityAmount
      healthCause
      cause
      county
      ageBand
      deathsOfAgeGroup
      populationOfAgeGroup
      totalDeaths
      totalDeathsOfAgeGroup
      totalPopulation
      countyCode
      question
      questionCauseTag1
      questionCauseTag2
      questionCauseType
      questionAgeTag4
      questionAgeTag5
      questionDependentQuestionId
      questionRootQuestion
      questionAnswerType
      questionYesDependent
      questionNoDependent
      questionOrder
      stateCode
      health4LifeInsurance
      policyType
      premiumAmountPerMonth
      livingsizeSqft
      agencyName
      emailId
      firstName
      lastName
      licenseNumber
      phoneNumber
      potraitImage
      agentBrandingCodes
      agentBrandingCode
      genericPlaceHolder1
      genericPlaceHolder2
      genericPlaceHolder3
    }
  }
`;
