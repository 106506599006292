import React from "react";
import { BallRotate } from 'react-pure-loaders';
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";

import 'rc-slider/assets/index.css';
import Utility from "./Utility";
import question_mark from "./assets/help_question_mark.svg"
import GlobalConstants from "../GlobalConstantsVariables";

const CLASS_NAME = ":ExtensionLabels:";

export default class ExtensionLabels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            isLoading: false,
            stateCode: this.props.stateCode,
            livingsizeSqft: this.props.livingsizeSqft,
            propertyTaxAmount: this.props.propertyTaxAmount,
            mortgageAmount: this.props.mortgageAmount,
            homeInsuranceAmount: this.props.homeInsuranceAmount,
            lifeInsuranceAmount: this.props.lifeInsuranceAmount,
            utilityPercent: 7,
            propertyTaxPercent: 10,
            mortgagePercent: 60,
            homeInsurancePercent: 3,
            lifeInsurancePercent: 1,
            currentCount: 2
        }
        this.fetchCalculatedFields(this.props.id);
    }
    fetchCalculatedFields(id) {
        let METHOD_NAME = ":fetchCalculatedFields:";
        console.log("Entered:id: ", id, METHOD_NAME, CLASS_NAME);
        if (id == null || id === '') {
            return;
        }
    }

    timer = () => {
        const METHOD_NAME = ":timer:";
        this.setState({ isLoading: true });
        let localCurrentCount;
        console.log("localCurrentCount:", localCurrentCount, " # this.props.homeInsuranceAmount: ", this.props.homeInsuranceAmount, METHOD_NAME, CLASS_NAME);
        if (this.props.id === '' || this.props.id == null) {
            return;
        }
        localCurrentCount = this.state.currentCount - 1;
        this.setState({
            currentCount: localCurrentCount,
            homeInsuranceAmount: this.state.homeInsuranceAmount + '.'
        });
        this.fetchCalculatedFields(this.props.id);
        if (this.state.currentCount < 1) {
            clearInterval(this.intervalId);
            this.setState({ isLoading: false });
        }
    }
    componentDidMount() {
        let METHOD_NAME = ":componentDidMount:";
        const tag = GlobalConstants.getPacLifeNextAPITag();
        GlobalConstants.getAPIInputJson(tag).then((localJsonPacLife) => {
            METHOD_NAME = `:getAPIInputJson:then:${METHOD_NAME}`;
            console.log("@@@@@BEFORE:getQuotesFromPacLifeNext:apiJson:", localJsonPacLife, METHOD_NAME, CLASS_NAME);
            let areAllInputsAvailableFlag = GlobalConstants.areAllInputsAvailable(tag, localJsonPacLife);
            console.log("@@@@@BEFORE:areAllInputsAvailableFlag:", areAllInputsAvailableFlag, METHOD_NAME, CLASS_NAME);
            // if (areAllInputsAvailableFlag) {
            //     QuotesHelper.getQuotesFromPacLifeNext(localJsonPacLife)
            //         .then(json => {
            //             METHOD_NAME = `:getQuotesFromPacLifeNext:then:${METHOD_NAME}`;
            //             console.log("@@@@@PacLifeNext:json:", json, METHOD_NAME, CLASS_NAME);
            //             const payload = JSON.parse(json.Payload);
            //             console.log("@@@@@PacLifeNext:Payload:", payload, METHOD_NAME, CLASS_NAME);
            //             if (payload.body != null) {
            //                 console.log("@@@@@payload.data.premium:", payload.body['output.0.premium'], METHOD_NAME, CLASS_NAME);
            //                 this.setState({ 'pacLifeNextPremium': payload.body['output.0.premium'] });
            //             } else {
            //                 console.log("@@@@@payload.data.premium: null", METHOD_NAME, CLASS_NAME);
            //             }
            //         });
            // } else {
            //     console.log("@@@@@ Incomplete Inputs", METHOD_NAME, CLASS_NAME);
            // }
            // this.setState({ isLoading: false });
        });
    }
    componentWillUnmount() {
        // React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
        clearInterval(this.intervalId);
    }
    render() {
        // let totalAmountLocal = Number(this.props.utilityAmount || 0) + Number(this.props.propertyTaxAmount || 0) + Number(this.props.mortgageAmount || 0) + Number(this.state.homeInsuranceAmount || 0) + Number(this.props.lifeInsuranceAmount || 0);
        let totalAmountLocal = 0;
        // console.log("this.props.mortgageAmount: ", this.props.mortgageAmount, METHOD_NAME, CLASS_NAME);
        // console.log("this.props.homeInsuranceAmount: ", this.props.homeInsuranceAmount, METHOD_NAME, CLASS_NAME);
        // console.log("this.props.Number(this.props.lifeInsuranceAmount).toFixed(): ", Number(this.props.lifeInsuranceAmount).toFixed(), METHOD_NAME, CLASS_NAME);
        // console.log("this.props.propertyTaxAmount: ", this.props.propertyTaxAmount, METHOD_NAME, CLASS_NAME);

        totalAmountLocal = (+this.props.mortgageAmount || 0) + (+this.props.propertyTaxAmount || 0) + (+this.props.utilityAmount || 0);
        let lifeInsurancePercentageLocal = (this.props.lifeInsuranceAmount * 100 / totalAmountLocal).toFixed();
        lifeInsurancePercentageLocal = +lifeInsurancePercentageLocal === 0 ? '<1' : lifeInsurancePercentageLocal;

        return (
            <div id={this.props.extension ? "charts-up" : "smallscreen"}>
                <BallRotate
                    color={'#7c648c'}
                    loading={this.state.isLoading}
                />
                <Row className='extlabel-info'>
                    <Col xs={9}>Predicted Home Owner Costs</Col>  <span>${Utility.getFormattedNumberValue(totalAmountLocal.toFixed())}</span>
                </Row>
                <div className="d-flex flex-row align-items-center justify-content-between lg-grey">
                    <div className='mv-lft'>
                        <span className="text-label">
                            <span className="bs-rounded-circle mortgage">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Mortgage
                        </span>
                        <span className="perc-label">
                            ({(this.props.mortgageAmount * 100 / totalAmountLocal).toFixed()}% of total)
                        </span>
                       
                            <>
                                <OverlayTrigger
                                   placement={'bottom'}
                                    overlay={
                                        <Tooltip>
                                            {GlobalConstants.getHelpTooltipText("mortgageAmount")}
                                        </Tooltip>
                                    }
                                >
                                    <img src={question_mark} role="button" className="img-help" alt={'help'} />
                                </OverlayTrigger>
                            </>
                      
                    </div>
                    {
                        (!this.props.notEnoughDataAvailableFlag || (this.props.mortgageAmount > 0)) ?
                            <>
                                <Col xs={2} className='d-flex justify-content-end'>
                                    <span className="info-num"><span>&nbsp;${Utility.getFormattedNumberValue(this.props.mortgageAmount)}</span></span>
                                </Col>
                            </>
                            : <Col className='no-data'>Insufficient Data</Col>
                    }
                </div>

                <div className="d-flex flex-row align-items-center justify-content-between lg-grey">
                    <div className='mv-lft'>
                        <span className="text-label">
                            <span className="bs-rounded-circle propertyTax">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Property Tax
                    </span>
                        <span className="perc-label">
                            ({(this.props.propertyTaxAmount * 100 / totalAmountLocal).toFixed()}% of total)
                    </span>
                   
                            <>
                                <OverlayTrigger
                                   placement={'bottom'}
                                    overlay={
                                        <Tooltip>
                                            {GlobalConstants.getHelpTooltipText("propertyTaxAmount")}
                                        </Tooltip>
                                    }
                                >
                                    <img src={question_mark} role="button" className="img-help" alt={'help'} />
                                </OverlayTrigger>
                            </>
                      
                    </div>
                    {
                        (!this.props.notEnoughDataAvailableFlag || (this.props.propertyTaxAmount > 0)) ?
                            <>
                                <Col xs={2} className='d-flex justify-content-end'>
                                    <span className="info-num"><span>&nbsp;${Utility.getFormattedNumberValue(this.props.propertyTaxAmount)}</span></span>
                                </Col>
                            </>
                            : <Col className='no-data'>Insufficient Data</Col>
                    }
                </div>

                <div className="d-flex flex-row align-items-center justify-content-between lg-grey">
                    <div className='mv-lft'>
                        <span className="text-label">
                            <span className="bs-rounded-circle utilities">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Utilities
                    </span>
                        <span className="perc-label">
                            ({(this.props.utilityAmount * 100 / totalAmountLocal).toFixed()}% of total)
                    </span>
                   
                            <>
                                <OverlayTrigger
                                   placement={'bottom'}
                                    overlay={
                                        <Tooltip>
                                            {GlobalConstants.getHelpTooltipText("utilities")}
                                        </Tooltip>
                                    }
                                >
                                    <img src={question_mark} role="button" className="img-help" alt={'help'} />
                                </OverlayTrigger>
                            </>
                      
                    </div>
                    {
                        (!this.props.notEnoughDataAvailableFlag || (this.props.utilityAmount > 0)) ?
                            <>
                                <Col xs={2} className='d-flex justify-content-end'>
                                    <span className="info-num"><span>&nbsp;${Utility.getFormattedNumberValue(this.props.utilityAmount)}</span></span>
                                </Col>
                            </>
                            : <Col className='no-data'>Insufficient Data</Col>
                    }
                </div>
                <div className="line-break"></div>

                <Row className="extlabel-info bold">
                    <Col>Predicted Insurance Costs</Col> <span>${(+this.props.homeInsuranceAmount + +this.props.lifeInsuranceAmount).toFixed()}</span>
                </Row>


                <div className="font-large d-flex justify-content-between flex-row">
                    <div className="col-xs-9">
                        <span className="text-label">
                            <span className="bs-rounded-circle lifeInsurance">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Life Insurance
                        </span>
                        <span className="life-insurance grp2">
                            {lifeInsurancePercentageLocal}% of total
                        </span>
                   
                            <>
                                <OverlayTrigger
                                   placement={'bottom'}
                                    overlay={
                                        <Tooltip>
                                            {GlobalConstants.getHelpTooltipText("lifeInsurance")}
                                        </Tooltip>
                                    }
                                >
                                    <img src={question_mark} role="button" className="img-help" alt={'help'} />
                                </OverlayTrigger>
                            </>
                      
                    </div>
                    {
                        (!this.props.notEnoughDataAvailableFlag || (this.props.lifeInsuranceAmount > 0)) ?
                            <>
                                <Col xs={2} className='d-flex justify-content-end'>
                                    <span className="info-num"><span>&nbsp;${this.props.lifeInsuranceAmount ? Number(this.props.lifeInsuranceAmount).toFixed() : '0'}</span></span>
                                </Col>
                            </>
                            : <Col className='no-data'>No Data</Col>
                    }
                </div>


                <div className="font-large d-flex justify-content-between flex-row">

                    <div className='col-xs-9'>
                        <span className="text-label">
                            <span className="bs-rounded-circle homeInsurance">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;Home Insurance
                    </span>
                        <span className="home-insurance grp2">
                            {(this.props.homeInsuranceAmount * 100 / totalAmountLocal).toFixed()}% of total
                    </span>
                   
                            <>
                                <OverlayTrigger
                                   placement={'bottom'}
                                    overlay={
                                        <Tooltip>
                                            {GlobalConstants.getHelpTooltipText("homeInsuranceAmount")}
                                        </Tooltip>
                                    }
                                >
                                    <img src={question_mark} role="button" className="img-help" alt={'help'} />
                                </OverlayTrigger>
                            </>
                      

                    </div>
                    {
                        (!this.props.notEnoughDataAvailableFlag || (this.props.homeInsuranceAmount > 0)) ?
                            <>
                                <Col xs={2} className='d-flex justify-content-end'>
                                    <span className="info-num">
                                        <span>&nbsp;${Utility.getFormattedNumberValue(this.props.homeInsuranceAmount)}</span>
                                    </span>
                                </Col>
                            </>
                            : <Col className='no-data'>No Data</Col>
                    }

                </div>

                {
                    this.props.displayPacLifeNextQuote && this.state.pacLifeNextPremium &&
                    <Row className={this.props.extension ? "font-weight-bold" : "fs18px"}>
                        <Col xs={9}>
                            <span className="text-label">
                                <span className="bs-rounded-circle lifeInsurance">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;PacLife-Next Life Insurance
                            </span>
                            <span>{(this.state.pacLifeNextPremium * 100 / totalAmountLocal).toFixed()}% of total </span>
                       
                            <>
                                <OverlayTrigger
                                   placement={'bottom'}
                                    overlay={
                                        <Tooltip>
                                            {GlobalConstants.getHelpTooltipText("lifeInsurance")}
                                        </Tooltip>
                                    }
                                >
                                    <img src={question_mark} role="button" className="img-help" alt={'help'} />
                                </OverlayTrigger>
                            </>
                      
                        </Col>

                        <Col xs={2} className='d-flex justify-content-end'>
                            <span className="form-label"><span>&nbsp;-</span></span>
                        </Col><Col xs={2} className='d-flex justify-content-end'>
                            <span className="form-label"><span>&nbsp;${this.state.pacLifeNextPremium != null ? parseFloat(this.state.pacLifeNextPremium).toFixed() : ''}</span></span>
                        </Col>
                    </Row>
                }
                { this.props.extension &&
                    <div className="text-center setBtn">
                        <div className="orangeGradient">
                            <button>Get Your Quote</button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}