/* eslint-disable no-template-curly-in-string */
import React, { Component } from "react";
import axios from "axios";
import AWS from "aws-sdk";

// import TableRow from "./TableRow";
import SplitPane from "react-split-pane";
import styles from "../App.scss";
// Button, FormText
import {  Form, Row, Col, FormLabel, FormGroup, FormControl, Container } from "react-bootstrap";
import { MDBContainer } from "mdbreact";
// import { AsyncTypeahead } from "react-bootstrap-typeahead";
// import "react-bootstrap-typeahead/css/Typeahead.css";
// import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
// import SearchResultMenuItem from "./SearchResultMenuItem";
import { Chart, Doughnut } from 'react-chartjs-2';
// import AlertCompo from './Alert.component';
import * as EmailValidator from 'email-validator';
import { default as NumberFormat } from 'react-number-format';
import { BallRotate } from 'react-pure-loaders';

import 'chart.piecelabel.js';
import Utility from "./Utility";
// import Autocomplete from 'react-google-autocomplete';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import loader from '../purple-line-loader1.svg';
import ComponentHelper from './ComponentHelper';
// If you want to use the provided css Copied all styles to Split// import 'react-google-places-autocomplete/dist/index.min.css';

// AIzaSyDt2HM0PNAkw1XY5anITxTQt9L_EVAPwCE
let hideRightPane = false;
let debugMode = false;
let onlyOnce = true;
let protocol;
let domain;
let port;
let windowInnerHeight;
let windowInnerWidth;
let splitPaneSplit = "vertical";
let splitPaneMinSize = 400;
let splitPaneSplitMaxSize = 400;
let splitPaneHeight = 531;         //eslint-disable-line
const CLASS_NAME = "create:";

function getClientWindowDetails() {
  let METHOD_NAME = 'getClientWindowDetails:';
  let fullURL = window.location.href;
  let url_object = new URL(fullURL);

  protocol = url_object.protocol;
  domain = url_object.hostname;
  port = url_object.port;
  windowInnerHeight = window.innerHeight;
  windowInnerWidth = window.innerWidth;

  if (windowInnerWidth <= 420) {
    splitPaneSplit = "horizontal";
    splitPaneMinSize = (windowInnerWidth / 2).toFixed();
    splitPaneSplitMaxSize = (windowInnerWidth / 2).toFixed();
    splitPaneHeight = 1199;
    hideRightPane = true;
  } else if (windowInnerWidth > 420) {
    splitPaneHeight = 531;
    hideRightPane = false;
    splitPaneSplit = "vertical";
    let size = (windowInnerWidth / 2).toFixed();
    size = size > 400 ? 400 : size;
    splitPaneSplitMaxSize = size;
    splitPaneMinSize = size;
    splitPaneSplitMaxSize = size;
  }
  let webBrowserData = { hideRightPane: hideRightPane, splitPaneSplit: splitPaneSplit, splitPaneMinSize: splitPaneMinSize, splitPaneSplitMaxSize: splitPaneSplitMaxSize, protocol: protocol, domain: domain, port: port, windowInnerHeight: windowInnerHeight, windowInnerWidth: windowInnerWidth };
  saveLeadsRawData(`0000-${METHOD_NAME}`, webBrowserData);
}
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
let reactReference = React.createRef();
const AWS_SERVER_HEADERS = {         //eslint-disable-line
  'accept': 'application/json',
  'x-api-key': 'AdPhiUp7P54WsqUGQ0wM49crOApjg3on9sphXezV',
  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
};
const AWS_API_GATEWAY = 'https://qaubc7vbg1.execute-api.us-west-2.amazonaws.com';        //eslint-disable-line
const DOMAIN_NAME = "https://alpha.getmybubble.io";
const PROXY_URL = "https://cors-anywhere.herokuapp.com/";

const BUBBLE_SERVER_HEADERS = { accept: "application/json", apikey: "b0ea821699eaeb01ebea45dc96a547e8" };
const _defaultChartTitle = 'Please enter required information to personalize estimates for your property';
//let chartTitleTemplate = 'Total estimated Monthly Expenses for:addressOneLine is "totalMonthlyExpenses"';
let chartTitle;
const _defaultLabels = ['', '', '', '', ''];
const _chartLabelsOri = ['Mortgage', 'Home Insurance', 'Utilities', 'Property Tax', 'Life Insurance'];
let _chartLabels = _chartLabelsOri;
let switchedLifeInsuPropTax = false;
const _backgroundColorGrey = ['#696969', '#808080', '#A9A9A9', '#C0C0C0', '#D3D3D3'];

const _backgroundColorOri = ['#DFD5E2', '#F3B2DB', '#6FCFEB', '#99E6D8', '#4A1149'];
const _hoverBackgroundColorOri = ['#bda8c3', '#ec86c6', '#2cb9e2', '#47d2b9', '#350c35'];
const _hoverBorderColorOri = ['#82608b', '#e244a7', '#1789aa', '#23907c', '#200720'];
const ATTOM_DOMAIN = "https://api.gateway.attomdata.com";
const ATTOM_SERVER_HEADERS = {
  accept: "application/json",
  apikey: "b0ea821699eaeb01ebea45dc96a547e8",
  Origin: `${DOMAIN_NAME}:3001/costEstimator`,
}
let _backgroundColor = _backgroundColorOri;
let _hoverBackgroundColor = _hoverBackgroundColorOri;
let _hoverBorderColor = _hoverBorderColorOri;
const _hoverBorderWidth = 2;
//const _defaultIs_primary_address = "Yes";
const _defaultAge = "O";
const _defaultGender = 'OO';
const _defaultIs_smoker = 'N';
const _defaultHealth = '0';
const _defaultCoverage = 500000;
const _defaultPolicyTennure = 20;

const _defaultAVM = -1;
const _defaultStateCode = 'CO';
const _defaultLivingSize = 2345;
//const _defaultHomeInsuranceValue = 567;
const _defaultLifeInsuranceValue = 123;
const _defaultUtilityAmount = 234;           //eslint-disable-line
const _defaultLiabilityAmount = 300000;
let staticStateHash = {};
let prev_email_address;
let email_address;
let prev_home_address;                       //eslint-disable-line
let home_address;                            //eslint-disable-line

let is_primary_address;
let age = _defaultAge;
let gender = _defaultGender;
let coverage = _defaultCoverage;
let policyTennure = _defaultPolicyTennure;
let health = _defaultHealth;
let avmStateCode;
let countyFIP;
let averageRETRateCounty;
let avmAmount = _defaultAVM;
let mortgageAmount;
let isPrimaryResidence = false;

let stateCode = _defaultStateCode;                 //eslint-disable-line
let livingSizeSqft = _defaultLivingSize;
let isSmokerValue = _defaultIs_smoker;
let lifeInsuranceAmount;
let lifeInsuranceText;
let propertyTaxRate;
let propertyTaxAmount;
let homeInsuranceAmount;
let liabilityAmount = _defaultLiabilityAmount;
let dwellingAmount;
//let assdTtlValue;
//let mktTtlValue;
let mktTtlValue_assdttlvalue;
let avg_ann_prem;
let avg_dwelling;
let utilityAmount;
let addressOneLine = '';
let livingsizeSqft;
let apprImprValue;
let assdImprValue;
let assessment_tax_taxPerSizeUnit;
let assessment_tax_taxYear;
let assessment_tax_taxAmt;
let totalMonthlyExpenses;
let zip_code;
//let gla;
//let bath_full;
//let bath_half;
//let stories;

//let coverage; coverage = calculateCoverage(inputAvmAmount);
// let inputAvmAmount = 1450000;
function calculateCoverage(inputAvmAmount) {
  let multiplyingFactor = 100000;
  if (inputAvmAmount > 1000001 && inputAvmAmount < 2000001) {
    multiplyingFactor = 500000;
  }
  // console.log('inputAvmAmount: ', inputAvmAmount, '# inputAvmAmount x 80%: ', (inputAvmAmount * 80) / 100);
  // console.log('multiplyingFactor: ', multiplyingFactor, '# (((inputAvmAmount * 80) / 100) / multiplyingFactor).toFixed(): ', (((inputAvmAmount * 80) / 100) / multiplyingFactor).toFixed());
  let outputCoverage = (((inputAvmAmount * 80) / 100) / multiplyingFactor).toFixed() * multiplyingFactor;
  outputCoverage = outputCoverage > 2000001 ? 2000000 : outputCoverage;
  // console.log('outputCoverage: ', outputCoverage);
  return outputCoverage;
}
function calculateMortgageAmount(inputAvm, isPrimaryResidence = true) {
  let interestRate = isPrimaryResidence ? 0.003 : 0.0035;
  // mortgage = P[r(1+r)^n/((1+r)^n)-1)]
  let val = (inputAvm * 80 / 100) * (interestRate * Math.pow((1 + interestRate), 360) / ((Math.pow((1 + interestRate), 360)) - 1));
  mortgageAmount = val.toFixed();
  return mortgageAmount;
}
/**
 * calculatePropertyTaxAmount Formula implementation
 * @param {*} value 
 */
function calculatePropertyTaxAmount(averageRETRateCounty) {
  let outputValue = ((averageRETRateCounty / 1000) * avmAmount) / 12;
  outputValue = outputValue.toFixed();
  return outputValue;
}
const state_avg_utility_cost_per_sqft = { 'VT': 0.2580165289, 'MT': 0.175995098, 'MS': 0.2083342203, 'NH': 0.2698076923, 'ME': 0.2792844257, 'LA': 0.2132082867, 'WV': 0.2295157526, 'AR': 0.2166741071, 'WI': 0.2340623128, 'TN': 0.2177218615, 'AK': 0.2951145892, 'GA': 0.2319612837, 'AL': 0.2287666667, 'HI': 0.5587614679, 'MI': 0.2684444444, 'CT': 0.2749833703, 'WY': 0.1914766082, 'KY': 0.2364857143, 'NC': 0.2321722222, 'NE': 0.2330513419, 'SC': 0.256652221, 'VA': 0.2270094937, 'MA': 0.2689965596, 'MN': 0.2212800875, 'NM': 0.2133405876, 'IL': 0.2473223039, 'IN': 0.2361423651, 'NY': 0.2705839002, 'ID': 0.1779037267, 'MO': 0.2645393939, 'KS': 0.2368462402, 'PA': 0.2534117647, 'OK': 0.2473654066, 'OH': 0.2761111111, 'SD': 0.192827621, 'WA': 0.193998949, 'ND': 0.2303970917, 'IA': 0.2815870968, 'DE': 0.2344388889, 'RI': 0.3092298578, 'FL': 0.2650472255, 'NJ': 0.2486896552, 'OR': 0.2142134831, 'MD': 0.2237552083, 'CO': 0.1901458137, 'UT': 0.1519175705, 'TX': 0.2017626785, 'AZ': 0.2503743619, 'CA': 0.2694461538, 'NV': 0.220169392 };

let theDataArray = [calculateMortgageAmount(_defaultAVM), getRandomInt(1000, 1450), getRandomInt(400, 550), getRandomInt(1000, 1250)];

function setGetChartTitle(totalMonthlyExpensesText) {
  if (avmAmount == null || lifeInsuranceAmount == null) {
    chartTitle = _defaultChartTitle;
  } else {
    chartTitle = ''; // chartTitleTemplate.replace('totalMonthlyExpenses', totalMonthlyExpensesText).replace('addressOneLine', addressOneLine);
  }
  return chartTitle;
}
// setGetTheDataArray({ "source": "", "avmAmount": e.target.value });
// setGetTheDataArray({ "source": "", "source": "", mortgageAmount": _defaultAVM,"propertyTaxAmount": _defaultPropertyTaxAmount,"utilityAmount": _defaultUtilityAmount,"homeInsurance": _defaultHomeInsurance,"lifeInsurance":_defaultLifeInsurance});
/* Following function is usable for all chart element setting*/
function setGetTheDataArray(keyValHash) {
  let METHOD_NAME = "setGetTheDataArray:";
  console.log(CLASS_NAME, METHOD_NAME, "Entered:Input:keyValHash: ", keyValHash);
  // ///////////////// Data[0] Mortgage //////////////
  let value = 0;
  if (keyValHash["avmAmount"] != null) {
    value = Utility.calculateMortgageAmount(keyValHash["avmAmount"], isPrimaryResidence);
    mortgageAmount = value;
    theDataArray[0] = value;
  }

  if (keyValHash["mortgageAmount"] != null) {
    mortgageAmount = keyValHash["mortgageAmount"];
    theDataArray[0] = mortgageAmount;
  }
  // ///////////////// Data[1] Home Insurance //////////////
  if (keyValHash["homeInsurance"] != null) {
    value = keyValHash["homeInsurance"];
    theDataArray[1] = value;
  }// https://smartasset.com/taxes/property-taxes?render=json&
  // ///////////////// Data[2] Utilities ////////////// 
  if (keyValHash["livingSizeSqft"] != null && keyValHash["avmStateCode"] != null) {
    value = (state_avg_utility_cost_per_sqft[keyValHash["avmStateCode"]] * keyValHash["livingSizeSqft"]).toFixed();
    theDataArray[2] = value;
    utilityAmount = value;
  }
  if (keyValHash["utilityAmount"] != null && !isNaN(keyValHash["utilityAmount"])) {
    value = keyValHash["utilityAmount"].toFixed();
    theDataArray[2] = value;
    utilityAmount = value;
  }
  // ///////////////// Data[3] PropertyTax //////////////
  if (keyValHash["propertyTaxAmount"] != null) {
    value = keyValHash["propertyTaxAmount"];
    propertyTaxAmount = keyValHash["propertyTaxAmount"];
    if (switchedLifeInsuPropTax) {
      theDataArray[4] = propertyTaxAmount;
    } else {
      theDataArray[3] = propertyTaxAmount;
    }
  }
  if (keyValHash["averageRETRateCounty"] != null) {
    value = keyValHash["averageRETRateCounty"];
    propertyTaxAmount = calculatePropertyTaxAmount(value);
    if (switchedLifeInsuPropTax) {
      theDataArray[4] = propertyTaxAmount;
    } else {
      theDataArray[3] = propertyTaxAmount;
    }
  }

  // ///////////////// Data[4] Life Insurance //////////////
  if (keyValHash["lifeInsurance"] != null) {
    console.log("keyValHash[lifeInsurance]:", keyValHash["lifeInsurance"]);
    lifeInsuranceAmount = keyValHash["lifeInsurance"];

    if (switchedLifeInsuPropTax) {
      theDataArray[3] = lifeInsuranceAmount;
    } else {
      theDataArray[4] = lifeInsuranceAmount;
      // Rearrange the values and colors
      let tempLast = theDataArray[4];
      theDataArray[4] = theDataArray[3];
      theDataArray[3] = tempLast;
      tempLast = _backgroundColor[4];
      _backgroundColor[4] = _backgroundColor[3];
      _backgroundColor[3] = tempLast;
      tempLast = _hoverBackgroundColor[4];
      _hoverBackgroundColor[4] = _hoverBackgroundColor[3];
      _hoverBackgroundColor[3] = tempLast;
      tempLast = _hoverBorderColor[4];
      _hoverBorderColor[4] = _hoverBorderColor[3];
      _hoverBorderColor[3] = tempLast;
      tempLast = _chartLabels[4];
      _chartLabels[4] = _chartLabels[3];
      _chartLabels[3] = tempLast;
      switchedLifeInsuPropTax = true;
    }
  }
  totalMonthlyExpenses = theDataArray.reduce(function (a, b) { return parseInt(a) + parseInt(b); }, 0);
  chartTitle = setGetChartTitle(Utility.getThousandsSeparatedStr(totalMonthlyExpenses));
  console.log(CLASS_NAME, METHOD_NAME, "totalMonthlyExpenses: ", totalMonthlyExpenses);
  console.log(CLASS_NAME, METHOD_NAME, " theDataArray:", theDataArray);
  console.log(CLASS_NAME, METHOD_NAME, "_chartLabels: ", _chartLabels);
  return theDataArray;
}
/* DONE: 'Mortgage' input: AVM. Calculation: M = P[r(1+r)^n/((1+r)^n)-1)]
     'Property Tax' input: address1, address2 # from API, 
     'Utilities': Input: State Code and Property's living Sqft,
     'Home Insurance': ???
     'Life Insurance':  Inputs: AVM: 300000 Age: 40 - 45 Years: 25, Gender: M Health Status: super_healthy Shridhar API
  */
let displayError = false;
let onlyAtPageLoad = true;
let _defaultDatasets = [{
  data: [1, 1, 1, 1, 1],
  backgroundColor: _backgroundColorGrey,
  hoverBackgroundColor: _backgroundColorGrey,
  hoverBorderColor: _backgroundColorGrey,
  hoverBorderWidth: _hoverBorderWidth
}];
let datasets = _defaultDatasets;

function getLifeInsurancePercentText(inputInsuranceValue) {
  let METHOD_NAME = "getLifeInsurancePercentText:";
  console.log(CLASS_NAME, METHOD_NAME, "inputInsuranceValue:", inputInsuranceValue);
  let outputPercentage = 1;
  let dollarText = '';
  if (inputInsuranceValue != null) {
    let tempVar = (inputInsuranceValue / totalMonthlyExpenses) * 100;
    outputPercentage = tempVar.toFixed(1);
    dollarText = ` ($${inputInsuranceValue})`;
  }
  let finalPercent = ` ${outputPercentage}% `;
  console.log(CLASS_NAME, METHOD_NAME, "inputInsuranceValue: ", inputInsuranceValue, " # outputPercentage: ", outputPercentage, " # finalPercent: ", finalPercent);
  lifeInsuranceText = `Life Insurance ${finalPercent} ${dollarText} of total cost`;
}
staticStateHash = {
  isLoading: false,
  error: false,
  errorMsg: '',
  labels: _defaultLabels,
  chart_title: _defaultChartTitle,
  // Helpful site: for shares: https://www.tutorialrepublic.com/html-reference/html-color-picker.php
  datasets: datasets,
  term: "",
  options: [],
  person_name: "",
  business_name: "",
  business_gst_number: "",
  health: _defaultHealth,
  email_address: "",
  home_address: "",
  avmamount: _defaultAVM,
  mortgageAmount: '--',
  avm_state_code: _defaultStateCode,
  utilityAmount: '--',
  livingsize_sqft: _defaultLivingSize,
  is_primary_address: '--',
  age: '--',
  gender: '--',
  is_smoker: _defaultIs_smoker,
  coverage: _defaultCoverage,
  policyTennure: _defaultPolicyTennure,
  lifeInsuranceAmount: '--',
  lifeInsurancePercent: _defaultLifeInsuranceValue,
  propertyTaxAmount: '--',
  homeInsuranceAmount: '--',
  business: [],
  output_email_flag: false,
};
function splitAddress(address) {
  // {this.onHandleSearch}
  // Google API Response structure 7084 Horizon Circle, Windermere, FL, USA
  let splitted = address != null && address.indexOf(', ') > -1 ? address.split(', ') : null;
  let address1;
  let address2;
  if (splitted != null) {
    splitted.pop();// remove  USA
    let state = splitted.pop();
    let city = splitted.pop();
    address2 = `${city}, ${state}`;
    address1 = splitted.join(' ');
  } else {
    address1 = address;
    address2 = 'Ave';
  }
  return [address1, address2];
}
async function getSetHomeInsuranceFromMongo(inputQueryJson) {
  let METHOD_NAME = "getSetHomeInsuranceFromMongo:";
  console.log(CLASS_NAME, METHOD_NAME, ".then:inputQueryJson:", inputQueryJson);
  // { liability: liabilityAmount, zip_code: searchResults[0].zip_code, dwelling: dwellingAmount }
  let queryParameterArray = [inputQueryJson.liability, inputQueryJson.zip_code];
 
  let queryString = queryParameterArray.join("__");
  let url = `${DOMAIN_NAME}/bubbleHomeInsurance/search/${queryString}`;
  let response;
  if (queryString.indexOf("--") === -1) {
    response = await axios.get(url, {
      headers: BUBBLE_SERVER_HEADERS,
    });
  }
  return response;
}
function testIntegrateAWSPostCall() {                //eslint-disable-line
  // let cour_3579_resultJson = {
  //   propnum: "0", month: "1", day: "1", year: "2020",
  //   address: "3579 COUR DU VIN, SAN JOSE CA",
  //   day: "1",
  //   bath_full: "4",
  //   bath_half: "1",
  //   gla: "2491",
  //   month: "1",
  //   propnum: "0",
  //   quality_code: "1",
  //   stories: "2",
  //   year: "2020",
  //   zip: "95148"
  // };
  let resultJson = {
    attic_result: 0,
    basement_result: 0,
    carport_result: 0,
    covered_result: 0,
    customs: 0,
    deck_result: 0,
    demo_costs: 21340.680523698804,
    dwelling_result: 403175.0772064718,
    enclosed_result: 0,
    garage_result: 0,
    jobid_v3: 'utzacxwh20200616034716528683fsyklxzn',
    pool_result: 0,
    propnum: 0,
    address: "3573 COUR DU VIN, SAN JOSE, CA 95148",
    zip: "95148",
    gla: 2596,
  };
  clientGetSetDwellingCost(JSON.stringify(resultJson));
}
async function prepareBackTestingData() {                //eslint-disable-line
  let METHOD_NAME = "prepareBackTestingData:";
  console.log(CLASS_NAME, METHOD_NAME, "Entered");
  // let addressArray = ["3573 cour Du vin", "San jose CA"];
  let addressArrayArray = [["3573 cour Du vin", "San jose CA"], ["3562 COUR DU VIN", "San jose CA"],
  ["3567 COUR DU VIN", "San jose CA"], ["3591 COUR DU VIN", "San jose CA"],
  ["3561 COUR DU VIN", "San jose CA"], ["3559 CHEMIN DE RIVIERE", "San jose CA"],
  ["3583 CHEMIN DE RIVIERE", "San jose CA"]];
  addressArrayArray = [["2829 Glen Decker Ct", "San jose CA"],
  ["3813 Pleasant Vista Dr", "San jose CA"]];
  addressArrayArray = [["88 N Jackson Ave UNIT 225", "San jose CA"],
  ["2018 Quadros Ln", "San jose CA"], ["2020 Quadros Ln", "San jose CA"],
  ["2809 Glen Sharon Way", "San Jose, CA 95148"]];
  //   Avm, Assd values, Calc values, Mkt values, Impr percentage, Last sales price
  let output = ['addressOneLine', 'assdimprpersizeunit', 'assdimprvalue', 'assdlandpersizeunit', 'assdlandvalue', 'assdttlpersizeunit', 'assdttlvalue', 'assessment_lastModified', 'assessment_pubDate', 'avmAmount', 'calcimprind', 'calcimprpersizeunit', 'calcimprvalue', 'calclandind', 'calclandpersizeunit', 'calclandvalue', 'calcttlind', 'calcttlvalue', 'calcvaluepersizeunit', 'first_current_amount', 'first_current_date', 'first_current_lenderLastName', 'improvementPercent', 'interestRate', 'livingsizeSqft', 'mktimprvalue', 'mktlandvalue', 'mktttlvalue', 'recorderThroughDate', 'saleDisclosureType', 'sale_amount', 'second_current_amount', 'second_current_date'];
  const headers = { accept: "application/json", apikey: "b0ea821699eaeb01ebea45dc96a547e8" };      //eslint-disable-line
  for (let index = 0; index < addressArrayArray.length; index += 1) {
    let addressArray = addressArrayArray[index];

    // Loop
    let outputJson = {}
    let url =
      `${PROXY_URL}${ATTOM_DOMAIN}/propertyapi/v1.0.0/attomavm/detail?Address1=${addressArray[0]}&Address2=${addressArray[1]}`;
    // /////////// AVM
    url = url.replace(/ /g, "%20");
    console.log(CLASS_NAME, METHOD_NAME, "url:", url);
    await axios.get(url, { headers: ATTOM_SERVER_HEADERS, }).then((response12) => {   //eslint-disable-line
      let searchResults12 = response12.data.property.map((i) => ({
        addressOneLine: i.address.oneLine,
        avmAmount: i.avm.amount.value,
        livingsizeSqft: i.building.size.livingsize
      }));
      addressOneLine = searchResults12[0].addressOneLine;
      livingsizeSqft = searchResults12[0].livingsizeSqft;
      outputJson.addressOneLine = addressOneLine;
      outputJson.avmAmount = searchResults12[0].avmAmount;
      outputJson.livingsizeSqft = livingsizeSqft;
    }, (error) => {
      console.log("searchResults12:error:", error);
    });
    // console.log(CLASS_NAME,METHOD_NAME, "searchResults12:outputJson:", outputJson);
    url =
      `${PROXY_URL}${ATTOM_DOMAIN}/propertyapi/v1.0.0/property/expandedprofile?Address1=${addressArray[0]}&Address2=${addressArray[1]}`;
    url = url.replace(/ /g, "%20");
    console.log(CLASS_NAME, METHOD_NAME, "url:", url);
    await axios.get(url, { headers: ATTOM_SERVER_HEADERS, }).then((response13) => {
      let searchResults13 = response13.data.property.map((i) => ({
        first_current_amount: i.assessment.mortgage.FirstConcurrent.amount,
        first_current_lenderLastName: i.assessment.mortgage.FirstConcurrent.lenderLastName,
        first_current_date: i.assessment.mortgage.FirstConcurrent.date,
        interestRate: i.assessment.mortgage.FirstConcurrent.interestRate,
        second_current_amount: i.assessment.mortgage.SecondConcurrent.amount,
        second_current_date: i.assessment.mortgage.SecondConcurrent.date,
        improvementPercent: i.assessment.improvementPercent,
        assessment_lastModified: i.vintage.lastModified,
        assessment_pubDate: i.vintage.pubDate,
        recorderThroughDate: i.sale.recorderThroughDate,
        sale_amount: i.sale.amount.saleAmt,
        saleDisclosureType: i.sale.amount.saleDisclosureType
      }));
      outputJson.first_current_amount = searchResults13[0].first_current_amount;
      outputJson.first_current_lenderLastName = searchResults13[0].first_current_lenderLastName;
      outputJson.first_current_amount = searchResults13[0].first_current_amount;
      outputJson.first_current_date = searchResults13[0].first_current_date;
      outputJson.interestRate = searchResults13[0].interestRate;
      outputJson.second_current_amount = searchResults13[0].second_current_amount;
      outputJson.second_current_date = searchResults13[0].second_current_date;
      outputJson.improvementPercent = searchResults13[0].improvementPercent;
      outputJson.assessment_lastModified = searchResults13[0].assessment_lastModified;
      outputJson.assessment_pubDate = searchResults13[0].assessment_pubDate;
      outputJson.recorderThroughDate = searchResults13[0].recorderThroughDate;
      outputJson.sale_amount = searchResults13[0].sale_amount;
      outputJson.saleDisclosureType = searchResults13[0].saleDisclosureType;
    }, (error) => {
      console.log("searchResults13:error:", error);
    });
    // console.log(CLASS_NAME,METHOD_NAME, "searchResults13:outputJson:", outputJson);
    url =
      `${PROXY_URL}${ATTOM_DOMAIN}/propertyapi/v1.0.0/assessment/detail?Address1=${addressArray[0]}&Address2=${addressArray[1]}`;
    url = url.replace(/ /g, "%20");
    console.log(CLASS_NAME, METHOD_NAME, "url:", url);
    await axios.get(url, { headers: ATTOM_SERVER_HEADERS, }).then((response14) => {
      let searchResults14 = response14.data.property.map((i) => ({
        mktimprvalue: i.assessment.market.mktimprvalue,
        mktlandvalue: i.assessment.market.mktlandvalue,
        mktttlvalue: i.assessment.market.mktttlvalue,
        assdimprpersizeunit: i.assessment.assessed.assdimprpersizeunit,
        assdimprvalue: i.assessment.assessed.assdimprvalue,
        assdlandpersizeunit: i.assessment.assessed.assdlandpersizeunit,
        assdlandvalue: i.assessment.assessed.assdlandvalue,
        assdttlpersizeunit: i.assessment.assessed.assdttlpersizeunit,
        assdttlvalue: i.assessment.assessed.assdttlvalue,
        calcimprind: i.assessment.calculations.calcimprind,
        calcimprpersizeunit: i.assessment.calculations.calcimprpersizeunit,
        calcimprvalue: i.assessment.calculations.calcimprvalue,
        calclandind: i.assessment.calculations.calclandind,
        calclandpersizeunit: i.assessment.calculations.calclandpersizeunit,
        calclandvalue: i.assessment.calculations.calclandvalue,
        calcttlind: i.assessment.calculations.calcttlind,
        calcttlvalue: i.assessment.calculations.calcttlvalue,
        calcvaluepersizeunit: i.assessment.calculations.calcvaluepersizeunit
      }));
      outputJson.mktimprvalue = searchResults14[0].mktimprvalue;
      outputJson.mktlandvalue = searchResults14[0].mktlandvalue;
      outputJson.mktttlvalue = searchResults14[0].mktttlvalue;
      outputJson.assdimprpersizeunit = searchResults14[0].assdimprpersizeunit;
      outputJson.assdimprvalue = searchResults14[0].assdimprvalue;
      outputJson.assdlandpersizeunit = searchResults14[0].assdlandpersizeunit;
      outputJson.assdlandvalue = searchResults14[0].assdlandvalue;
      outputJson.assdttlpersizeunit = searchResults14[0].assdttlpersizeunit;
      outputJson.assdttlvalue = searchResults14[0].assdttlvalue;
      outputJson.calcimprind = searchResults14[0].calcimprind;
      outputJson.calcimprpersizeunit = searchResults14[0].calcimprpersizeunit;
      outputJson.calcimprvalue = searchResults14[0].calcimprvalue;
      outputJson.calclandind = searchResults14[0].calclandind;
      outputJson.calclandpersizeunit = searchResults14[0].calclandpersizeunit;
      outputJson.calclandvalue = searchResults14[0].calclandvalue;
      outputJson.calcttlind = searchResults14[0].calcttlind;
      outputJson.calcttlvalue = searchResults14[0].calcttlvalue;
      outputJson.calcvaluepersizeunit = searchResults14[0].calcvaluepersizeunit;
    }, (error) => {
      console.log("searchResults14:error:", error);
    });
    // console.log(CLASS_NAME,METHOD_NAME, "searchResults14:outputJson:", outputJson);
    let rowArray = [];
    let keyArray = [];
    for (var key in outputJson) {
      rowArray.push(outputJson[key] == null ? "" : outputJson[key].toString());
      keyArray.push(key);
    }

    output.push(rowArray);
    output.push(keyArray);
    // output.push(["${outputJson.addressOneLine}", '${outputJson.assdimprpersizeunit}', '${outputJson.assdimprvalue}', '${outputJson.assdlandpersizeunit}', '${outputJson.assdlandvalue}', '${outputJson.assdttlpersizeunit}', '${outputJson.assdttlvalue}', '${outputJson.assessment_lastModified}', '${outputJson.assessment_pubDate}', '${outputJson.avmAmount}', '${outputJson.calcimprind}', '${outputJson.calcimprpersizeunit}', '${outputJson.calcimprvalue}', '${outputJson.calclandind}', '${outputJson.calclandpersizeunit}', '${outputJson.calclandvalue}', '${outputJson.calcttlind}', '${outputJson.calcttlvalue}', '${outputJson.calcvaluepersizeunit}', '${outputJson.first_current_amount}', '${outputJson.first_current_date}', '${outputJson.first_current_lenderLastName}', '${outputJson.improvementPercent}', '${outputJson.interestRate}', '${outputJson.livingsizeSqft}', '${outputJson.mktimprvalue}', '${outputJson.mktlandvalue}', '${outputJson.mktttlvalue}', '${outputJson.recorderThroughDate}', '${outputJson.saleDisclosureType}', '${outputJson.sale_amount}', '${outputJson.second_current_amount}', '${outputJson.second_current_date}']);
    // end of loop
  }
  for (let index2 = 0; index2 < output.length; index2 += 1) {
    console.log(CLASS_NAME, METHOD_NAME, "searchResults14output:", output[index2]);
  }

}
/**
 * getPropertyTaxRateFromMongo base data: 
 * /Users/sdhaiphule/workspace/Bubble-Insurance/data/property_tax_CountyPropertyTaxes_20160401073240.xls
 * /Users/sdhaiphule/workspace/Bubble-Insurance/data/property_tax_CountyPropertyTaxes_20160401073240.xlsx
szp-mbp-017301:shridhar sdhaiphule$ 

 * @param {*} inputQueryJson structure: [debug_]<STATE_CODE>__<COUNTY_FIP>
 * example: CO__80212
 */
async function getPropertyTaxRateFromMongo(inputQueryJson) {
  let METHOD_NAME = "getPropertyTaxRateFromMongo:";
  // console.log(CLASS_NAME,METHOD_NAME, "inputQueryJson:", inputQueryJson);
  let queryParameterArray = [inputQueryJson.state_code, inputQueryJson.county_fip];
  let queryString = queryParameterArray.join("__");
  let url = `${DOMAIN_NAME}/bubblePropertyTax/search/${queryString}/`;
  let response2;
  if (queryString.indexOf("--") === -1) {
    response2 = await axios.get(url, {
      headers: BUBBLE_SERVER_HEADERS,
    });
  }
  console.log(CLASS_NAME, METHOD_NAME, "Leaving");
  return response2;
}

async function httpsPostREQUEST2DynamoDB(jSONObject) {       //eslint-disable-line
  if (!onlyOnce) {
    return null;
  }
  let METHOD_NAME = "httpsPostREQUEST2DynamoDB:";
  const url = `https://qaubc7vbg1.execute-api.us-west-2.amazonaws.com/v1/bubble`;
  const SERVER_HEADERS = {
    'accept': 'application/json',
    'x-api-key': 'AdPhiUp7P54WsqUGQ0wM49crOApjg3on9sphXezV',
    'Access-Control-Allow-Methods': 'POST',
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    'fun': 'postDwellingCost'
  };

  console.log(CLASS_NAME, METHOD_NAME, "Input jSONObject: ", jSONObject);
  // Default options are marked with *
  const response = await fetch(url, {        //eslint-disable-line
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'no-cors', // no-cors, *cors, same-origin
    json: true,
    headers: SERVER_HEADERS,
    body: jSONObject // body data type must match "Content-Type" header
  }).then(data => {
    console.log(CLASS_NAME, METHOD_NAME, "fetch:data:", data); // JSON data parsed by `response.json()` call
  });

}
async function getDwellingCost2DynamoDB(body) {        //eslint-disable-line
  if (!onlyOnce) {
    return null;
  }
  let METHOD_NAME = "getDwellingCost2DynamoDB:";
  const axios = require('axios');
  const url = `https://qaubc7vbg1.execute-api.us-west-2.amazonaws.com/v1/bubble/4529--WINONA--CT,--DENVER,--CO--80212--USA1`;
  const SERVER_HEADERS = {
    'accept': 'application/json',
    'x-api-key': 'AdPhiUp7P54WsqUGQ0wM49crOApjg3on9sphXezV',
    'fun': 'getDwellingCost'
  };
  await axios.get(url, body, { headers: SERVER_HEADERS }).then((response) => {
    METHOD_NAME += 'then:';
    console.log(CLASS_NAME, METHOD_NAME, "response: ", response);
  });
  onlyOnce = onlyOnce ? false : true;
}
/**
 * 
 * @param {Working function to get Data from DynamoDB via Lambda function} body 
 */
async function insertDwellingCost2DynamoDB(body) {         //eslint-disable-line
  if (!onlyOnce) {
    return null;
  }
  // Initialize the Amazon Cognito credentials provider
  AWS.config.region = 'us-west-2'; // Region
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-west-2:44ac9557-ce0d-49a6-a84c-995523a10152',
  });

  var lambda = new AWS.Lambda();

  var params = {
    FunctionName: 'dwellingcost5-Lambda-SCOMW3US0YHS',
    InvocationType: 'RequestResponse',
    LogType: 'Tail',
    Payload: '{ "fun": "getDwellingCost", "parameters": { "dwellingCostId": "4529--WINONA--CT,--DENVER,--CO--80212--USA1" }}'
  };
  lambda.invoke(params, function (err, data) {
    if (err) {
      console.log("ErrorErrorErrorErrorErrorError", err);
    } else {
      console.log("Returned: " + data.Payload);
    }
  });
  onlyOnce = onlyOnce ? false : true;
}
async function clientGetSetDwellingCost(jsonObject) {
  if (!onlyOnce) {
    return null;
  }
  // Initialize the Amazon Cognito credentials provider
  AWS.config.region = 'us-west-2'; // Region
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-west-2:44ac9557-ce0d-49a6-a84c-995523a10152',
  });

  var lambda = new AWS.Lambda();
  var params = {
    FunctionName: 'dwellingcost5-Lambda-SCOMW3US0YHS',
    InvocationType: 'RequestResponse',
    LogType: 'Tail',
    Payload: `{ "fun": "postDwellingCost", "parameters": ${jsonObject}}`
  };
  lambda.invoke(params, function (err, data) {
    if (err) {
      console.log("ErrorErrorErrorErrorErrorError", err);
    } else {
      console.log("Returned: " + data.Payload);
    }
  });
  onlyOnce = onlyOnce ? false : true;

}

/**
 * getSetDwellingAmountData
 *  Call AWS-Lamda to GET/INSERT SELECT
 * @param {*} addressDetails 
 */
async function getSetDwellingAmountData(addressDetails) {
  let METHOD_NAME = "getSetDwellingAmountData:";
  console.log(CLASS_NAME, METHOD_NAME, "Entered:Input: addressDetails(inputDwellingCostId): ", addressDetails);
  // Initialize the Amazon Cognito credentials provider
  AWS.config.region = 'us-west-2'; // Region
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-west-2:44ac9557-ce0d-49a6-a84c-995523a10152',
  });
  console.log("&&&&&&& addressDetails:", addressDetails, CLASS_NAME, METHOD_NAME); // request succeeded

  var lambda = new AWS.Lambda();
  var params = {
    FunctionName: 'dwellingcost5-Lambda-SCOMW3US0YHS',
    InvocationType: 'RequestResponse',
    LogType: 'Tail',
    Payload: `{ "fun": "getSetDwellingCost", "parameters": ${JSON.stringify(addressDetails)}}`
  };
  console.log("&&&&&&& params:", params, CLASS_NAME, METHOD_NAME); // request succeeded

  lambda.invoke(params, function (error, data) {
    METHOD_NAME += "lambda.invoked:";
    if (error) {
      console.log(CLASS_NAME, METHOD_NAME, "error:", error); // an error occurred
      console.log("Response data and headers: " + JSON.stringify(this.httpResponse));
    } else {
      // console.log(CLASS_NAME,METHOD_NAME, "data:", data); // request succeeded
      const parsedJSON = JSON.parse(data.Payload);
      console.log("&&&&&&& parsedJSON:", parsedJSON, CLASS_NAME, METHOD_NAME); // request succeeded
      dwellingAmount = parsedJSON?.body?.dwelling_result || null;
      console.log("&&&&&&& dwellingAmount:", dwellingAmount, CLASS_NAME, METHOD_NAME); // request succeeded
    }
  });
}
function deepCopyJson(oldValue) {
  let strValue = JSON.stringify(oldValue);
  return JSON.parse(strValue);
}
async function saveLeadsRawData(eventStr, json) {
  let METHOD_NAME = "saveLeadsRawData:";
  let localJson = deepCopyJson(json);
  console.log(CLASS_NAME, METHOD_NAME, "Entered:Input: localJson: ", localJson);
  var date = new Date();
  localJson["created_at"] = date.toISOString().slice(0, 19).replace('T', ' ');
  if (email_address !== null) {
    localJson["email"] = email_address;
  }
  localJson["event_str"] = eventStr;

  // Initialize the Amazon Cognito credentials provider
  AWS.config.region = 'us-west-2'; // Region
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-west-2:44ac9557-ce0d-49a6-a84c-995523a10152',
  });

  var lambda = new AWS.Lambda();

  var params = {
    FunctionName: 'dwellingcost5-Lambda-SCOMW3US0YHS',
    InvocationType: 'Event',
    LogType: 'Tail',
    Payload: `{ "fun": "postLeadsRawData", "parameters": ${JSON.stringify(localJson)}}`
  };
  lambda.invoke(params, function (error, data) {
    METHOD_NAME += "lambda.invoked:";
    if (error) {
      console.log(CLASS_NAME, METHOD_NAME, "error:", error); // an error occurred
      // Using this keyword to access AWS.Response object and properties
      console.log("Response data and headers: " + JSON.stringify(this.httpResponse));
    }
  });
}
/* This function will selectively reset the scores/fields
*/
function prepareScoresHash4Reset() {
  avmAmount = null;
  homeInsuranceAmount = null;
  lifeInsuranceAmount = null;
  mortgageAmount = null;
  propertyTaxAmount = null;
  utilityAmount = null;
  lifeInsuranceText = '';
  _backgroundColor = _backgroundColorOri;
  _hoverBackgroundColor = _hoverBackgroundColorOri;
  _hoverBorderColor = _hoverBorderColorOri;
  _chartLabels = _chartLabelsOri;
  let outputHash = {
    isLoading: false,
    avmAmount: '--',
    homeInsuranceAmount: '--',
    lifeInsuranceAmount: '--',
    mortgageAmount: '--',
    propertyTaxAmount: '--',
    utilityAmount: '--',
    home_address: '',
    chart_title: _defaultChartTitle,
    labels: _defaultLabels,
    datasets: [{
      data: [1, 1, 1, 1, 1],
      backgroundColor: _backgroundColorGrey,
      hoverBackgroundColor: _backgroundColorGrey,
      hoverBorderColor: _backgroundColorGrey,
      hoverBorderWidth: _hoverBorderWidth
    }]
  };


  return outputHash;
}
export default class Create extends Component {

  constructor(props) {
    super(props);
    this.onChangeEmailAddress = this.onChangeEmailAddress.bind(this);
    this.onChangeHomeAddress = this.onChangeHomeAddress.bind(this);
    this.onChangeIsPrimaryAddress = this.onChangeIsPrimaryAddress.bind(this);
    this.onChangeAge = this.onChangeAge.bind(this);
    this.onChangeGender = this.onChangeGender.bind(this);
    this.onChangeIsSmoker = this.onChangeIsSmoker.bind(this);
    this.onChangeHealth = this.onChangeHealth.bind(this);
    this.onChangeCoverage = this.onChangeCoverage.bind(this);
    this.onChangePolicyTennure = this.onChangePolicyTennure.bind(this);
    this.onChangeAvmAmount = this.onChangeAvmAmount.bind(this);

    this.onChangeMortgageAmount = this.onChangeMortgageAmount.bind(this);
    this.onChangePropertyTaxAmount = this.onChangePropertyTaxAmount.bind(this);
    this.onChangeUtilityAmount = this.onChangeUtilityAmount.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeOptOut = this.onChangeOptOut.bind(this);
    this.state = staticStateHash;
  }
  getLifeInsuranceScore = async () => {
    let METHOD_NAME = "getLifeInsuranceScore:";
    console.log(CLASS_NAME, METHOD_NAME, "Entered:health: ", health);
    if (email_address == null) {
      this.setState({ error: true, errorMsg: "Please enter email address" });
      return null;
    } else if ((avmAmount == null || avmAmount <= 0) && gender === 'OO' && age === 'O') {
      this.setState({ error: true, errorMsg: "Please enter gender, home address and age" });
      return null;
    } else if ((avmAmount == null || avmAmount <= 0) && gender !== 'OO' && age === 'O') {
      this.setState({ error: true, errorMsg: "Please enter home address and age" });
      return null;
    } else if (avmAmount == null || avmAmount <= 0) {
      this.setState({ error: true, errorMsg: "Please enter home address" });
      return null;
    } else if (gender === 'OO') {
      this.setState({ error: true, errorMsg: "Please enter gender" });
      return null;
    } else if (age === 'O') {
      this.setState({ error: true, errorMsg: "Please enter age" });
      return null;
    }
    this.setState({ isLoading: true });
    let queryParameterArray = [];
    let searchResults = null;
    queryParameterArray.push(coverage);
    queryParameterArray.push(age);
    queryParameterArray.push(policyTennure);
    queryParameterArray.push(gender === 'O' ? 'M' : gender);
    queryParameterArray.push(health);
    console.log(CLASS_NAME, METHOD_NAME, "health:", health);
    console.log(CLASS_NAME, METHOD_NAME, "gender:", gender);
    let response, queryString;                       //eslint-disable-line
    queryString = queryParameterArray.join("__");
    // Patch avmAmount = 0 Issue
    // 300000__40 - 45__25__M__super_healthy coverage	age	term	gender	super_healthy
    // 350000__31%20-%2035__35__M__healthy
    // 350000__31%20-%2035__20____super_healthy
    if (queryString.indexOf("--") === -1) {
      // let url = `${DOMAIN_NAME}/bubbleLifeInsurance/search/${queryString}`;
      // response = await axios.get(url, {
      //   headers: {
      //     accept: "application/json",
      //     apikey: "b0ea821699eaeb01ebea45dc96a547e8",
      //   },
      // }).then((response) => {
      //   METHOD_NAME += 'then:';
      //   console.log(CLASS_NAME, METHOD_NAME, "response: ", JSON.stringify(response, null, " "));
      //   searchResults = response.data.map((i) => ({
      //     id: i._id,
      //     lifeInsurance: i.avg_output
      //   }));
      // v2__CO__1__3__20__0__30__2000000  # premium:  7.916666666666666
      // insertedRecordCount:  2688  # id:  v2__WA__0__0__20__1__30__100000  # premium:  17.0
      // # v2_stateCode__gender__health4LifeInsurance__policyType__isSmoker__age__coverageAmount
      // # stateCode____________coverageAmount
      let DATA_RELEASE_VERSION = "v2";
      // # 'Female': 0, 'Male': 1, 'Other': 1
      let genderForLifeIssurance = ['1', '2', '3'].indexOf(gender) ? 1 : 0;
      let ageForLifeIssurance = age.split(' ')[2];
      queryParameterArray = [];
      queryParameterArray.push(DATA_RELEASE_VERSION);
      queryParameterArray.push(avmStateCode);
      queryParameterArray.push(genderForLifeIssurance);
      queryParameterArray.push(health);
      queryParameterArray.push(policyTennure);
      queryParameterArray.push(isSmokerValue === 'Yes' ? 1 : 0);
      queryParameterArray.push(ageForLifeIssurance);
      queryParameterArray.push(coverage);

      queryString = queryParameterArray.join("__");
      console.log(CLASS_NAME, METHOD_NAME, "queryString:", queryString);
      // let searchResults;
      ComponentHelper.getData(queryString).then((array) => {
        METHOD_NAME += 'then:';
        console.log("array: ", array, METHOD_NAME, CLASS_NAME);
        for (let counter = array.length - 1; counter >= 0; counter -= 1) {
          if (array[counter][0] === 'premiumAmountPerMonth') {
            searchResults = [{
              lifeInsurance: array[counter][1]
            }];
            // console.log("array[counter][1]:premiumAmountPerMonth: ", array[counter][1], METHOD_NAME, CLASS_NAME);
            break;
          }
        }
        if (searchResults != null && searchResults[0] != null && searchResults[0].lifeInsurance != null) {
          lifeInsuranceAmount = searchResults[0].lifeInsurance;
          // lifeInsuranceAmount = isSmokerValue == 'Yes' ? lifeInsuranceAmount * 2.3 : lifeInsuranceAmount;
          lifeInsuranceAmount = Number.parseFloat(lifeInsuranceAmount);
          console.log("lifeInsuranceAmount:", lifeInsuranceAmount, CLASS_NAME, METHOD_NAME);

          datasets[0].data = setGetTheDataArray({ "source": `${METHOD_NAME}:then`, "avmAmount": avmAmount, "mortgageAmount": mortgageAmount, "propertyTaxAmount": propertyTaxAmount, "lifeInsurance": lifeInsuranceAmount, "homeInsurance": homeInsuranceAmount });

          Chart.pluginService.register({
            beforeDraw: function (chart) {
              if (chart.config.options.elements.center) {
                // Get ctx from string
                var ctx = chart.chart.ctx;

                // Get options from the center object in options
                var centerConfig = chart.config.options.elements.center;
                var fontStyle = centerConfig.fontStyle || 'Arial';
                var txt = centerConfig.text;
                var color = centerConfig.color || '#000';
                var maxFontSize = centerConfig.maxFontSize || 75;
                var sidePadding = centerConfig.sidePadding || 20;
                var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
                // Start with a base font of 30px
                ctx.font = "30px " + fontStyle;

                // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                var stringWidth = ctx.measureText(txt).width;
                var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

                // Find out how much the font can grow in width.
                var widthRatio = elementWidth / stringWidth;
                var newFontSize = Math.floor(30 * widthRatio);
                var elementHeight = (chart.innerRadius * 2);

                // Pick a new font size so it will not be larger than the height of label.
                var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                var minFontSize = centerConfig.minFontSize;
                var lineHeight = centerConfig.lineHeight || 25;
                var wrapText = false;

                if (minFontSize === undefined) {
                  minFontSize = 20;
                }

                if (minFontSize && fontSizeToUse < minFontSize) {
                  fontSizeToUse = minFontSize;
                  wrapText = true;
                }

                // Set font settings to draw it correctly.
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                ctx.font = fontSizeToUse + "px " + fontStyle;
                ctx.fillStyle = color;

                if (!wrapText) {
                  ctx.fillText(txt, centerX, centerY);
                  return;
                }

                var words = txt.split(' ');
                var line = '';
                var lines = [];

                // Break words up into multiple lines if necessary
                for (var n = 0; n < words.length; n++) {
                  var testLine = line + words[n] + ' ';
                  var metrics = ctx.measureText(testLine);
                  var testWidth = metrics.width;
                  if (testWidth > elementWidth && n > 0) {
                    lines.push(line);
                    line = words[n] + ' ';
                  } else {
                    line = testLine;
                  }
                }

                // Move the center up depending on line height and number of lines
                centerY -= (lines.length / 2) * lineHeight;

                for (let n = 0; n < lines.length; n++) {
                  ctx.fillText(lines[n], centerX, centerY);
                  centerY += lineHeight;
                }
                //Draw text in center
                ctx.fillText(line, centerX, centerY);
              }
            }
          });
          getLifeInsurancePercentText(lifeInsuranceAmount);
          this.setState({
            isLoading: false,
            lifeInsuranceAmount: lifeInsuranceAmount,
            labels: _chartLabels,
            chart_title: chartTitle,
            datasets: [{
              data: datasets[0].data,
              backgroundColor: _backgroundColor,
              hoverBackgroundColor: _hoverBackgroundColor,
              hoverBorderColor: _hoverBorderColor,
              hoverBorderWidth: _hoverBorderWidth
            }],
          });
        } else {
          console.log(CLASS_NAME, METHOD_NAME, "then.searchResults == null");
          this.setState({ isLoading: false });
        }
      }, (error) => {
        console.log(CLASS_NAME, METHOD_NAME, "error:", error);
      });
    } else {
      console.log("bubbleLifeInsurance/search NOT invoked");
    }
  }
  onChangeEmailAddress(e) {
    email_address = e.target.value;

    if (email_address == null || email_address.trim().length === 0) {
      this.setState(prepareScoresHash4Reset());
      this.onHandleSearch("Invalid home address");
      return null;
    }
    if (!EmailValidator.validate(email_address)) {
      this.setState({ error: true, errorMsg: 'Please enter valid email address' });
      email_address = email_address.trim();
      prev_email_address = email_address;
      return null;
    }
    if (prev_email_address != null && email_address.trim() !== prev_email_address) {
      this.setState(prepareScoresHash4Reset());
      this.onHandleSearch("Invalid home address");
      return null;
    }
    email_address = email_address.trim();
    this.setState({ name: email_address });
    prev_email_address = email_address;
  }

  onChangeHomeAddress(e) {
    home_address = e.target.value;
    staticStateHash.home_address = e.target.value;
    this.setState({ home_address: e.target.value });
  }
  onChangeIsPrimaryAddress(e) {
    is_primary_address = e.target.value;
    is_primary_address = is_primary_address === 'O' ? "No" : is_primary_address;

    staticStateHash.is_primary_address = is_primary_address;
    if (avmAmount > 0) {
      isPrimaryResidence = is_primary_address === "Yes";
      mortgageAmount = Utility.calculateMortgageAmount(avmAmount, isPrimaryResidence);
    }
    this.setState({ is_primary_address: is_primary_address, mortgageAmount: mortgageAmount });

  }
  onChangeAge(e) {
    age = e.target.value;
    staticStateHash.age = e.target.value;
    this.getLifeInsuranceScore();
    this.setState({ age: e.target.value });

  }
  onChangeGender(e) {
    if (email_address == null) {
      this.setState({ error: true, errorMsg: 'Please enter email address' });
      return null;
    } else if (health == null || health === 'O') {
      this.setState({ error: true, errorMsg: 'Please select health value' });
    }
    gender = e.target.value;
    gender = gender === 'O' ? 1 : gender;
    staticStateHash.gender = gender;
    this.getLifeInsuranceScore();
    this.setState({ gender: gender });

  }
  onChangeIsSmoker(e) {
    isSmokerValue = e.target.value;
    if (isSmokerValue === 'O') {
      this.setState({ error: true, errorMsg: 'Please select Is Smoker value' });
    } else {
      staticStateHash.is_smoker = isSmokerValue;
      this.getLifeInsuranceScore();
    }
    this.setState({ is_smoker: isSmokerValue });
  }
  onChangeHealth(e) {
    health = e.target.value;
    if (health === 'O') {
      this.setState({ health: health });
      this.setState({ error: true, errorMsg: 'Please select health value' });
    } else {
      this.setState({ health: health });
      staticStateHash.health = health;
      this.getLifeInsuranceScore();
    }
  }
  onChangeCoverage(e) {
    coverage = e.target.value;
    this.getLifeInsuranceScore();
    this.setState({ coverage: coverage });
  }
  onChangePolicyTennure(e) {
    policyTennure = e.target.value;
    staticStateHash.policyTennure = e.target.value;
    this.getLifeInsuranceScore();
    this.setState({ policyTennure: e.target.value });
  }
  onChangeOptOut(e) {
    let flag = false;
    console.log('onChangeOptOut:e.target: ', e.target);
    if (this.state.output_email_flag === false) {
      flag = true;
    }
    this.setState({ output_email_flag: flag });
    saveLeadsRawData('onChangeOptOut', { email: email_address, output_email_flag: e.target.value })
  }
  onChangeAvmAmount(e) {
    let METHOD_NAME = "onChangeAvmAmount:";
    console.log(CLASS_NAME, METHOD_NAME, "Entered:Input:avmAmount:", avmAmount, " # averageRETRateCounty: ", averageRETRateCounty);
    avmAmount = e.target.value;
    avmAmount = parseInt(avmAmount.replace('$', '').replace(',', '').replace(',', ''));
    if (avmAmount > 10000) {
      coverage = calculateCoverage(avmAmount);
      this.setState({ coverage: coverage });
      mortgageAmount = Utility.calculateMortgageAmount(avmAmount);
      datasets[0].data = setGetTheDataArray({
        "source": "onChangeAvmAmount", "avmAmount": avmAmount, "averageRETRateCounty": averageRETRateCounty
      });
      datasets = [{
        data: datasets[0].data,
        backgroundColor: _backgroundColor,
        hoverBackgroundColor: _hoverBackgroundColor,
        hoverBorderColor: _hoverBorderColor,
        hoverBorderWidth: _hoverBorderWidth
      }]
      this.setState({
        isLoading: false,
        avmAmount: avmAmount,
        propertyTaxAmount: propertyTaxAmount,
        lifeInsuranceAmount: lifeInsuranceAmount,
        homeInsuranceAmount: homeInsuranceAmount,
        mortgageAmount: mortgageAmount,
        utilityAmount: utilityAmount,
        chart_title: chartTitle,
        datasets: datasets
      });
    } else {
      console.log(CLASS_NAME, METHOD_NAME, "Invalid Input:avmAmount:", avmAmount);
    }
  }
  onChangeMortgageAmount(e) {
    mortgageAmount = e.target.value;
    this.setState({ mortgageAmount: mortgageAmount });
  }
  onChangePropertyTaxAmount(e) {
    let METHOD_NAME = "onChangePropertyTaxAmount:";
    console.log(CLASS_NAME, METHOD_NAME, 'Entered:Input:e.target.value:', e.target.value);
    propertyTaxAmount = e.target.value;
    propertyTaxAmount = parseInt(propertyTaxAmount.replace('$', '').replace(',', '').replace(',', ''));
    if (propertyTaxAmount > 10) {
      this.setState({ isLoading: true });

      datasets[0].data = setGetTheDataArray({
        "source": "onChangePropertyTaxAmount", "avmAmount": avmAmount, "propertyTaxAmount": propertyTaxAmount, "avmStateCode": avmStateCode, "livingSizeSqft": livingSizeSqft
      });
      datasets = [{
        data: datasets[0].data,
        backgroundColor: _backgroundColor,
        hoverBackgroundColor: _hoverBackgroundColor,
        hoverBorderColor: _hoverBorderColor,
        hoverBorderWidth: _hoverBorderWidth
      }]
      this.setState({
        isLoading: false,
        avmAmount: avmAmount,
        propertyTaxAmount: propertyTaxAmount,
        lifeInsuranceAmount: lifeInsuranceAmount,
        homeInsuranceAmount: homeInsuranceAmount,
        mortgageAmount: mortgageAmount,
        utilityAmount: utilityAmount,
        chart_title: chartTitle,
        datasets: datasets
      });
    } else {
      console.log(CLASS_NAME, METHOD_NAME, "Invalid Input:propertyTaxAmount:", propertyTaxAmount);
    }
  }
  onChangeUtilityAmount(e) {
    // utilityAmount = e.target.value;
    // utilityAmount = parseInt(utilityAmount.replace('$', '').replace(',', '').replace(',', ''));
    // this.setState({ utilityAmount: utilityAmount });
    let METHOD_NAME = "onChangeUtilityAmount:";
    console.log(CLASS_NAME, METHOD_NAME, 'Entered:Input:e.target.value:', e.target.value);
    utilityAmount = e.target.value;
    utilityAmount = parseInt(utilityAmount.replace('$', '').replace(',', '').replace(',', ''));
    if (utilityAmount > 10) {
      this.setState({ isLoading: true });

      datasets[0].data = setGetTheDataArray({
        "source": "onChangeUtilityAmount", "avmAmount": avmAmount, "utilityAmount": utilityAmount, "propertyTaxAmount": propertyTaxAmount
      });
      datasets = [{
        data: datasets[0].data,
        backgroundColor: _backgroundColor,
        hoverBackgroundColor: _hoverBackgroundColor,
        hoverBorderColor: _hoverBorderColor,
        hoverBorderWidth: _hoverBorderWidth
      }]
      this.setState({
        isLoading: false,
        avmAmount: avmAmount,
        propertyTaxAmount: propertyTaxAmount,
        lifeInsuranceAmount: lifeInsuranceAmount,
        homeInsuranceAmount: homeInsuranceAmount,
        mortgageAmount: mortgageAmount,
        utilityAmount: utilityAmount,
        chart_title: chartTitle,
        datasets: datasets
      });
    } else {
      console.log(CLASS_NAME, METHOD_NAME, "Invalid Input:utilityAmount:", utilityAmount);
    }
  }
  componentDidUpdate() {
    // console.log("componentDidUpdate:Entered");
    if (this.state.error) {
      setTimeout(() => this.setState({ error: false, errorMsg: '' }), 3000);
    }
    // console.log("componentDidUpdate:Leaving");
  }
  componentDidMount() {
    let METHOD_NAME = "componentDidMount:";
    axios
      .get("https://alpha.getmybubble.io/business")
      .then((response) => {
        this.setState({ business: response.data });
      })
      .catch(function (error) {
        console.log(CLASS_NAME, METHOD_NAME, "error:", error);
      });
  }
  // tabRow() {
  //   return this.state.business.map(function (object, i) {
  //     return <TableRow obj={object} key={i} />;
  //   });
  // }
  onSubmit(e) {
    e.preventDefault();
    const obj = {
      email_address: this.state.email_address,
      home_address: this.state.home_address,
      is_primary_address: this.state.is_primary_address,
      age: this.state.age,
      gender: this.state.gender,
      isSmokerValue: this.state.is_smoker,
      health: this.state.health,
    };

    axios
      .post("https://alpha.getmybubble.io//business/add", obj)
      .then((res) => console.log(res.data));

    this.setState({
      email_address: "",
      home_address: "",
      is_primary_address: "",
      age: "",
      gender: "",
      is_smoker: "",
      health: "",
    });
  }
  onFormSubmit = (event) => {
    //Arrow function makes sure the value of 'this' is always the instance of the search bar
    event.preventDefault(); //Stops browser from submitting form automatically and refreshing the pagee
    this.props.onSubmit(this.state.term);
  };
  //4529 Winona Court ud-current-location
  onHandleSearch = async (term) => {
    let METHOD_NAME = "onHandleSearch:";
    // testIntegrateAWSPostCall();
    // prepareBackTestingData();
    // let term = inputJson.description;
    console.log(CLASS_NAME, METHOD_NAME, "Input:term:", term);

    this.setState({ isLoading: true });
    let searchResults;
    if (term === "Invalid home address" || term.trim().length === 0) {
      searchResults = [{ id: "0000", title: "Address not found", avmamount: "N/A" }]
      term = null;
      this.setState(prepareScoresHash4Reset());
      // reactReference.current.clear();
      datasets[0].data = [1, 1, 1, 1];
      datasets = [{
        data: datasets[0].data,
        backgroundColor: _backgroundColorGrey,
        hoverBackgroundColor: _backgroundColorGrey,
        hoverBorderColor: _backgroundColorGrey,
        hoverBorderWidth: _hoverBorderWidth
      }]
      this.setState({
        isLoading: false,
        chart_title: _defaultChartTitle,
        datasets: datasets
      });
      return null;

    } else if (email_address == null) {
      this.setState({ error: true, errorMsg: "Please enter email address" });
      this.setState({ isLoading: false });
      this.setState({ home_address: term });
      this.setState(prepareScoresHash4Reset());
      reactReference.current.clear();
      datasets[0].data = [1, 1, 1, 1];
      datasets = [{
        data: datasets[0].data,
        backgroundColor: _backgroundColorGrey,
        hoverBackgroundColor: _backgroundColorGrey,
        hoverBorderColor: _backgroundColorGrey,
        hoverBorderWidth: _hoverBorderWidth
      }]
      this.setState({
        isLoading: false,
        chart_title: _defaultChartTitle,
        datasets: datasets
      });
      return null;
    } else if (email_address != null &&
      (term.trim().length === 0 || term.trim().length < 6 || term.trim().indexOf(' ') === -1)) {
      this.setState({ error: true, errorMsg: "Please provide address details" });
      this.setState({ isLoading: false });
      this.setState({ home_address: term });
      return null;
    } else {
      this.setState(prepareScoresHash4Reset());
      this.setState({ isLoading: true });
      let addressArray = splitAddress(term);
      let url =
        `${PROXY_URL}${ATTOM_DOMAIN}/propertyapi/v1.0.0/avm/detail?address1=${addressArray[0]}&Address2=${addressArray[1]}`;
      url = url.replace(/ /g, "%20");
      await axios.get(url, {
        headers: ATTOM_SERVER_HEADERS,
      }).catch(function (error) {
        console.log(CLASS_NAME, METHOD_NAME, "catch(function (error) ");
        // if (error.response) {
        //   // Request made and server responded
        //   console.log(CLASS_NAME, METHOD_NAME, error.response.data);
        //   console.log(CLASS_NAME, METHOD_NAME, error.response.status);
        //   console.log(CLASS_NAME, METHOD_NAME, error.response.headers);
        // } else if (error.request) {
        //   // The request was made but no response was received
        //   console.log(CLASS_NAME, METHOD_NAME, error.request);
        // } else {
        //   // Something happened in setting up the request that triggered an Error
        //   console.log(CLASS_NAME, METHOD_NAME, 'Error', error.message);
        // }
        displayError = true;
      }).then((response1) => {
        if (displayError) {
          return null;
        }
        console.log(CLASS_NAME, METHOD_NAME, 'Entered then((response1)');
        //Extract details from the search
        searchResults = response1.data.property.map((i) => ({
          id: i.identifier.obPropId,
          county_fip: i.identifier.fips,
          title: i.address.oneLine,
          zip_code: i.address.postal1,
          avmAmount: i.avm.amount.value,
          livingsize_sqft: i.building.size.livingsize,
          avm_state_code: i.address.countrySubd,
          line1: i.address.line1,
          line2: i.address.line2,
          gla: i.building.size.livingsize.toString(),
          bath_full: i.building.rooms.bathsfull,
          bath_half: i.building.rooms.bathshalf,
          stories: i.building.summary.levels
        }));
        if (response1.data.property === [] || searchResults === []) {
          console.log(CLASS_NAME, METHOD_NAME, "avmATTOM.searchResults.then.response1:searchResults == [] || response1.data.property === []: ", searchResults);
          this.setState({
            isLoading: false,
            options: searchResults,
            chart_title: 'Try another address search',
            home_address: term,
            title: term,
            datasets: _defaultDatasets
          });
        } else {
          METHOD_NAME = "avmATTOM.searchResults.else.loop:";
          console.log(CLASS_NAME, METHOD_NAME, "searchResults[0]: ", searchResults[0]);
          avmStateCode = searchResults[0].avm_state_code;
          addressOneLine = searchResults[0].title;
          livingSizeSqft = searchResults[0].livingsize_sqft;
          countyFIP = searchResults[0].county_fip;
          avmAmount = searchResults[0].avmAmount;
          coverage = calculateCoverage(avmAmount);
          searchResults[0].coverage = coverage;
          this.setState({ coverage: coverage });
          // //////////// Lead data update /////////
          saveLeadsRawData("After_avm_detail_response", searchResults[0]);
          // //////////// Lead data update ends/////////
          if (avmAmount === null || avmAmount === 0) {
            displayError = true;
            return null;
          }
          zip_code = searchResults[0].zip_code;
          let addressDetails = {
            'propnum': '0',
            'month': '1',
            'day': '1',
            'year': '2020',
            'address': addressOneLine,
            'zip': zip_code,
            'quality_code': '1',
            'gla': searchResults[0].gla
          };
          addressDetails['bath_full'] = searchResults[0].bath_full !== null ? searchResults[0].bath_full.toString() : '1';
          addressDetails['bath_full'] = addressDetails['bath_full'] !== '0' ? addressDetails['bath_full'] : '1';
          addressDetails['bath_half'] = searchResults[0].bath_half !== null ? searchResults[0].bath_half.toString() : '1';
          addressDetails['bath_half'] = addressDetails['bath_half'] !== '0' ? addressDetails['bath_half'] : '1';
          addressDetails['stories'] = searchResults[0].stories !== null ? searchResults[0].stories.toString() : '1';
          addressDetails['stories'] = addressDetails['stories'] !== '0' ? addressDetails['stories'] : '1';


          this.setState({
            isLoading: true
          });
          getSetDwellingAmountData(addressDetails);
          console.log(CLASS_NAME, METHOD_NAME, "Inputs for getPropertyTaxRate: avmStateCode: ", avmStateCode, " # countyFIP:", countyFIP);
          getPropertyTaxRateFromMongo({ state_code: avmStateCode, county_fip: countyFIP }).then((response2) => {
            let METHOD_NAME = "getPropertyTaxRateFromMongo.then:";
            console.log(CLASS_NAME, METHOD_NAME, "response2: ", JSON.stringify(response2, null, " "));

            if (response2 != null && response2.data != null && response2.data != null) {
              const searchResults2 = response2.data.map((i) => ({
                average_ret_rate_county: i.average_ret_rate_county,
              }));
              console.log(CLASS_NAME, METHOD_NAME, "searchResults2: ", JSON.stringify(searchResults2, null, " "));
              if (searchResults2 != null
                && searchResults2[0].average_ret_rate_county != null) {
                averageRETRateCounty = searchResults2[0].average_ret_rate_county;
                datasets[0].data = setGetTheDataArray({ "source": `${METHOD_NAME}`, "avmAmount": avmAmount, "averageRETRateCounty": averageRETRateCounty, "avmStateCode": avmStateCode, "livingSizeSqft": livingSizeSqft });
                stateCode = avmStateCode;
                datasets = [{
                  data: datasets[0].data,
                  backgroundColor: _backgroundColor,
                  hoverBackgroundColor: _hoverBackgroundColor,
                  hoverBorderColor: _hoverBorderColor,
                  hoverBorderWidth: _hoverBorderWidth
                }];
                this.setState({
                  isLoading: false,
                  options: searchResults,
                  chart_title: 'Please enter Gender, Primary Residence, & Age for Life Insurance estimate',
                  home_address: searchResults[0].title,
                  title: searchResults[0].title,
                  mortgageAmount: mortgageAmount,
                  propertyTaxAmount: propertyTaxAmount,
                  utilityAmount: utilityAmount,
                  lifeInsuranceAmount: lifeInsuranceAmount,
                  datasets: datasets
                });
              }
              // Following method sets: homeInsuranceAmount
              getSetHomeInsuranceFromMongo({ liability: liabilityAmount, zip_code: zip_code }).then((response) => {
                let METHOD_NAME = "getSetHomeInsuranceFromMongo.then:";
                if (response != null) {
                  console.log(CLASS_NAME, METHOD_NAME, "response:", response);
                  searchResults = response.data.map((i) => ({
                    id: i._id,
                    avg_ann_prem: i.avg_ann_prem,
                    avg_dwelling: i.avg_dwelling
                  }));

                  if (searchResults != null && searchResults[0] != null && searchResults[0].avg_ann_prem != null) {
                    avg_ann_prem = searchResults[0].avg_ann_prem;
                    avg_dwelling = searchResults[0].avg_dwelling;
                    // ------------- Extrapulation ---------------
                    // For   avg_dwelling    avg_ann_prem
                    // For input dwelling    x
                    // x = avg_ann_prem * dwelling / avg_dwelling

                    let tempVal = (dwellingAmount * avg_ann_prem) / (avg_dwelling * 12); // Per month /12
                    homeInsuranceAmount = tempVal.toFixed();
                    console.log("getSetHomeInsuranceFromMongo.then:", "dwellingAmount:", dwellingAmount);
                    // console.log("getSetHomeInsuranceFromMongo.then:", "avg_ann_prem:", avg_ann_prem);
                    // console.log("getSetHomeInsuranceFromMongo.then:", "avg_dwelling:", avg_dwelling, " # searchResults[0].avg_dwelling: ", searchResults[0].avg_dwelling);
                    console.log("getSetHomeInsuranceFromMongo.then:", "homeInsuranceAmount:", homeInsuranceAmount);

                    mortgageAmount = Utility.calculateMortgageAmount(avmAmount);
                    datasets[0].data = setGetTheDataArray({ "source": `${METHOD_NAME}`, "avmAmount": avmAmount, "mortgageAmount": mortgageAmount, "propertyTaxAmount": propertyTaxAmount, "homeInsurance": homeInsuranceAmount, "avmStateCode": avmStateCode, "livingSizeSqft": livingSizeSqft });
                    let localState = {
                      isLoading: false,
                      avmAmount: avmAmount,
                      propertyTaxAmount: propertyTaxAmount,
                      lifeInsuranceAmount: lifeInsuranceAmount,
                      homeInsuranceAmount: homeInsuranceAmount,
                      mortgageAmount: mortgageAmount,
                      utilityAmount: utilityAmount,
                      title: addressOneLine,
                      chart_title: chartTitle,
                      labels: _chartLabels,
                      datasets: [{
                        data: datasets[0].data,
                        backgroundColor: _backgroundColor,
                        hoverBackgroundColor: _hoverBackgroundColor,
                        hoverBorderColor: _hoverBorderColor,
                        hoverBorderWidth: _hoverBorderWidth
                      }],
                    };
                    // //////////// Lead data update /////////
                    saveLeadsRawData("After_getSetHomeInsuranceFromMongo", localState);
                    // //////////// Lead data update ends/////////
                    this.setState(localState);
                    if (gender !== null && gender !== 'OO'
                      && age !== null && age !== 'O') {
                      this.getLifeInsuranceScore();
                    }
                  } else {
                    console.log(CLASS_NAME, METHOD_NAME, "then.searchResults == null");
                    this.setState({ isLoading: false });
                  }
                } else {
                  console.log(CLASS_NAME, METHOD_NAME, "response == null");
                }
              }, (error) => {
                console.log(CLASS_NAME, METHOD_NAME, "error:", error);
              });
            }// response2 if loop
          }, (error) => {
            console.log("getPropertyTaxRateFromMongo:", error);
          });
        }
      });
      if (displayError) {
        let errorMessage = 'No matching results, try another address';
        this.setState({ isLoading: false, error: true, errorMsg: errorMessage });
        saveLeadsRawData(`1000-${METHOD_NAME}`, { email: email_address, addressOneLine: term, error: errorMessage });
        displayError = false;
      }
    }

  };
  render() {
    if (onlyAtPageLoad) {
      getClientWindowDetails();
      onlyAtPageLoad = false;
    }
    return (
      <React.Fragment>
        <div style={{
          marginTop: 2
        }}
        >

          <MDBContainer>
            <div id="error" class={!this.state.error ? 'hiddenDiv' : 'fa fa-trash'}>
              {/* &nbsp;<AlertCompo error={this.state.error} errorMsg={this.state.errorMsg} /> */}
            </div>
            <div id="normal" class={!this.state.error ? '' : 'hiddenDiv'}>
              &nbsp;
          </div>
            <div className="h-auto">

              <SplitPane
                split="vertical"
                minSize={400}
                maxSize={400}
              >
                <div style={{
                  marginTop: 25, marginLeft: 15, marginRight: 15, marginBottom: 5, paddingLeft: 15,
                }}
                  className={styles.leftPane} >
                  <h6 style={{
                    marginLeft: -10, paddingLeft: -10,
                  }}>Enter your home address and preferences</h6>

                  <Form onSubmit={this.onSubmit}>
                    <Row><Col>
                      <div>
                        <FormGroup controlId="this.state.email_address" >
                          <label class="required-field form-label">Email address</label>
                          <FormControl type="text" placeholder="Email address" onChange={this.onChangeEmailAddress} value={email_address} />
                        </FormGroup>
                      </div>
                    </Col>
                      <Col>
                        <div>
                          <FormGroup
                            onChange={this.onChangeIsPrimaryAddress}
                            controlId="this.onChangeIsPrimaryAddress">
                            <FormLabel>Primary Residence</FormLabel>
                            <FormControl as="select" value={this.state.is_primary_address}>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </FormControl>
                          </FormGroup>
                        </div>
                      </Col> </Row>
                    <Row><Col><div class='google-places-autocomplete'>
                      <label class="required-field form-label">Home address</label>
                      <GooglePlacesAutocomplete
                        placeholder="Your home address"
                        loader={<img src={loader} alt=''/>}
                        class='google-places-autocomplete-input'
                        ref={(instance) => { reactReference = instance }}
                        onSelect={({ description }) => {
                          let METHOD_NAME = "onSelect:";
                          console.log(CLASS_NAME, METHOD_NAME, "description:", description);
                          this.setState({ term: description });
                          this.onHandleSearch(description);
                          // if(description.indexOf(', ') != -1) {}
                        }}
                        componentRestrictions={{ country: "us" }}
                        minLengthAutocomplete="3"
                      />
                    </div></Col></Row>
                    {/* onSelect={console.log} onHandleSearch <Autocomplete
                      style={{ width: '100%' }}
                      onPlaceSelected={(place) => {
                        console.log('Autocomplete:', place);
                      }}
                      types={['()']}
                      componentRestrictions={{ country: "us" }}
                    /> */}

                    {/* <Row><Col><div className="form-group">
                    <label class="required-field form-label">Home address</label>
                    <AsyncTypeahead
                      id="searchItemId"
                      {...this.state}
                      labelKey="title"
                      isLoading={this.state.isLoading}
                      onSearch={this.onHandleSearch}
                      useCache={false}
                      ref={reactReference}
                      placeholder="Your home address"
                      renderMenuItemChildren={(option, props) => (
                        <SearchResultMenuItem key={option.id} item={option} />
                      )}
                    />
                  </div></Col></Row> */}
                    <Row><Col>
                      <div>
                        <FormGroup controlId="this.select.gender" >
                          <FormLabel>Gender</FormLabel>
                          <FormControl as="select" onChange={this.onChangeGender} value={this.state.gender}>
                            <option value='3'>Select</option>
                            <option value="1">Male</option>
                            <option value="0">Female</option>
                            <option value='2'>Other</option>
                          </FormControl>
                        </FormGroup>
                      </div>
                    </Col><Col><div>
                      <FormGroup controlId="this.state.age">
                        <FormLabel>Age</FormLabel>
                        <FormControl as="select" value={this.state.age} onChange={this.onChangeAge}>
                          <option value="O">Select</option>
                          <option value="< 25">&lt; 25</option>
                          <option value="26 - 30">26 - 30</option>
                          <option value="31 - 35">31 - 35</option>
                          <option value="36 - 40">36 - 40</option>
                          <option value="41 - 45">41 - 45</option>
                          <option value="46 - 50">46 - 50</option>
                          <option value="51 - 55">51 - 55</option>
                          <option value="> 55">&gt; 55</option>
                        </FormControl>
                      </FormGroup>
                    </div></Col></Row>
                    <Row><Col><div>
                      <FormGroup
                        onChange={this.onChangeHealth}
                        controlId="this.select.health"
                      >
                        <FormLabel>Health</FormLabel>
                        <FormControl as="select" value={this.state.health} onChange={this.onChangeHealth}>
                          {/*<option value='O'>Select</option> */}
                          <option value="0">Excellent</option>
                          <option value="1">Good</option>
                          <option value="2">Average</option>
                          <option value="3">Poor</option>
                        </FormControl>
                      </FormGroup></div></Col><Col><div>
                        <FormGroup controlId="this.select.is_smoker" >
                          <FormLabel>Smoker</FormLabel>
                          <FormControl as="select" value={this.state.is_smoker} onChange={this.onChangeIsSmoker}>
                            {/*<option value='O'>Select</option> */}
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </FormControl>
                        </FormGroup>
                      </div></Col></Row>
                    <Row><Col><div>
                      <FormGroup
                        onChange={this.onChangeCoverage}
                        controlId="this.select.coverage"
                      >
                        <FormLabel>Coverage desired</FormLabel>
                        <FormControl as="select" value={this.state.coverage}>
                          <option value="">Coverage</option>
                          <option value="100000">$100K</option>
                          <option value="200000">$200K</option>
                          <option value="300000">$300K</option>
                          <option value="400000">$400K</option>
                          <option value="500000">$500K</option>
                          <option value="600000">$600K</option>
                          <option value="700000">$700K</option>
                          <option value="800000">$800K</option>
                          <option value="900000">$900K</option>
                          <option value="1000000">$1M</option>
                          <option value="1250000">$1.25M</option>
                          <option value="1500000">$1.5M</option>
                          <option value="1750000">$1.75M</option>
                          <option value="2000000">$2M</option>
                        </FormControl>
                      </FormGroup></div></Col><Col><div>
                        <FormGroup
                          onChange={this.onChangePolicyTennure}
                          controlId="this.select.policyTennure"
                        >
                          <FormLabel>Term desired</FormLabel>
                          <FormControl as="select" value={this.state.policyTennure}>
                            <option value="10">10 Years</option>
                            <option value="15">15 Years</option>
                            <option value="20">20 Years</option>
                            <option value="25">25 Years</option>
                            <option value="30">30 Years</option>
                            <option value="35">35 Years</option>
                            <option value="40">40 Years</option>
                          </FormControl>
                        </FormGroup></div></Col></Row>
                    <div class="rowStouted">
                      <label>
                        <input checked={!this.state.output_email_flag} onChange={this.onChangeOptOut}
                          type="checkbox" id="customControlAutosizing" class="checkboxStyle" />&nbsp;We promise no spam - Your information stays with Bubble. But uncheck to opt out
                        </label><br />
                    </div>
                    <div class="rowStouted">
                      <label class="required-field form-label">Costs are estimates only and actual costs may vary based on the above and other individual attributes. Mortgage costs assume a 30-year fixed loan on 80% of the home value estimate.<br />
                      </label>
                    </div>
                  </Form>
                </div>
                <div
                  style={{
                    height: 531, width: 540,
                    marginTop: 25, marginLeft: 15, marginRight: 15, marginBottom: 5, paddingLeft: 15,
                    paddingRight: 4, borderLeft: 1, borderLeftStyle: "double", borderWidth: 4, borderLeftColor: "#7F6289"
                  }}
                  className={styles.rightPane}
                >
                  <h6>Estimated monthly costs</h6>
                  <Form onSubmit={this.onSubmit2}>
                    <Container fluid>
                      <Row>
                        <Col>
                          <FormGroup controlId="this.state.avmAmount" >
                            <FormLabel>Home value estimate</FormLabel>
                            <NumberFormat class="form-control" placeholder="--" displayType={'input'} thousandSeparator={true} prefix={'$'} onChange={this.onChangeAvmAmount} value={this.state.avmAmount} />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup controlId="this.state.propertyTaxAmount" >
                            <FormLabel><small><span class="propertyTax">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></small>&nbsp;Property Tax/MO</FormLabel>
                            <NumberFormat class="form-control" placeholder="--" displayType={'input'} thousandSeparator={true} prefix={'$'} onChange={this.onChangePropertyTaxAmount} value={this.state.propertyTaxAmount} />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup controlId="this.state.utilityAmount" >
                            <FormLabel><small><span class="utilities">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></small>&nbsp;Utilities/MO</FormLabel>
                            <NumberFormat class="form-control" placeholder="--" displayType={'input'} thousandSeparator={true} prefix={'$'} onChange={this.onChangeUtilityAmount} value={this.state.utilityAmount} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div>
                            <FormLabel><small><span class="mortgage">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></small>&nbsp;Mortgage/MO</FormLabel>
                            <div>
                              <div class={this.state.mortgageAmount != null && this.state.mortgageAmount > 0 ? "customTextField" : "hiddenDiv"}>
                                <NumberFormat placeholder="--" readOnly="true" displayType={'text'} thousandSeparator={true} prefix={'$'} value={this.state.mortgageAmount} />
                              </div>
                              <div class={this.state.mortgageAmount == null || this.state.mortgageAmount === '--' || this.state.mortgageAmount === '' ? "dummyDiv" : "hiddenDiv"}>--
                            </div>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <FormLabel><small><span class="homeInsurance">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></small>&nbsp;Home insurance/MO</FormLabel>
                            <div>
                              <div class={this.state.homeInsuranceAmount != null && this.state.homeInsuranceAmount > 0 ? "customTextField" : "hiddenDiv"}>
                                <NumberFormat placeholder="--" readOnly="true" displayType={'text'} thousandSeparator={true} prefix={'$'} value={this.state.homeInsuranceAmount} />
                              </div>
                              <div class={this.state.homeInsuranceAmount == null || this.state.homeInsuranceAmount === '--' || this.state.homeInsuranceAmount === '' ? "dummyDiv" : "hiddenDiv"}>--
                            </div>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <FormLabel><small><span class="lifeInsurance">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></small>&nbsp;Life insurance/MO</FormLabel>
                            <div>
                              <div class={this.state.lifeInsuranceAmount != null && this.state.lifeInsuranceAmount > 0 ? "customTextField" : "hiddenDiv"}>
                                <NumberFormat placeholder="--" readOnly="true" displayType={'text'} thousandSeparator={true} prefix={'$'} value={this.state.lifeInsuranceAmount} />
                              </div>
                              <div class={this.state.lifeInsuranceAmount == null || this.state.lifeInsuranceAmount === '--' || this.state.lifeInsuranceAmount === '' ? "dummyDiv" : "hiddenDiv"}>--
                            </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <div class={propertyTaxAmount == null ? "hiddenDiv" : "chart-header"}>
                      <h6>Estimated Monthly expenses for <span class="chart-header-purple">{addressOneLine.split(',')[0]}</span> is {Utility.getThousandsSeparatedStr(totalMonthlyExpenses)}</h6>
                    </div>
                    <div class="chart-container">
                      <BallRotate
                        color={'#7c648c'}
                        loading={this.state.isLoading}
                      />
                      <div className={styles.rightPane}>
                        <Doughnut
                          options={{ percentageInnerCutout: 57, responsive: true, maintainAspectRatio: false }, {    //eslint-disable-line
                            legend: { display: false, position: 'bottom', fullWidth: false },
                            pieceLabel: {
                              render: function (args) {
                                let returnValue = '';
                                let preFix;
                                let postFix = '';
                                preFix = args.label === 'Mortgage' ? '---  ' : '';
                                postFix = args.label !== 'Mortgage' ? '  ---' : '';
                                postFix = args.label === 'Home Insurance' ? ' -' : postFix;

                                returnValue = args.label !== '' ? preFix + args.label + ' ' + args.percentage + '% ($' + args.value + ')' + postFix : '';
                                return returnValue;
                              },
                              fontColor: '#333333',
                              position: 'outside',
                              fontSize: 12,
                              segment: true,
                              showZero: true,
                              showActualPercentages: true,
                              segmentColor: '#000',
                              fontStyle: "'Lato', sans-serif"
                            },
                            elements: {
                              center: {
                                text: lifeInsuranceText,
                                color: '#333333', // Default is #000000
                                fontStyle: 'Arial', // Default is Arial
                                sidePadding: 10, // Default is 20 (as a percentage)
                                minFontSize: 13, // Default is 20 (in px), set to false and text will not wrap.
                                lineHeight: 15 // Default is 25 (in px), used for when text wraps
                              }
                            },
                            title: {
                              display: true,
                              text: this.state.chart_title,
                              padding: 0
                            }
                          }}
                          data={{
                            labels: this.state.labels, datasets: this.state.datasets
                          }} />
                      </div>
                    </div>
                  </Form>
                  {/* <h3 align="center">Leads Raw Data</h3>
                <table
                  className="table table-striped"
                  style={{ marginTop: 20 }}
                >
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Age</th>
                      <th>Health</th>
                      <th colSpan="2">Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.tabRow()}</tbody>
                </table> */}
                </div>
              </SplitPane>
            </div>
            <div class={debugMode ? 'debugDiv' : 'hiddenDiv'}>
              <h3 align="center">Debug Data</h3>
              <table
                className="table table-striped"
                style={{ marginTop: 10 }}
              >
                <div class="customTableRow">
                  <tr>
                    <td>Variable Name 1111111111111111111111</td>
                    <td>Value 2222222222222222222222</td>
                    <td>More Info 3333333333333333333333</td>
                    <td></td><td></td><td></td>
                  </tr>
                </div>
                <div class={homeInsuranceAmount == null ? "hiddenDiv" : "customTableRow"}>
                  <tr>
                    <td>apprImprValue: {apprImprValue}</td>
                    <td>assdImprValue: {assdImprValue}</td>
                    <td>dwellingAmount: {dwellingAmount}</td>
                    <td><b>homeInsuranceAmount: {homeInsuranceAmount}</b></td><td></td><td></td>
                  </tr>
                </div>
                <div class={propertyTaxAmount == null ? "hiddenDiv" : "customTableRow"}>
                  <tr>
                    <td>(deprecated) assessment_tax_taxPerSizeUnit: {assessment_tax_taxPerSizeUnit} # assessment_tax_taxAmt: {assessment_tax_taxAmt} # assessment_tax_taxYear: {assessment_tax_taxYear}</td>
                    <td>(deprecated) mktTtlValue_assdttlvalue: {mktTtlValue_assdttlvalue}</td><td>propertyTaxRate: {propertyTaxRate}</td>
                    <td>avmAmount: {avmAmount}</td>
                    <td>State Code: {avmStateCode} # County FIP: {countyFIP}</td>
                    <td>averageRETRateCounty: {averageRETRateCounty}</td>
                    <td><b>propertyTaxAmount(taxRate*AVM/(10*12)): {propertyTaxAmount}</b></td>
                  </tr>
                </div>
                <div class={protocol == null ? "hiddenDiv" : "customTableRow"}>
                  <tr>
                    <td>domain: {domain}</td>
                    <td>Protocol: {protocol}</td>
                    <td>port: {port}</td><td>debugMode: {debugMode}</td><td></td><td></td>
                  </tr>
                </div>
              </table>
            </div>
          </MDBContainer>
        </div >
      </React.Fragment>);
  }
}
