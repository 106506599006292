import React from "react";

import bubble_logo from "./assets/icon-logo-browser-extension.svg";
//target="_blank" rel="noopener noreferrer"
const AgentHeader = ({ agentName, brokerName, brokerLogoS3Link, address, brokerWebSite }) => {
  const url = brokerWebSite ? brokerWebSite : 'http://www.getmybubble.com/contact-us';
  return (
    <div className="d-flex flex-row justify-content-between">
      <div className="d-flex flex-column">

        <div className="d-flex b-logo">
            <img src={bubble_logo} alt="Bubble Logo" />
            <span className="chart-header-purple">
                Bubble Personal Insurance Co-Pilot
            </span>
        </div>

        {/* <div className="d-flex agent">
            <div className="img-crop">
                <img src={brokerLogoS3Link} alt="Logo" />
            </div>
            <div className="d-flex flex-column">
                <span className="agent-name">  {agentName} </span> 
                <span className="agency-name"> {brokerName} </span> 
            </div>
            <div className='agentlink'>
                <a role="button" href={url} onClick={e => {e.preventDefault(); window.open(url, '_blank') }} >
                    Visit Website
                </a>
            </div>
        </div> */}

        <div className="d-flex flex-column address">
            <span>Home Address</span> 
            <div>{address}</div>
        </div>

      </div>
    </div>
  );
};

export default AgentHeader;
