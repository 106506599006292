import React from "react";
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';
import ComponentHelper from "./ComponentHelper";
import Utility from "./Utility";
import GlobalVariables from "../GlobalConstantsVariables";
//import { del } from "idb-keyval";

const CLASS_NAME = ":MortgageComponent:";
const FINAL_COUNT = 3;
let calledOnceFlag = false;
let calculatedFieldsLocalCopy = {};
let checkPointArray = [];
let amountsFetchedCount = 0;   //eslint-disable-line

export default class MortgageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            isLoading: true,
            interestRate: this.props.interestRate || GlobalVariables.getInterestRatePrimaryResidence(),
            avmAmount: this.props.avmAmount,
            loanAmount: (Number(this.props.avmAmount.toString()) * 80 / 100),
            coverageDesired: this.props.coverageDesired,
            utilityAmount: this.props.utilityAmount,
            propertyTaxAmount: this.props.propertyTaxAmount,
            loanType: this.props.loanType,
            mortgageAmount: this.props.mortgageAmount,
            homeInsuranceAmount: this.props.homeInsuranceAmount,
            lifeInsuranceAmount: this.props.lifeInsuranceAmount,
            currentCount: 1
        }
        this.fetchCalculatedFields = this.fetchCalculatedFields.bind(this);
        this.onAvmAmountTextChange = this.onAvmAmountTextChange.bind(this);
        this.onAfterChangeAvmAmount = this.onAfterChangeAvmAmount.bind(this);
        this.onChangeAvmAmount = this.onChangeAvmAmount.bind(this);
        this.onAfterChangeLoanAmount = this.onAfterChangeLoanAmount.bind(this);
        this.onChangeLoanAmount = this.onChangeLoanAmount.bind(this);

        this.onAfterChangeInterestAmount = this.onAfterChangeInterestAmount.bind(this);
        this.onChangeInterestAmount = this.onChangeInterestAmount.bind(this);

        this.onAfterChangeMortgageAmount = this.onAfterChangeMortgageAmount.bind(this);
        this.onChangeMortgageAmount = this.onChangeMortgageAmount.bind(this);

        if (!this.props.mortgageWasInitiated) {
            this.mortgageDefaultValues = Object.assign({}, this.state);
            delete this.mortgageDefaultValues['isLoading'];
            delete this.mortgageDefaultValues['currentCount'];
            delete this.mortgageDefaultValues['id'];
        }
    }

    //text input gives non number value so has to be converted to number
    onLoanTypeChange = e => {
        let METHOD_NAME = "onLoanTypeChange";
        let loanType = e.target.value;
        console.log("mort:Entered:Input:interstRate:", loanType, METHOD_NAME);
        loanType = parseInt(loanType.replace(' yrs', ''));
        this.onLoanTypeChange(loanType);
    }

    // sliders gives number value and can be used to directly update state
    onLoanTypeChange = value => {
        const key = "loanType"
        this.setState({ [key]: value })
        this.props.onChangeItem(key, value);
    }

    // input text
    onChangeInterestRate = e => {
        let METHOD_NAME = "onChangeInterestRate";
        let interestRate = e.target.value;
        console.log("mort:Entered:Input:interstRate:", interestRate, METHOD_NAME);

        interestRate = parseInt(interestRate.replace('%', ''));

        this.setState({ interestRate });
        this.props.onChangeItem('interestRate', interestRate)
    }
    onAfterChangeInterestAmount = (value) => {
        let METHOD_NAME = ":onAfterChangeInterestAmount:";
        console.log("mort:Entered:value: ", value, METHOD_NAME);
        let mortgageAmountLocal = Utility.calculateMortgageAmount(this.state.avmAmount, true, this.state.loanAmount, value);
        this.setState({ mortgageAmount: mortgageAmountLocal });
        console.log("mort:mortgageAmountLocal: ", mortgageAmountLocal, METHOD_NAME);
        this.props.onChangeItem('mortgageAmount', mortgageAmountLocal);
        this.props.onChangeItem('interestRate', value);
    }
    // slider value
    onChangeInterestAmount = (value) => {
        this.setState({
            interestRate: value
        })
    }

    //text input
    onAvmAmountTextChange(e) {
        let METHOD_NAME = "onAvmAmountTextChange:";
        let avmAmount = e.target.value;
        console.log("mort:Entered:Input:avmAmount:", avmAmount, " # averageRETRateCounty: ", METHOD_NAME);
        avmAmount = parseInt(avmAmount.replace('$', '').replace(',', '').replace(',', ''));
        this.onAvmAmountChange(avmAmount)
    }

    onAfterChangeAvmAmount = (value) => {
        let METHOD_NAME = ":onAfterChangeAvmAmount:";
        console.log("mort:Entered:avmAmount:value: ", value, " # this.state.avmAmount:", this.state.avmAmount, METHOD_NAME);
        let hash = {};
        hash['id'] = this.state.id;
        hash['avmAmount'] = value;
        let mortgageAmountLocal = Utility.calculateMortgageAmount(value, true, this.state.loanAmount, this.state.interestRate);
        this.setState({ mortgageAmount: mortgageAmountLocal });
        console.log("mort:mortgageAmountLocal: ", mortgageAmountLocal, METHOD_NAME);
        hash['mortgageAmount'] = mortgageAmountLocal;
        this.props.onChangeAmountsFromHash('StepMortgage', hash);
    }
    // slider input
    onChangeAvmAmount = (value) => {
        if (!value || value < 1) {
            return;
        }
        this.setState({
            avmAmount: value,
        });
    }
    onAfterChangeMortgageAmount = (value) => {
        this.props.onChangeItem('mortgageAmount', value);
    }
    onAfterChangeLoanAmount = (value) => {
        let METHOD_NAME = ":onAfterChangeLoanAmount:";
        console.log("mort:Entered:value: ", value, METHOD_NAME);
        let mortgageAmountLocal = Utility.calculateMortgageAmount(this.state.avmAmount, true, value, this.state.interestRate);
        this.setState({ mortgageAmount: mortgageAmountLocal });
        console.log("mort:mortgageAmountLocal: ", mortgageAmountLocal, METHOD_NAME);
        this.props.onChangeItem('mortgageAmount', mortgageAmountLocal);
    }

    // sliders gives number value and can be used to directly update state
    onChangeLoanAmount = (value) => {
        let showLoanWarning;
        if (value > this.state.avmAmount) {
            value = this.state.avmAmount
            showLoanWarning = true;
            setTimeout(
                () => this.setState({ showLoanWarning: null }), 2000
            )
        }

        if (isNaN(this.state.avmAmount)) {
            value = 0;
        }
        this.setState({
            loanAmount: value,
            showLoanWarning
        });
        this.props.onChangeItem('loanAmount', value)
    }
    onChangeMortgageAmount = (value) => {
        if (!value || value < 1) {
            return;
        }
        this.setState({ mortgageAmount: value });
        this.props.onChangeItem('mortgageAmount', value)
    }

    onChangeHomeInsuranceAmount = e => {
        let METHOD_NAME = "onChangeHomeInsuranceAmount";
        let homeInsuranceAmount = e.target.value;
        console.log("mort:Entered:Input:homeInsuranceAmount:", homeInsuranceAmount, METHOD_NAME);

        homeInsuranceAmount = parseInt(homeInsuranceAmount.replace('$', '').replace(',', '').replace(',', ''));

        this.setState({ homeInsuranceAmount });
        this.props.onChangeItem("homeInsuranceAmount", homeInsuranceAmount)
    }

    //text input
    onPropertyTaxTextInputChange = e => {
        let propertyTaxAmount = e.target.value;
        const METHOD_NAME = "PropertyTaxTextInputChange"
        console.log("mort:Entered:Input:propertyTaxAmount:", propertyTaxAmount, METHOD_NAME);
        propertyTaxAmount = parseInt(propertyTaxAmount.replace('$', '').replace(',', '').replace(',', ''));
        this.onPropertyTaxAmountChange(propertyTaxAmount);
    }

    //slider input
    onPropertyTaxAmountChange = (value) => {
        const key = "propertyTaxAmount"
        const METHOD_NAME = "onPropertyTaxAmountChange"
        console.log("mort:Entered:Input:propertyTaxAmount:", value, METHOD_NAME);

        if (!value || value === 0) {
            return;
        }
        this.props.onChangeItem(key, value);
        this.setState({
            propertyTaxAmount: value
        })
    }

    // text input
    onChangeUtility = e => {
        let utility = e.target.value;
        utility = parseInt(utility.replace('$', '').replace(',', '').replace(',', ''));

        this.onUtilityAmountChange(utility);
    }

    // slider input
    onUtilityAmountChange = (value) => {
        const key = "utilityAmount"

        if (!value || value < 1) {
            return;
        }

        this.props.onChangeItem(key, value);
        this.setState({
            [key]: value
        })
        this.props.onChangeItem(key, value)
    }

    fetchCalculatedFields(id) {
        let METHOD_NAME = ":fetchCalculatedFields:";
        console.log("mort:Entered:id: ", id, METHOD_NAME);
        if (!id) {
            return;
        }

        if (checkPointArray.length > 1) {
            this.props.refreshDataArrayLink("whatIfScenarioDataArray");
            checkPointArray.push("whatIfScenarioDataArray");
        } else if (checkPointArray.length === FINAL_COUNT) {
            clearInterval(this.intervalId);
        }

        console.log("mort:Entered:this.state.calculatedCoverage: ", this.state.calculatedCoverage, METHOD_NAME);
        console.log("mort:Entered:whatIfScenarioDataArray: ", this.props.whatIfScenarioDataArray, METHOD_NAME);
        console.log("mort:Entered:this.state.homeInsuranceAmount: ", this.state.homeInsuranceAmount, METHOD_NAME);
        if (!calledOnceFlag) {
            calledOnceFlag = true;
            let paramName, key;
            console.log("Entered:this.state.calculatedCoverage: ", this.state.calculatedCoverage, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
            if (this.state.calculatedCoverage == null && this.props.listingPriceAmount != null) {
                let calculateCoverageLocal = Utility.calculateCoverage(this.props.listingPriceAmount);
                this.props.onChangeItem('calculateCoverage', calculateCoverageLocal);
                this.setState({ calculateCoverage: calculateCoverageLocal });
                this.setState({ 'avmAmount': this.props.listingPriceAmount });
                console.log("calculateCoverageLocal: ", calculateCoverageLocal, " # this.state.calculatedCoverage: ", this.state.calculatedCoverage, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
            } else if (this.props.listingPriceAmount == null) {
                ComponentHelper.getCachedData2(`${id}++calculatedCoverage`).then((data) => {
                    METHOD_NAME = `:getCachedData2.then:calculatedCoverage`;
                    //console.log("output:data: ", data, "#",Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                    let json = JSON.parse(data.Payload);
                    let cachedValue1 = json.body.output;
                    this.setState({ 'calculatedCoverage': cachedValue1 });
                    this.props.onChangeItem('calculatedCoverage', cachedValue1);
                    console.log('%%% calculatedCoverage: ', cachedValue1, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                    //'getQuotesFromPacLifeNext': ["DONE:solicitationState", "DONE:faceAmount", "riskClass", "gender", "premiumFrequency", "dateOfBirth", "productCode"
                    GlobalVariables.setAPIInputJson(GlobalVariables.getPacLifeNextAPITag(), { 'calculatedCoverage': cachedValue1 });
                    amountsFetchedCount += 1;
                });// calculatedCoverage
                ComponentHelper.getCachedData2(`${id}++avmAmount`).then((data) => {
                    METHOD_NAME = `:getCachedData2.then:avmAmount`;
                    //console.log("output:data: ", data, "#",Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                    let json = JSON.parse(data.Payload);
                    let cachedValue1 = json.body.output;
                    this.setState({ 'avmAmount': cachedValue1 });
                    this.props.onChangeItem('avmAmount', cachedValue1);
                    console.log('%%% avmAmount: ', cachedValue1, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                    //'getQuotesFromPacLifeNext': ["DONE:solicitationState", "DONE:faceAmount", "riskClass", "gender", "premiumFrequency", "dateOfBirth", "productCode"
                    GlobalVariables.setAPIInputJson(GlobalVariables.getPacLifeNextAPITag(), { 'avmAmount': cachedValue1 });
                    amountsFetchedCount += 1;
                    let mortgageAmountLocal = Utility.calculateMortgageAmount(cachedValue1);
                    console.log("%%% avmAmount: ", cachedValue1, " # mortgageAmountLocal: ", mortgageAmountLocal, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                    this.setState({ 'mortgageAmount': mortgageAmountLocal });
                    this.props.onChangeItem('mortgageAmount', mortgageAmountLocal);
                });// avmAmount
            } else {
                let cachedValue1 = Utility.calculateCoverage(this.props.listingPriceAmount);
                this.setState({ 'calculatedCoverage': cachedValue1 });
                this.setState({ 'avmAmount': this.props.listingPriceAmount });
                this.props.onChangeItem('calculatedCoverage', cachedValue1);
                console.log('%%% calculatedCoverage: ', cachedValue1, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                //'getQuotesFromPacLifeNext': ["DONE:solicitationState", "DONE:faceAmount", "riskClass", "gender", "premiumFrequency", "dateOfBirth", "productCode"
                GlobalVariables.setAPIInputJson(GlobalVariables.getPacLifeNextAPITag(), { 'calculatedCoverage': cachedValue1 });
                console.log("%%% this.props.listingPriceAmount: ", this.props.listingPriceAmount, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                let mortgageAmountLocal = Utility.calculateMortgageAmount(this.props.listingPriceAmount);
                console.log("%%% this.props.listingPriceAmount: ", this.props.listingPriceAmount, " # mortgageAmountLocal: ", mortgageAmountLocal, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                this.setState({ 'mortgageAmount': mortgageAmountLocal });
                this.props.onChangeItem('mortgageAmount', mortgageAmountLocal);
                console.log("%%% mortgageAmount: ", mortgageAmountLocal, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                key = `${this.props.id}++mortgageAmount`;
                let value = mortgageAmountLocal;
                ComponentHelper.setDataInCache(key, value);// mortgageAmount
            }
            paramName = 'propertyTaxAmount';
            key = `${id}++${paramName}`;
            ComponentHelper.getCachedData(key);// propertyTaxAmount
            paramName = 'utilityAmount';
            key = `${id}++${paramName}`;
            ComponentHelper.getCachedData(key);// utilityAmount
            // paramName = 'lifeInsuranceAmount';
            // key = `${id}++${paramName}`;
            // ComponentHelper.getCachedData(key);// lifeInsuranceAmount
            // paramName = 'homeInsuranceAmount';
            // key = `${id}++${paramName}`;
            // ComponentHelper.getCachedData(key);//homeInsuranceAmount
        }
        const hash = ComponentHelper.getLocalCacheFor(id);
        delete hash.updatedAt;
        if (Object.entries(calculatedFieldsLocalCopy).toString() !== Object.entries(hash).toString()) {
            console.log("mort:BEFORE this.stateeeeeeeee:", this.state, METHOD_NAME);
            console.log("mort:BEFORE hashhashhash stateeeeeeeee:", hash, METHOD_NAME);
            //   this.setState(hash);
            //    this.props.onChangeAmountsFromHash('Mortgage', hash);
            console.log("mort:After onChangeAmountsFromHash", METHOD_NAME);
        } else {
            console.log("mort:calculatedFieldsLocalCopy === hash", METHOD_NAME);
        }
    }
    resetToDeafult = () => {
        for (const key in this.mortgageDefaultValues) {
            this.setState({ [key]: this.mortgageDefaultValues[key] });
            this.props.onChangeItem(key, this.mortgageDefaultValues[key])
        }
        this.props.resetToDefaultValues()
    }
    timer = () => {
        const METHOD_NAME = ":timer:";
        this.setState({ isLoading: true });
        let localCurrentCount;
        console.log("mort:localCurrentCount:", localCurrentCount, " # this.state.homeInsuranceAmount: ", this.state.homeInsuranceAmount, METHOD_NAME);
        if (!this.props.id) {
            return;
        }
        localCurrentCount = this.state.currentCount - 1;
        this.setState({
            currentCount: localCurrentCount
        });
        this.fetchCalculatedFields(this.props.id);
        if (this.state.currentCount < 1) {
            clearInterval(this.intervalId);
            this.setState({ isLoading: false });
        }
    }
    componentDidMount() {
        // React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
        this.intervalId = setInterval(this.timer.bind(this), 2000);

        if (!this.props.mortgageWasInitiated) {
            for (const key in this.mortgageDefaultValues) {
                this.props.setDefaultValues(key, this.mortgageDefaultValues[key])
            }
            this.props.onChangeItem('mortgageWasInitiated', true)
        }
    }
    componentWillUnmount() {
        // React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
        clearInterval(this.intervalId);
    }
    componentDidUpdate(prevProps, prevState) {
        const METHOD_NAME = ":componentDidUpdate:";
        if (prevState.avmAmount !== this.state.avmAmount) {
            console.log('avmAmount state has changed.', METHOD_NAME, CLASS_NAME);
            let loanAmountLocal = Number((0.8 * this.state.avmAmount).toFixed());
            this.setState({ loanAmount: loanAmountLocal });
        }
    }
    // componentDidUpdate(prevState, prevProps) {
    //     // React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
    //     if (prevState.avmAmount > 0 && prevState.avmAmount !== this.props.avmAmount) {
    //         METHOD_NAME += 'lifeInsuranceAmount !== this.props.lifeInsuranceAmount';
    //         console.log("prevState.lifeInsuranceAmount:", prevState.lifeInsuranceAmount, METHOD_NAME, CLASS_NAME);
    //         console.log("this.props.lifeInsuranceAmount:", this.props.lifeInsuranceAmount, METHOD_NAME, CLASS_NAME);
    //         this.setState({ lifeInsuranceAmount: this.props.lifeInsuranceAmount, isLoading: false });
    //     }
    // }
    calculateMinMaxStep4Slider(inputValue) {
        let outputValueArray = [0, 1000, 50];
        if (inputValue > 1000) {
            outputValueArray = [0, 10000, 1000, ((inputValue / 1000).toFixed() * 1000)];
        } else if (inputValue > 100) {
            outputValueArray = [0, 1000, 100, ((inputValue / 100).toFixed() * 100)];
        } if (inputValue < 100) {
            outputValueArray = [0, 100, 10, ((inputValue / 10).toFixed() * 10)];
        }
        return outputValueArray;
    }
    render() {
        return (
                <div className="mortgage">

                    <div className="d-flex slider-row">

                        <div className="d-flex flex-column fs-slider-block">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="slider-label">
                                    Property Value
                            </span>
                                <div className="label">
                                    {(this.state.avmAmount > 0) ? `$ ${Utility.getFormattedNumberValue((!this.state.avmAmount && this.state.avmAmount < 1000) ? `${this.state.avmAmount}000` : this.state.avmAmount)}` : "Select"} &nbsp;
                            </div>
                            </div>
                            <div className="slider-aligned">
                                <Slider
                                    value={(this.state.avmAmount != null && this.state.avmAmount < 1000) ? `${this.state.avmAmount}000` : this.state.avmAmount}
                                    min={0}
                                    max={4000000}
                                    step={25000}
                                    onAfterChange={this.onAfterChangeAvmAmount}
                                    onChange={this.onChangeAvmAmount} />
                            </div>
                            {/*<div className='d-flex flex-column m-2 slider-info-text' >
                        <FormGroup controlId="this.state.avmAmount" >
                            <NumberFormat className="bubble-input" placeholder="Property Value" displayType={'input'}
                                thousandSeparator={true} prefix={'$'} onChange={this.onAvmAmountTextChange} value={this.state.avmAmount} />
                        </FormGroup>
                    </div>*/}
                        </div>

                        <div className="d-flex flex-column fs-slider-block">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="slider-label">
                                    Loan Amount
                            </span>
                                {this.state.showLoanWarning && <div className="small pt-2">
                                    Loan Amount cannot be more than property value.
                            </div>}
                                <div className="label">
                                    {(this.state.loanAmount > 0) ? `$ ${Utility.getFormattedNumberValue((!this.state.loanAmount && this.state.loanAmount < 1000) ? `${this.state.loanAmount}000` : this.state.loanAmount)}` : "Select"} &nbsp;
                            </div>
                            </div>
                            <div className="slider-aligned">
                                <Slider
                                    value={(this.state.loanAmount != null && this.state.loanAmount < 1000) ? `${this.state.loanAmount}000` : this.state.loanAmount}
                                    min={0}
                                    max={4000000}
                                    step={25000}
                                    onAfterChange={this.onAfterChangeLoanAmount}
                                    onChange={this.onChangeLoanAmount} />
                            </div>
                            {/* {<div className='d-flex flex-column m-2 slider-info-text' >
                        <FormGroup controlId="this.state.loanAmount" >
                            <NumberFormat className="bubble-input" placeholder="Loan Amount" displayType={'input'}
                                thousandSeparator={true} prefix={'$'} onChange={this.onChangeLoanAmount} value={this.state.loanAmount} />
                        </FormGroup>
                        </div>} */}
                        </div>


                    </div>

                    <div className="d-flex slider-row">

                        <div className="d-flex flex-column fs-slider-block">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="slider-label">
                                    Interest Rate
                            </span>
                                <div className="label">
                                    {this.state.interestRate} %&nbsp;
                        </div>
                            </div>
                            <div className="slider-aligned">
                                <Slider
                                    value={this.state.interestRate}
                                    min={0}
                                    max={30}
                                    step={1}
                                    onAfterChange={this.onAfterChangeInterestAmount}
                                    onChange={this.onChangeInterestAmount} />
                            </div>
                            {/*     <div className='d-flex flex-column m-2 slider-info-text' >
                        <FormGroup controlId="this.state.loanAmount" >
                            <NumberFormat className="bubble-input" placeholder="Interest Rate" displayType={'input'}
                                thousandSeparator={true} suffix={"%"} onChange={this.onChangeInterestRate} value={this.state.interestRate} />
                        </FormGroup>
                    </div> */}
                        </div>

                        <div className="d-flex flex-column fs-slider-block">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="slider-label">
                                    Loan Duration
                            </span>
                                <div className="label">
                                    {this.props.loanType} Years &nbsp;
                        </div>
                            </div>
                            <div className="slider-aligned">
                                <Slider
                                    value={this.props.loanType}
                                    min={10}
                                    max={35}
                                    step={5}
                                    onChange={this.onLoanTypeChange} />
                            </div>
                            {/* {   <div className='d-flex flex-column m-2 slider-info-text' >
                        <FormGroup controlId="this.state.loanType" >
                            <NumberFormat
                                placeholder="Loan Type"
                                className="bubble-input"
                                type="text"
                                suffix=" Years"
                                onChange={this.onChangeLoanType}
                                value={this.state.loanType} />
                        </FormGroup>
                    </div> } */}
                        </div>



                    </div>

                    <div className="d-flex slider-row">
                        <div className="d-flex flex-column fs-slider-block">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="slider-label">
                                    Mortgage
                                </span>
                                <div className="label">
                                    {(this.state.mortgageAmount > 0) ? `$ ${Utility.getFormattedNumberValue(this.props.mortgageAmount)}` : "Select"} &nbsp;
                                </div>
                            </div>
                            <div className="slider-aligned">
                                <Slider
                                    value={this.props.mortgageAmount}
                                    min={0}
                                    max={20000}
                                    step={500}
                                    onAfterChange={this.onAfterChangeMortgageAmount}
                                    onChange={this.onChangeMortgageAmount} />
                            </div>
                        </div>
                        <div className="d-flex flex-column fs-slider-block">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="slider-label">
                                    Property Tax
                                </span>
                                <div className="label">
                                    {(this.props.propertyTaxAmount > 0) ? `$ ${Utility.getFormattedNumberValue(this.props.propertyTaxAmount)}` : "Select"} &nbsp;
                                </div>
                            </div>
                            <div className="slider-aligned">
                                <Slider
                                    value={this.props.propertyTaxAmount}
                                    min={0}
                                    max={2000}
                                    step={100}
                                    onChange={this.onPropertyTaxAmountChange} />
                            </div>
                            {/* {<div className='d-flex flex-column m-2 slider-info-text'>
                        <FormGroup controlId="this.state.propertyTaxAmount" >
                            <NumberFormat
                                className="bubble-input"
                                placeholder="Property Tax"
                                displayType={'input'}
                                thousandSeparator={true}
                                onChange={this.onPropertyTaxTextInputChange}
                                prefix={"$"}
                                value={this.state.propertyTaxAmount} />
                        </FormGroup>
                    </div>} */}
                        </div>

                    </div>

                    <div className="d-flex  slider-row">
                        <div className="d-flex flex-column fs-slider-block">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="slider-label">
                                    Utilities
                                </span>
                                <div className="label">
                                    {(this.props.utilityAmount > 0) ? `$ ${Utility.getFormattedNumberValue(this.props.utilityAmount)}` : "Select"} &nbsp;
                                </div>
                            </div>
                            <div className="slider-aligned">
                                <Slider value={this.props.utilityAmount} min={0} max={1000} step={50}
                                    onChange={this.onUtilityAmountChange} />
                            </div>
                        </div>
                            {/* {       <div className='d-flex flex-column m-2 slider-info-text'>
                    <FormGroup controlId="this.state.utilityAmount" >
                        <NumberFormat
                            className="bubble-input"
                            placeholder="Utility"
                            displayType={'input'}
                            thousandSeparator={true}
                            onChange={this.onChangeUtility}
                            prefix={"$"}
                            value={this.state.utilityAmount} />
                    </FormGroup>
                </div>} */}
                        <div className="d-flex flex-column fs-slider-block hidden" id="hide-this">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="slider-label">
                                    Utilities
                                </span>
                                <div className="label">
                                    {(this.props.utilityAmount > 0) ? `$ ${Utility.getFormattedNumberValue(this.props.utilityAmount)}` : "Select"} &nbsp;
                                </div>
                            </div>
                            <div className="slider-aligned">
                                <Slider value={this.props.utilityAmount} min={0} max={1000} step={50}
                                    onChange={this.onUtilityAmountChange} />
                            </div>
                        </div>
                    </div>
                    {/* {     <BallRotate
                    color={'#7c648c'}
                    loading={this.state.isLoading}
                />} */}

                    {/* <span className='d-flex flex-row align-items-end mb-3'>
                    <div className="slider-aligned fs-slider-input">
                        <FormLabel><small><span className="mortgage bs-rounded-circle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></small>&nbsp;Mortgage</FormLabel>
                    </div>
                    <div className='d-flex flex-column m-2 slider-info-text'>
                        <FormGroup controlId="this.state.mortgageAmount" >
                            <NumberFormat
                                thousandSeparator={true}
                                className="bubble-input"
                                placeholder="Mortage amount"
                                prefix={'$'}
                                onChange={this.onChangeMortgageAmount}
                                value={this.props.mortgageAmount} />
                        </FormGroup>
                    </div>
                </span>

                <span className='d-flex flex-row align-items-end mb-3'>
                    <div className="slider-aligned fs-slider-input">
                        <FormLabel><small><span className="homeInsurance bs-rounded-circle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></small>&nbsp;Home insurance</FormLabel>
                    </div>
                    <div className='d-flex flex-column m-2 slider-info-text'>
                        <FormGroup controlId="this.props.homeInsuranceAmount" >
                            <NumberFormat
                                thousandSeparator={true}
                                className="bubble-input"
                                placeholder="Home Insurance"
                                onChange={this.onChangeHomeInsuranceAmount}
                                value={this.props.homeInsuranceAmount}
                                prefix={'$'} />
                        </FormGroup>
                    </div>
                </span>

                <span className='d-flex flex-row align-items-end mb-3'>
                    <div className="slider-aligned fs-slider-input">
                        <FormLabel><small><span className="lifeInsurance bs-rounded-circle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></small>&nbsp;Life insurance</FormLabel>
                    </div>
                    <span className='d-flex m-2'>${this.state.lifeInsuranceAmount != null ? parseFloat(this.state.lifeInsuranceAmount).toFixed(2) : ''} </span>
                </span>
                
                <div className="w-100 resetBtnDiv2">
                    <button className="orangeGradient" onClick={this.resetToDeafult}>
                        Reset All
                    </button>
                </div>

                */}
                </div>
        )
    }
}