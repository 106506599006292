import React from 'react';
import { FormGroup } from 'react-bootstrap';
import { BallRotate } from 'react-pure-loaders';
import NumberFormat from 'react-number-format'

import CoverageMultiSelect from "./CoverageMultiSelect.Component";
import ComponentHelper from "./ComponentHelper";

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

let CLASS_NAME = 'InsuranceFilter';
const FINAL_COUNT = 3;
let checkPointArray = [];

export default class InsuranceFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            isLoading: true,
            data: "",
            fullAddress: "",
            dbId: '',
            calculatedCoverage: '',
            homeInsuranceAmount: 'Calculating ',
            currentCount: 3
        }
    }
   /* changeActiveItem = () => {
        let isactive = this.state.isActive;

        if (isactive) {
            this.setState({
                isActive: false
            }, () =>
                this.props.onClick(0))
        } else {
            this.setState({
                isActive: true
            }, () =>
                this.props.onClick(2))
        }
    } */
    fetchCalculatedFields(id) {
        let METHOD_NAME = ":fetchCalculatedFields:";
        console.log("Entered:id: ", id, METHOD_NAME, CLASS_NAME);
        if (id == null || id === '') {
            return;
        }
        console.log("Entered:this.state.calculatedCoverage: ", this.state.calculatedCoverage, METHOD_NAME, CLASS_NAME);
        console.log("Entered:this.state.homeInsuranceAmount: ", this.state.homeInsuranceAmount, METHOD_NAME, CLASS_NAME);

        this.setState({ isLoading: true });
        let paramName, key;
        paramName = "calculatedCoverage";
        key = `${id}++${paramName}`;
        ComponentHelper.getCachedData(key).then((hash) => {
            METHOD_NAME += `:${paramName}:then:`
            console.log("AFTER getCachedData:hash:", hash, METHOD_NAME, CLASS_NAME);
            if (hash[key] != null) {
                let calculatedCoverageRound = Math.round(hash[key] / 100000) * 100000;
                calculatedCoverageRound = calculatedCoverageRound / 1000;
                this.setState({ [paramName]: calculatedCoverageRound });
                this.props.onChangeItem("coverageDesired", calculatedCoverageRound);
                checkPointArray.push(paramName);

                paramName = 'mortgageAmount';
                key = `${id}++${paramName}`;
                ComponentHelper.getCachedData(key).then((hash) => {
                    METHOD_NAME += `:${paramName}:then:`
                    console.log("AFTER getCachedData:hash:", hash, METHOD_NAME, CLASS_NAME);
                    if (hash[key] != null) {
                        this.setState({ [paramName]: hash[key] });
                        this.props.onChangeItem(paramName, hash[key]);
                        checkPointArray.push(paramName);

                        paramName = 'mortgageAmount';
                        key = `${id}++${paramName}`;
                        ComponentHelper.getCachedData(key).then((hash) => {
                            METHOD_NAME += `:${paramName}:then:`
                            console.log("AFTER getCachedData:hash:", hash, METHOD_NAME, CLASS_NAME);
                            if (hash[key] != null) {
                                this.setState({ isLoading: false, [paramName]: hash[key] });
                                this.props.onChangeItem(paramName, hash[key]);
                                checkPointArray.push(paramName);
                            }
                        });
                    }
                });
            }
        });



        if (checkPointArray.length > 1) {
            this.props.refreshDataArrayLink("whatIfScenarioDataArray");
            checkPointArray.push("whatIfScenarioDataArray");
        } else if (checkPointArray.length === FINAL_COUNT) {
            clearInterval(this.intervalId);
        }
    }

    timer = () => {
        const METHOD_NAME = ":timer:";
        let localCurrentCount;
        console.log("localCurrentCount:", localCurrentCount, " # this.state.homeInsuranceAmount: ", this.state.homeInsuranceAmount, METHOD_NAME, CLASS_NAME);
        if (this.props.address === '' || this.props.address == null) {
            this.setState({
                homeInsuranceAmount: ''
            });
            return;
        }
        localCurrentCount = this.state.currentCount - 1;
        this.setState({
            currentCount: localCurrentCount,
            homeInsuranceAmount: this.state.homeInsuranceAmount + '.'
        });
        this.fetchCalculatedFields(this.props.id);
        if (this.state.currentCount < 1) {
            clearInterval(this.intervalId);
            if (this.state.homeInsuranceAmount.startsWith('Calculating')) {
                this.setState({
                    homeInsuranceAmount: this.state.homeInsuranceAmount + '?'
                });
            }
        }
    }
    componentDidMount() {
        // React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
        this.intervalId = setInterval(this.timer.bind(this), 1000);
    }
    componentWillUnmount() {
        // React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
        clearInterval(this.intervalId);
    }
    render() {

        return (
            /*  < OverlayTrigger
                trigger="click"
                placement={'bottom'}
                overlay={
                    < Popover id={`popover-positioned-${'bottom'}`
                    }>
                        <Popover.Content>
                            <div style={{ background: '#F6F6F6', margin: '0', padding: '0' }} className='popDown-content'>
                                <div style={{ width: '80%' }}>
                                    <label>
                                        Coverage desired
                                    </label>
                                    <ValueSlider min={100} max={700} units={'K$'} onChange={this.props.onCoverageDesiredDetails} value={this.props.coverageDesired} />
                                </div>
                                <div style={{ width: '80%' }}>
                                    <label>
                                        Term desired
                                    </label>
                                    <ValueSlider min={10} max={40} units={'years'} onChange={this.props.onTermDesiredDetails} value={this.props.termDesired} />
                                </div>
                            </div>
                        </Popover.Content>
                    </Popover >
                }
            >
                <div
                    className={this.state.isActive ? "active1 dropbtn" : "dropbtn"}
                    onClick={this.changeActiveItem}
                    style={activeItem === 0 || activeItem === 2 ? { textAlign: 'center' } : { pointerEvents: 'none' }}
                >
                    Life Insurance
              </div>
            </OverlayTrigger >
            */

            <section className="column-2">

                <div className='d-flex flex-row align-items-end mb-5'>
                    <div className="slider-aligned"> 
                    COVERAGE
                        <Slider
                            onChange={this.props.onCoverageDesiredDetails}
                            value={this.props.coverageDesired}
                            defaultValue={800}
                            min={100}
                            max={1000} />
                            </div>
                        <div className='d-flex flex-column m-2 slider-info-text' >
                        <FormGroup controlId="this.props.coverageDesired" >
                            <NumberFormat className="bubble-input" placeholder="Bedrooms" displayType={'input'}
                               prefix={"$"} suffix={" K"} value={this.props.coverageDesired} />
                        </FormGroup>
                        </div> 
                </div>
                <div className='d-flex flex-row align-items-end mb-5'>
                    <div className="slider-aligned">  
                    MORTGAGE
                        <Slider onChange={this.props.onMortgageDetails} 
                        defaultValue={700}
                        value={this.props.mortgage} 
                        min={100} 
                        max={1000} />
                        </div>
                    <div className="d-flex flex-column m-2 slider-info-text">
                        <FormGroup controlId="this.props.mortgage" >
                            <NumberFormat className="bubble-input" placeholder="Bedrooms" displayType={'input'}
                               prefix={"$"} suffix={" K"} value={this.props.mortgage} />
                        </FormGroup>
                    </div>
                </div>
                <div className='d-flex flex-row align-items-end mb-5'>
                    <div className="slider-aligned"> 
                        TERM DESIRED
                        <Slider 
                        onChange={this.props.onTermDesiredDetails} 
                        defaultValue={20}
                        value={this.props.termDesired}
                        min={10} 
                        max={30} />
                    </div>
                        
                    <div className="d-flex flex-column m-2 slider-info-text">
                        <FormGroup controlId="this.props.termDesired" >
                            <NumberFormat className="bubble-input" placeholder="Bedrooms" displayType={'input'}
                               suffix={" yrs"} value={this.props.termDesired} />
                        </FormGroup> 
                    </div>
                </div>
                    <CoverageMultiSelect selectedArray={this.props.selectedCoveragesArray}
                    onSubmit={this.props.onSelectedCoveragesArrayChange} />
                <BallRotate
                    color={'#7c648c'}
                    loading={this.state.isLoading}
                />
            </section >
        )
    }
}