import React from 'react';
import { Row, Col } from 'react-bootstrap';

import QuestionAnswer from './QuestionAnswer.component';

export default class QuestionGenerator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionAnswerArray: [`What is Your Height in Inches? Provide answer in Integer Value Only`,
                `How much do you weigh in Pounds(lbs)? Provide answer in Integer Value Only`],
            question: '',
            answerText: '',
            previousQuestionId: '',
            answersArray: [],
            answeredQuestionIdArray: [],
            isAllQuestionsDone: false,
            activeItem: 0
        }
    }

    componentDidMount() {
        let array = this.state.questionAnswerArray.map((_) => '')
        this.setState({
            answersArray: array
        })
    }

    isAllQuestionsDone = () => {
        //check if all questions are submitted
        if (this.state.activeItem < this.state.answeredQuestionIdArray.length) {
            return;
        }
        else {
            const ans = this.state.answersArray.findIndex((val) => val === '')

            if (ans === -1) {
                console.log('done')
                this.setState({
                    isAllQuestionsDone: true
                })
            }
            else {
                this.setState({
                    activeItem: ans
                })
            }
        }
    }

    changeActiveItem = (e) => {
        e.preventDefault();
        this.setState({
            activeItem: e.target.dataset.index
        })
    }

    submitAnswer = () => {
        const array = this.state.answersArray.map((obj, i) => {
            return i === +this.state.activeItem ? this.state.answerText : obj
        })
        this.setState({
            answersArray: array
        }, () =>
            this.setState({
                answerText: '',
                activeItem: this.state.activeItem + 1
            }, this.isAllQuestionsDone)
        )
    }

    setAnwser = (value) => {
        this.setState({
            answerText: value
        })
    }


    render() {
        const questionLinks = this.state.questionAnswerArray.map(
            (_, i) => (<span className='btn ml-4' key={i} data-index={i} onClick={this.changeActiveItem}>
                Q{i + 1}
            </span>)
        )
        return (
            <React.Fragment>
            
                    {this.state.isAllQuestionsDone ? `All answers submitted` :
                        <Col md={8}>
                            <div>
                                Question: {Number(this.state.activeItem) + 1}
                            </div>
                            <Row>
                                {this.state.questionAnswerArray[this.state.activeItem]}
                            </Row>
                            <QuestionAnswer onChange={this.setAnwser} answerText={this.state.answerText} />
                            <Row>
                                {questionLinks}
                            </Row>
                            <Row className='flex-row-reverse'>
                                <button className='buttonRounded' onClick={this.submitAnswer}>
                                    Submit
                                </button>
                            </Row>
                        </Col>
                    }
               
            </React.Fragment>
        )
    }
}
