import React from 'react';

export default class QuestionAnswer extends React.Component {
    setAnwser = (e) => {
        this.props.onChange(e.target.value);
    }
    render() {
        const { answerText } = this.props;
        return (
         <>
               Answer: 
            <input  className='m-2' type='text' value={answerText} onChange={this.setAnwser}/>
         </>    
        )
    }
}