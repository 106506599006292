import React from 'react';

import { JSONToHTMLTable } from '@kevincobain2000/json-to-html-table';

import ComponentHelper from './ComponentHelper';

class GetSavedData extends React.Component {

    constructor() {
        super()
        this.state = {
            id: Math.random().toString(36).substr(3, 6).replace(/0/g, '1').replace(/o/g, 'p'),
            pin: "",
            dataCategory: "",
            createdAt: "",
        }
    }

    componentDidMount() {
        console.log("ididid:", this.state.id)
    }

    loadData = async () => {
        if (this.state.id === this.state.pin.trim()) {
            let dataArray = await ComponentHelper.getAppDataList({ "dataCategory": { "eq": this.state.dataCategory } }, 0);
            console.log(dataArray)
            this.setState({ dataArray, isAuthorised: true, authFailed: null })
        } else {
            this.setState({ authFailed: true })
        }
    }

    render() {
        return (
            <div className="registration-page">
                <div className="container mt-2">
                    <div className="d-flex flex-row justify-content-center">
                        <label htmlFor="dataCategory" className="col-3 mt-4">Data Category:</label>
                        <input type="text" name="dataCategory" value={this.state.dataCategory} className="col-6"
                            onChange={e => this.setState({ dataCategory: e.target.value })} />
                    </div>
                    <div className="d-flex justify-content-center">
                        <label htmlFor="authorizationCode" className="col-3 mt-4">Authorization Code:</label>
                        <input type="text" name="authorizationCode" value={this.state.pin} className="col-6"
                            onChange={e => this.setState({ pin: e.target.value })} />
                    </div>
                    <div className="d-flex justify-content-center">
                        <label htmlFor="createdAt" className="col-3 mt-4">Created At:</label>
                        <input type="text" name="createdAt" value={this.state.createdAt} className="col-6"
                            onChange={e => this.setState({ createdAt: e.target.value })} />
                    </div>
                    <div className="d-flex flex-row justify-content-center mt-3">
                        <button type="submit" className="buttonRounded mt-2" onClick={this.loadData}> Load Data </button>
                    </div>
                </div>
                {
                    this.state.authFailed &&
                    <div className="text-center mt-3">
                        Wrong code entered
                    </div>
                }
                {this.state.isAuthorised &&
                    <div className="mt-2 bg-purple">
                        <JSONToHTMLTable data={{ 'data': this.state.dataArray }} tableClassName="table table-sm" />
                    </div>
                }
            </div>
        )
    }
}

export default GetSavedData;