import React from 'react';

export default class Clock extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentCount: 10 }
    }
    timer() {
        this.setState({
            currentCount: this.state.currentCount - 1
        })
        if (this.state.currentCount < 1) {
            clearInterval(this.intervalId);
        }
    }
    componentDidMount() {
        // React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
        this.intervalId = setInterval(this.timer.bind(this), 1000);
    }
    componentWillUnmount() {
        // React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
        clearInterval(this.intervalId);
    }
    render() {
        return (
            <div>{this.state.currentCount}</div>
        );
    }
}