import React from "react";
import Home from './components/Home.component';

import './App.scss';
import "bootstrap/dist/css/bootstrap.min.css";
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

// <div className='app'>
// 	<h1>Estimator What Ifs</h1>
// 	<Navigation />
// 	<Home />
// </div>
const App = () => (

	<Home />

);

export default App;
