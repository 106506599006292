import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
//import Toggle from '../components/Toggle.Component';
import ValueSlider from '../components/Slider.Component';
import ComponentHelper from "./ComponentHelper";
import GlobalConstants from "../GlobalConstantsVariables";
import Utility from "./Utility";

const CLASS_NAME = ":IndividualDetails:";
let idLocal = 'idLocalShridhar';
let clientIdLocal = 'clientIdLocalShridhar';

export default class IndividualDetails extends Component {
    constructor(props) {
        super(props);
        let METHOD_NAME = ":constructor:";

        let intermediateState = {
            id: null,
            clientId: null,
            isActive: false,
            birthYearArray: [],
            mortgageAmount: -1,
            livingsizeSqft: -1,
            coverageDesired: 400,
            termDesired: 20,
            birthYear: -1,
            age: null,
            gender: -1,
            health: -1,
            smoker: -1,
            height: -1,
            weight: -1,
        };
        this.setStateFromCache = this.setStateFromCache.bind(this);
        this.pushDataInDBAndCache = this.pushDataInDBAndCache.bind(this);

        this.refreshDoughnut = this.refreshDoughnut.bind(this);
        this.onChangeMortgage = this.onChangeMortgage.bind(this);
        this.onAfterChangeMortgage = this.onAfterChangeMortgage.bind(this);
        this.onChangeLivingsizeSqft = this.onChangeLivingsizeSqft.bind(this);
        this.onAfterChangeLivingsizeSqft = this.onAfterChangeLivingsizeSqft.bind(this);
        this.onChangeBirthYear = this.onChangeBirthYear.bind(this);
        this.onAfterChangeBirthYear = this.onAfterChangeBirthYear.bind(this);
        this.onChangeGender = this.onChangeGender.bind(this);
        this.onAfterChangeGender = this.onAfterChangeGender.bind(this);
        this.onChangeHealth = this.onChangeHealth.bind(this);
        this.onAfterChangeHealth = this.onAfterChangeHealth.bind(this);
        this.onChangeSmoking = this.onChangeSmoking.bind(this);
        this.onAfterChangeSmoking = this.onAfterChangeSmoking.bind(this);
        this.onChangeCoverage = this.onChangeCoverage.bind(this);
        this.onChangeTermDesired = this.onChangeTermDesired.bind(this);
        this.onChangePolicyTerm = this.onChangePolicyTerm.bind(this);

        // Logic: expecting id and clientId from parent or sibling (this.props.id, this.props.clientId)
        // if not use localvalues
        intermediateState.id = this.props.id != null ? this.props.id : idLocal;
        intermediateState.clientId = this.props.clientId != null ? this.props.clientId : clientIdLocal;
        let paramName, key;
        paramName = 'All';
        key = `${intermediateState.clientId}++${paramName}`;

        ComponentHelper.getCachedData2(key).then((response) => {
            METHOD_NAME += ":getCachedData2.then:";
            console.log("response: ", response, METHOD_NAME, CLASS_NAME);
            let json = JSON.parse(response.Payload);
            console.log("json.body.output: ", json.body.output, METHOD_NAME, CLASS_NAME);
            if (json.body.output === "" || json.body.output === {}) {
                METHOD_NAME += ":emptyCache:";
                let inputJson = {};
                inputJson.clientId = intermediateState.clientId;
                inputJson.id = intermediateState.clientId;
                inputJson.cacheId = intermediateState.clientId + "++All";
                inputJson.dataCategory = GlobalConstants.getClientDataCategoryAll();
                inputJson.eventStr = CLASS_NAME.replace(/:/g, '') + 'Insert';
                ComponentHelper.saveAppDataAndCache(inputJson);
                this.setStateFromCache(inputJson);
            } else {
                METHOD_NAME += ":setState.prepare:";
                const stateInputJsonStr = json.body.output.replace(/__-__/g, '"');
                let stateInputJson = JSON.parse(stateInputJsonStr);
                let jsonConfirmed = {};
                Object.entries(stateInputJson).forEach(([key, value]) => {
                    if (key === 'age') {
                        jsonConfirmed['birthYear'] = Utility.getBirthYearFromAge(value);
                        // console.log('jsonConfirmed[birthYear]: ', stateInputJson['birthYear'], 'type:', typeof (stateInputJson['birthYear']), METHOD_NAME, CLASS_NAME);
                    } else {
                        jsonConfirmed[key] = value;
                    }
                });
                this.setStateFromCache(jsonConfirmed);
                console.log('stateInputJson: ', stateInputJson, METHOD_NAME, CLASS_NAME);
            }

        });

        this.state = intermediateState;
    }
    setStateFromCache(inputJson) {
        this.setState(inputJson);
        // setTimeout(() => { this.setState(inputJson); }, 100);
    }
    pushDataInDBAndCache(inputJson) {
        const METHOD_NAME = ":pushDataInDBAndCache:";
        let stateJson = {};
        let key, value;
        key = inputJson.key; value = inputJson.value;
        delete inputJson.key; delete inputJson.value;
        this.setState(inputJson);
        // UI Specific parameters to cache
        stateJson.age = this.state.age;
        stateJson.ageBand = this.state.ageBand;
        stateJson.birthYear = this.state.birthYear;
        stateJson.gender = this.state.gender;
        stateJson.health = this.state.health;
        stateJson.Smoking = this.state.Smoking;
        // stateJson. = this.state.
        stateJson.clientId = this.state.clientId;
        stateJson.id = this.state.clientId;
        stateJson.cacheId = this.state.clientId + "++All";
        stateJson.eventStr = CLASS_NAME.replace(/:/g, '') + 'Update';
        console.log("stateJson:", stateJson, METHOD_NAME, CLASS_NAME);

        ComponentHelper.updateAppDataAndCache(GlobalConstants.getClientDataCategoryAll(), stateJson);
        //     let json4DyanoDB = {
        //         id:this.state.clientId;
        //         cacheId: `${}`;
        //         stateJson.eventStr = CLASS_NAME.replace(/:/g, '') + 'Update';
        //         `${inputJson.key}`; inputJson.value
        // };

        // ComponentHelper.updateAppDataAndCache("IndividualUpdates", json4DyanoDB); sdsfsdf
        if (this.props.onChangeAmountsFromHash != null) {
            this.refreshDoughnut(key, value);
        }
    }
    refreshDoughnut(changedInputParamName, inputValue) {
        let METHOD_NAME = ":refreshDoughnut:";
        let paramName, key, calculatedCoverageLocal;
        console.log("Entered:Input:changedInputParamName:", changedInputParamName, "# inputValue:", METHOD_NAME, CLASS_NAME);
        /*
        Life Insurance field received:
         1. re-calculate life insurance 
         2. Update AppData
        */
        let inputKeyLocal = changedInputParamName;
        if (GlobalConstants.getLifeInsuranceFieldsArray().indexOf(inputKeyLocal) > -1) {
            paramName = 'calculatedCoverage';
            key = `${this.props.id}++${paramName}`;
            ComponentHelper.getCachedData2(key).then((data) => {
                METHOD_NAME += ":getCachedData2.then:";
                console.log("data: ", data, METHOD_NAME, CLASS_NAME);
                let json = JSON.parse(data.Payload);
                calculatedCoverageLocal = json.body.output;
                this.setState({ coverage: calculatedCoverageLocal });
                //DATA_RELEASE_VERSION__stateCode__gender__health4LifeInsurance__policyTerm__smoker__age__coverageAmount
                // IN____________600000
                // v2__stateCode__1__1__20__0__35__coverageAmount
                console.log("this.props.stateCode:", this.props.stateCode, " # key: ", key, " # calculatedCoverageLocal: ", calculatedCoverageLocal, METHOD_NAME, CLASS_NAME);
                if (this.props.lifeInsuranceAmount == null || calculatedCoverageLocal == null) {
                    console.log("Invalid input this.state.lifeInsuranceAmount == null : this.props.stateCode: ", this.props.stateCode, " # this.props.lifeInsuranceAmount: ", this.props.lifeInsuranceAmount, " # this.props.calculatedCoverage: ", this.props.calculatedCoverage, METHOD_NAME, CLASS_NAME);
                    return;
                }
                let genderLocal = this.state.gender != null && this.state.gender !== -1 ? this.state.gender : 1;
                let healthLocal = this.state.health != null && this.state.health !== -1 ? this.state.health : 1;
                let policyTermLocal = this.state.policyTerm != null ? this.state.policyTerm : 20;
                let smokerLocal = this.state.smoker != null && this.state.smoker !== -1 ? this.state.smoker : 1;
                let ageLocal = this.state.birthYear != null && this.state.birthYear !== -1 ? this.state.birthYear : 1990;
                ageLocal = Utility.getAgeFromBirthYear(this.state.birthYear);
                console.log("ageLocal:", ageLocal, METHOD_NAME, CLASS_NAME);
                ageLocal = ageLocal != null && ageLocal.toString().length > 0 ? ageLocal : 30;

                const idForLifeInsuranceAmount = `${GlobalConstants.getDataReleaseVersion()}__${this.props.stateCode}__${genderLocal}__${healthLocal}__${policyTermLocal}__${smokerLocal}__${ageLocal}__${calculatedCoverageLocal}`;
                // make sure proper address is keyed and is saved in DB to fetch lifeInsuranceAmount
                ComponentHelper.getData(idForLifeInsuranceAmount).then((array) => {
                    if (array != null && array.length > 0) {
                        // console.log("array: ", array, METHOD_NAME, CLASS_NAME);
                        let inputJson = { 'id': this.props.id, 'lifeInsuranceAmount': array[0].lifeInsuranceAmount, 'calculatedCoverage': calculatedCoverageLocal };

                        if (inputKeyLocal === 'birthYear') {
                            inputJson['age'] = ageLocal;
                            inputJson['ageBand'] = Utility.getFormattedAge(inputValue);
                        } else {
                            inputJson[inputKeyLocal] = inputValue;
                        }
                        this.props.onChangeAmountsFromHash('IndividualDetails', inputJson);
                        console.log("array[0].lifeInsuranceAmount:", array[0].lifeInsuranceAmount, METHOD_NAME, CLASS_NAME);
                        this.setState({ 'lifeInsuranceAmount': array[0].lifeInsuranceAmount, 'calculatedCoverage': calculatedCoverageLocal });
                    }
                });
            });
        }
    }
    onAfterChangeMortgage(value) {
        let METHOD_NAME = ":onAfterChangeMortgage:";
        console.log("Entered:Mortgage:value ", value, METHOD_NAME, CLASS_NAME);
        let valueLocal = value.toString().length === 3 ? Number(`${value.toString()}000`) : value;
        this.pushDataInDBAndCache({ 'value': valueLocal, 'key': 'mortgageAmount', 'mortgageAmount': valueLocal });
        const hash = ComponentHelper.getLocalCacheFor(this.state.id);
        // console.log("BEFORE this.stateeeeeeeee:", this.state, METHOD_NAME, CLASS_NAME);
        // console.log("BEFORE hashhashhash stateeeeeeeee:", hash, METHOD_NAME, CLASS_NAME);
        this.setState(hash);
        hash['id'] = this.state.id;
        hash['mortgageAmount'] = valueLocal;
        this.props.onChangeAmountsFromHash('StepDougnutChart', hash);
    }
    onChangeMortgage(value) {
        const key = 'mortgageAmount';
        let valueLocal = value.toString().length === 3 ? Number(`${value.toString()}000`) : value;
        this.setState({ [key]: valueLocal });
    }
    onAfterChangeLivingsizeSqft(value) {
        let METHOD_NAME = ":onAfterChangeLivingsizeSqft:";
        console.log("Entered:livingsizeSqft:value ", value, METHOD_NAME, CLASS_NAME);
        this.pushDataInDBAndCache({ 'value': value, 'key': 'livingsizeSqft', 'livingsizeSqft': value });
    }
    onChangeLivingsizeSqft(value) {
        const key = 'livingsizeSqft';
        this.setState({ [key]: value });
    }
    onAfterChangeBirthYear(inputValue) {
        let METHOD_NAME = ":onAfterChangeBirthYear:";
        console.log("Entered:birthYear:inputValue ", inputValue, METHOD_NAME, CLASS_NAME);

        let ageLocal = Utility.getAgeFromBirthYear(inputValue);
        console.log("ageLocal:", ageLocal, METHOD_NAME, CLASS_NAME);
        ageLocal = ageLocal != null && ageLocal.toString().length > 0 ? ageLocal : 30;

        let stateJson = { 'key': 'birthYear', 'value': inputValue, 'birthYear': inputValue, 'age': ageLocal, 'ageBand': Utility.getFormattedAge(inputValue) };
        this.pushDataInDBAndCache(stateJson);
    }
    onChangeBirthYear(value) {
        const key = 'birthYear';
        this.setState({ [key]: value });
    }
    onAfterChangeGender(value) {
        let METHOD_NAME = ":onAfterChangeGender:";
        console.log("Entered:gender:value ", value, METHOD_NAME, CLASS_NAME);
        this.pushDataInDBAndCache({ 'value': value, 'key': 'gender', 'gender': value });
        // // this.refreshDoughnut(key, value);
    }
    onChangeGender(value) {
        const key = 'gender';
        this.setState({ [key]: value });
    }
    onAfterChangeHealth(value) {
        let METHOD_NAME = ":onAfterChangeHealth:";
        console.log("Entered:health:value ", value, METHOD_NAME, CLASS_NAME);
        this.pushDataInDBAndCache({ 'value': value, 'key': 'health', 'health': value });
        // // this.refreshDoughnut(key, value);
    }
    onChangeHealth(value) {
        const key = 'health';
        this.setState({ [key]: value });
    }
    onAfterChangeSmoking(value) {
        let METHOD_NAME = ":onAfterChangeSmoking:";
        console.log("Entered:Smoking:value ", value, METHOD_NAME, CLASS_NAME);
        this.pushDataInDBAndCache({ 'value': value, 'key': 'smoking', 'smoking': value });
        // // this.refreshDoughnut(key, value);
    }
    onChangeSmoking(value) {
        const key = 'smoker';
        //   value = value === 0 ? false : true
        this.setState({ [key]: value });
    }
    onChangeHeight(value) {
        const key = 'height';
        this.props.onChangeItem(key, value);
        this.setState({ [key]: value });
    }
    onChangeWeight(value) {
        const key = 'weight';
        this.setState({ [key]: value });
    }
    onChangeCoverage(value) {
        const key = 'coverageDesired';
        this.setState({ [key]: value });
    }
    onChangeTermDesired(value) {
        const key = 'termDesired';
        this.setState({ [key]: value });
    }
    onChangePolicyTerm(value) {
        const key = 'policy_term';
        this.setState({ [key]: value });
    }
    componentDidUpdate(prevState, prevProps) {
        // we access props with this.props
        // and state with this.state

        // prevState contains state before update
        // prevProps contains props before update

    }
    render() {

        return (
            /*<OverlayTrigger
                trigger="click"
                placement={'bottom'}
                overlay={
                    <Popover id={`popover-positioned-${'bottom'}`}>
                        <Popover.Content>
                            <div style={{ background: '#F6F6F6' }} className='popDown-content'>
                                <label>
                                    Age
                                    </label>
                                <span style={{ width: '80%' }}>
                                    <ValueSlider min={25} max={75} units={'years'} onChange={this.props.onIndividualAgeDetails} value={this.props.age} />
                                </span>
    
                                <label>
                                    Gender
                                    </label>
                                <select
                                    style={styles}
                                    name="gender"
                                    placeholder='gender'
                                    value={this.state.gender}
                                    onChange={this.props.onIndividualDetails}>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                                <label>
                                    Health
                                    </label>
                                <select
                                    style={styles}
                                    name="health"
                                    placeholder='health'
                                    value={this.state.health}
                                    onChange={this.props.onIndividualDetails}>
                                    <option value="Atheltic">Atheltic</option>
                                    <option value="Good">Good</option>
                                    <option value="Okayish">Okayish</option>
                                </select>
                                <label>
                                    Smoker
                                    </label>
                                <Toggle onChange={this.props.onSmokerDetails} value={this.props.smoker} />
                            </div>
                        </Popover.Content>
                    </Popover>
                }
            >
                <div
                    className={this.state.isActive ? "active1 dropbtn" : "dropbtn"}
                    onClick={this.changeActiveItem}
                    style={activeItem === 0 || activeItem === 3 ? { textAlign: 'center' } : { pointerEvents: 'none' }}
                >
                    Individual Details
              </div>
            </OverlayTrigger>*/
            // id="individualDetails"
            <div className={this.props.extension ? "column-2" : "individualDetails"}>
                {
                    this.props.extension ? <></> :
                        <div className="row-seperator">
                            <div>
                                <hr />
                            </div>
                            <span>
                                Home
                            </span>
                            <div>
                                <hr />
                            </div>
                        </div>
                }
                <Row>
                    <Col md={6} xs={6}>
                        <div className='d-flex flex-row justify-content-around'>
                            <div className='healthInfo'>
                                <ValueSlider
                                    label={'Mortgage'}
                                    min={100}
                                    step={100}
                                    max={5000}
                                    onAfterChange={this.onAfterChangeMortgage}
                                    onChange={this.onChangeMortgage}
                                    value={this.state.mortgageAmount}
                                    defaultValue={this.state.mortgageAmount} />
                                {this.state.mortgageAmount === -1 ? 'Select?' : `$${this.state.mortgageAmount}`}
                            </div>
                        </div>
                    </Col>
                    <Col md={6} xs={6}>
                        <div className='d-flex flex-row justify-content-around'>
                            <div className='healthInfo'>
                                <ValueSlider
                                    label={'Living Area(sqft)'}
                                    min={-1}
                                    max={10000}
                                    step={100}
                                    onAfterChange={this.onAfterChangeLivingsizeSqft}
                                    onChange={this.onChangeLivingsizeSqft}
                                    value={this.state.livingsizeSqft}
                                    defaultValue={this.state.livingsizeSqft} />
                                {this.state.livingsizeSqft === -1 ? 'Select?' : this.state.livingsizeSqft}
                            </div>
                        </div>
                    </Col>
                </Row>
                {
                    this.props.extension ? <></> :
                        <div className="row-seperator">
                            <div>
                                <hr />
                            </div>
                            <span>
                                Personal
                            </span>
                            <div>
                                <hr />
                            </div>
                        </div>
                }
                <Row>
                    <Col md={6} xs={6}>
                        <div className='d-flex flex-row justify-content-around'>
                            <div className='healthInfo'>
                                <ValueSlider
                                    label={'Birth Year'}
                                    min={1950}
                                    max={2020}
                                    onAfterChange={this.onAfterChangeBirthYear}
                                    onChange={this.onChangeBirthYear}
                                    value={this.state.birthYear}
                                    defaultValue={this.state.birthYear} />
                                {this.state.birthYear === -1 ? 'Unknown?' : this.state.birthYear}
                            </div>
                        </div>
                    </Col>
                    <Col md={6} xs={6}>
                        <div className='d-flex flex-row justify-content-around'>
                            <div className='healthInfo'>
                                <ValueSlider
                                    label={'Gender'}
                                    min={-1}
                                    max={2}
                                    onAfterChange={this.onAfterChangeGender}
                                    onChange={this.onChangeGender}
                                    value={this.state.gender}
                                    defaultValue={this.state.gender} />
                                {this.state.gender === -1 ? 'Unknown?' : (this.state.gender === 0 ? 'Female' : (this.state.gender === 1 ? 'Male' : 'Other'))}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} xs={6}>
                        <div className='d-flex flex-row justify-content-around'>
                            <div className='healthInfo'>
                                <ValueSlider
                                    label={'Health'}
                                    min={-1}
                                    max={2}
                                    onAfterChange={this.onAfterChangeHealth}
                                    onChange={this.onChangeHealth}
                                    value={this.state.health}
                                    defaultValue={this.state.health} />
                                {this.state.health === -1 ? 'Unknown?' : (this.state.health === 0 ? 'Iffy' : (this.state.health === 2 ? 'Excellent' : 'Good'))}
                            </div>
                        </div>
                    </Col>
                    <Col md={6} xs={6}>
                        <div className='d-flex flex-row justify-content-around'>
                            <span className='healthInfo '>
                                <ValueSlider
                                    label={"Smoker"}
                                    min={-1}
                                    max={1}
                                    value={this.state.smoker}
                                    onAfterChange={this.onAfterChangeSmoking}
                                    onChange={this.onChangeSmoking}
                                />
                                {this.state.smoker === -1 ? 'Unknown?' : (this.state.smoker === 1 ? "Yes" : "No")}
                            </span>
                        </div>
                    </Col>
                </Row>

                <div className='d-flex flex-row justify-content-around'>
                    <div className='healthInfo'>
                        <ValueSlider
                            onChange={this.onChangeCoverage}
                            value={this.state.coverageDesired}
                            defaultValue={800}
                            label={'Coverage'}
                            min={100}
                            max={1000} />
                    ${this.state.coverageDesired}K
                </div>
                    <div className='healthInfo '>
                        <ValueSlider
                            onChange={this.onChangeTermDesired}
                            defaultValue={20}
                            value={this.state.termDesired}
                            label={'Term Desired'}
                            min={10}
                            max={30}
                            step={5} />
                        {this.state.termDesired} Years
                </div>
                </div>
                {/* 
                <div className='d-flex flex-row justify-content-around'>
                    <div className='healthInfo'>
                        Life Insurance: ${this.state.lifeInsuranceAmount || this.props.lifeInsuranceAmount}
                    </div>
                    <div className='healthInfo'>
                        Coverage : ${this.state.calculatedCoverage || this.props.calculatedCoverage}
                    </div>
                </div> */}
            </div>
        )
    }
}
