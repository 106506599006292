import React from "react";
import { Chart, Doughnut } from "react-chartjs-2";
import 'chart.piecelabel.js';
import { BallRotate } from 'react-pure-loaders';

import ComponentHelper from "./ComponentHelper";
import Utility from "./Utility";
import GlobalVariables from "../GlobalConstantsVariables";
const CLASS_NAME = ":BubbleDoughnut:";
let chartTitle;
let amountsFetchedCount = 0;
const _chartLabelsOri = ['Mortgage', 'Home Insurance', 'Utilities', 'Life Insurance', 'Property Tax'];
let _chartLabels = _chartLabelsOri;
// const _backgroundColorOri = ['#DFD5E2', '#F3B2DB', '#6FCFEB', '#4A1149', '#99E6D8'];
// const _hoverBackgroundColorOri = ['#bda8c3', '#ec86c6', '#2cb9e2', '#350c35', '#47d2b9'];
// const _hoverBorderColorOri = ['#82608b', '#e244a7', '#1789aa', '#200720', '#23907c'];
const _backgroundColorOri = ['#5c2fff', '#ff7007', '#CEC0FF', '#ffa61a', ' #9D82FF', 'transparent'];
const _hoverBackgroundColorOri = ['#5c2fff', '#ff7007', '#CEC0FF', '#ffa61a', ' #9D82FF'];
const _hoverBorderColorOri = ['#5c2fff', '#82608b', '#1789aa', '#200720', '#23907c'];
let _backgroundColor = _backgroundColorOri;
let _hoverBackgroundColor = _hoverBackgroundColorOri;
let _hoverBorderColor = _hoverBorderColorOri;
const _backgroundColorGrey = ['#696969', '#808080', '#A9A9A9', '#C0C0C0', '#D3D3D3'];
const _hoverBorderWidth = 2;
let _defaultDatasets = [{
    data: [1, 1, 1, 1, 1],
    backgroundColor: _backgroundColorGrey,
    hoverBackgroundColor: _backgroundColorGrey,
    hoverBorderColor: _backgroundColorGrey,
    hoverBorderWidth: _hoverBorderWidth
}];
const FINAL_COUNT = 1;
let reloadAttempts = 0;
let localAmountsHash = {};
let totalAmountLocal = '0';
/*function setGetChartTitle(totalMonthlyExpensesText) {
    // if (avmAmount == null || lifeInsuranceAmount == null) {
    chartTitle = '';
    // } else {
    //     chartTitle = ''; // chartTitleTemplate.replace('totalMonthlyExpenses', totalMonthlyExpensesText).replace('addressOneLine', addressOneLine);
    // }
    return chartTitle;
}
// setGetTheDataArray({ "source": "", "avmAmount": e.target.value });
// setGetTheDataArray({ "source": "", "source": "", mortgageAmount": _defaultAVM,"propertyTaxAmount": _defaultPropertyTaxAmount,"utilityAmount": _defaultUtilityAmount,"homeInsurance": _defaultHomeInsurance,"lifeInsurance":_defaultLifeInsurance});
/* Following function is usable for all chart element setting*/
/*function setGetTheDataArray(keyValHash) {
    let METHOD_NAME = "setGetTheDataArray:";
    let theDataArray = [];
    console.log(CLASS_NAME, METHOD_NAME, "Entered:Input:keyValHash: ", keyValHash);
    // ///////////////// Data[0] Mortgage //////////////
    let value = 0;
    // if (keyValHash["avmAmount"] != null) {
    //     value = calculateMortgageAmount(keyValHash["avmAmount"], isPrimaryResidence);
    //     mortgageAmount = value;
    //     theDataArray[0] = value;
    // }

    if (keyValHash["mortgageAmount"] != null) {
        theDataArray[0] = keyValHash["mortgageAmount"];
    }
    // ///////////////// Data[1] Home Insurance //////////////
    if (keyValHash["homeInsuranceAmount"] != null) {
        value = keyValHash["homeInsuranceAmount"];
        theDataArray[1] = value;
    }
    // ///////////////// Data[2] Utilities ////////////// 
    // if (keyValHash["livingSizeSqft"] != null && keyValHash["avmStateCode"] != null) {
    //     value = (state_avg_utility_cost_per_sqft[keyValHash["avmStateCode"]] * keyValHash["livingSizeSqft"]).toFixed();
    //     theDataArray[2] = value;
    //     utilityAmount = value;
    // }
    if (keyValHash["utilityAmount"] != null && !isNaN(keyValHash["utilityAmount"])) {
        value = keyValHash["utilityAmount"].toFixed();
        theDataArray[2] = value;
    }
    // ///////////////// Data[3] PropertyTax //////////////
    if (keyValHash["propertyTaxAmount"] != null) {
        theDataArray[3] = keyValHash["propertyTaxAmount"];
    }

    // ///////////////// Data[4] Life Insurance //////////////
    if (keyValHash["lifeInsuranceAmount"] != null) {
        console.log("keyValHash[lifeInsuranceAmount]:", keyValHash["lifeInsuranceAmount"]);
        theDataArray[4] = keyValHash["lifeInsuranceAmount"];
    }
    let totalMonthlyExpenses = theDataArray.reduce(function (a, b) { return parseInt(a) + parseInt(b); }, 0);
    chartTitle = setGetChartTitle(Utility.getThousandsSeparatedStr(totalMonthlyExpenses));
    console.log(CLASS_NAME, METHOD_NAME, "totalMonthlyExpenses: ", totalMonthlyExpenses);
    console.log(CLASS_NAME, METHOD_NAME, " theDataArray:", theDataArray);
    return theDataArray;
}*/
export default class BubbleDoughnut extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            insurance: 0,
            chartDataChild: [],
            whatIfScenarioDataArray: [],
            chart_title: chartTitle,
            datasets: _defaultDatasets,
            id: this.props.id,
            isLoading: true,
            avmAmount: this.props.avmAmount,
            utilityAmount: this.props.utilityAmount,
            propertyTaxAmount: this.props.propertyTaxAmount,
            mortgageAmount: this.props.mortgageAmount,
            homeInsuranceAmount: this.props.homeInsuranceAmount,
            lifeInsuranceAmount: this.props.lifeInsuranceAmount,
            totalAmount: this.props.totalAmountDoughNut || 0,
            currentCount: 1
        };
        this.getTotalAmount = this.getTotalAmount.bind(this);
        this.fetchCalculatedFields = this.fetchCalculatedFields.bind(this);
    }
    getTotalAmount() {
        let totalAmount = 0;
        const METHOD_NAME = ":getTotalAmount:";
        //.toString() || 0) + parseInt(this.state..toString()) || 0);
        // console.log("%%% this.state.mortgageAmount: ", this.state.mortgageAmount, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // console.log("%%% this.state.homeInsuranceAmount: ", this.state.homeInsuranceAmount, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // console.log("%%% this.state.utilityAmount: ", this.state.utilityAmount, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // console.log("%%% this.state.lifeInsuranceAmount: ", this.state.lifeInsuranceAmount, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // console.log("%%% this.state.propertyTaxAmount: ", this.state.propertyTaxAmount, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        let mortgageAmountLocal, homeInsuranceAmountLocal, utilityAmountLocal, lifeInsuranceAmountLocal, propertyTaxAmountLocal;
        mortgageAmountLocal = this.props.mortgageAmount != null && this.props.mortgageAmount.toString() !== '1' ? parseInt(this.props.mortgageAmount.toString()) : 0;
        homeInsuranceAmountLocal = this.props.homeInsuranceAmount != null && this.props.homeInsuranceAmount.toString() !== '1' ? parseInt(this.props.homeInsuranceAmount.toString()) : 0;
        utilityAmountLocal = this.props.utilityAmount != null && this.props.utilityAmount.toString() !== '1' ? parseInt(this.props.utilityAmount.toString()) : 0;
        lifeInsuranceAmountLocal = this.props.lifeInsuranceAmount != null && this.props.lifeInsuranceAmount.toString() !== '1' ? parseInt(this.props.lifeInsuranceAmount.toString()) : 0;
        propertyTaxAmountLocal = this.props.propertyTaxAmount != null && this.props.propertyTaxAmount.toString() !== '1' ? parseInt(this.props.propertyTaxAmount.toString()) : 0;
        totalAmount = mortgageAmountLocal + homeInsuranceAmountLocal + utilityAmountLocal + lifeInsuranceAmountLocal + propertyTaxAmountLocal;

        totalAmount = `${totalAmount}`.split('.')[0];
        // console.log("%%% mortgageAmountLocal: ", mortgageAmountLocal, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // console.log("%%% homeInsuranceAmountLocal: ", homeInsuranceAmountLocal, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // console.log("%%% utilityAmountLocal: ", utilityAmountLocal, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // console.log("%%% lifeInsuranceAmountLocal: ", lifeInsuranceAmountLocal, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // console.log("%%% propertyTaxAmountLocal: ", propertyTaxAmountLocal, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // console.log("%%% totalAmount: ", totalAmount, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);

        if (!(mortgageAmountLocal > 0 && homeInsuranceAmountLocal > 0 && utilityAmountLocal > 0 && lifeInsuranceAmountLocal > 0 && propertyTaxAmountLocal > 0 && isNaN(totalAmount))) {
            totalAmount = 'Recalculating';
        } else if ((isNaN(totalAmount) || totalAmount === 0) && reloadAttempts <= FINAL_COUNT) {
            totalAmount = 'Recalculating';
            reloadAttempts += 1;
            console.log("%%% totalAmount === 0 Before fetchCalculatedFields", "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
            setTimeout(() => {
                amountsFetchedCount = 0;
                if(!this.props.donutWasMounted)
                this.fetchCalculatedFields(this.props.id);
            }, 3000);
            totalAmount = 'Recalculating..';
        } else if ((totalAmount === 0 || isNaN(totalAmount)) && reloadAttempts > FINAL_COUNT) totalAmount = 'Recalculating';

        totalAmount = (+this.props.lifeInsuranceAmount || 0) + (+this.props.mortgageAmount || 0) + (+this.props.propertyTaxAmount || 0) + (+this.props.utilityAmount || 0) + (+this.props.homeInsuranceAmount || 0);
        totalAmount = totalAmount.toFixed();

        return totalAmount;
    }
    fetchCalculatedFields(id) {
        let METHOD_NAME = ":fetchCalculatedFields:";
        let key, value;
        console.log("%%% Entered:id: ", id, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
     
        if (!id) {
            return;
        }

        // if (checkPointArray.length > 1) {
        //     this.props.refreshDataArrayLink("whatIfScenarioDataArray");
        //     checkPointArray.push("whatIfScenarioDataArray");
        // } else if (checkPointArray.length === FINAL_COUNT) {
        //     this.endPromiseWithClearInterval(this.intervalId);
        // }

        console.log("Entered:this.state.calculatedCoverage: ", this.state.calculatedCoverage, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        if (this.state.calculatedCoverage == null && this.props.listingPriceAmount != null) {
            let calculateCoverageLocal = Utility.calculateCoverage(this.props.listingPriceAmount);
            this.props.onChangeItem('calculateCoverage', calculateCoverageLocal);
            this.setState({ calculateCoverage: calculateCoverageLocal });
            console.log("calculateCoverageLocal: ", calculateCoverageLocal, " # this.state.calculatedCoverage: ", this.state.calculatedCoverage, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        }
        // console.log("Entered:whatIfScenarioDataArray: ", this.props.whatIfScenarioDataArray, "#",Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        console.log("Entered:this.state.homeInsuranceAmount: ", this.state.homeInsuranceAmount, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        this.setState({ isLoading: true });
        // ['Mortgage', 'Home Insurance', 'Utilities', 'Life Insurance', 'Property Tax'];
        if (this.props.listingPriceAmount == null) {
            ComponentHelper.getCachedData2(`${id}++calculatedCoverage`).then((data) => {
                METHOD_NAME = `:getCachedData2.then:calculatedCoverage`;
                //console.log("output:data: ", data, "#",Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                let json = JSON.parse(data.Payload);
                let cachedValue1 = json.body.output;
                this.setState({ 'calculatedCoverage': cachedValue1 });
                this.props.onChangeItem('calculatedCoverage', cachedValue1);
                console.log('%%% calculatedCoverage: ', cachedValue1, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                //'getQuotesFromPacLifeNext': ["DONE:solicitationState", "DONE:faceAmount", "riskClass", "gender", "premiumFrequency", "dateOfBirth", "productCode"
                GlobalVariables.setAPIInputJson(GlobalVariables.getPacLifeNextAPITag(), { 'calculatedCoverage': cachedValue1 });
                amountsFetchedCount += 1;
            });// calculatedCoverage
        } else {
            // calculate coverageAmount from listingPrice if available
            let cachedValue1 = Utility.calculateCoverage(this.props.listingPriceAmount);
            this.setState({ 'calculatedCoverage': cachedValue1 });
            this.props.onChangeItem('calculatedCoverage', cachedValue1);
            console.log('%%% calculatedCoverage: ', cachedValue1, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
            //'getQuotesFromPacLifeNext': ["DONE:solicitationState", "DONE:faceAmount", "riskClass", "gender", "premiumFrequency", "dateOfBirth", "productCode"
            GlobalVariables.setAPIInputJson(GlobalVariables.getPacLifeNextAPITag(), { 'calculatedCoverage': cachedValue1 });
        }
        console.log("%%% this.props.listingPriceAmount: ", this.props.listingPriceAmount, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // if listingPriceAmount is 0 or not present get it from ATTOM api via cache
        // 2 attempts to get the value
        if (this.props.listingPriceAmount == null || this.props.listingPriceAmount.toString() === "0") {
            ComponentHelper.getCachedData2(`${id}++mortgageAmount`).then((data) => {
                METHOD_NAME = `:getCachedData2.then:mortgageAmount`;
                // console.log("#@# output:data: ", data, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                let json = JSON.parse(data.Payload);
                console.log("#@# json11: ", json, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                let cachedValue4 = json.body.output;
                if (cachedValue4 != null && cachedValue4.length > 0) {
                    json = {}; json['mortgageAmount'] = cachedValue4;
                    this.setState(json);
                    this.props.onChangeItem('mortgageAmount', cachedValue4);
                    console.log('#@# %%% mortgageAmount4: ', cachedValue4, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                    amountsFetchedCount += 1;
                    this.setState({ isLoading: false });
                } else {
                    setTimeout(() => {
                        this.setState({ isLoading: true });
                        ComponentHelper.getCachedData2(`${id}++mortgageAmount`).then((data2) => {
                            METHOD_NAME = `:getCachedData2.then2:mortgageAmount`;
                            // console.log("#@# output:data: ", data, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                            let json2 = JSON.parse(data2.Payload);
                            console.log("#@# json2: ", json2, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                            let cachedValue5 = json2.body.output;
                            json2 = {}; json2['mortgageAmount'] = cachedValue5;
                            this.setState(json2);
                            this.props.onChangeItem('mortgageAmount', cachedValue5);
                            console.log('#@# %%% mortgageAmountAmount5: ', cachedValue5, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                            amountsFetchedCount += 1;
                            this.setState({ isLoading: false });
                        });
                    }, 2500);
                }
            });// mortgageAmountAmount
        } else {
            let mortgageAmountLocal = Utility.calculateMortgageAmount(this.props.listingPriceAmount);
            console.log("%%% this.props.listingPriceAmount: ", this.props.listingPriceAmount, " # mortgageAmountLocal: ", mortgageAmountLocal, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
            this.setState({ 'mortgageAmount': mortgageAmountLocal });
            this.props.onChangeItem('mortgageAmount', mortgageAmountLocal);
            console.log("%%% mortgageAmount: ", mortgageAmountLocal, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
            key = `${this.props.id}++mortgageAmount`;
            value = mortgageAmountLocal;
            ComponentHelper.setDataInCache(key, value);// mortgageAmount
        }
        ComponentHelper.getCachedData2(`${id}++homeInsuranceAmount`).then((data) => {
            METHOD_NAME = `:getCachedData2.then:homeInsuranceAmount`;
            // console.log("#@# output:data: ", data, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
            let json = JSON.parse(data.Payload);
            console.log("#@# json11: ", json, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
            let cachedValue4 = json.body.output;
            if (cachedValue4 != null && cachedValue4.length > 0) {
                json = {}; json['homeInsuranceAmount'] = cachedValue4;
                this.setState(json);
                this.props.onChangeItem('homeInsuranceAmount', cachedValue4);
                console.log('#@# %%% homeInsuranceAmount4: ', cachedValue4, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                amountsFetchedCount += 1;
                this.setState({ isLoading: false });
            } else {
                setTimeout(() => {
                    this.setState({ isLoading: true });
                    ComponentHelper.getCachedData2(`${id}++homeInsuranceAmount`).then((data2) => {
                        METHOD_NAME = `:getCachedData2.then2:homeInsuranceAmount`;
                        // console.log("#@# output:data: ", data, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                        let json2 = JSON.parse(data2.Payload);
                        console.log("#@# json2: ", json2, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                        let cachedValue5 = json2.body.output;
                        json2 = {}; json2['homeInsuranceAmount'] = cachedValue5;
                        this.setState(json2);
                        this.props.onChangeItem('homeInsuranceAmount', cachedValue5);
                        console.log('#@# %%% homeInsuranceAmount5: ', cachedValue5, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                        amountsFetchedCount += 1;
                        this.setState({ isLoading: false });
                    });
                }, 2500);
            }
        });// homeInsuranceAmount
        console.log("%%% this.props.stateCode: ", this.props.stateCode, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        console.log("%%% this.props.livingsizeSqft: ", this.props.livingsizeSqft, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        let utilityAmountLocal = Utility.calculateUtilityAmount(this.props.stateCode, this.props.livingsizeSqft);
        this.setState({ 'utilityAmount': utilityAmountLocal });
        this.props.onChangeItem('utilityAmount', utilityAmountLocal);
        console.log("%%% utilityAmount: ", utilityAmountLocal, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        key = `${this.props.id}++utilityAmount`;
        console.log("%%% key: ", key, " # utilityAmount: ", utilityAmountLocal, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        value = utilityAmountLocal;
        ComponentHelper.setDataInCache(key, value);

        ComponentHelper.getCachedData2(`${id}++lifeInsuranceAmount`).then((data) => {
            METHOD_NAME = `:getCachedData2.then:lifeInsuranceAmount`;
            console.log("%%% output:data: ", data, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
            let json = JSON.parse(data.Payload);
            let cachedValue7 = json.body.output;
            if (cachedValue7 != null && cachedValue7.length > 0) {
                json = {}; json['lifeInsuranceAmount'] = cachedValue7;
                this.setState(json);
                this.props.onChangeItem('lifeInsuranceAmount', cachedValue7);
                console.log('%%% lifeInsuranceAmount: ', cachedValue7, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                amountsFetchedCount += 1;
                this.setState({ isLoading: false });
            } else {
                setTimeout(() => {
                    this.setState({ isLoading: true });
                    ComponentHelper.getCachedData2(`${id}++lifeInsuranceAmount`).then((data2) => {
                        METHOD_NAME = `:getCachedData2.then2:lifeInsuranceAmount`;
                        //console.log("output:data: ", data, "#",Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                        let json2 = JSON.parse(data2.Payload);
                        let cachedValue8 = json2.body.output;
                        json2 = {}; json2['lifeInsuranceAmount'] = cachedValue8;
                        this.setState(json2);
                        this.props.onChangeItem('lifeInsuranceAmount', cachedValue8);
                        console.log('%%% lifeInsuranceAmount2: ', cachedValue8, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                        amountsFetchedCount += 1;
                        this.setState({ isLoading: false });
                    });
                }, 2000);

            }
        });// lifeInsuranceAmount
        ComponentHelper.getCachedData2(`${id}++propertyTaxAmount`).then((data) => {
            METHOD_NAME = `:getCachedData2.then:propertyTaxAmount`;
            //console.log("output:data: ", data, "#",Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
            let json = JSON.parse(data.Payload);
            let cachedValue9 = json.body.output;
            if (cachedValue9 != null && cachedValue9.length > 0) {
                json = {}; json['propertyTaxAmount'] = cachedValue9;
                this.setState(json);
                this.props.onChangeItem('propertyTaxAmount', cachedValue9);
                console.log('%%% propertyTaxAmount: ', cachedValue9, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                amountsFetchedCount += 1;
                this.setState({ isLoading: false });
            } else {
                setTimeout(() => {
                    ComponentHelper.getCachedData2(`${id}++propertyTaxAmount`).then((data2) => {
                        METHOD_NAME = `:getCachedData2.then2:propertyTaxAmount`;
                        //console.log("output:data: ", data, "#",Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                        let json2 = JSON.parse(data2.Payload);
                        let cachedValue10 = json2.body.output;
                        json2 = {}; json2['propertyTaxAmount'] = cachedValue10;
                        this.setState(json2);
                        this.props.onChangeItem('propertyTaxAmount', cachedValue10);
                        console.log('%%% propertyTaxAmount2: ', cachedValue10, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                        amountsFetchedCount += 1;
                        this.setState({ isLoading: false });
                    });
                }, 1000);

            }
        });// propertyTaxAmount
        ////const hash = ComponentHelper.getLocalCacheFor(id);
        // console.log("BEFORE this.stateeeeeeeee:", this.state, "#",Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // console.log("BEFORE hashhashhash stateeeeeeeee:", hash, "#",Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        ////this.setState(hash);
        ////hash['id'] = id;
        ////this.props.onChangeAmountsFromHash('StepDougnutChart', hash);
        // console.log("this.stateeeeeeeee:", this.state, "#",Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
    }// end of fetchCalculatedFields
    timer = () => {
        let METHOD_NAME = ":timer:";
        this.setState({ isLoading: true });
        let localCurrentCount;
        console.log("Entered:Input:this.props.id:", this.props.id, " # this.state.homeInsuranceAmount:", this.state.homeInsuranceAmount, " #this.state.currentCount: ", this.state.currentCount, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);

        console.log("localAmountsHashlocalAmountsHashlocalAmountsHash:", localAmountsHash, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        console.log("%%% amountsFetchedCount: ", amountsFetchedCount, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);

        if (!this.props.id) {
            METHOD_NAME += ':this.props.id == null:';
            console.log("this.props.id == null", "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
            return;
        } else if (this.props.id) {
            amountsFetchedCount = 0;
            if(this.props.donutWasMounted < 2)
            this.fetchCalculatedFields(this.props.id);
        }
        localCurrentCount = this.state.currentCount - 1;
        console.log("localCurrentCount:", localCurrentCount, " # this.state.homeInsuranceAmount: ", this.state.homeInsuranceAmount, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);

        this.setState({
            currentCount: localCurrentCount,
        });

        if (localCurrentCount < 1) {
            this.endPromiseWithClearInterval(this.intervalId);
            this.setState({ isLoading: false });
        }
    }
    checkIfAllDataPresent = () => {
        if (!(this.props.lifeInsuranceAmount > 0) || (!(this.props.mortgageAmount > 0)) || (!(this.props.propertyTaxAmount > 0)) || (!(this.props.utilityAmount > 0)) || (!(this.props.homeInsuranceAmount > 0))) {
            this.props.onChangeItem('notEnoughDataAvailableFlag', true)
        }
    }
    componentDidMount() {
        let METHOD_NAME = ":componentDidMount:";
        console.log("this.props.calculatedAmountsFlag:", this.props.calculatedAmountsFlag, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        this.props.onChangeItem('donutWasMounted', (this.props.donutWasMounted || 0) + 1 )
        // React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
        this.intervalId = setInterval(this.timer.bind(this), 1000);
        const totalAmount = this.getTotalAmount();
        // console.log("*&* totalAmount:", totalAmount, "#",Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // console.log("*&* this.state.homeInsuranceAmount:", this.state.homeInsuranceAmount, "#",Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // // console.log("*&* totalAmount:", totalAmount, "#",Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // console.log("*&* isNaN(this.state.homeInsuranceAmount):", isNaN(this.state.homeInsuranceAmount), "#",Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // console.log("*&* isNaN(totalAmount):", isNaN(totalAmount), "#",Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);

        if (totalAmount === 0 || isNaN(totalAmount) || isNaN(this.state.homeInsuranceAmount) || this.state.homeInsuranceAmount < 2) {
            setTimeout(() => {
                amountsFetchedCount = 0;
                let inputJson = {
                    eventStr: "componentDidMount-awaitProcessCalculateAndCache", id: this.props.id, fullAddress: this.props.fullAddress, primaryResidence: 'Yes',
                    bed: this.props.bed, bath: this.props.bath, livingsizeSqft: this.props.livingsizeSqft, listingPriceAmount: this.props.listingPriceAmount
                };
                console.log("*&* Before awaitProcessCalculateAndCache:", "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
                ComponentHelper.awaitProcessCalculateAndCache(inputJson);
            }, 3000);
            // Do not call API again if all 5 values are fetched
        } else {
            this.setState({ isLoading: false });
        }
        setTimeout(this.checkIfAllDataPresent, 10000)
        // if (this.props.calculatedAmountsFlag.contains("Failed processCalculateAndCache2 for sessionId: session-1601209848709-6319")) {
        //     // React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
        //     this.intervalId = setInterval(this.timer.bind(this), 1000);
        // }
    }
    componentWillUnmount() {
        // React will be deprecating three of of their lifecycle methods soon! These are: componentWillMount, componentWillRecieveProps and componentWillUpdate.
        this.endPromiseWithClearInterval(this.intervalId);
    }
    endPromiseWithClearInterval(thisDotId) {
        console.log("endPromiseWithClearInterval:", "Entered");
        totalAmountLocal = this.getTotalAmount();
        setTimeout(() => {
            this.setState({
                isLoading: false,
                labels: _chartLabels,
                chart_title: chartTitle,
                totalAmount: totalAmountLocal,
                datasets: [{
                    data: [this.state.mortgageAmount || 0, this.state.homeInsuranceAmount || 0, this.state.utilityAmount || 0, this.state.lifeInsuranceAmount || 0, this.state.propertyTaxAmount || 0],
                    backgroundColor: _backgroundColor,
                    hoverBackgroundColor: _hoverBackgroundColor,
                    hoverBorderColor: _hoverBorderColor,
                    hoverBorderWidth: _hoverBorderWidth
                }],
            });
            clearInterval(thisDotId);
        }, 1);
    }
    componentDidUpdate(prevProps, prevState) {
        let METHOD_NAME = ":componentDidUpdate:";

        // prevState contains state before update
        // prevProps contains props before update
        if (prevState.lifeInsuranceAmount > 0 && prevState.lifeInsuranceAmount !== this.props.lifeInsuranceAmount) {
            METHOD_NAME += 'lifeInsuranceAmount !== this.props.lifeInsuranceAmount';
            console.log("prevState.lifeInsuranceAmount:", prevState.lifeInsuranceAmount, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
            // console.log("this.props.lifeInsuranceAmount:", this.props.lifeInsuranceAmount, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
            this.setState({ lifeInsuranceAmount: this.props.lifeInsuranceAmount, isLoading: false });
        }
        if (prevState.mortgageAmount > 0 && prevState.mortgageAmount !== this.props.mortgageAmount) {
            METHOD_NAME += 'mortgageAmount !== this.props.mortgageAmount';
            this.setState({ mortgageAmount: this.props.mortgageAmount, isLoading: false });
        }
        if (prevState.propertyTaxAmount > 0 && prevState.propertyTaxAmount !== this.props.propertyTaxAmount) {
            METHOD_NAME += 'propertyTaxAmount !== this.props.propertyTaxAmount';
            this.setState({ propertyTaxAmount: this.props.propertyTaxAmount, isLoading: false });
        }
        if (prevState.homeInsuranceAmount > 0 && prevState.homeInsuranceAmount !== this.props.homeInsuranceAmount) {
            METHOD_NAME += 'homeInsuranceAmount !== this.props.homeInsuranceAmount';
            this.setState({ homeInsuranceAmount: this.props.homeInsuranceAmount, isLoading: false });
        }
    }
    render() {
        let METHOD_NAME = ":render:";
        Chart.pluginService.register({
            beforeDraw: function (chart) {
                if (chart.config.options.elements.center) {
                    // Get ctx from string
                    var ctx = chart.chart.ctx;

                    // Get options from the center object in options
                    var centerConfig = chart.config.options.elements.center;
                    chart.config.options.rotation = 1 * Math.PI; 
                    chart.config.options.circumference = 1 * Math.PI
                    // following cutout decides how thin or thick the dougnut ring should be
                    chart.config.options.cutoutPercentage = 75;
                  //  chart.config.options.animation.duration = 0;
                    var fontStyle = centerConfig.fontStyle || 'Arial';
                    var txt = centerConfig.text;
                    var color = centerConfig.color || '#000';
                    var maxFontSize = centerConfig.maxFontSize || 75;
                    var sidePadding = centerConfig.sidePadding || 20;
                    var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
                    // Start with a base font of 30px
                    ctx.font = "30px " + fontStyle;

                    // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                    var stringWidth = ctx.measureText(txt).width;
                    var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

                    // Find out how much the font can grow in width.
                    var widthRatio = elementWidth / stringWidth;
                    var newFontSize = Math.floor(30 * widthRatio);
                    var elementHeight = (chart.innerRadius * 2);

                    // Pick a new font size so it will not be larger than the height of label.
                    var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                    var minFontSize = centerConfig.minFontSize;
                    var lineHeight = centerConfig.lineHeight || 25;
                    var wrapText = false;

                    if (minFontSize === undefined) {
                        minFontSize = 20;
                    }

                    if (minFontSize && fontSizeToUse < minFontSize) {
                        fontSizeToUse = minFontSize;
                        wrapText = true;
                    }

                    // Set font settings to draw it correctly.
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                    var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                    ctx.font = fontSizeToUse + "px " + fontStyle;
                    ctx.fillStyle = color;

                    if (!wrapText) {
                        ctx.fillText(txt, centerX, centerY);
                        return;
                    }

                    var words = txt.split(' ');
                    var line = '';
                    var lines = [];

                    // Break words up into multiple lines if necessary
                    for (var n = 0; n < words.length; n++) {
                        var testLine = line + words[n] + ' ';
                        var metrics = ctx.measureText(testLine);
                        var testWidth = metrics.width;
                        if (testWidth > elementWidth && n > 0) {
                            lines.push(line);
                            line = words[n] + ' ';
                        } else {
                            line = testLine;
                        }
                    }

                    // Move the center up depending on line height and number of lines
                    centerY -= (lines.length / 2) * lineHeight;

                    for (let n = 0; n < lines.length; n++) {
                        ctx.fillText(lines[n], centerX, centerY);
                        centerY += lineHeight;
                    }
                    //Draw text in center
                    ctx.fillText(line, centerX, centerY);
                }
            }
        });
        console.log("this.state:", this.state, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        totalAmountLocal = this.getTotalAmount();
        let staticText = `$${Utility.getFormattedNumberValue(totalAmountLocal)}`;
        // // != null && this.props.homeInsuranceAmount === 1 ? this.state.homeInsuranceAmount : 1;
        // console.log("0000state.homeInsuranceAmountLocal:", homeInsuranceAmountLocal, "#", Utility.getCurrentDate("db_format"), METHOD_NAME, CLASS_NAME);
        // homeInsuranceAmountLocal = this.state.homeInsuranceAmount === 1 ? this.props.homeInsuranceAmount : 1;
        // Extension
        // this.props.doughnutWidth = "75%";
        // this.props.doughnutHeight = "65%";
        // full screen
        // doughnutWidth={"65%"}
        // doughnutHeight={"65%"}
        return (
            <>  <div id="donut-sum" className="doughnut-value">{staticText}</div>
                <Doughnut
                    width={this.props.doughnutWidth}
                    height={this.props.doughnutHeight}
                    options={{ percentageInnerCutout: 57, responsive: true, maintainAspectRatio: false }, {   //eslint-disable-line
                        legend: { display: false },
                        pieceLabel: {
                            render: function (args) {
                                let returnValue = '';
                                let preFix;
                                let postFix = '';
                                preFix = args.label === 'Mortgage' ? '--' : '';
                                postFix = args.label !== 'Mortgage' ? '--' : '';
                                postFix = args.label === 'Home Insurance' ? '--' : postFix;

                                // returnValue = args.label !== '' ? preFix + args.percentage + '% ($' + args.value + ')' + postFix : '';
                                let argsPercentageLocal = Number(args.percentage).toFixed() < 1 ? '< 1' : args.percentage;
                                returnValue = args.label !== '' ? preFix + argsPercentageLocal + '% ' + postFix : '';
                                return returnValue;
                            },
                            fontColor: '#333333',
                            position: 'outside',
                            fontSize: 11,
                            segment: true,
                            showZero: true,
                            showActualPercentages: true,
                            segmentColor: '#000',
                            fontStyle: "'Lato', sans-serif",
                        },
                        responsive: true,   
                        elements: {
                            center: {
                                text: '',
                                color: '#333333', // Default is #000000
                                fontStyle: 'Arial', // Default is Arial
                                sidePadding: 10, // Default is 20 (as a percentage)
                                minFontSize: 13, // Default is 20 (in px), set to false and text will not wrap.
                                lineHeight: 15 // Default is 25 (in px), used for when text wraps
                            }
                        },      
                        title: {
                            display: true,
                        },
                        animation: false
                    }}
                    data={{
                        labels: _chartLabelsOri, datasets: [{
                            data: [this.props.mortgageAmount || 0, this.props.homeInsuranceAmount || 0, this.props.utilityAmount || 0, Number(this.props.lifeInsuranceAmount).toFixed() || this.props.lifeInsuranceAmount || 0, this.props.propertyTaxAmount || 0],
                            backgroundColor: _backgroundColor,
                            hoverBackgroundColor: _hoverBackgroundColor,
                            hoverBorderColor: _hoverBorderColor,
                            hoverBorderWidth: _hoverBorderWidth
                        }],
                    }} />
                {this.props.ext0 ?
                    <div id="ext-0-donut">
                        <BallRotate
                            color={'#7c648c'}
                            loading={this.state.isLoading}
                        /></div> :
                    <div className="row justify-content-center align-items-center">
                        <BallRotate
                            color={'#7c648c'}
                            loading={this.state.isLoading}
                        /></div>
                }
            </>
        )
    }
}
// data: [1983, 178, 81, 10, 232],